<template>
	<div>
		<div class="row">
			<div
				class="input-field filter-input col s7"
				v-if="listaStocProfile.length > 0"
			>
				<input placeholder="Cauta profil" v-model="filterInput" />
			</div>
			<div class="col s3 offset-s2 info">
				<i class="material-icons">info</i>
				<h6>Profile livrate - materie prima</h6>
			</div>
		</div>
		<div class="card-panel">
			<table class="highlight responsive-table">
				<thead v-if="listaStocProfile.length > 0">
					<tr>
						<th class="width3">Nr.</th>
						<th class="width15">Tip profil</th>
						<th>Lungime</th>
						<th>Cantitate</th>
						<th>Greutate neta</th>
						<th>Comanda</th>
						<!-- <th>Comenzi</th> -->
						<th>Incarca pe aviz</th>
						<!-- <th>Comenzi</th> -->
					</tr>
				</thead>
				<thead v-else>
					<p class="bold">Nu exista profile in stoc</p>
				</thead>
				<tbody>
					<tr
						v-for="(profil, index) in filterBy(listaStocProfile, filterInput)"
						:key="index"
					>
						<td>{{ index + 1 }}</td>
						<td>{{ profil.profil_tip }}</td>
						<td>{{ profil.cota_profil }} mm</td>
						<td>{{ profil.cantitate }} buc</td>
						<td>
							{{
								greutate(
									profil.cota_profil,
									profil.profil_greutate_ml,
									profil.cantitate
								)
							}}
							kg
						</td>
						<td v-if="!profil.comenzi && profil.comenzi_obj">
							<div v-for="item in profil.comenzi_obj" :key="item.nr">
								{{ item.nr }} | {{ item.pos }} | {{ item.subpos }}
							</div>
						</td>
						<td
							v-else-if="
								profil.comenzi &&
									profil.comenzi_obj &&
									profil.comenzi.length < profil.comenzi_obj.length
							"
						>
							<div
								v-for="item in filterComenzi(
									profil.comenzi_obj,
									profil.comenzi
								)"
								:key="item.nr"
							>
								{{ item.nr }} | {{ item.pos }} | {{ item.subpos }}
							</div>
						</td>
						<td v-else>Ramas pe stoc fara comanda</td>
						<!-- <td class="width25" v-if="profil.comenzi && profil.comenzi.length > 0"><span v-for="comanda in profil.comenzi">{{comanda.nr}} | {{comanda.pos}} | {{comanda.subpos}} &nbsp; &nbsp;</span></td>
            <td v-else> </td> -->
						<td>
							<label>
								<input
									type="checkbox"
									:value="profil.id"
									v-model="selected"
									@change="$emit('selectProfile', selected)"
								/>
								<span> </span>
							</label>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="row">
			<!-- <pre>{{listaStocProfile}}</pre> -->
		</div>
	</div>
</template>

<script>
export default {
	name: "StocProfile",
	data() {
		return {
			filterInput: "",
			selected: [],
		};
	},
	props: ["listaStocProfile"],
	methods: {
		filterBy(list, value) {
			value = value.charAt(0).toLowerCase() + value.slice(1);
			return list.filter(function(profil) {
				return profil.profil_tip.toLowerCase().indexOf(value) > -1;
			});
		},
		filterComenzi(comenzi, deb) {
			return comenzi.filter(function(item) {
				let com = deb.find((obj) => {
					return obj.nr == item.nr;
				});

				return com == undefined;
			});
		},
		greutate(cota, gml, buc) {
			return Math.round((((cota / 1000) * gml) / 1000) * buc);
		},
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.info {
	margin-top: 20px;
}
.info h6 {
	vertical-align: middle;
	margin: 0;
	display: inline-block;
}
.info i {
	vertical-align: middle;
	margin-right: 10px;
	display: inline-block;
}
</style>
