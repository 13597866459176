<template>
	<div>
		<h5 v-if="edit">Modifica model gard</h5>
		<h5 v-else>Adauga model gard</h5>
		<br />
		<form class="row" v-on:submit.prevent="addEditModelGard">
			<div class="col s6">
				<label for="denumire_model" :class="edit ? 'active' : ''"
					>Denumire</label
				>
				<input
					id="denumire_model"
					type="text"
					class="validate"
					v-model="model.denumire_model"
				/>
			</div>
			<div class="col s6">
				<label for="gol_referinta" class="active"
					>Distanta min dintre profile (mm)</label
				>
				<input
					id="gol_referinta"
					type="number"
					class="validate"
					placeholder=""
					v-model="model.min_gol"
				/>
			</div>
			<div class="col s12 mtop-10">
				<h6>Preturi (euro)</h6>
				<div class="row">
					<div class="input-field col s6">
						<label for="panou" class="active">Panou</label>
						<input
							id="panou"
							type="number"
							step="1"
							class="validate"
							placeholder="euro"
							v-model="model.pret_panou"
						/>
					</div>
					<div class="input-field col s6">
						<label for="panou_rama" class="active">Panou rama</label>
						<input
							id="panou_rama"
							type="number"
							step="1"
							class="validate"
							placeholder="euro"
							v-model="model.pret_panou_rama"
						/>
					</div>
					<div class="input-field col s6">
						<label for="poarta_mica" class="active">Poarta pietonala</label>
						<input
							id="poarta_mica"
							type="number"
							step="1"
							class="validate"
							placeholder="euro"
							v-model="model.pret_poarta_mica"
						/>
					</div>
					<div class="input-field col s6">
						<label for="poarta_mare" class="active">Poarta auto</label>
						<input
							id="poarta_mare"
							type="number"
							step="1"
							class="validate"
							placeholder="euro"
							v-model="model.pret_poarta_mare"
						/>
					</div>
					<div class="input-field col s6">
						<label for="poarta_ap" class="active">Poarta autoportanta</label>
						<input
							id="poarta_ap"
							type="number"
							step="1"
							class="validate"
							placeholder="euro"
							v-model="model.pret_poarta_mare_ap"
						/>
					</div>
				</div>
			</div>
			<div class="col s12">
				<div class="row"></div>
			</div>
			<div class="col s12 mtop-10 mbottom-10">
				<button type="submit" class="btn">Salveaza</button>
				<a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
			</div>
		</form>
		<!-- <pre>{{model}}</pre> -->
	</div>
</template>

<script>
import Vue from "vue";

export default {
	name: "AddEditGard",
	data() {
		return {
			model: "",
		};
	},
	props: ["currentModel", "edit"],
	computed: {},
	watch: {
		currentModel: function() {
			this.model = Vue.util.extend({}, this.currentModel);
		},
	},
	methods: {
		addEditModelGard() {
			if (
				!this.model.denumire_model ||
				!this.model.min_gol ||
				!this.model.pret_panou ||
				!this.model.pret_panou_rama ||
				!this.model.pret_poarta_mica ||
				!this.model.pret_poarta_mare ||
				!this.model.pret_poarta_mare_ap
			) {
				M.toast({ html: "Completati toate campurile!" });
			} else {
				if (this.edit) {
					this.$store.dispatch("editModelGard", this.model);
				} else {
					let filename = this.model.denumire_model
						.replace(/\s+/g, "")
						.toLowerCase();
					let newModel = this.model;
					newModel.image_panou = filename + "_panou.png";
					newModel.image_panou_rama = filename + "_panou_rama.png";
					newModel.image_poarta_mica = filename + "_poarta_mica.png";
					newModel.image_poarta_mare = filename + "_poarta_mare.png";

					this.$store.dispatch("addModelGard", this.model);
				}
				M.Modal.getInstance(document.querySelector(".modal-add-gard")).close();
			}
		},
	},
	created: function() {},
	mounted: function() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
