<template>
    <div>
        <span class="font-medium">
            {{ suprafataTotala }}
        </span> m²
    </div>
</template>

<script>
export default {
    props: {
        item: Object
    },
    data() {
        return {}
    },
    computed: {
        suprafataTotala() {
            return (Number(this.item.gard_obj.suprafataPanouri) + Number(this.item.gard_obj.suprafataPP) + Number(this.item.gard_obj.suprafataPA)).toFixed(2);
        }
    }
}
</script>