<template>
	<div class="container">
		<div class="flex flex-row justify-between items-center my-4">
			<h4 class="text-4xl">Setari</h4>
			<div class="">
				<p class="">
					Curs Banca Transilvania Vanzare:
					<span class="bold">1 EUR = {{ curs }} RON</span>
				</p>
			</div>
		</div>
		<div class="flex flex-col md:flex-row justify-between items-start">
			<div class="w-52 mr-4 bg-white shadow">
				<div class="side-menu">
					<ul class="tabs">
						<li class="tab">
							<a class="btn-icon" @click.prevent="changeView('materie-prima')">Materie prima</a>
						</li>
						<li class="tab">
							<a class="btn-icon" @click.prevent="changeView('setari-vanzari')">Preturi Vanzari</a>
						</li>
						<li class="tab">
							<a class="btn-icon" @click.prevent="changeView('setari-productie')">Preturi Productie</a>
						</li>
						<li class="tab">
							<a class="btn-icon" @click.prevent="changeView('garduri')">Garduri Aluminiu</a>
						</li>
						<li class="tab">
							<a class="btn-icon" @click.prevent="changeView('contracte-productie')">Contracte
								productie</a>
						</li>
						<li class="tab">
							<a class="btn-icon" @click.prevent="changeView('oameni-productie')">Oameni Productie</a>
						</li>
						<li class="tab">
							<a class="btn-icon"
								@click.prevent="changeView('setari-consumabile')">Materiale/Consumabile</a>
						</li>
						<li v-if="user.rol == 'admin'" class="tab">
							<a class="btn-icon" @click.prevent="changeView('setari-furnizori')">Furnizori</a>
						</li>
						<li v-if="user.rol == 'admin'" class="tab">
							<a class="btn-icon" @click.prevent="changeView('setari-users')">Utilizatori</a>
						</li>
					</ul>
				</div>
			</div>

			<div class="w-auto flex-1">
				<!-- Materie-prima section -->
				<div v-if="currentTab == 'materie-prima'">
					<div class="bg-white shadow px-8 mb-4">
						<ul class="tabs">
							<li class="tab col s3">
								<a href="#lista-materiale">Materiale</a>
							</li>
							<li class="tab col s3">
								<a href="#lista-produse">Produse</a>
							</li>
							<li class="tab col s3">
								<a href="#lista-aliaje">Aliaje</a>
							</li>
						</ul>
					</div>
					<div class="bg-white shadow px-8 py-4">
						<SetariMateriale id="lista-materiale" />
						<SetariProduse id="lista-produse" />
						<SetariAliaje id="lista-aliaje" />
					</div>
				</div>
				<!-- Vanzari section -->
				<div v-if="currentTab == 'setari-vanzari'">
					<div class="bg-white shadow px-8 mb-4">
						<ul class="tabs">
							<li class="tab col s3">
								<a href="#preturi-materie-prima">Preturi pag. ofertare</a>
							</li>
							<li class="tab col s3">
								<a href="#preturi-vanzari">Preturi materie prima HUB</a>
							</li>
							<li class="tab col s3">
								<a href="#preturi-debitare">Preturi debitare HUB</a>
							</li>
							<li class="tab col s3">
								<a href="#preturi-transport">Preturi transport HUB</a>
							</li>
						</ul>
					</div>
					<div class="bg-white shadow px-8 py-4">
						<SetariPreturiMateriePrima id="preturi-materie-prima" />
						<SetariVanzari id="preturi-vanzari" />
						<SetariPreturiDebitare id="preturi-debitare" />
						<SetariPreturiTransport id="preturi-transport" />
					</div>
				</div>
				<!-- Productie section -->
				<div class="bg-white shadow p-8" v-if="currentTab == 'setari-productie'">
					<ul class="tabs mbottom-20">
						<li class="tab col s3">
							<a href="#preturi-manopera">Preturi Manopera</a>
						</li>
						<li class="tab col s3">
							<a href="#preturi-vopsit">Preturi Vopsit</a>
						</li>
						<li class="tab col s3">
							<a href="#preturi-servicii">Preturi Servicii</a>
						</li>
					</ul>
					<PreturiManopera id="preturi-manopera" />
					<PreturiVopsit id="preturi-vopsit" />
					<PreturiServicii id="preturi-servicii" />
				</div>
				<!-- Garduri section -->
				<SetariGarduri v-if="currentTab == 'garduri'" />
				<!-- Contracte productie section -->
				<SetariContracteProductie v-if="currentTab == 'contracte-productie'" />
				<!-- Oameni productie section -->
				<SetariOameniProductie v-if="currentTab == 'oameni-productie'" />
				<!-- Consumabile section -->
				<SetariConsumabile v-if="currentTab == 'setari-consumabile'" />
				<!-- Furnizori section -->
				<div v-if="user.rol == 'admin' && currentTab == 'setari-furnizori'">
					<SetariFurnizori />
				</div>
				<!-- Users section -->
				<div v-if="user.rol == 'admin' && currentTab == 'setari-users'">
					<SetariUsers />
				</div>
			</div>
		</div>
		<br />
		<div>
			<v-btn to="/tests">Tests</v-btn>
		</div>
		<!-- <pre>{{user}}</pre> -->
		<!-- <pre>{{$store.state}}</pre> -->
	</div>
</template>

<script>
import SetariUsers from "@/components/Setari/SetariUsers";
import SetariMateriale from "@/components/Setari/SetariMateriale";
import SetariVanzari from "@/components/Setari/SetariVanzari";
import SetariPreturiDebitare from "@/components/Setari/SetariPreturiDebitare";
import SetariPreturiTransport from "@/components/Setari/SetariPreturiTransport";
import SetariProduse from "@/components/Setari/SetariProduse";
import SetariAliaje from "@/components/Setari/SetariAliaje";
import PreturiManopera from "@/components/Setari/PreturiManopera";
import PreturiVopsit from "@/components/Setari/PreturiVopsit";
import PreturiServicii from "@/components/Setari/PreturiServicii";
import SetariGarduri from "@/components/Setari/SetariGarduri";
import SetariContracteProductie from "@/components/Setari/SetariContracteProductie";
import SetariOameniProductie from "@/components/Setari/SetariOameniProductie";
import SetariConsumabile from "@/components/Setari/SetariConsumabile";
import SetariFurnizori from "@/components/Setari/SetariFurnizori";
import SetariPreturiMateriePrima from '@/components/Setari/SetariPreturiMateriePrima';

export default {
	name: "Setari",
	data() {
		return {
			currentTab: "materie-prima",
		};
	},
	computed: {
		loading() {
			return this.$store.getters.loading;
		},
		curs() {
			return this.$store.getters.curs;
		},
		user() {
			return this.$store.getters.loggedUser;
		}
	},
	components: {
		SetariUsers,
		SetariMateriale,
		SetariVanzari,
		SetariPreturiDebitare,
		SetariPreturiTransport,
		SetariProduse,
		SetariAliaje,
		PreturiManopera,
		PreturiVopsit,
		PreturiServicii,
		SetariGarduri,
		SetariContracteProductie,
		SetariOameniProductie,
		SetariConsumabile,
		SetariFurnizori,
		SetariPreturiMateriePrima
	},
	methods: {
		changeView(tab) {
			this.currentTab = tab;
		},
		fetchCurrency() {
			this.$store.dispatch("fetchCurrency");
		},
	},
	created: function () {
		this.fetchCurrency();
	},
	updated: function () {
		M.Tabs.init(document.querySelectorAll(".tabs"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.side-menu {
	padding: 15px 0;
}

.side-menu .tabs {
	height: auto;
}

.side-menu .tabs .tab {
	display: block;
	text-align: left;
	text-transform: capitalize;
}

.side-menu .tabs .tab a.active {
	background-color: #f5f5f5;
	/* border-bottom: 2px solid #26a69a; */
}
</style>
