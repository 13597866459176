<template>
	<div class="bg-white px-6 py-6 shadow mb-2">
		<h6 class="grey lighten-4 title font-bold">
			Firme montaj - contract
		</h6>
		<ul class="my-4">
			<FirmaMontaj
				v-for="firma in firme_montaj"
				:key="firma.id_firma"
				:firma="firma"
				:id_firma_activa="firma_montaj_activa.id_firma"
				class="border-b p-2"
			/>
		</ul>
		<!-- <h6 class="grey lighten-4 p-4 font-bold mb-6">
			Text implicit contracte productie
		</h6>
		<label for="textarea1">Textarea</label>
		<div class="input-field w-full">
			<textarea id="textarea1" rows="4" class="materialize-textarea"></textarea>
		</div> -->
		<!-- <div>{{ firma_montaj_activa }}</div> -->
	</div>
</template>

<script>
import FirmaMontaj from "@/components/Setari/SetariContracte/FirmaMontaj";

export default {
	name: "SetariContracteProductie",
	data() {
		return {};
	},
	computed: {
		loading() {
			return this.$store.getters.loading;
		},
		firme_montaj() {
			return this.$store.getters.firme_montaj;
		},
		firma_montaj_activa() {
			return this.$store.getters.firma_montaj_activa;
		},
	},
	components: {
		FirmaMontaj,
	},
	methods: {},
	created: function() {
		this.$store.dispatch("fetchFirmeMontaj");
	},
	mounted: function() {},
	updated: function() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
