<template>
	<div class="container">
		<form v-on:submit="addComenzi">
			<div class="document-header row">
				<h4 class="header col s5">Adauga comenzi</h4>
				<div class="col s2 offset-s5">
					<div class="input-field">
						<input id="data" type="date" class="validate" v-model="today" />
						<label for="data">Data</label>
					</div>
				</div>
			</div>
			<!-- document header -->
			<div class="card-panel">
				<div class="document-body">
					<table class="responsive-table comenziDeb">
						<thead>
							<tr>
								<th class="center-align">Nr.</th>
								<!-- <th>Profil nou</th> -->
								<th>Profile in stoc</th>
								<th class="center-align">Client</th>
								<th class="center-align">Comanda</th>
								<th class="center-align">pos</th>
								<th class="center-align">subpos</th>
								<th class="center-align">Lungime finala</th>
								<th class="center-align">Cantitate finala</th>
								<th class="center-align">Tol -</th>
								<th class="center-align">Tol +</th>
								<th class="center-align">Termen finalizare</th>
							</tr>
						</thead>
						<tbody v-for="(comanda, index) in comenziDeb">
							<tr>
								<td class="width5">
									<input
										type="number"
										class="validate center-align"
										v-model="comanda.nr"
									/>
								</td>
								<td class="width25">
									<model-list-select
										id="profil"
										:list="profileStoc"
										option-value="nr"
										:custom-text="profil_name"
										v-model="comanda.profil"
										placeholder="Profil | Cota | Buc"
										@change="calcScrap(index)"
									></model-list-select>
								</td>
								<td class="width10">
									<input
										type="text"
										placeholder="Client"
										class="validate center-align"
										v-model="comanda.client"
									/>
								</td>
								<td class="width10">
									<input
										type="text"
										placeholder="nr. comanda"
										class="validate center-align"
										v-model="comanda.comanda.nr"
									/>
								</td>
								<td class="width5">
									<input
										type="text"
										class="validate center-align"
										v-model="comanda.comanda.pos"
									/>
								</td>
								<td class="width5">
									<input
										type="text"
										class="validate center-align"
										v-model="comanda.comanda.subpos"
									/>
								</td>
								<td class="width75">
									<input
										type="number"
										step="1"
										placeholder="mm"
										class="validate center-align"
										v-model="comanda.lungime_finala"
									/>
								</td>
								<td class="width75">
									<input
										type="number"
										step="1"
										placeholder="buc"
										class="validate center-align"
										v-model="comanda.cantitate_finala"
									/>
								</td>
								<td class="width5">
									<input
										type="number"
										step=".1"
										class="validate center-align"
										v-model="comanda.tol_min"
									/>
								</td>
								<td class="width5">
									<input
										type="number"
										step=".1"
										class="validate center-align"
										v-model="comanda.tol_max"
									/>
								</td>
								<td class="width10">
									<input
										type="date"
										class="validate"
										v-model="comanda.termen_finalizare"
									/>
								</td>
								<td class="width3 delete" @click="deleteComandaForm(index)">
									X
								</td>
								<td></td>
							</tr>
						</tbody>
					</table>
					<br />
					<a
						class="btn blue-grey lighten-2 btn-small"
						@click="addNewComandaForm"
						>Adauga comanda</a
					>
				</div>
			</div>
			<div class="btns right">
				<router-link
					class="btn-large blue-grey lighten-2"
					to="/debitare/comenzi"
					>Anuleaza</router-link
				>
				<button type="submit" class="btn-large">Salveaza</button>
			</div>
		</form>
		<div class="row">
			<br /><br />
			<div class="col s4">
				<!-- <pre>{{profileStoc}}</pre> -->
			</div>
			<div class="col s4">
				<!-- <pre>{{$data}}</pre> -->
			</div>
		</div>
	</div>
</template>

<script>
// import { ModelSelect } from 'vue-search-select'
import { ModelListSelect } from "vue-search-select";
import moment from "moment";

export default {
	name: "AddComenziDeb",
	data() {
		return {
			today: moment().format("YYYY-MM-DD"),
			comenziDeb: [
				{
					nr: "1",
					profil: {},
					client: "",
					comanda: {
						nr: "",
						pos: "",
						subpos: "",
					},
					lungime_finala: "",
					cantitate_finala: "",
					buc_scrap: "",
					cota_scrap: "",
					rest: "",
					tol_min: "",
					tol_max: "",
					termen_finalizare: "",
				},
			],
		};
	},
	computed: {
		profileStoc() {
			return this.$store.getters.selectStocProfile;
		},
	},
	components: {
		// ModelSelect
		ModelListSelect,
	},
	methods: {
		// calcScrap(index) {
		//   var comanda = this.comenziDeb[index]
		//   let intreg = Math.trunc( Number(comanda.profil.cota_profil)/comanda.lungime_finala )
		//   let cota_scrap = Math.trunc( Number(comanda.profil.cota_profil)%comanda.lungime_finala )
		//   let buc_scrap = comanda.cantitate_finala / intreg
		//   let rest = Number(comanda.profil.cantitate) - buc_scrap
		//   this.comenziDeb[index].buc_scrap = buc_scrap
		//   this.comenziDeb[index].cota_scrap = cota_scrap
		//   this.comenziDeb[index].rest = rest
		// },
		profil_name(item) {
			return `${item.profil_tip} - ${item.cota_profil}mm - ${item.cantitate}buc`;
		},
		addNewComandaForm() {
			let nr = this.comenziDeb.length + 1;
			this.comenziDeb.push({
				nr: "" + nr + "",
				profil: {},
				client: "",
				comanda: {
					nr: "",
					pos: "",
					subpos: "",
				},
				lungime_finala: "",
				cantitate_finala: "",
				buc_scrap: "",
				cota_scrap: "",
				rest: "",
				tol_min: "",
				tol_max: "",
				termen_finalizare: "",
			});
		},
		deleteComandaForm(index) {
			if (this.comenziDeb.length > 1) {
				this.comenziDeb.splice(index, 1);
			}
		},
		addComenzi(e) {
			if (
				this.comenziDeb.find(
					(obj) =>
						this.isEmpty(obj.profil) ||
						obj.comanda.nr === "" ||
						obj.lungime_finala === "" ||
						obj.cantitate_finala === ""
				)
			) {
				M.toast({ html: "Completati toate campurile!" });
			} else {
				let self = this;
				this.comenziDeb.forEach(function(comanda) {
					let intreg = Math.trunc(
						Number(comanda.profil.cota_profil) / comanda.lungime_finala
					);
					let cota_scrap = Math.trunc(
						Number(comanda.profil.cota_profil) % comanda.lungime_finala
					);
					let buc_scrap = comanda.cantitate_finala / intreg;
					let rest = Number(comanda.profil.cantitate) - buc_scrap;
					comanda.buc_profil = intreg;
					comanda.buc_scrap = buc_scrap;
					comanda.cota_scrap = cota_scrap;
					comanda.rest = rest;

					if (
						comanda.profil.comenzi_obj === undefined ||
						comanda.profil.comenzi_obj === null
					) {
						comanda.profil.comenzi_obj = [];
					}
					let obj_comanda = {
						nr: comanda.comanda.nr,
						pos: comanda.comanda.pos,
						subpos: comanda.comanda.subpos,
						an: self.today.slice(2, 4),
					};
					comanda.profil.comenzi_obj.push(obj_comanda);

					let newComanda = {
						id_profil: comanda.profil.profil_id,
						client: comanda.client,
						comanda_obj: JSON.stringify(comanda.comanda),
						comenzi_obj: JSON.stringify(comanda.profil.comenzi_obj),
						cota_livrata: comanda.profil.cota_profil,
						stoc_id: comanda.profil.stoc_id,
						lungime_finala: comanda.lungime_finala,
						cantitate_finala: comanda.cantitate_finala,
						buc_profil: comanda.buc_profil,
						buc_scrap: comanda.buc_scrap,
						cota_scrap: comanda.cota_scrap,
						rest: comanda.rest,
						tol_min: comanda.tol_min,
						tol_max: comanda.tol_max,
						termen_finalizare: comanda.termen_finalizare,
						data_comanda: self.today,
					};
					// console.log(newComanda);
					self.$store.dispatch("addComandaDeb", newComanda);
				});
				this.$router.push({
					path: "/debitare/comenzi",
					query: M.toast({ html: "Comenzi adaugate!" }),
				});
			}
			e.preventDefault();
		},
		//General function that checks if an object is empty
		isEmpty(obj) {
			for (var key in obj) {
				if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
			}
			return true;
		},
		fetchStocProfile() {
			this.$store.dispatch("fetchStocProfile");
		},
		fetchListaDeb() {
			this.$store.dispatch("fetchListaDebitari");
		},
	},
	created: function() {
		this.fetchStocProfile();
		this.fetchListaDeb();
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table.comenziDeb tbody tr {
	border: none;
}
td label {
	margin-left: 20px;
}
td.delete {
	cursor: pointer;
	color: #e65100;
	padding-left: 15px;
}
</style>
