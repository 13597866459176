<template>
	<div class="container pt-6 pb-10">
		<div class="flex flex-col lg:flex-row justify-between items-center mb-6">
			<h1 v-if="isNull" class="page-title">Adauga module</h1>
			<h1 v-else class="page-title">Editeaza module</h1>
		</div>

		<EditModule v-if="currentOferta" :gard_obj="isNull ? module : currentOferta.gard_obj" :tip="'oferta'"
			:id="currentOferta.id_oferta" :username="currentOferta.username"></EditModule>

		<!-- <pre>{{ currentOferta }}</pre> -->
	</div>
</template>

<script>
import EditModule from "@/components/Productie/Garduri/EditModule";

export default {
	name: "OfertaGard",
	data() {
		return {
			module: [
				{
					nr: "1",
					tip: "",
					model: "",
					pp_included: false,
					denumire_model: "",
					latime_profil: "",
					distanta_gol: "",
					ranforsare: "false",
					gol_afisat: "",
					p_gol: "",
					nr_profile: "",
					filename: "",
					pret: "",
					latime: "",
					inaltime: "",
					latime_pp: "",
					buc: 1,
					suprafata: "",
					suprafata_pp: "",
					cost_modul: "",
					cost_total: "",
					marja: "",
					pret_modul: "",
				},
			],
		};
	},
	components: {
		EditModule,
	},
	computed: {
		currentOferta() {
			return this.$store.getters.currentOfertaProductie;
		},
		isNull() {
			if (this.currentOferta.gard_obj) {
				return false;
			} else {
				return true;
			}
		},
	},
	methods: {},
	created: function () {
		this.$store.dispatch("fetchOfertaProductie", this.$route.params.id);
	},
	mounted: function () { },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
