<template>
    <div class="flex flex-row items-center gap-2">
        <div>
            <span class="mr-2"
                :class="{ 'text-red-500': item.interes == 'nu e interesat', 'text-green-500 font-bold': item.interes == 'e interesat' }">{{
                    item.interes | NoData }}</span>
            <v-tooltip v-if="item.interes_details" color="teal" right>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon color="grey" dark v-bind="attrs" v-on="on">
                        mdi-information
                    </v-icon>
                </template>
                <span>{{ item.interes_details }}</span>
            </v-tooltip>
        </div>
        <v-tooltip color="teal" right>
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="teal" icon text @click.stop="selectCurrentOffer()">
                    <v-icon color="grey" dark v-bind="attrs" v-on="on">
                        mdi-pencil
                    </v-icon>
                </v-btn>
            </template>
            <span>Schimba status interes</span>
        </v-tooltip>
    </div>
</template>

<script>
export default {
    props: {
        item: Object
    },
    data() {
        return {
            currentOfferID: null,
            currentInteresStatus: null,
            currentInteresDetails: null
        }
    },
    methods: {
        selectCurrentOffer() {
            this.$emit('openModal', { id: this.item.id_cerere, status: this.item.interes, details: this.item.interes_details, returnDate: this.item.returnDate })
        },
    }
}
</script>