<template>
	<div class="container pt-6 pb-10">
		<div class="flex justify-between items-center py-2 mb-4">
			<h1 class="page-title">Comenzi finalizate</h1>
			<div class="flex flex-row flex-grow-0 gap-4">
				<v-btn to="/comenzi" color="blue" class="uppercase" dark>
					Comenzi active
				</v-btn>
			</div>
		</div>

		<div class="flex flex-row justify-end mb-4">
			<div class="w-60 monthpicker">
				<span>Selecteaza luna: </span>
				<vue-monthly-picker class="month-picker" :monthLabels="monthLabels" dateFormat="MMMM YYYY"
					:clearOption="clear" v-model="selectedMonth" @selected="fetchComenzi"></vue-monthly-picker>
			</div>
		</div>

		<v-toolbar flat color="light-grey" class="rounded mb-4">
			<div class="flex flex-row justify-between items-center gap-10">
				<div class="w-1/2 flex flex-row justify-start items-center flex-grow-0 gap-2">
					<v-text-field v-model="filterInput" clearable flat solo hide-details
						prepend-inner-icon="mdi-magnify" label="Cauta oferta dupa client"></v-text-field>
					<!-- <v-text-field v-model="searchClient" clearable flat solo hide-details @keydown.enter="searchOffers"
						@click:clear="resetInitialOffers" prepend-inner-icon="mdi-magnify"
						label="Cauta oferta dupa client">
					</v-text-field>
					<v-btn color="blue-grey" class="uppercase" large dark @click="searchOffers">Cauta</v-btn> -->
				</div>
				<div class="flex justify-end items-center flex-grow-0 gap-2">
					<v-select ref="selectAgent" v-model="filterAgent" class="w-60" :items="agents" item-text="username"
						item-value="id_user" :clearable="['admin'].includes(user.rol)"
						@click:clear="$refs.selectAgent.blur()" label="Intocmit de" solo hide-details></v-select>
					<!-- <div class="w-60">
						<v-menu v-model="monthpickerMenu" class="w-60" :close-on-content-click="false" :nudge-left="40"
							transition="scale-transition" offset-y min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-model="monthText" class="date-field"
									:class="{ 'opacity-60': isOffersSearchList }" label="Luna" solo
									prepend-inner-icon="mdi-calendar" hide-details readonly v-bind="attrs"
									v-on="on"></v-text-field>
							</template>
<v-date-picker v-model="selectedMonth" no-title type="month" @input="changeMonth"></v-date-picker>
</v-menu>
</div> -->

				</div>
			</div>
		</v-toolbar>

		<div class="card-panel">
			<table class="highlight responsive-table">
				<thead v-if="comenzi.length > 0">
					<tr>
						<th class="width3">Nr.</th>
						<th class="width15">Data comanda</th>
						<th>Nume Client</th>
						<th>Valoare</th>
						<th>Ultimul status trimis</th>
						<th>Firma</th>
						<th>Intocmit de</th>
						<th class="center-align">Documente</th>
					</tr>
				</thead>
				<thead v-else>
					<p class="bold">Nu exista comenzi finalizate</p>
				</thead>
				<tbody>
					<tr v-for="comanda in filterBy(
					comenzi,
					filterInput,
					filterAgent,
					filterFirma
				)">
						<td>{{ comanda.id_comanda }}</td>
						<td>{{ comanda.data | formatDate }}</td>
						<td>{{ comanda.nume }}</td>
						<td>{{ comanda.suma }} {{ comanda.moneda }}</td>
						<td>{{ comanda.ultimul_status | formatDateTime | NoData }}</td>
						<td v-if="comanda.firma == '1'">Cuprolli Manufacturing</td>
						<td v-else-if="comanda.firma == '2'">Cuprolli Construction</td>
						<td v-else>Cuprolli Trading</td>
						<td>{{ comanda.username }}</td>
						<td class="status center-align width10">
							<i v-if="comanda.ok_contract == 1" class="green-text material-icons">description</i>
							<i v-else-if="comanda.contract == 1" class="orange-text material-icons">description</i>
							<i v-else class="grey-text text-lighten-1 material-icons">description</i>
							<i v-if="comanda.ok_factura == 1" class="green-text material-icons">attach_money</i>
							<i v-else-if="comanda.factura == 1" class="orange-text material-icons">attach_money</i>
							<i v-else class="grey-text text-lighten-1 material-icons">attach_money</i>
							<i v-if="comanda.ok_awb == 1" class="green-text material-icons">local_shipping</i>
							<i v-else-if="comanda.awb == 1" class="orange-text material-icons">local_shipping</i>
							<i v-else class="grey-text text-lighten-1 material-icons">local_shipping</i>
						</td>
						<td class="center-align">
							<router-link class="btn blue-grey lighten-2 btn-small"
								v-bind:to="'/comenzi/single/' + comanda.id_comanda">Detalii</router-link>
						</td>
						<td class="status blue-grey center-align width10">
							Comanda {{ comanda.stare }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="legend right">
			<ul>
				<li>
					<i class="green-text material-icons">label</i><label>Document verificat</label>
				</li>
				<li>
					<i class="orange-text material-icons">label</i><label>Document incarcat dar neverificat</label>
				</li>
				<li>
					<i class="grey-text material-icons">label</i><label>Document inexistent</label>
				</li>
			</ul>
		</div>
		<!-- <pre>{{user}}</pre> -->
		<!-- <pre>{{$data}}</pre> -->
	</div>
</template>

<script>
import firebase from "firebase/app";
import moment from "moment";
import VueMonthlyPicker from "vue-monthly-picker";
// import { StatusIndicator } from 'vue-status-indicator'

export default {
	name: "ComenziFinalizate",
	data() {
		return {
			user_email: firebase.auth().currentUser.email,
			selectedMonth: moment(),
			selectedUser: null,
			monthLabels: [
				"ian",
				"feb",
				"mar",
				"apr",
				"mai",
				"iun",
				"iul",
				"aug",
				"sep",
				"oct",
				"noi",
				"dec",
			],
			filterInput: "",
			filterFirma: 0,
			clear: false,
		};
	},
	computed: {
		comenzi() {
			return this.$store.getters.comenziFinalizate;
		},
		user() {
			return this.$store.getters.loggedUser;
		},
		firstday() {
			return this.selectedMonth.startOf("month").format("YYYY-MM-DD");
		},
		lastday() {
			return this.selectedMonth.endOf("month").format("YYYY-MM-DD");
		},
		filterAgent: {
			get: function () {
				return this.selectedUser
			},
			// setter
			set: function (newValue) {
				this.selectedUser = newValue
			},
		},
		agents() {
			let array = [];
			let user = {};
			let self = this;
			this.comenzi.forEach(function (comanda) {
				user = {
					id_user: comanda.id_user,
					username: comanda.username,
				};
				if (self.containsObject(user, array)) {
					return;
				} else {
					array.push(user);
				}
			});
			return array.filter(this.onlyUnique);
		},
	},
	components: {
		VueMonthlyPicker,
	},
	methods: {
		fetchComenzi() {
			this.$store.dispatch("fetchComenziFinalizate", {
				date1: this.firstday,
				date2: this.lastday,
			});
		},
		fetchUser() {
			let mail = this.user_email;
			this.$store.dispatch("fetchUser", mail);
		},
		filterBy(list, value, agent, firma) {
			value = value.toLowerCase();
			// See if current user has comenzi
			let has = this.agents.find(function (item) {
				return item.id_user == agent;
			});
			//filter different cases
			return list.filter((comanda) => {
				if (has == undefined) {
					return comanda.nume.toLowerCase().indexOf(value) > -1;
				} else if (firma == 0) {
					return (
						comanda.id_user.indexOf(agent) > -1 &&
						comanda.nume.toLowerCase().indexOf(value) > -1
					);
				} else {
					return (
						comanda.id_user.indexOf(agent) > -1 &&
						comanda.nume.toLowerCase().indexOf(value) > -1 &&
						comanda.firma.indexOf(firma) > -1
					);
				}
			});
		},
		onlyUnique(value, index, self) {
			return self.indexOf(value) === index;
		},
		containsObject(obj, list) {
			var i;
			for (i = 0; i < list.length; i++) {
				if (list[i].id_user === obj.id_user) {
					return true;
				}
			}
			return false;
		},
	},
	created: function () {
		this.fetchComenzi();
		this.fetchUser();
	},
	mounted: function () {
		if (this.user.rol == 'admin') {
			this.selectedUser = null
		} else {
			this.selectedUser = this.user.id_user
		}
	},
	updated: function () {
		M.FormSelect.init(document.querySelectorAll("select"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.legend {
	margin-right: 184px;
}

.legend i {
	vertical-align: middle;
	margin-right: 10px;
}

.monthpicker span {
	color: #9e9e9e;
	font-size: 0.8rem;
}

.vue-monthly-picker .input {
	background-color: transparent;
	border: none;
	box-shadow: none;
}

.vue-monthly-picker .month-year-label {
	border-bottom: 1px solid #9e9e9e;
}
</style>
