<template>
	<div>
		<v-card v-for="(modul, index) in gard_obj" class="flex justify-between mb-6" :key="index">
			<div class="w-10 flex justify-center items-center flex-grow-0 px-2 bg-gray-100">
				<span class="font-medium text-2xl">{{ index + 1 }}</span>
			</div>
			<EditModul :modul="modul" :modeleGard="modeleGard" :username="username" class="flex-grow"></EditModul>
			<div class="w-10 flex justify-center items-center flex-grow-0 px-1 hover:bg-red-100">
				<span class="delete-icon" @click="deleteModul(index)">
					<i class="material-icons grey-text">delete_forever</i>
				</span>
			</div>
		</v-card>
		<div class="flex flex-col lg:flex-row justify-between items-start">
			<a class="btn blue-grey lighten-2" @click="addNewModul">Adauga modul nou</a>
			<div class="btn-group">
				<router-link class="btn btn-large blue-grey lighten-2"
					:to="'/oferte-productie/single/' + id">Anuleaza</router-link>
				<button class="btn btn-large" @click="saveModuleGard()">
					Salveaza
				</button>
			</div>
		</div>
		<!-- <pre>Cost: {{cost_fara_tvaG}}</pre> -->
		<!-- <pre>Pret: {{val_fara_tvaG}}</pre> -->
		<!-- <pre>{{ gard_obj }}</pre> -->
		<!-- <pre>{{$data}}</pre> -->
		<!-- <pre>{{ modeleGard }}</pre> -->
	</div>
</template>

<script>
import EditModul from "@/components/Productie/Garduri/EditModul";

export default {
	name: "EditModule",
	data() {
		return {};
	},
	props: ["gard_obj", "tip", "id", "username"],
	components: {
		EditModul,
	},
	computed: {
		modeleGard() {
			return this.$store.getters.modele_gard;
		},
		cost_fara_tvaG() {
			if (this.gard_obj.length > 1) {
				let cost = this.gard_obj.reduce(function (a, b) {
					return { cost_total: a.cost_total + b.cost_total };
				});
				return cost.cost_total;
			} else {
				return this.gard_obj[0].cost_total;
			}
		},
		val_fara_tvaG() {
			if (this.gard_obj.length > 1) {
				let pret = this.gard_obj.reduce(function (a, b) {
					return { pret_modul: a.pret_modul + b.pret_modul };
				});
				return pret.pret_modul;
			} else {
				return this.gard_obj[0].pret_modul;
			}
		},
	},
	methods: {
		addNewModul() {
			let nr = this.gard_obj.length + 1;
			this.gard_obj.push({
				nr: "" + nr + "",
				tip: "",
				model: "",
				pp_included: false,
				denumire_model: "",
				latime_profil: "",
				distanta_gol: "",
				ranforsare: "false",
				gol_afisat: "",
				p_gol: "",
				nr_profile: "",
				filename: "",
				pret: "",
				latime: "",
				inaltime: "",
				latime_pp: "",
				buc: 1,
				suprafata: "",
				suprafata_pp: "",
				cost_modul: "",
				cost_total: "",
				marja: "",
				pret_modul: "",
			});
		},
		deleteModul(index) {
			// console.log(index);
			M.FormSelect.init(document.querySelectorAll("select"));
			if (this.gard_obj.length > 1) {
				this.gard_obj.splice(index, 1);
			}
		},
		saveModuleGard() {
			let newGard = {
				gard_obj: JSON.stringify(this.gard_obj),
				cost_fara_tvaG: this.cost_fara_tvaG,
				val_fara_tvaG: this.val_fara_tvaG,
				tip: this.tip,
				id: this.id,
			};
			this.$store.dispatch("saveModuleGard", newGard);
		},
	},
	created: function () {
		this.$store.dispatch("fetchModeleGard");
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#model>input {
	margin-bottom: 8px;
}

.delete-icon i:hover {
	cursor: pointer;
	color: red !important;
}
</style>
