<template>
	<div>
		<v-menu offset-y :close-on-content-click="true">
			<template v-slot:activator="{ on, attrs }">
				<v-btn color="blue-grey" class="uppercase" dark v-bind="attrs" v-on="on">
					<v-icon>mdi-file-pdf-box</v-icon>
					Oferta
				</v-btn>
			</template>
			<v-list>
				<v-list-item link @click="$emit('download')">
					<v-icon class="mr-1">mdi-download-outline</v-icon>
					Descarca
				</v-list-item>
				<v-list-item link @click="visibleEmailModal = true">
					<v-icon class="mr-1">mdi-email-outline</v-icon>
					Trimite pe email
				</v-list-item>
			</v-list>
		</v-menu>
		<v-dialog v-model="visibleEmailModal" max-width="600px">
			<v-card class="rounded">
				<v-card-title>Trimite oferta PDF</v-card-title>
				<v-card-text>
					<div v-if="oferta.email">
						<p class="text-lg">Email client: <span class="font-semibold">{{ oferta.email
								}}</span></p>
						<p v-if="oferta.cc_obj != undefined && oferta.cc_obj.length > 0" class="text-lg">
							CC:
							<span v-for="item, idx in oferta.cc_obj" :key="item" class="font-semibold">{{ item }}<span
									v-if="idx != oferta.cc_obj.length - 1">, </span></span>
						</p>
					</div>
					<v-alert v-else text prominent type="error" icon="mdi-email">
						Clientul nu are adresa de email completata!
					</v-alert>
				</v-card-text>
				<div class="flex flex-row justify-end gap-2 mt-4 pr-4 pb-4">
					<v-btn color="blue-grey" class="uppercase" dark @click="visibleEmailModal = false">Anuleaza</v-btn>
					<v-btn color="teal" dark class="uppercase" :disabled="!oferta.email" :loading="sendingEmail"
						@click="$emit('sendEmail')">Trimite</v-btn>
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>

export default {
	props: {
		oferta: Object,
		sendingEmail: Boolean
	},
	data() {
		return {
			visibleEmailModal: false
		};
	},
	components: {},
	computed: {},
	watch: {
		sendingEmail(newVal, oldVal) {
			if (newVal != oldVal) {
				if (!newVal) {
					this.visibleEmailModal = false
				}
			}
			return
		}
	},
	methods: {},
	created() { },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
