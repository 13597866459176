<template>
	<div class="container">
		<router-link class="btn blue-grey lighten-2 back-btn left" to="/debitare"
			>Inapoi</router-link
		>
		<!-- <router-link class="btn right" to="/oferte/add">Avize</router-link> -->
		<h4 class="header">Avize</h4>
		<br />
		<div class="card-panel">
			<table class="highlight responsive-table">
				<thead>
					<tr>
						<th>Nr. aviz</th>
						<th>Data aviz</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(aviz, index) in avize" :key="index">
						<td>{{ aviz.nr_aviz }}</td>
						<td>{{ aviz.data | formatDate }}</td>
						<td class="center-align">
							<router-link
								class="btn blue-grey lighten-2 btn-small"
								v-bind:to="'/debitare/avize/single/' + aviz.id_aviz"
								>Detalii</router-link
							>
						</td>
						<!-- <td class="center-align"><button class="btn grey darken-1" @click="createPDF(1, index)"><i class="material-icons left">file_download</i>PDF</button></td> -->
					</tr>
				</tbody>
			</table>
		</div>
		<!-- <pre>{{avize}}</pre> -->
	</div>
</template>

<script>
import moment from "moment";
import jsPDF from "jspdf";

export default {
	name: "Avize",
	data() {
		return {
			today: moment(),
		};
	},
	computed: {
		avize() {
			return this.$store.getters.avize;
		},
	},
	watch: {},
	methods: {
		createPDF(de, index) {
			let arr = this.avize[index];
			let nr_aviz = arr.id_aviz;
			let pdfName = "Aviz_nr" + nr_aviz + "_" + this.today.format("DD_MM_YYYY");
			var doc = new jsPDF();

			doc.setFont("arial");
			doc.setFontStyle("normal");
			doc.setFontSize(9);
			doc.setTextColor(100);

			//Document header
			doc.setFontStyle("bold");
			doc.text("Furnizor: ", 10, 15);
			doc.text("CUI: ", 10, 20);
			doc.text("Nr. ONRC: ", 10, 25);
			doc.text("Sediu/Pct. lucru: ", 10, 30);
			doc.setFontStyle("normal");
			doc.text("Cuprolli Manufacturing srl", 25, 15);
			doc.text("RO35671874", 18, 20);
			doc.text("J35/468/2016", 27, 25);
			doc.text("Dumbravita / Ghiroda", 36, 30);
			doc.text("Str. Caprioarei, nr.11, jud. Timis", 10, 35);

			doc.setFontStyle("bold");
			doc.text("Cumparator: ", 130, 15);
			doc.text("CUI: ", 130, 20);
			doc.text("Nr. ONRC: ", 130, 25);
			doc.text("Sediu/Pct. lucru: ", 130, 30);
			doc.setFontStyle("normal");
			doc.text("Hydro Extrusion srl", 150, 15);
			doc.text("RO16344329", 138, 20);
			doc.text("J02/682/2004", 147, 25);
			doc.text("Chisineu-Cris, jud. Arad", 156, 30);
			doc.text("Str. Tudor Vladimirescu, FN, DN79/A", 130, 35);

			//center v line
			//doc.line(105, 40, 105, 60)

			doc.setFontSize(13);
			doc.text("AVIZ DE INSOTIRE A MARFII", 75, 60);
			doc.setFontSize(9);
			doc.setTextColor(100);
			doc.text("Seria CM", 90, 65);
			doc.text("Nr. " + nr_aviz, 110, 65);
			doc.text("Data: " + this.today.format("DD.MM.YYYY"), 93, 70);

			let space = 86;

			// Products table
			if (arr.obj_mat) {
				doc.setFontStyle("bold");
				doc.text("MATERIALE DEBITATE ", 10, space);
				space = space + 10;
				//Table head
				doc.text("Nr.", 10, space);
				doc.text("Denumire produs", 18, space);
				doc.text("U.M.", 100, space);
				doc.text("Cantitate", 113, space);
				doc.text("Pachete", 135, space);
				doc.text("Stadiu comanda", 175, space);
				doc.setDrawColor(150);
				doc.setLineWidth(0.4);
				doc.line(10, space + 2, 200, space + 2);
				//Table lines (content)
				space = space + 7;
				doc.setFontStyle("normal");
				let nr = 1;
				arr.obj_mat.forEach((item) => {
					doc.text(String(nr), 11, space);
					doc.text(item.denumire_produs, 19, space);
					doc.text("buc", 101, space);
					doc.text(String(item.cantitate), 117, space);
					doc.text(item.pachete, 135, space);
					doc.text(item.status, 182, space);
					doc.setLineWidth(0.2);
					doc.line(10, space + 3, 200, space + 3);
					nr++;
					space = space + 8;
				});
			}

			space = space + 20;
			// Profile table
			if (arr.obj_profile) {
				doc.setFontStyle("bold");
				doc.text("PROFILE ", 10, space);
				space = space + 10;
				//Table head
				doc.text("Nr.", 10, space);
				doc.text("Denumire produs", 18, space);
				doc.text("U.M.", 100, space);
				doc.text("Cantitate", 113, space);
				doc.text("Greutate", 145, space);
				doc.setDrawColor(150);
				doc.setLineWidth(0.4);
				doc.line(10, space + 2, 200, space + 2);
				//Table lines (content)
				space = space + 7;
				doc.setFontStyle("normal");
				let nr = 1;
				arr.obj_profile.forEach((item) => {
					doc.text(String(nr), 11, space);
					doc.text(item.denumire_produs, 19, space);
					doc.text("buc", 101, space);
					doc.text(String(item.cantitate), 117, space);
					doc.text(item.greutate + " kg", 146, space);
					doc.setLineWidth(0.2);
					doc.line(10, space + 3, 200, space + 3);
					nr++;
					space = space + 8;
				});
			}

			space = space + 20;
			// Scrap table
			if (arr.obj_scrap) {
				doc.setFontStyle("bold");
				doc.text("SCRAP ", 10, space);
				space = space + 10;
				//Table head
				doc.text("Nr.", 10, space);
				doc.text("Denumire produs", 18, space);
				doc.text("U.M.", 100, space);
				doc.text("Cantitate", 113, space);
				doc.text("Greutate", 145, space);
				doc.setDrawColor(150);
				doc.setLineWidth(0.4);
				doc.line(10, space + 2, 200, space + 2);
				//Table lines (content)
				space = space + 7;
				doc.setFontStyle("normal");
				let nr = 1;
				arr.obj_scrap.forEach((item) => {
					doc.text(String(nr), 11, space);
					doc.text(item.denumire_produs, 19, space);
					doc.text("buc", 101, space);
					doc.text(String(item.cantitate), 117, space);
					doc.text(item.greutate + " kg", 146, space);
					doc.setLineWidth(0.2);
					doc.line(10, space + 3, 200, space + 3);
					nr++;
					space = space + 8;
				});
			}

			doc.text(String(space), 10, space);

			if (space > 200) {
				doc.setFontStyle("normal");
				doc.text("continuare pe pagina urmatoare", 10, 270);
				doc.text("Page 1", 100, 290);
				doc.addPage();
				space = 10;
			}

			// Print PDF
			if (de == 1) {
				doc.save(pdfName + ".pdf");
			}
			//else if (de == 2){
			//   var pdf = btoa(doc.output());
			//   // Send doc to php script for emailing forward
			//   this.$http.post('http://hub.cuprolli.ro/app_back/mail.php', {data: pdf, client_mail: this.oferta.email})
			//     .then(function(response){
			//       M.toast({html: 'Oferta trimisa!'})
			//     })
			// }
		},
		fetchAvize() {
			this.$store.dispatch("fetchAvize");
		},
	},
	created: function() {
		this.fetchAvize();
	},
	updated: function() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
