<template>
	<div v-if="loading" class="progress container mtop-20">
		<div class="indeterminate"></div>
	</div>
	<div v-else class="container">
		<div class="flex flex-row justify-between items-center my-4">
			<h4 class="text-4xl">Lucrari Productie</h4>
		</div>
		<div class="card-panel">
			<div class="input-field filter-input col s3">
				<input placeholder="Cauta lucrare dupa client" v-model="filterInput" />
			</div>
			<table class="highlight responsive-table">
				<thead>
					<tr>
						<th>Nr.</th>
						<th>Data lucrare</th>
						<th>Client</th>
						<th>Judet</th>
						<th>MP lucrare</th>
						<th>Timp executie</th>
						<th>Programare</th>
						<th>Intocmit de</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="lucrare in filterBy(lucrari, filterInput)">
						<td>{{ lucrare.id_lucrare }}</td>
						<td>{{ lucrare.data | formatDate }}</td>
						<td>{{ lucrare.nume }}</td>
						<td>{{ lucrare.oras }}</td>
						<td>-</td>
						<td>{{ lucrare.t_executie }} <span>{{ lucrare.t_executie == 1 ? 'zi' : 'zile' }}</span></td>
						<td v-if="lucrare.start && lucrare.end">
							{{ lucrare.start | formatDate }} -
							{{ endLucrareDate(lucrare.end) }}
						</td>
						<td v-else class="red-text">Nu este programata</td>
						<td>{{ lucrare.username }}</td>
						<td>
							<router-link class="btn blue-grey lighten-2 right btn-small"
								v-bind:to="'/lucrari-productie/single/' + lucrare.id_lucrare">Detalii</router-link>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<!-- <pre>{{lucrari}}</pre> -->
		<!-- <pre>{{$data}}</pre> -->
	</div>
</template>

<script>
import moment from "moment-business-days";

export default {
	name: "LucrariProductie",
	data() {
		return {
			filterInput: "",
		};
	},
	computed: {
		lucrari() {
			return this.$store.getters.lucrari;
		},
		loading() {
			return this.$store.getters.loading;
		},
	},
	methods: {
		filterBy(list, value) {
			value = value.charAt(0).toLowerCase() + value.slice(1);
			return list.filter(function (lucrare) {
				return lucrare.nume.toLowerCase().indexOf(value) > -1;
			});
		},
		endLucrareDate(date) {
			let newDate = moment(date, "YYYY-MM-DD")
				.businessSubtract(1)
				.format("DD/MM/YYYY");
			return newDate;
		},
	},
	created: function () {
		this.$store.dispatch("fetchLucrari");
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
