<template>
	<div class="container">
		<form v-on:submit="addAviz">
			<div class="document-header row">
				<h4 class="header col s5">Adauga profile in stoc</h4>
				<div class="col s2 offset-s3">
					<div class="input-field">
						<input id="aviz" type="text" class="validate" v-model="nr_aviz" />
						<label for="aviz">Nr. aviz</label>
					</div>
				</div>
				<div class="col s2">
					<div class="input-field">
						<input id="data" type="date" class="validate" v-model="today" />
						<label for="data">Data</label>
					</div>
				</div>
			</div>
			<!-- document header -->
			<div class="card-panel">
				<div class="document-body">
					<h5 class="header inline-title">Profile</h5>
					<div class="btns right">
						<a class="btn blue-grey modal-trigger" href="#addcote"
							>Adauga cote profil</a
						>
						<a class="btn modal-trigger" href="#addprofil">Adauga profil nou</a>
					</div>
					<table class="responsive-table materiale">
						<thead>
							<tr>
								<th class="center-align">Nr.</th>
								<!-- <th>Profil nou</th> -->
								<th>Profil</th>
								<th>Lungime</th>
								<th class="center-align">Cantitate</th>
							</tr>
						</thead>
						<tbody v-for="(material, index) in materiale">
							<tr>
								<td class="width5">
									<input
										type="number"
										class="validate center-align"
										v-model="material.nr"
									/>
								</td>
								<!-- <td class="width75">
                  <label>
                    <input type="checkbox" v-model="material.new"/>
                    <span> </span>
                  </label>
                </td> -->
								<td class="width15">
									<model-select
										id="profil"
										:options="profile"
										v-model="material.profil"
										placeholder="Alege profil"
										@input="setCote(material.profil.value, index)"
									></model-select>
								</td>
								<!-- <td v-else class="width15"><input type="text" placeholder='Nume profil' class="validate" v-model="material.profil.text"></td> -->
								<td class="width10">
									<model-select
										id="cote"
										:options="material.cote"
										v-model="material.lungime"
										placeholder="Lungime"
									></model-select>
								</td>
								<!-- <td v-else class="width10"><input type="number" step='1' placeholder='mm' class="validate center-align" v-model="material.lungime"></td> -->
								<td class="width75">
									<input
										type="number"
										step="1"
										placeholder="buc"
										class="validate center-align"
										v-model="material.cantitate"
									/>
								</td>
								<td class="width3 delete" @click="deleteMaterialForm(index)">
									X
								</td>
								<td></td>
							</tr>
						</tbody>
					</table>
					<br />
					<a
						class="btn blue-grey lighten-2 btn-small"
						@click="addNewMaterialForm"
						>Adauga profil</a
					>
					<span class="padd-left-100">Nr. total profile: {{ nr_profile }}</span>
				</div>
			</div>
			<div class="btns right">
				<router-link
					class="btn-large blue-grey lighten-2"
					to="/debitare/stocuri"
					>Anuleaza</router-link
				>
				<button type="submit" class="btn-large">Salveaza</button>
			</div>
		</form>

		<!--  Modal begin -->
		<div id="addprofil" class="modal modal-addprofil">
			<div class="modal-content">
				<h5 class="header">Adauga profil nou</h5>
				<div class="card-panel">
					<div class="row">
						<div class="input-field col s2">
							<input
								id="tip"
								type="text"
								class="validate"
								v-model="profil.tip"
							/>
							<label for="tip">Denumire profil</label>
						</div>
						<div class="input-field col s2">
							<input
								id="greutateML"
								type="number"
								step=".01"
								class="validate"
								v-model="profil.greutate_ml"
							/>
							<label for="greutateML">Greutate / ML (g)</label>
						</div>
						<div class="input-field col s2">
							<input
								id="bucpac"
								type="number"
								class="validate"
								v-model="profil.bucpac"
							/>
							<label for="bucpac">Buc / pachet</label>
						</div>
					</div>
					<div class="row">
						<div class="col s4">
							<h6>Adauga cota</h6>
							<div class="input-field col s6">
								<input
									id="cota"
									type="text"
									class="validate"
									v-model="new_cota.cota"
								/>
								<label for="cota">cota (mm)</label>
							</div>
							<div class="input-field col s5">
								<input
									id="pret"
									type="number"
									step="0.01"
									class="validate"
									v-model="new_cota.pret"
								/>
								<label for="pret">pret (eur)</label>
							</div>
							<div class="input-field col s1">
								<span
									><button class="btn" @click.prevent="addCota(1)">
										+
									</button></span
								>
							</div>
						</div>
						<ul class="cote col s7 offset-s1">
							<h6>Cote:</h6>
							<span v-if="cote === undefined || cote.length == 0"
								>Adaugati cote</span
							>
							<li v-for="(item, index) in cote">
								{{ item.cota }} mm - {{ item.pret }} eur
								<span class="delete" @click.prevent="deleteCota(1, index)"
									>X</span
								>
							</li>
						</ul>
					</div>
				</div>
				<div class="btns right-align">
					<a href="#!" class="modal-action modal-close btn-large blue-grey"
						>Anuleaza</a
					>
					<button class="btn-large modal-close" v-on:click="addNewProfil()">
						Adauga
					</button>
					<br />
				</div>
			</div>
		</div>
		<!--  Modal end -->
		<!--  Modal begin -->
		<div id="addcote" class="modal modal-addcote">
			<div class="modal-content">
				<h5 class="header">Editeaza cote profil existent</h5>
				<div class="card-panel">
					<div class="row">
						<div class="input-field col s3">
							<model-select
								id="profil"
								:options="profile"
								v-model="editProfil"
								placeholder="Alege profil"
								@input="fetchProfil(editProfil.value)"
							></model-select>
						</div>
					</div>
					<!-- <div class="row">
            <div class="input-field col s2">
              <input id="greutateML" type="number" step=".01" class="validate" v-model="profiltoedit.profil_greutate_ml">
              <label class="active" for="greutateML">Greutate / ML (g)</label>
            </div>
            <div class="input-field col s2">
              <input id="bucpac" type="number" class="validate" v-model="profiltoedit.profil_bucpac">
              <label class="active" for="bucpac">Buc / pachet</label>
            </div>
          </div> -->
					<div class="row">
						<div class="col s4">
							<h6>Adauga cota</h6>
							<div class="input-field col s6">
								<input
									id="cotaedit"
									type="text"
									class="validate"
									v-model="new_edit_cota.cota"
								/>
								<label for="cotaedit">cota (mm)</label>
							</div>
							<div class="input-field col s5">
								<input
									id="pretedit"
									type="number"
									step="0.01"
									class="validate"
									v-model="new_edit_cota.pret"
								/>
								<label for="pretedit">pret (eur)</label>
							</div>
							<div class="input-field col s1">
								<span
									><button class="btn" @click.prevent="addCota(2)">
										+
									</button></span
								>
							</div>
						</div>
						<ul class="cote col s7 offset-s1">
							<h6>Cote:</h6>
							<span
								v-if="
									profiltoedit.profil_cote === undefined ||
										profiltoedit.profil_cote.length == 0
								"
								>Adaugati cote</span
							>
							<li
								v-for="(item, index) in profiltoedit.profil_cote"
								:key="index"
							>
								{{ item.cota }} mm - {{ item.pret }} eur
								<span class="delete" @click.prevent="deleteCota(2, index)"
									>X</span
								>
							</li>
						</ul>
					</div>
				</div>
				<div class="btns right-align">
					<a href="#!" class="modal-action modal-close btn-large blue-grey"
						>Anuleaza</a
					>
					<button
						class="btn-large modal-close"
						v-on:click="editCote(editProfil.value)"
					>
						Salveaza
					</button>
					<br />
				</div>
			</div>
		</div>
		<!--  Modal end -->
		<!-- <pre>{{profiltoedit}}</pre> -->
		<!-- <pre>{{$data}}</pre> -->
		<!-- <pre>{{nr_profile}}</pre> -->
	</div>
</template>

<script>
import { ModelSelect } from "vue-search-select";
import moment from "moment";

export default {
	name: "AddProfileStoc",
	data() {
		return {
			today: moment().format("YYYY-MM-DD"),
			nr_aviz: "",
			profil: {},
			editProfil: {},
			cote: [],
			new_cota: {},
			new_edit_cota: {},
			materiale: [
				{
					nr: "1",
					profil: {},
					lungime: "",
					cantitate: "",
					cote: [],
				},
			],
		};
	},
	computed: {
		profile() {
			return this.$store.getters.selectProfile;
		},
		profiltoedit() {
			return this.$store.getters.profil;
		},
		nr_profile() {
			let nr = 0;
			this.materiale.forEach(function(mat) {
				nr = nr + Number(mat.cantitate);
			});
			return nr;
		},
	},
	components: {
		ModelSelect,
	},
	methods: {
		addCota(a) {
			if (a == 1) {
				if (this.new_cota.cota !== "" || this.new_cota.pret !== "") {
					this.cote.push(this.new_cota);
					function sortNumber(a, b) {
						return a.cota - b.cota;
					}
					this.cote.sort(sortNumber);
					this.new_cota = {};
				}
			} else if (a == 2) {
				if (this.new_edit_cota.cota !== "" || this.new_edit_cota.pret !== "") {
					this.profiltoedit.profil_cote.push(this.new_edit_cota);
					function sortNumber(a, b) {
						return a.cota - b.cota;
					}
					this.profiltoedit.profil_cote.sort(sortNumber);
					this.new_edit_cota = {};
				}
			}
		},
		deleteCota(a, i) {
			if (a == 1) {
				this.cote.splice(i, 1);
			} else if (a == 2) {
				this.profiltoedit.profil_cote.splice(i, 1);
			}
		},
		addNewProfil() {
			if (!this.profil.tip || !this.cote) {
				M.toast({ html: "Completati toate campurile!" });
			} else {
				let newProfil = {
					profil_tip: this.profil.tip,
					profil_cote: JSON.stringify(this.cote),
					profil_greutate_ml: this.profil.greutate_ml,
					profil_bucpac: this.profil.bucpac,
				};

				this.$store.dispatch("addProfil", newProfil);
				this.profil = {};
				this.cote = [];
			}
		},
		addNewMaterialForm() {
			let nr = this.materiale.length + 1;
			this.materiale.push({
				nr: "" + nr + "",
				profil: {},
				lungime: "",
				cantitate: "",
				cote: [],
			});
		},
		deleteMaterialForm(index) {
			if (this.materiale.length > 1) {
				this.materiale.splice(index, 1);
			}
		},
		editCote(id) {
			let profilCote = JSON.stringify(this.profiltoedit.profil_cote);
			this.$store.dispatch("editProfilCote", {
				id: id,
				profilCote: profilCote,
			});
		},
		addAviz(e) {
			if (
				!this.nr_aviz ||
				!this.materiale[0].profil.value ||
				!this.materiale[0].lungime ||
				!this.materiale[0].cantitate
			) {
				M.toast({ html: "Completati toate campurile!" });
			} else {
				let self = this;
				this.materiale.forEach(function(material) {
					let newMat = {
						id_profil: material.profil.value,
						cota_profil: material.lungime,
						cantitate: material.cantitate,
						data: self.today,
						aviz: self.nr_aviz,
					};
					// console.log(newMat);
					self.$store.dispatch("addAvizStoc", newMat);
				});
				this.$router.push({
					path: "/debitare/stocuri",
					query: M.toast({ html: "Aviz adaugat!" }),
				});
			}
			e.preventDefault();
		},
		fetchProfile() {
			this.$store.dispatch("fetchProfile");
		},
		fetchProfil(id) {
			this.$store.dispatch("fetchProfil", id);
		},
		setCote(id, index) {
			this.materiale[index].cote = this.$store.getters.selectCote(id);
		},
	},
	created: function() {
		this.fetchProfile();
	},
	mounted: function() {
		M.Modal.init(document.querySelector(".modal-addcote"));
		M.Modal.init(document.querySelector(".modal-addprofil"));
		M.FormSelect.init(document.querySelectorAll("select"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table.materiale tbody tr {
	border: none;
}
td label {
	margin-left: 20px;
}
td.delete {
	cursor: pointer;
	color: #e65100;
	padding-left: 15px;
}
ul.cote {
	display: block;
	margin: 0;
}
ul.cote span {
	color: #aaaaaa;
	font-size: 12px;
}
ul li {
	display: inline-block;
	background-color: #f1f1f1;
	border-radius: 4px;
	margin: 0 10px 10px 0;
	padding: 2px 10px;
}
li span.delete {
	cursor: pointer;
	margin-left: 10px;
	color: #e65100;
}
.padd-left-100 {
	padding-left: 100px;
}
</style>
