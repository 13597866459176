<template>
	<v-card class="px-6 py-6">
		<div class="flex flex-row justify-between">
			<h2 class="text-2xl font-medium mb-4">Adauga client</h2>
			<p v-if="firma" class="text-sm text-blue-500 font-semibold">ANAF API</p>
		</div>
		<v-form ref="addClientForm" v-model="validClientForm" @submit.prevent="addClient">
			<div class="bg-gray-100 px-4 py-0.5 rounded-xl mb-4">
				<v-radio-group v-model="firma" row>
					<v-radio label="Persoana fizica" color="teal" :value="false"></v-radio>
					<v-radio label="Persoana juridica" color="teal" :value="true"></v-radio>
				</v-radio-group>
			</div>
			<div v-if="firma" class="grid grid-cols-2 gap-4">
				<div class="flex flex-row justify-start gap-1">
					<v-text-field ref="emailField" v-model="client.cui" label="CUI" type="text"
						hint="RO123456 sau 123456" outlined dense :rules="[rules.required, rules.cui]"></v-text-field>
					<v-btn class="teal lighten-2" text dark :loading="loadingCompanyDetails"
						@click="getCompanyData">Cauta</v-btn>
				</div>
				<v-text-field label="Reg Com" text outlined dense :rules="[rules.required]"
					v-model="client.reg_com"></v-text-field>
			</div>
			<div class="grid grid-cols-2 gap-x-4">
				<v-text-field v-if="firma" label="Denumire" text outlined dense :rules="[rules.required]"
					v-model="client.nume"></v-text-field>
				<v-text-field :label="firma ? 'Nume persoana' : 'Nume si prenume'" text outlined dense
					:rules="firma ? [] : [rules.required]" v-model="client.persoana"></v-text-field>
				<v-text-field class="col-span-2" label="Adresa" text outlined dense
					v-model="client.adresa"></v-text-field>
				<v-text-field label="Email" text outlined dense :rules="[rules.email, rules.isEmailOrPhone]"
					v-model="client.email"></v-text-field>
				<v-text-field label="Telefon" text outlined dense :rules="[rules.phone, rules.isEmailOrPhone]"
					v-model="client.tel"></v-text-field>
			</div>
			<v-alert v-if="visibleWarnings && warningListActive.length" border="left" colored-border type="warning"
				dense elevation="1">
				<p class="font-medium my-1">Nu ai completat: <span v-for="item, idx in warningListActive" :key="idx"
						class="text-yellow-600 font-semibold">{{ item
						}}<span v-if="idx != warningList.length - 1">, </span></span></p>
				<p class="leading-6 mb-1">
					Datele clientului vor trebui actualizate pe parcurs pentru a putea folosi toate functiile de
					trimitere si generare a documentelor.
				</p>
			</v-alert>
			<div class="flex flex-row justify-end gap-2 mt-4">
				<v-btn color="blue-grey" class="uppercase" dark large @click="closeModal">Anuleaza</v-btn>
				<v-btn color="teal" dark class="uppercase" large type="submit">Salveaza</v-btn>
			</div>
		</v-form>
	</v-card>
</template>

<script>
export default {
	name: "AddClient",
	data() {
		return {
			validClientForm: false,
			companyDataLoading: false,
			visibleWarnings: false,
			warningList: [],
			firma: false,
			client: {
				nume: null,
				cui: null,
				reg_com: null,
				adresa: null,
				oras: null,
				persoana: null,
				email: null,
				tel: null,
			},
			info: {},
			rules: {
				required: value => !!value || 'Câmp obligatoriu',
				cui: value => !value || /^(RO)?[0-9]{2,9}$/.test(value) || 'CUI/CIF invalid',
				regcom: value => !value || /^([J|j]|[F|f]|[C|c])[0-9]{2}\/[0-9]+\/(19|20)([0-9]{2})$/.test(value) || 'Număr înregistrare incorect',
				email: value => !value || /.+@.+\..+/.test(value) || "Adresa email invalida",
				isEmailOrPhone: () => this.isEmailOrPhone || "Email sau telefon obligatoriu",
				phone: value => !value || /(\(?\+?\d{1,4}\)?\s?)?\d{6,12}/.test(value) || "Numar incorect",
			}
		};
	},
	props: ["clientData"],
	computed: {
		loadingCompanyDetails() {
			return this.$store.getters.loadingCompanyDetails
		},
		isEmailOrPhone() {
			return !!this.client.tel || !!this.client.email
		},
		warningListActive: {
			get() {
				const warnings = []
				if (this.firma && !this.client.persoana) {
					warnings.push('nume persoana')
				}
				if (!this.client.adresa) {
					warnings.push('adresa')
				}
				if (!this.client.email) {
					warnings.push('emailul')
				}
				if (!this.client.tel) {
					warnings.push('telefonul')
				}
				this.warningList = warnings
				return warnings
			},
			set() {
				this.warningList = []
			}
		}
	},
	watch: {
		firma(newVal, oldVal) {
			if (newVal != oldVal) {
				this.$refs.addClientForm.resetValidation();
				this.visibleWarnings = false
				this.warningList = []
			}
		}
	},
	methods: {
		getLowercaseAddress(str) {
			str = str.toLowerCase();
			const words = str.split(" ");
			for (let i = 0; i < words.length; i++) {
				words[i] = words[i][0].toUpperCase() + words[i].substr(1);
			}
			return words.join(" ");
		},
		loadClientData() {
			this.client.cui = this.clientData.codfiscal
			this.client.nume = this.clientData.nume
			this.client.email = this.clientData.email
			this.client.tel = this.clientData.tel
			this.client.persoana = this.clientData.persoana

			if (this.clientData.nume != "") {
				this.firma = "true";
			}
		},
		replaceSpecialCh(str) {
			if (str) {
				return str
					.replace(/ș|Ș/g, "s")
					.replace(/ț|Ț/g, "t")
					.replace(/î|Î/g, "i")
					.replace(/ă|â|Â|Ă/g, "a")
			}
		},
		async getCompanyData() {
			// check if cui is valid format
			const validCUI = await this.$refs.emailField.validate();

			if (validCUI) {
				let cui = this.client.cui
				cui = cui.replace(/\D/g, "");
				const companyDetails = await this.$store.dispatch("fetchCompanyDetailsFromAnaf", cui);
				if (companyDetails === undefined) {
					alert('Nu au fost gasite date!')
				} else {
					this.client.nume = companyDetails.date_generale.denumire;
					this.client.adresa = this.getLowercaseAddress(companyDetails.date_generale.adresa);
					this.client.reg_com = companyDetails.date_generale.nrRegCom;
					this.client.cui = `${companyDetails.inregistrare_scop_Tva.scpTVA ? 'RO' : ''}${companyDetails.date_generale.cui}`
				}
			} else {
				alert("verificati CUI/CIF!");
			}

			// if (validCUI) {
			// 	this.companyDataLoading = true
			// 	let cui = this.client.cui
			// 	cui = cui.replace(/\D/g, "");
			// 	console.log('api call')
			// 	this.$http
			// 		.get(process.env.VUE_APP_OPEN_API_HOST + cui, {
			// 			headers: { "x-api-key": process.env.VUE_APP_OPEN_API_KEY },
			// 		})
			// 		.then(
			// 			function (response) {
			// 				this.client.nume = response.body.denumire;
			// 				this.client.adresa = response.body.adresa + ", jud: " + response.body.judet;
			// 				this.client.reg_com = response.body.numar_reg_com;
			// 				this.companyDataLoading = false
			// 			},
			// 			(response) => {
			// 				if (response.status == 404 || response.status == 202) {
			// 					alert("CUI/CIF negasit!");
			// 				}
			// 			}
			// 		)
			// 		.catch(function (error) {
			// 			this.companyDataLoading = false
			// 			console.log("Errore: " + error.status + " " + error.statusText);
			// 		});

			// } else {
			// 	alert("verificati CUI/CIF!");
			// 	this.companyDataLoading = false
			// }
		},
		closeModal() {
			this.firma = false
			this.client = {
				nume: null,
				cui: null,
				reg_com: null,
				adresa: null,
				oras: null,
				persoana: null,
				email: null,
				tel: null
			}
			this.visibleWarnings = false
			this.$refs.addClientForm.resetValidation();
			this.$emit('closeModal')
		},
		async addClient() {
			const valid = await this.$refs.addClientForm.validate();

			if (valid) {
				if (!this.visibleWarnings && this.warningListActive.length) {
					this.visibleWarnings = true
					return
				}
				let newClient = {
					firma: this.firma,
					nume: this.client.nume,
					cui: this.client.cui,
					reg_com: this.client.reg_com,
					adresa: this.replaceSpecialCh(this.client.adresa),
					oras: this.client.oras,
					persoana: this.client.persoana,
					email: this.client.email,
					tel: this.client.tel,
				};
				if (!newClient.nume) {
					newClient.nume = newClient.persoana;
					newClient.persoana = null;
				}
				this.$emit("clientName", newClient.nume);

				const duplicates = await this.$store.dispatch("checkDuplicateClient", newClient);

				if (!duplicates) {
					this.$store.dispatch("addClient", newClient);
					// console.log(newClient)
					this.closeModal()
					this.client = {};
				} else {
					alert('Clientul exista deja')
				}
			}
		}
	},
	created: function () {
		if (this.clientData != undefined) {
			this.loadClientData();
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.info i {
	float: left;
	margin-top: 15px;
}

.info p {
	/* display: inline-block; */
	margin-left: 50px;
}
</style>
