<template>
	<v-app id="app">
		<Navbar />
		<Loading v-show="loading" />
		<v-main class="bg-light-grey">
			<router-view />
		</v-main>
		<Footer v-if="isLoggedIn" />
	</v-app>
</template>

<script>
import firebase from "firebase/app";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import Loading from "@/components/Loading";

export default {
	name: "App",
	components: {
		Navbar,
		Footer,
		Loading,
	},
	computed: {
		loading() {
			return this.$store.getters.loading;
		},
		isLoggedIn() {
			return firebase.auth().currentUser;
		},
	},
};
</script>

<style>
body {
	background-color: #f3f4f8;
	/* font-size: 14px; */
	margin-bottom: 100px;
}

.section-title {
	@apply text-2xl uppercase font-medium
}

.page-title {
	@apply text-4xl font-medium
}

.page-title-secondary {
	@apply text-3xl font-medium
}

/* Vuetify overwrite */
.v-card {
	@apply border-0 !important
}

/* remove below until Vueityf styles end; after Materialize styles removed */
.v-application ul a {
	color: inherit !important;
}

.v-application a.btn {
	color: white !important;
}

.v-date-picker-table tr,
.v-date-picker-table--date tr {
	border-bottom: 0 !important;
}

.v-date-picker-table td,
.v-date-picker-table--date td {
	padding: 0 !important;
}

.v-input input[type="text"]:not(.browser-default),
.v-input input[type="date"]:not(.browser-default),
.v-input input[type="number"]:not(.browser-default),
.v-input textarea.materialize-textarea {
	font-size: inherit;
	border-bottom: none;
	margin: 0;
}

.v-input input[type="text"]:focus:not(.browser-default),
.v-input input[type="date"]:focus:not(.browser-default),
.v-input input[type="number"]:focus:not(.browser-default),
.v-input textarea.materialize-textarea {
	font-size: inherit;
	border-bottom: none;
	box-shadow: none;
}

.v-page {
	height: auto;
	line-height: 1 !important;
	background-color: transparent;
	width: auto;
	height: auto;
	box-shadow: none;
}

.container {
	padding-top: 0;
	padding-bottom: 0;
}

/* end Vuetify overwrite */
.date-field input {
	@apply select-none
}

.white-bg {
	background-color: #ffffff;
}

h4.header {
	@apply text-4xl;
}

/* .container {
	width: 95%;
	@apply 2xl:px-6
} */

.navbar-fixed {
	background-color: #626d7f;
}

.mtop-0 {
	margin-top: 0 !important;
}

.mtop-10 {
	margin-top: 10px;
}

.mtop-20 {
	margin-top: 20px;
}

.mtop-30 {
	margin-top: 30px;
}

.mbottom-0 {
	margin-bottom: 0;
}

.mbottom-10 {
	margin-bottom: 10px;
}

.mbottom-20 {
	margin-bottom: 20px;
}

.mbottom-30 {
	margin-bottom: 30px;
}

.mr-10 {
	margin-right: 10px;
}

.mr-20 {
	margin-right: 20px;
}

.ml-10 {
	margin-left: 10px;
}

.ml-20 {
	margin-left: 20px;
}

.pa-10 {
	padding: 10px;
}

.pa-20 {
	padding: 20px;
}

.ptop-10 {
	padding-top: 10px;
}

.ptop-20 {
	padding-top: 20px;
}

.pbottom-10 {
	padding-bottom: 10px;
}

.pbottom-20 {
	padding-bottom: 20px;
}

.nav {
	background-color: #626d7f;
	@apply text-white h-full;
}

.nav .nav-link {
	@apply flex flex-row justify-start items-center px-5;
}

.nav .nav-link:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.nav .nav-link i:first-child {
	@apply mr-2;
}

.nav .router-link-active {
	background-color: rgba(0, 0, 0, 0.1);
}

.bold {
	font-weight: 700;
}

.italic {
	font-style: italic;
}

.document-header input[type="text"],
table input[type="number"] {
	font-size: 13px;
}

table input[type="text"],
table input[type="number"] {
	font-size: 13px;
}

td>label>span {
	height: 15px !important;
}

table {
	font-size: 13px;
}

input[type="text"]:not(.browser-default),
input[type="date"]:not(.browser-default),
input[type="number"]:not(.browser-default),
textarea.materialize-textarea {
	font-size: 13px;
}

.ui.search.selection.dropdown>input.search {
	padding: 10px 0 !important;
}

.width3 {
	width: 3%;
}

.width5 {
	width: 5%;
}

.width6 {
	width: 6%;
}

.width75 {
	width: 7.5%;
}

.width10 {
	width: 10%;
}

.width15 {
	width: 15%;
}

.width20 {
	width: 20%;
}

.width25 {
	width: 25%;
}

.width30 {
	width: 30%;
}

.width35 {
	width: 35%;
}

.width40 {
	width: 40%;
}

.width50 {
	width: 50%;
}

.width55 {
	width: 55%;
}

.block {
	display: block;
}

.left-margin {
	margin-left: 30px;
}

.right-margin {
	margin-right: 30px;
}

.btn {
	@apply text-white uppercase px-6;
}

.btn:hover {
	background-color: inherit @apply bg-opacity-70 !important;
}

.btn-group {
	@apply -ml-2;
}

.btn-group .btn {
	@apply mx-1;
}

.btn-text {
	font-size: 12px;
	font-weight: bold;
	padding: 0;
	text-transform: uppercase;
	cursor: pointer;
	line-height: 1.8;
	background: transparent;
	border: none;
}

.icon-btn-text {
	font-size: 22px;
}

.caps-text {
	text-transform: uppercase;
}

.back-btn {
	margin-right: 30px;
}

.back-btn::before {
	font-family: "Material Icons";
	font-weight: normal;
	font-style: normal;
	font-size: 24px;
	/* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	vertical-align: middle;
	direction: ltr;
	content: "\E314";
}

.bold {
	font-weight: bold;
}

.header>.col.s4:first-child,
.footer>.col.s4:first-child,
.footer>.col:first-child {
	padding: 0 0.75rem 0 0;
}

.header>.col.s4:last-child,
.footer>.col.s4:last-child,
.footer>.col:last-child {
	padding: 0 0 0 0.75rem;
}

.content .col {
	padding: 0;
}

.card-panel .header {
	margin-top: 0;
}

.ui.selection.dropdown {
	border: none !important;
	border-radius: 0 !important;
	border-bottom: 1px solid #9e9e9e !important;
	background: transparent !important;
}

.ui.selection.dropdown .search {
	height: 1rem !important;
}

.document-header {
	margin-top: 10px;
}

.document-header #client_id {
	height: 1.2rem;
}

.document-header .ui.dropdown:not(.button)>.default.text {
	color: rgba(0, 0, 0, 0.87);
}

#client_id>input.search,
#agent_id>input.search,
#material>input.search,
#aliaj>input.search,
#um>input.search,
#umm>input.search,
#denumire>input.search,
#echipa>input.search,
#profil>input.search,
#cote>input.search,
#st1>input.search,
#st2>input.search,
#st3>input.search,
#manopera>input.search,
#serv>input.search,
#consumabil>input.search,
#furnizor>input.search,
#furnizori>input.search,
#model>input.search,
.text-cursor-fix {
	height: 1em;
	margin: 0;
	padding: 0.67861429em 0 0.67861429em 0;
}

.input-field.select {
	margin-top: 8px;
}

.dropdown-content li>a,
.dropdown-content li>span {
	font-size: 14px;
}

.btns-right {
	margin-top: 13px;
}

.debitari .time-picker input.display-time {
	height: 3rem;
	width: 100%;
	padding: 0;
	border: none;
	border-bottom: 1px dotted rgba(0, 0, 0, 0.42);
}

.debitari .time-picker {
	width: 5rem;
}

.modal-small,
.modal-status,
.modal-delete {
	width: 320px;
}

.modal-docs {
	width: 360px;
}

.modal-add {
	width: 440px;
}

.modal-small {
	width: 320px;
	overflow-y: visible;
}

.modal-medium {
	width: 820px;
}

.btn-icon {
	cursor: pointer;
}

.btn-icon-padding {
	cursor: pointer;
	vertical-align: bottom;
	padding: 0 10px;
}

.icon-btn {
	font-size: 18px;
	vertical-align: top;
}

i.info {
	margin-top: 4px;
	padding-bottom: 40px;
}

input::-webkit-input-placeholder {
	color: rgba(0, 0, 0, 0.5);
	font-size: 13px;
}

input::-moz-placeholder {
	color: rgba(0, 0, 0, 0.7);
	font-size: 13px;
}

input:-ms-input-placeholder {
	color: rgba(0, 0, 0, 0.5);
	font-size: 13px;
}

.select-wrapper input.select-dropdown {
	font-size: 13px;
}

div.selection>div.default {
	color: rgba(0, 0, 0, 0.5);
	/* font-size: 13px; */
}

.radio-btns label {
	margin-right: 20px;
}

.title {
	padding: 10px 20px;
}

.inline-title,
.inline-div {
	display: inline-block;
}

.inline-title {
	margin-top: 5px;
}

.inline-subtitle {
	margin-top: 5px;
}

.side-menu li.indicator {
	display: none;
}

.status {
	padding: 2px 6px;
	color: #fff;
}

.status_green {
	background-color: #3ac569;
}

.tabs .tab a {
	color: #000;
}

.tabs .tab a:hover {
	background-color: #fff;
	color: #000;
}

.tabs .tab a.active {
	background-color: #fff;
	color: #000;
}

.tabs .tab a:focus,
.tabs .tab a:focus.active {
	background-color: #fff;
	outline: none;
}

.tabs .indicator {
	background-color: #26a69a;
}

.mx-table td,
.mx-table-date td {
	text-align: center;
}
</style>
