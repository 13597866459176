<template>
	<div v-if="oferta" class="container ">
		<div class="flex flex-col lg:flex-row justify-between items-center mt-6">
			<h4 class="header">Editeaza oferta</h4>
			<div class="flex justify-between items-center">
				<div class="input-field mr-2 mb-0">
					<input id="client" type="text" v-model="oferta.nume" readonly />
					<label class="active" for="client_id">Nume client</label>
				</div>
				<div class="input-field w-20 mx-2 mb-0">
					<select id="moneda" v-model="oferta.moneda" form="editOferta">
						<option value="eur">EUR</option>
						<option value="ron">RON</option>
					</select>
					<label for="moneda">Moneda</label>
				</div>
				<div class="input-field w-36 mx-2 mb-0">
					<input id="data" type="date" v-model="oferta.data" form="editOferta" />
					<label for="data">Data</label>
				</div>
				<div class="input-field ml-2 mb-0">
					<input id="user" type="text" v-model="oferta.username" readonly />
					<label class="active" for="user">Intocmit de</label>
				</div>
			</div>
		</div>

		<form id="editOferta" v-on:submit.prevent="updateOferta">
			<!-- document header -->
			<div class="bg-white px-6 py-6 shadow mb-4">
				<h5 class="sub-title">Produse</h5>
				<AddProducts ref="AddProducts" :initialAdd="false" :oferta="oferta" :produse="oferta.produse_obj"
					:produseCustom="oferta.custom_obj" @weightChanged="changeTotalWeight" />
			</div>

			<div class="bg-white px-6 py-6 shadow mb-4">
				<div class="flex flex-col lg:flex-row justify-between">
					<h5 class="sub-title">Debitare</h5>
					<a v-if="!oferta.debitare_obj || !oferta.debitare_obj.form" class="btn bg-gray-400"
						@click="showDebitareForm">Adauga cost debitare
					</a>
					<span v-else class="hover:text-red-500 cursor-pointer" @click="hideDebitareForm()"><i
							class="material-icons delete">close</i></span>
				</div>
				<div v-if="oferta.debitare_obj.form" class="flex flex-col lg:flex-row -mx-2">
					<div class="input-field w-96 mx-2">
						<input id="debitare_name" type="text" class="validate" v-model="oferta.debitare_obj.name" />
						<label for="debitare_name" class="active">Denumire</label>
					</div>
					<div class="flex">
						<div class="input-field w-28 mx-2">
							<input id="debitare_qty" type="number" class="validate center-align"
								v-model="oferta.debitare_obj.cantitate" />
							<label for="debitare_qty" class="active">{{
		oferta.debitare_obj.um
	}}</label>
						</div>
						<div class="input-field w-28 mx-2">
							<input id="debitare_price" type="number" step="0.01" class="validate center-align"
								v-model="oferta.debitare_obj.pret" />
							<label for="debitare_price" class="active">Pret unitar</label>
						</div>
						<div class="input-field w-28 mx-2">
							<input id="debitare_value" type="text" class="center-align" placeholder="0.00"
								v-model="valDebitare" readonly />
							<label for="debitare_value" class="active">Val. fara TVA</label>
						</div>
					</div>
				</div>
			</div>

			<div class="bg-white px-6 py-6 shadow mb-4">
				<div class="flex flex-col lg:flex-row justify-between">
					<h5 class="sub-title">Transport</h5>
					<div class="flex flex-row items-center justify-end gap-10">
						<p v-if="!oferta.transport_obj.form">
							<label>
								<input type="checkbox" v-model="oferta.transport_obj.included" />
								<span>Transport inclus</span>
							</label>
						</p>
						<a v-if="!oferta.transport_obj || !oferta.transport_obj.form" class="btn bg-gray-400"
							:disabled="disabledTransportBtn" @click="showTransportForm">Adauga cost transport
						</a>
						<span v-else class="hover:text-red-500 cursor-pointer" @click="hideTransportForm()"><i
								class="material-icons delete">close</i></span>
					</div>
				</div>
				<div v-if="oferta.transport_obj.form" class="flex flex-col lg:flex-row -mx-2">
					<div class="input-field w-96 mx-2">
						<input id="transport_name" type="text" class="validate" v-model="oferta.transport_obj.name" />
						<label for="transport_name" class="active">Denumire</label>
					</div>
					<div class="flex">
						<div class="input-field w-28 mx-2">
							<input id="transport_qty" type="number" class="validate center-align"
								v-model="oferta.transport_obj.cantitate" />
							<label for="transport_qty" class="active">{{
		oferta.transport_obj.um
	}}</label>
						</div>
						<div class="input-field w-28 mx-2">
							<input id="transport_price" type="number" step="0.01" class="validate center-align"
								v-model="oferta.transport_obj.pret" />
							<label for="transport_price" class="active">Pret unitar</label>
						</div>
						<div class="input-field w-28 mx-2">
							<input id="transport_value" type="text" class="center-align" placeholder="0.00"
								v-model="valTransport" readonly />
							<label for="transport_value" class="active">Val. fara TVA</label>
						</div>
					</div>
				</div>
			</div>

			<div class="flex justify-between mb-4 bg-gray-200 ">
				<h5 class="sub-title mb-4 w-1/2 mt-6 mx-6">Totaluri</h5>
				<table class="w-1/4">
					<tr>
						<td class="">Total fara TVA</td>
						<td class="center-align">
							<span>{{ isNaN(totals) ? 0 : totals }} {{ oferta.moneda }}</span>
						</td>
					</tr>
					<tr>
						<td class="">Valoare TVA</td>
						<td class="center-align">
							<span>{{ isNaN(tax_total) ? 0 : tax_total }}
								{{ oferta.moneda }}</span>
						</td>
					</tr>
					<tr>
						<td class="bold">Total cu TVA</td>
						<td class="center-align bold">
							<span>{{ isNaN(grand_total()) ? 0 : grand_total() }}
								{{ oferta.moneda }}</span>
						</td>
					</tr>
				</table>
			</div>

			<div class="bg-white px-6 pt-6 pb-2 shadow mb-4">
				<h5 class="sub-title mb-4">Alte detalii</h5>
				<div class="flex flex-col lg:flex-row -mx-2">
					<div class="input-field w-1/6 mx-2">
						<input id="t_livrare" type="text" class="validate" v-model="oferta.t_livrare" />
						<label for="t_livrare">Conditii livrare</label>
					</div>
					<div class="input-field w-1/6 mx-2">
						<input id="t_plata" type="text" class="validate" v-model="oferta.t_plata" />
						<label for="t_plata">Conditii plata</label>
					</div>
					<div class="input-field w-1/6 mx-2">
						<input id="valid" type="text" class="validate" v-model="oferta.valid" />
						<label for="valid">Valabilitate oferta</label>
					</div>
					<div class="input-field w-3/6 mx-2">
						<textarea id="texarea" class="materialize-textarea" v-model="oferta.obs"></textarea>
						<label for="textarea">Observatii</label>
					</div>
				</div>
			</div>

			<div class="btn-group flex justify-end mb-20">
				<a href="javascript:history.go(-1)" class="btn btn-large blue-grey lighten-2">Anuleaza</a>
				<button type="submit" class="btn btn-large">Salveaza</button>
			</div>
		</form>
		<!-- <pre>{{$data}}</pre> -->
		<!-- <pre>{{ oferta }}</pre> -->
		<!-- <pre>{{ preturiDebitare }}</pre> -->
	</div>
</template>

<script>
// import { ModelSelect } from "vue-search-select";
import AddProducts from "@/components/Oferte/AddOferte/AddProducts";

export default {
	name: "EditOferta",
	components: {
		AddProducts,
	},
	data() {
		return {
			totalWeight: "",
			disabledTransportBtn: false
		};
	},
	computed: {
		oferta() {
			return this.$store.getters.oferta;
		},
		materiale() {
			return this.$store.getters.selectMateriale;
		},
		ums() {
			return this.$store.getters.ums;
		},
		denumiri() {
			return this.$store.getters.denumiri;
		},
		arr_benzi() {
			return this.$store.getters.arr_benzi;
		},
		arr_bareP() {
			return this.$store.getters.arr_bareP;
		},
		arr_table() {
			return this.$store.getters.arr_table;
		},
		arr_teviR() {
			return this.$store.getters.arr_teviR;
		},
		arr_teviP() {
			return this.$store.getters.arr_teviP;
		},
		arr_profileLT() {
			return this.$store.getters.arr_profileLT;
		},
		arr_profile() {
			return this.arr_bareP
				.concat(this.arr_teviR)
				.concat(this.arr_teviP)
				.concat(this.arr_profileLT);
		},
		valDebitare() {
			if (this.oferta.debitare_obj != null) {
				let val =
					this.oferta.debitare_obj.cantitate * this.oferta.debitare_obj.pret;
				this.oferta.debitare_obj.val = val;
				return val;
			}
		},
		valTransport() {
			if (this.oferta.transport_obj != null) {
				let val =
					this.oferta.transport_obj.cantitate * this.oferta.transport_obj.pret;
				this.oferta.transport_obj.val = val;
				return val;
			}
		},
		totals: function () {
			let t = 0;
			if (this.oferta.produse_obj) {
				this.oferta.produse_obj.forEach(function (produs) {
					t = t + Number(produs.pret_t);
				});
			}
			if (this.oferta.custom_obj.length > 0) {
				this.oferta.custom_obj.forEach(function (produs) {
					t = t + Number(produs.pret_t);
				});
			}
			if (this.oferta.debitare_obj != null && this.oferta.debitare_obj.form) {
				t = t + Number(this.valDebitare);
			}
			if (this.oferta.transport_obj != null && this.oferta.transport_obj.form) {
				t = t + Number(this.valTransport);
			}
			this.oferta.val_fara_tva = t.toFixed(2);
			return this.oferta.val_fara_tva;
		},
		tax_total: function () {
			let t = 0;
			if (this.oferta.produse_obj) {
				this.oferta.produse_obj.forEach(function (produs) {
					t = t + Number(produs.tva);
				});
			}
			if (this.oferta.custom_obj.length > 0) {
				this.oferta.custom_obj.forEach(function (produs) {
					t = t + Number(produs.tva);
				});
			}
			if (this.oferta.debitare_obj != null && this.oferta.debitare_obj.form) {
				t = t + Number(this.valDebitare) * 0.19;
			}
			if (this.oferta.transport_obj != null && this.oferta.transport_obj.form) {
				t = t + Number(this.valTransport) * 0.19;
			}
			this.oferta.val_tva = t.toFixed(2);
			return this.oferta.val_tva;
		},
		preturiDebitare() {
			return this.$store.getters.preturiDebitare;
		},
		preturiTransport() {
			return this.$store.getters.preturiTransport;
		},
	},
	watch: {
		"oferta.debitare_obj.form": function (newVal, oldVal) {
			if (newVal != oldVal) {
				this.setTransportPrice();
			}
		},
		"oferta.transport_obj.included": function (newVal, oldVal) {
			if (newVal != oldVal) {
				this.disabledTransportBtn = newVal
			}
		},
		totalWeight() {
			this.setDebitarePrice();
			this.setTransportPrice();
		},
	},
	methods: {
		changeTotalWeight(value) {
			this.totalWeight = value;
		},
		setDebitarePrice() {
			let price = this.preturiDebitare.find((item) => {
				if (this.totalWeight > 2000) {
					return Number(item.toWeight) == 2000;
				} else {
					return Number(item.toWeight) > this.totalWeight;
				}
			});
			this.oferta.debitare_obj.pret = price.pret_debitare;
		},
		setTransportPrice() {
			let price = this.preturiTransport.find((item) => {
				if (this.totalWeight > 2000) {
					return Number(item.toWeight) == 2000;
				} else {
					return Number(item.toWeight) >= this.totalWeight;
				}
			});

			let produs = this.oferta.produse_obj[0];
			let str = "";
			if (
				produs.denumire.value == "Tabla" ||
				produs.denumire.value == "Placa"
			) {
				let bigVal = Math.max(produs.dimL, produs.dimH);
				str += "tabla_";
				if (bigVal <= 2000) {
					str += "dim1";
				} else if (bigVal <= 2500) {
					str += "dim2";
				} else if (bigVal <= 3000) {
					str += "dim3";
				} else if (bigVal <= 4000) {
					str += "dim4";
				}
			} else if (
				this.arr_profile.includes(produs.denumire.value) ||
				produs.denumire.value == "Profil vopsit"
			) {
				str += "profile_";
				if (this.oferta.debitare_obj.form) {
					str += "4m";
				} else {
					str += "6m";
				}
			}
			this.oferta.transport_obj.pret = price[str];
		},
		isModified(produs) {
			let mod = false;
			if (produs.dim_text && produs.dim_text != produs.dim_str) {
				mod = true;
			}
			produs.modified = mod;
		},
		setAliaje(index) {
			let material = this.oferta.produse_obj[index].material;
			this.oferta.produse_obj[index].aliaje = this.$store.getters.selectAliaje(
				material
			);
		},
		setUm(index) {
			let list = [];
			if (
				this.arr_benzi.includes(this.oferta.produse_obj[index].denumire.value)
			) {
				let arr = ["m2", "ml", "kg"];
				list = this.ums.filter((um) => {
					return arr.includes(um.value);
				});
			} else if (
				this.arr_table.includes(this.oferta.produse_obj[index].denumire.value)
			) {
				let arr = ["m2", "buc", "kg"];
				list = this.ums.filter((um) => {
					return arr.includes(um.value);
				});
			} else if (
				this.arr_bareP.includes(
					this.oferta.produse_obj[index].denumire.value
				) ||
				this.arr_teviR.includes(
					this.oferta.produse_obj[index].denumire.value
				) ||
				this.arr_teviP.includes(
					this.oferta.produse_obj[index].denumire.value
				) ||
				this.arr_profileLT.includes(
					this.oferta.produse_obj[index].denumire.value
				) ||
				this.oferta.produse_obj[index].denumire.value == "Platbanda" ||
				this.oferta.produse_obj[index].denumire.value == "Bara rotunda" ||
				this.oferta.produse_obj[index].denumire.value == "Bara hexagonala" ||
				this.oferta.produse_obj[index].denumire.value == "Profil U"
			) {
				let arr = ["ml", "kg"];
				list = this.ums.filter((um) => {
					return arr.includes(um.value);
				});
			} else {
				list = this.ums;
			}
			this.oferta.produse_obj[index].ums = list;
		},
		setValoriProduse(index) {
			let currentProduct = this.oferta.produse_obj[index];
			let currentAliaj = currentProduct.aliaje.find(
				(aliaj) => aliaj.value === currentProduct.aliaj
			);
			this.oferta.produse_obj[index].gs = currentAliaj.gs;
			this.oferta.produse_obj[index].pret = currentAliaj.pret;
		},
		grand_total() {
			let t = Number(this.oferta.val_fara_tva) + Number(this.oferta.val_tva);
			this.oferta.suma = t.toFixed(2);
			return this.oferta.suma;
		},
		totalP(index) {
			let currentProduct = this.oferta.produse_obj[index];
			let dimL = currentProduct.dimL;
			let dimH = currentProduct.dimH;
			let dimG = currentProduct.dimG;

			var res;

			if (currentProduct.gs != "") {
				let gs = currentProduct.gs;

				if (this.arr_benzi.includes(currentProduct.denumire.value)) {
					if (currentProduct.um == "m2") {
						res = (1000 * dimG * gs) / 1000;
					} else {
						res = (dimL * dimG * gs) / 1000;
					}
				} else if (this.arr_bareP.includes(currentProduct.denumire.value)) {
					res = (dimL * dimH * gs) / 1000;
				} else if (currentProduct.denumire.value === "Platbanda") {
					res = (dimL * dimG * gs) / 1000;
				} else if (currentProduct.denumire.value === "Bara rotunda") {
					res = (0.785 * dimL * dimL * gs) / 1000;
				} else if (currentProduct.denumire.value === "Bara hexagonala") {
					res = (0.866 * dimL * dimL * gs) / 1000;
				} else if (this.arr_table.includes(currentProduct.denumire.value)) {
					if (currentProduct.um == "m2") {
						res = 1000 * gs * (dimG / 1000);
					} else {
						res = ((dimL * dimH) / 1000) * gs * (dimG / 1000);
					}
				} else if (this.arr_teviR.includes(currentProduct.denumire.value)) {
					res = 0.785 * (4 * dimL * dimG - 4 * dimG * dimG) * (gs / 1000);
				} else if (this.arr_teviP.includes(currentProduct.denumire.value)) {
					res = ((2 * dimL + 2 * dimH - 4 * dimG) / 1000) * dimG * gs;
				} else if (this.arr_profileLT.includes(currentProduct.denumire.value)) {
					res = ((dimL + dimH - dimG) / 1000) * dimG * gs;
				} else if (currentProduct.denumire.value === "Profil U") {
					res = ((dimL + 2 * dimH - 2 * dimG) / 1000) * dimG * gs;
				}
			} else {
				res = currentProduct.gspec;
			}

			var weight;
			if (currentProduct.um == "kg") {
				weight = Number(currentProduct.cantitate);
			} else {
				weight = Number(currentProduct.cantitate) * res;
			}

			let umPrice =
				(weight * Number(currentProduct.pret_kg)) /
				Number(currentProduct.cantitate);
			let total = Number(currentProduct.cantitate) * umPrice.toFixed(3);
			let tax = total * 0.19;

			this.oferta.produse_obj[index].dim_str = this.dimString(
				currentProduct.denumire.value,
				dimL,
				dimH,
				dimG
			);
			this.isModified(this.oferta.produse_obj[index]);

			this.oferta.produse_obj[index].greutate = weight.toFixed(2);
			this.oferta.produse_obj[index].pret_t = total.toFixed(2);
			this.oferta.produse_obj[index].pret_u = umPrice.toFixed(3);
			this.oferta.produse_obj[index].tva = tax.toFixed(2);
		},
		dimString(denumire, dimL, dimH, dimG) {
			let str = "";

			if (this.arr_table.includes(denumire)) {
				str = dimG + " x " + dimL + " x " + dimH + " mm";
			} else if (this.arr_benzi.includes(denumire)) {
				str = dimG + " x " + dimL + " mm";
			} else if (this.arr_bareP.includes(denumire)) {
				str = dimH + " x " + dimL + " mm";
			} else if (
				this.arr_teviR.includes(denumire) ||
				denumire === "Platbanda"
			) {
				str = dimL + " x " + dimG + " mm";
			} else if (
				this.arr_teviP.includes(denumire) ||
				this.arr_profileLT.includes(denumire) ||
				denumire === "Profil U"
			) {
				str = dimL + " x " + dimH + " x " + dimG + " mm";
			} else if (
				denumire === "Bara rotunda" ||
				denumire === "Bara hexagonala"
			) {
				str = "D " + dimL + " mm";
			}

			return str;
		},
		totalC(index) {
			let total =
				Number(this.oferta.custom_obj[index].cantitate) *
				Number(this.oferta.custom_obj[index].pret_u).toFixed(2);
			let tax = total * 0.19;

			this.oferta.custom_obj[index].pret_t = total.toFixed(2);
			this.oferta.custom_obj[index].tva = tax.toFixed(2);
		},
		addNewProductForm() {
			let nr = this.oferta.produse_obj.length + 1;
			this.oferta.produse_obj.push({
				nr: "" + nr + "",
				material: "",
				denumire: {},
				aliaj: "",
				gs: "",
				gspec: "",
				detalii: "",
				dimL: null,
				dimH: null,
				dimG: null,
				ums: [],
				um: "",
				cantitate: "",
				greutate: "",
				pret: "",
				pret_kg: "",
				pret_t: "",
				pret_u: "",
				tva: "",
				t_livrare: "",
				aliaje: [],
				dim_text: "",
			});
		},
		deleteProductForm(index) {
			this.oferta.produse_obj.splice(index, 1);
		},
		addNewCustomProductForm() {
			let nr =
				Number(this.oferta.produse_obj.length) +
				Number(this.oferta.custom_obj.length) +
				1;
			this.oferta.custom_obj.push({
				nr: "" + nr + "",
				denumire: "",
				dimensiune_text: "",
				um: "",
				cantitate: "",
				pret_t: "",
				pret_u: "",
				tva: "",
				detalii: "",
				t_livrare: "",
			});
		},
		deleteCustomProductForm(index) {
			this.oferta.custom_obj.splice(index, 1);
		},
		showDebitareForm() {
			this.oferta.debitare_obj = {
				form: true,
				name: "Debitare",
				um: "buc",
				cantitate: 1,
				pret: 0,
				val: 0,
			};
			this.oferta.debitare_obj.form = true;
		},
		hideDebitareForm() {
			this.oferta.debitare_obj.form = false;
		},
		showTransportForm() {
			this.oferta.transport_obj = {
				form: true,
				name: "Transport si impachetare",
				um: "buc",
				cantitate: 1,
				pret: 0,
				val: 0,
			};
			this.oferta.transport_obj.form = true;
		},
		hideTransportForm() {
			this.oferta.transport_obj.form = false;
		},
		fetchOferta(id) {
			this.$store.dispatch("fetchOferta", id);
		},
		updateOferta(e) {
			if (!this.oferta.data || !this.oferta.produse_obj) {
				M.toast({ html: "Completati toate campurile!" });
			} else {
				let updOferta = {
					data: this.oferta.data,
					suma: this.oferta.suma,
					val_fara_tva: this.oferta.val_fara_tva,
					val_tva: this.oferta.val_tva,
					valid: this.oferta.valid,
					t_livrare: this.oferta.t_livrare,
					t_plata: this.oferta.t_plata,
					obs: this.oferta.obs,
					moneda: this.oferta.moneda,
					produse_obj: JSON.stringify(this.oferta.produse_obj),
					custom_obj: JSON.stringify(this.oferta.custom_obj),
					debitare_obj: JSON.stringify(this.oferta.debitare_obj),
					transport_obj: JSON.stringify(this.oferta.transport_obj),
					livrare: JSON.stringify(this.oferta.livrare),
				};

				this.$store.dispatch("updOferta", {
					object: updOferta,
					id: this.$route.params.id,
				});
				e.preventDefault();
			}
			e.preventDefault();
		},
		fetchAliaje() {
			this.$store.dispatch("fetchAliaje");
		},
	},
	created: function () {
		this.fetchOferta(this.$route.params.id);
		this.fetchAliaje();
		this.$store.dispatch("fetchFormulaVanzari");
		this.$store.dispatch("fetchPreturiVanzari");
		this.$store.dispatch("fetchPreturiDebitare");
		this.$store.dispatch("fetchPreturiTransport");
	},
	updated: function () {
		M.updateTextFields();
		M.FormSelect.init(document.querySelectorAll("select"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sub-title {
	@apply text-base uppercase tracking-wider text-gray-500;
}

.input-field.obs {
	margin-top: 30px;
}

table.produse tbody tr {
	border: none;
}

.extra-info td {
	padding: 0;
}

.transport-form:before {
	content: "";
	display: block;
	height: 20px;
}

td.delete {
	cursor: pointer;
	color: #e65100;
}

.td_number span {
	font-size: 16px;
	padding: 8px 30px;
	border-bottom: 1px solid #9e9e9e;
}

table input:read-only {
	background-color: #f2f4f8;
}
</style>
