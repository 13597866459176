<template>
  <div>
    <div class="card-panel row">
      <div class="col s10">
        <table class="responsive-table servicii">
          <thead>
            <tr>
              <th class="center-align">Nr.</th>
              <th>Denumire</th>
              <th class="center-align">Cantitate</th>
              <th class="center-align">Pret/um
                <br />- lei -
              </th>
              <th class="center-align">UM</th>
              <th class="center-align">Marja %</th>
              <th class="center-align">Valoare fara TVA</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(obj,index) in serv_obj">
              <td class="width5">
                <input type="number" class="validate center-align" v-model="obj.nr" />
              </td>
              <td class="width35">
                <model-select
                  id="serv"
                  :options="selectPreturiServ"
                  v-model="obj.denumire"
                  placeholder="Denumire serviciu"
                  @input="setValoriServ(index)"
                ></model-select>
              </td>
              <td class="width10">
                <input
                  type="number"
                  step="1"
                  class="validate center-align"
                  v-model="obj.cantitate"
                  v-on:change="totalS(index)"
                />
              </td>
              <td class="width10">
                <input class="center-align" v-model="obj.pret" readonly />
              </td>
              <td class="width10">
                <input class="center-align" v-model="obj.um" readonly />
              </td>
              <td class="width10">
                <input
                  type="number"
                  step="0.1"
                  value="1"
                  placeholder="0"
                  class="validate center-align"
                  v-model="obj.marja"
                  v-on:change="totalS(index)"
                />
              </td>
              <td class="width15">
                <input class="center-align" placeholder="0.00" v-model="obj.pret_t" number readonly />
              </td>
              <td class="delete right" @click="deleteServForm(index)">X</td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col s9">
            <br />
            <a class="btn blue-grey lighten-2" @click="addNewServForm">Adauga serviciu</a>
          </div>
          <table class="col s3">
            <tr>
              <td class="center-align bold">Servicii - Total fara TVA</td>
              <td class="center-align">
                <span>{{totalsS}}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <button class="btn" @click="saveServicii()">Salveaza</button>
    <router-link v-if="tip == 'oferta'" class="btn blue-grey lighten-2" :to="'/oferte-productie/single/'+id">Anuleaza</router-link>
    <router-link v-else-if="tip == 'lucrare'" class="btn blue-grey lighten-2" :to="'/lucrari-productie/single/'+id">Anuleaza</router-link>
    <!-- <pre>{{tip}} {{id}}</pre> -->
    <!-- <pre>{{serv_obj}}</pre> -->
  </div>
</template>

<script>
import { ModelSelect } from "vue-search-select";

export default {
  name: "EditServicii",
  data() {
    return {
      cost_fara_tvaS: "0.00",
      val_fara_tvaS: "0.00"
    };
  },
  props: ["serv_obj", "tip", "id"],
  components: {
    ModelSelect
  },
  computed: {
    selectPreturiServ() {
      return this.$store.getters.selectPreturiServ;
    },
    totalsS: function() {
      let v = 0;
      let t = 0;
      this.serv_obj.forEach(function(obj) {
        v = v + Number(obj.pret_v);
        t = t + Number(obj.pret_t);
      });
      this.cost_fara_tvaS = v.toFixed(2);
      this.val_fara_tvaS = t.toFixed(2);
      return this.val_fara_tvaS;
    }
  },
  methods: {
    addNewServForm() {
      let nr = this.serv_obj.length + 1;
      this.serv_obj.push({
        nr: "" + nr + "",
        denumire: "",
        cantitate: null,
        pret: null,
        um: "",
        pret_v: null,
        marja: null,
        pret_t: null
      });
    },
    deleteServForm(index) {
      if (this.serv_obj.length > 1) {
        this.serv_obj.splice(index, 1);
      }
    },
    setValoriServ(index) {
      let currentObj = this.serv_obj[index];
      let currentServ = this.selectPreturiServ.find(
        serv => serv.value === currentObj.denumire
      );
      this.serv_obj[index].pret = currentServ.pret;
      this.serv_obj[index].um = currentServ.um;
    },
    totalS(index) {
      let cantitate = Number(this.serv_obj[index].cantitate);
      let pret = Number(this.serv_obj[index].pret);
      let total = cantitate * pret;
      this.serv_obj[index].pret_v = total.toFixed(2);
      total = total + total * (Number(this.serv_obj[index].marja) / 100);
      this.serv_obj[index].pret_t = total.toFixed(2);
    },
    saveServicii() {
      let newServicii = {
        serv_obj: JSON.stringify(this.serv_obj),
        cost_fara_tvaS: this.cost_fara_tvaS,
        val_fara_tvaS: this.val_fara_tvaS,
        tip: this.tip,
        id: this.id
      };
      this.$store.dispatch("saveServicii", newServicii);
    }
  },
  created: function() {
    this.$store.dispatch("fetchPreturiServ");
  },
  mounted: function() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
td.delete {
  cursor: pointer;
  color: #e65100;
}
.save {
  width: 100%;
  padding: 6px 30px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}
.save p {
  margin-right: 40px;
}
</style>
