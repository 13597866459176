<template>
	<div>
		<p class="text-2xl font-bold mb-4">
			Genereaza contractul de executie lucrari
		</p>
		<h5 class="text-base text-gray-500 font-bold uppercase pb-4">
			Detalii suplimentare
		</h5>

		<v-form ref="addContractForm" v-model="contractForm" @submit.prevent="generateContract">
			<v-text-field v-model="contract_details.denumire" outlined :rules="[rules.required]"
				label="Denumirea generica in contract"></v-text-field>
			<div v-if="!+oferta_productie.firma">
				<hr class="mb-4">
				<div class="font-medium mb-2">Date identificare persoana</div>
				<v-radio-group v-model="tip_document" row mandatory>
					<template v-slot:label>
						<div class="mr-6">Document:</div>
					</template>
					<v-radio label="CI" value="CI" color="teal"></v-radio>
					<v-radio label="Alt document" value="ALT" color="teal"></v-radio>
				</v-radio-group>
				<div class="flex gap-4">
					<v-text-field v-model="contract_details.cnp" class="flex-grow-0 w-48" dense outlined
						label="CNP"></v-text-field>
					<div v-if="tip_document == 'CI'" class="flex flex-grow-0 gap-2">
						<v-text-field v-model="contract_details.serie_ci" class="w-28" maxlength="2" dense outlined
							:rules="[rules.required]" label="Serie CI"></v-text-field>
						<v-text-field v-model="contract_details.numar_ci" class="w-32" maxlength="6" dense outlined
							:rules="[rules.required]" label="Numar CI"></v-text-field>
					</div>
					<v-text-field v-else v-model="contract_details.alt_document" class="flex-grow-0 w-80" dense outlined
						:rules="[rules.required]" label="Document"></v-text-field>
				</div>
			</div>
			<hr class="mb-4">
			<div>
				<div class="font-medium mb-4">Adrese si montaj</div>
				<v-text-field v-model="contract_details.adresa" outlined dense :rules="[rules.required]"
					:label="addressLabel">

				</v-text-field>
				<div>
					<v-checkbox v-model="contract_details.alta_locatie" color="teal" class="mt-0"
						label="Alta adresa pentru montaj"></v-checkbox>
					<v-text-field v-if="contract_details.alta_locatie" v-model="contract_details.locatie" outlined dense
						:rules="[rules.required]" label="Adresa montaj">
					</v-text-field>
				</div>
				<div>
					<v-checkbox v-model="contract_details.montaj_separat" color="teal" class="mt-0"
						label="Montaj separat (alta firma)"></v-checkbox>
					<v-radio-group v-model="contract_details.finisaj_finalizat" row mandatory>
						<template v-slot:label>
							<div class="mr-6">Este finalizata partea de finisaje?</div>
						</template>
						<v-radio label="DA" :value="true" color="teal"></v-radio>
						<v-radio label="NU" :value="false" color="teal"></v-radio>
					</v-radio-group>
				</div>
			</div>
			<div class="flex flex-row justify-end gap-2">
				<v-btn color="blue-grey" class="uppercase" large dark @click="$emit('closeModal')">Anuleaza</v-btn>
				<v-btn color="teal" class="uppercase" large dark type="submit">
					<v-icon left dark>
						mdi-cloud-upload
					</v-icon>
					Genereaza</v-btn>
			</div>
		</v-form>
	</div>
</template>

<script>
import moment from "moment";

export default {
	name: "AddContractPorductie",
	components: {},
	data() {
		return {
			contractForm: false,
			today: moment().format("YYYY-MM-DD"),
			contract_details: {
				denumire: "gard din aluminiu vopsit electrostatic",
				serie_ci: "",
				numar_ci: "",
				alt_document: "",
				cnp: "",
				adresa: this.oferta_address,
				alta_locatie: false,
				montaj_separat: false,
				firma_montaj: false,
				finisaj_finalizat: true,
				locatie: ""
			},
			tip_document: "CI",
			rules: {
				required: value => !!value || 'Câmp obligatoriu'
			}
		};
	},
	computed: {
		oferta_productie() {
			return this.$store.getters.currentOfertaProductie;
		},
		activeLabelAdresaContract() {
			if (
				this.oferta_productie.adresa == null ||
				this.oferta_productie.adresa == ""
			) {
				return false;
			} else {
				return true;
			}
		},
		// montaj: {
		// 	get() {
		// 		return !this.oferta_productie.persoana;
		// 	},
		// 	set(val) {
		// 		this.contract_details.montaj_separat = val
		// 		this.contract_details.firma_montaj = val
		// 			? this.firma_montaj_activa.id_firma
		// 			: val;
		// 	}
		// },
		firma_montaj_activa() {
			return this.$store.getters.firma_montaj_activa;
		},
		addressLabel() {
			let str = 'Adresa domiciliu si montaj'
			if (this.contract_details.alta_locatie) {
				str = 'Adresa domiciliu'
			}
			return str
		}
	},
	watch: {
		'contract_details.alta_locatie'(newVal) {
			if (!newVal) {
				this.contract_details.locatie = ""
			}
		}
	},
	methods: {
		oferta_address() {
			let address = this.oferta_productie.adresa;
			if (this.oferta_productie.oras != null) {
				address += `, ${this.oferta_productie.oras}`;
			}
			this.contract_details.adresa = address;
		},
		async generateContract() {
			const valid = await this.$refs.addContractForm.validate();

			if (valid) {

				let newContract = {
					client_id: this.oferta_productie.id_client,
					oferta_id: this.oferta_productie.id_oferta,
					oferta_data: this.oferta_productie.data,
					user_id: this.oferta_productie.id_user,
					data: this.today,
					detalii: JSON.stringify(this.contract_details),
				};

				// save Contract
				this.$store.dispatch("addContractProductie", newContract);
				this.$emit('closeModal')
			}
		},
	},
	created: function () {
		this.$store.dispatch("fetchFirmeMontaj");
	},
	mounted: function () {
		this.contract_details.montaj_separat = !+this.oferta_productie.firma;
		if (this.firma_montaj_activa) {
			this.contract_details.firma_montaj = this.firma_montaj_activa.id_firma;
		}
		this.oferta_address();
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
