<template>
    <div class="flex gap-2">
        <v-btn icon text color="teal"
            :href="`https://hub.cuprolli.ro/docs/configurator/oferta_gard_${item.id_cerere}_${item.client_obj.name}_${item.client_obj.surname}.pdf`"
            :download="`oferta_gard_${item.id_cerere}_${item.client_obj.name}_${item.client_obj.surname}`"
            target="_blank">
            <v-icon>mdi-cloud-download</v-icon>
        </v-btn>
        <v-btn icon text color="teal" @click="moveToOferte()">
            <v-icon>mdi-file-document-plus</v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    props: {
        item: Object,
        user: Object,
        today: String
    },
    data() {
        return {
            prices: {
                p_rama: 1.2, // %
                pp: 145, // eur
                pa: 35, // eur
                pap: 145, // eur
            },
            new_prices: {
                p_rama: 1.28, // %
                pp: 265, // eur
                pa: 45, // eur
                pap: 115, // eur
            },
            new_new_prices: {
                p_rama: 1.245, // %
                pp: 235, // eur
                pa: 75, // eur
                pap: 115, // eur
            },
            newest_prices: {
                p_rama: 1.245, // %
                pp: 235, // eur
                pa: 100, // eur
                pap: 210, // eur
            },
            newest_prices2: {
                p_rama: 1.191, // %
                pp: 236, // eur
                pa: 115, // eur
                pap: 247, // eur
            },
            newest_prices3: {
                p_rama: 1.27, // %
                pp: 237, // eur
                pa: 126, // eur
                pap: 254, // eur
            },
            newest_prices4: {
                p_rama: 35, // %
                pp: 212, // eur
                pa: 122, // eur
                pap: 252, // eur
            },
            partener_prices: {
                add_p: 7, // %
                p_rama: 1.233, // %
                pp: 228, // eur
                pa: 103, // eur
                pap: 178, // eur
            },
            new_partener_prices: {
                add_p: 7, // %
                p_rama: 1.233, // %
                pp: 228, // eur
                pa: 128, // eur
                pap: 273, // eur
            },
            suprafata_limita_panou: 0.1,
            suprafata_limita_pp: 1.9,
            suprafata_limita_pa: 6.7,
            suprafata_limita_pa_pp_included: 4.8,
        }
    },
    methods: {
        raportSuprafataMin(suprafata, minsuprafata) {
            if (suprafata < minsuprafata) {
                return 2 - suprafata / minsuprafata;
            } else {
                return 1;
            }
        },
        nrProfilePoarta(dim, panel) {
            let grosime_rama = 70;
            let dimensiune = dim - grosime_rama * 2;
            let nr =
                (dimensiune + panel.selectedProfil) /
                (panel.selectedProfil + Number(panel.gol_calculat)) -
                1;
            let mp = 1;
            if (panel.selectedSize == "medium") {
                mp = 2;
            } else if (panel.selectedSize == "large") {
                mp = 3;
            }
            return Math.ceil(nr - mp);
        },
        golPoarta(dim, nrProfile, profil) {
            let grosime_rama = 70;
            let dimensiune = dim - grosime_rama * 2;
            let gol = (dimensiune - nrProfile * profil) / (nrProfile + 1);
            return gol;
        },
        getFormatedGardObj(gard, price, id_cerere) {
            let arr = [];
            let priceVar;
            if (Number(id_cerere) >= 27700) {
                priceVar = this.newest_prices4;
            } else if (Number(id_cerere) >= 11834) {
                priceVar = this.newest_prices3;
            } else if (Number(id_cerere) >= 8838) {
                priceVar = this.newest_prices2;
            } else if (id_cerere >= "6446") {
                priceVar = this.newest_prices;
            } else if (id_cerere >= "4216") {
                priceVar = this.new_new_prices;
            } else if (id_cerere >= "2759") {
                priceVar = this.new_prices;
            } else {
                priceVar = this.prices;
            }

            let pricePanouri = gard.configuration.panouri.panelFrame
                ? price + priceVar.p_rama
                : price;
            let suprafataPanou = (
                gard.suprafataPanouri / gard.configuration.panouri.buc
            ).toFixed(3);
            let costModul = (
                suprafataPanou *
                pricePanouri *
                this.raportSuprafataMin(suprafataPanou, this.suprafata_limita_panou)
            ).toFixed(2);
            let gol = Math.round(gard.panel.gol_calculat * 10) / 10;
            let marja = this.user.username == "Mihai Zamfir" ? 10 : 0;
            let modelName =
                gard.model == "orizontal" || gard.model == "horizontal"
                    ? "orizontal"
                    : "vertical";

            if (gard.configuration.panouri.is) {
                // One obj for all panels
                let panelObj = {
                    nr: 1,
                    tip: gard.configuration.panouri.panelFrame ? "Panou_rama" : "Panou",
                    model: gard.model == "horizontal" ? 1 : 2,
                    denumire_model: modelName,
                    latime_profil: gard.panel.selectedProfil,
                    distanta_gol: gol.toFixed(1),
                    gol_afisat: "",
                    p_gol: "",
                    nr_profile: gard.panel.nrProfile,
                    grosime_rama: "70",
                    filename: gard.configuration.panouri.panelFrame
                        ? `${modelName}_panou_rama.png`
                        : `${modelName}_panou.png`,
                    pret: pricePanouri,
                    latime: gard.lPanou.toFixed(0),
                    inaltime: Number(gard.hPanou) * 1000,
                    buc: gard.configuration.panouri.buc,
                    suprafata: suprafataPanou,
                    cost_modul: costModul,
                    cost_total: (costModul * gard.configuration.panouri.buc).toFixed(2),
                    marja: marja,
                    pret_modul: (
                        Number(costModul) * gard.configuration.panouri.buc +
                        Number(costModul) * gard.configuration.panouri.buc * (marja / 100)
                    ).toFixed(2),
                    min_gol: "10",
                };
                arr.push(panelObj);
            }

            let nr_crt = 2;

            let pricePP = price + priceVar.pp;
            let pricePA = price + priceVar.pa;
            let pricePAP = price + priceVar.pap;

            // One obj for each poarta pietonala
            if (gard.configuration.poarta_p.is) {
                for (let i = 0; i < gard.configuration.poarta_p.items.length; i++) {
                    let lPoarta = gard.configuration.poarta_p.items[i].latime_gol;
                    let hPoarta = gard.dimensions.ht * 1000;
                    let suprafataPP = ((lPoarta / 1000) * hPoarta) / 1000;
                    let costPoarta = (
                        suprafataPP *
                        pricePP *
                        this.raportSuprafataMin(suprafataPP, this.suprafata_limita_pp)
                    ).toFixed(2);
                    let nrProfile = this.nrProfilePoarta(hPoarta, gard.panel);
                    let golPoarta = this.golPoarta(
                        hPoarta,
                        nrProfile,
                        gard.panel.selectedProfil
                    );

                    let poartaPObj = {
                        nr: nr_crt,
                        tip: "Poarta_mica",
                        model: gard.model == "horizontal" ? 1 : 2,
                        denumire_model: modelName,
                        latime_profil: gard.panel.selectedProfil,
                        distanta_gol: golPoarta.toFixed(1),
                        gol_afisat: "",
                        p_gol: "",
                        nr_profile: nrProfile,
                        filename: `${modelName}_poarta_mica.png`,
                        pret: pricePP,
                        latime: lPoarta,
                        inaltime: hPoarta,
                        buc: 1,
                        suprafata: suprafataPP,
                        cost_modul: costPoarta,
                        cost_total: costPoarta,
                        marja: marja,
                        pret_modul: (
                            Number(costPoarta) +
                            costPoarta * (marja / 100)
                        ).toFixed(2),
                        min_gol: "10",
                        grosime_rama: "70",
                    };
                    arr.push(poartaPObj);
                    nr_crt++;
                }
            }

            // One obj for each poarta auto
            if (gard.configuration.poarta_a.is) {
                for (let i = 0; i < gard.configuration.poarta_a.items.length; i++) {
                    let lPoarta = gard.configuration.poarta_a.items[i].latime_gol;
                    let tip_poarta_mare = gard.configuration.poarta_a.items[i].tip;
                    // if (tip_poarta_mare == "culisanta") {
                    //     lPoarta += 500;
                    // }

                    let hPoarta = gard.dimensions.ht * 1000;
                    let pp_included = gard.configuration.poarta_a.items[i].pp_included;
                    let latime_pp = gard.configuration.poarta_a.items[i].latime_pp;

                    let suprafataPA = ((lPoarta / 1000) * hPoarta) / 1000;
                    let suprafataPPIncluded = pp_included
                        ? ((latime_pp / 1000) * hPoarta) / 1000
                        : 0;

                    let costPA = 0;
                    let costPP = 0;

                    if (pp_included) {
                        costPA = (
                            (suprafataPA - suprafataPPIncluded) *
                            (tip_poarta_mare == "autoportanta" ? pricePAP : pricePA) *
                            this.raportSuprafataMin(suprafataPA, this.suprafata_limita_pa_pp_included)).toFixed(2);
                        costPP =
                            (suprafataPPIncluded *
                                pricePP *
                                this.raportSuprafataMin(
                                    suprafataPPIncluded,
                                    this.suprafata_limita_pp
                                )).toFixed(2);
                    } else {
                        costPA =
                            suprafataPA *
                            (tip_poarta_mare == "autoportanta" ? pricePAP : pricePA) *
                            this.raportSuprafataMin(suprafataPA, this.suprafata_limita_pa);
                    }
                    let costPoarta = (Number(costPA) + Number(costPP)).toFixed(2);

                    let nrProfile = this.nrProfilePoarta(hPoarta, gard.panel);
                    let golPoarta = this.golPoarta(
                        hPoarta,
                        nrProfile,
                        gard.panel.selectedProfil
                    );

                    let poartaAObj = {
                        nr: nr_crt,
                        tip: "Poarta_mare",
                        model: gard.model == "horizontal" ? 1 : 2,
                        pp_included: pp_included,
                        denumire_model: modelName,
                        latime_profil: gard.panel.selectedProfil,
                        distanta_gol: golPoarta.toFixed(1),
                        gol_afisat: "",
                        p_gol: "",
                        nr_profile: nrProfile,
                        filename: `${modelName}_poarta_mare${tip_poarta_mare == "autoportanta"
                            ? 3
                            : tip_poarta_mare == "culisanta"
                                ? 2
                                : ""
                            }${pp_included ? "_pp" : ""}.png`,
                        pret: tip_poarta_mare == "autoportanta" ? pricePAP : pricePA,
                        latime: lPoarta,
                        inaltime: hPoarta,
                        latime_pp: latime_pp,
                        buc: 1,
                        suprafata: suprafataPA,
                        cost_modul: costPoarta,
                        cost_total: costPoarta,
                        marja: marja,
                        pret_modul: (
                            Number(costPoarta) +
                            Number(costPoarta) * (marja / 100)
                        ).toFixed(2),
                        min_gol: "10",
                        grosime_rama: "70",
                        tip_poarta_mare: tip_poarta_mare,
                    };
                    arr.push(poartaAObj);
                    nr_crt++;
                }
            }
            return JSON.stringify(arr);
        },
        moveToOferte() {
            let marja = this.user.username == "Mihai Zamfir" ? 10 : 0;

            let newOferta = {
                nume: `${this.item.client_obj.surname} ${this.item.client_obj.name}`,
                adresa: "jud. " + this.item.client_obj.judet.text,
                email: this.item.client_obj.email,
                tel: this.item.client_obj.phone,
                data: this.today,
                tip_lucrare: "gard",
                country: "ro",
                denumire_lucrare: "gard din aluminiu vopsit electrostatic",
                noua: 0,
                gard_obj: this.getFormatedGardObj(
                    this.item.gard_obj,
                    this.item.client_obj.judet.price,
                    this.item.id_cerere
                ),
                color: this.item.gard_obj.color.name,
                cost_fara_tvaG: this.item.gard_obj.pretTotal,
                val_fara_tvaG:
                    Number(this.item.gard_obj.pretTotal) +
                    this.item.gard_obj.pretTotal * (marja / 100),
                user_id: this.user.id_user,
            };

            // console.log(newOferta);
            this.$store.dispatch("moveToOferteGarduri", newOferta);
        },
    }
}
</script>