
export const carport_ad = {
    print_carport_ad( doc, space, images ) {
        space += 10;

        doc.setLineWidth(0.3);
        doc.setLineDash([1, 1], 0);
        doc.line(8, space, 200, space);
        space += 5;
        doc.setLineDash([], 0);
        doc.addImage(images.data.carport, "JPEG", 110, space, 100, 67);
        space = space + 7;
        doc.setFont("Roboto", "bold");
        doc.setFontSize(10);
        doc.text("CARPORT ALUMINIU", 10, space);
        doc.text("Adăpostul ideal pentru mașina dumneavoastră", 10, space + 6);
        doc.setFontSize(8);
        doc.setFont("Roboto", "normal");

        doc.text("VEZI DETALII", 10, space + 14);
        doc.text("--> https://garduri-aluminiu.ro/carport-aluminiu", 10, space + 20);

        const desc = [
            "Carportul din aluminiu este un adapost elegant pentru mașina dumneavoastră cu o funcționalitate simplă și clară: protejează automobilul de intemperii.", 
            "El poate fi instalat practic deasupra oricărui loc de parcare, indiferent de locație, atâta timp cât spațiul permite.", 
        ]
        const descSplit = doc.splitTextToSize(desc, 110);
		
        doc.text(descSplit, 10, space + 34, {
            lineHeightFactor: 1.4,
        });
    }
}