<template>
  <div class="container">
    <h5 v-if="isNull" class="header">Adauga materia prima</h5>
    <h5 v-else class="header">Editeaza materia prima</h5>
    <EditMateriePrima v-if="currentOferta" :produse_obj="isNull ? produse : currentOferta.produse_obj" :tip="'oferta'" :id="currentOferta.id_oferta"></EditMateriePrima>
    <!-- <pre>{{currentOferta}}</pre> -->
  </div>
</template>

<script>
import EditMateriePrima from "@/components/Productie/EditMateriePrima";

export default {
  name: "OfertaMateriePrima",
  data() {
    return {
      produse: [
        {
          nr: "1",
          material: "",
          denumire: {},
          aliaj: "",
          gspec: "",
          dimL: null,
          dimH: null,
          dimG: null,
          cantitate: "",
          cantitate_kg: "",
          um: "",
          pret: "",
          pret_v: "",
          marja: "",
          pret_t: "",
          aliaje: []
        }
      ]
    };
  },
  components: {
    EditMateriePrima
  },
  computed: {
    currentOferta() {
      return this.$store.getters.currentOfertaProductie;
    },
    isNull() {
      if (this.currentOferta.produse_obj) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {},
  created: function() {
    this.$store.dispatch("fetchOfertaProductie", this.$route.params.id);
  },
  mounted: function() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
