<template>
  <div>
    <div class="row">
      <div class="input-field filter-input col s7" v-if="listaMateriale.length > 0">
        <input placeholder="Cauta profil" v-model="filterInput">
      </div>
      <div class="col s3 offset-s2 info">
          <i class="material-icons">info</i>
          <h6>Stoc Profile debitate - materiale</h6>
      </div>
    </div>
    <div class="card-panel">
      <table class="highlight responsive-table">
        <thead v-if="listaMateriale.length > 0">
          <tr>
            <th class="width3">Nr.</th>
            <th class="width15">Tip profil</th>
            <th>Lungime</th>
            <th>Cantitate</th>
            <th>Greutate neta</th>
            <th>Comanda</th>
            <th>Status comanda</th>
            <th>Incarca pe aviz</th>
          </tr>
        </thead>
        <thead v-else>
          <p class="bold">Nu exista materiale in stoc</p>
        </thead>
        <tbody>
          <tr v-for="material, index in filterBy(listaMateriale, filterInput)">
            <td>{{index + 1}}</td>
            <td>{{material.profil_tip}}</td>
            <td>{{material.lungime_finala }} mm</td>
            <td>{{material.cantitate}} buc</td>
            <td>{{greutate(material.lungime_finala, material.profil_greutate_ml, material.cantitate)}} kg</td>
            <td v-if="material.comanda_obj != undefined">{{material.comanda_obj.nr}} | {{material.comanda_obj.pos}} | {{material.comanda_obj.subpos}}</td>
            <td>{{status(material.cantitate, material.cantitate_finala)}}</td>
            <td>
              <label>
                <input type="checkbox" :value="material.id" v-model="selected" @change="$emit('selectMat', selected)"/>
                <span> </span>
              </label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <pre>Selected Materiale: {{$data}}</pre> -->
    <!-- <pre>{{listaMateriale}}</pre> -->
  </div>
</template>

<script>

export default {
  name: 'StocMateriale',
  data () {
    return {
      filterInput: '',
      selected: []
    }
  },
  props: ['listaMateriale'],
  computed: {

  },
  methods: {
    filterBy(list, value){
      value = value.charAt(0).toLowerCase() + value.slice(1);
      return list.filter(function(profil){
        return profil.profil_tip.toLowerCase().indexOf(value) > -1;
      });
    },
    status(a, b) {
      if (Number(a)<Number(b)) {
        return 'incompleta'
      } else {
        return 'ok'
      }
    },
    greutate(cota, gml, buc) {
      return Math.round(cota/1000 * gml/1000 * buc)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.info {
  margin-top: 20px;
}
.info h6 {
  vertical-align: middle;
  margin: 0;
  display: inline-block;
}
.info i {
  vertical-align: middle;
  margin-right: 10px;
  display: inline-block;
}
</style>
