<template>
	<div>
		<h6 class="text-base font-bold">Genereaza fisa marfa pentru livrare</h6>
		<table class="responsive-table materiale-table">
			<thead>
				<tr class="head">
					<th class="">Nr.</th>
					<th>Denumire produs</th>
					<th class="center-align">Cantitate</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(produs, index) in matDepozit" :key="produs.nr">
					<td class="width5">{{ index + 1 }}</td>
					<td><input type="text" v-model="produs.denumire" /></td>
					<td class="width20">
						<input
							class="center-align"
							type="text"
							v-model="produs.cantitate"
						/>
					</td>
					<td
						class="width3 delete center-align"
						@click="deleteProductDepozit(index)"
					>
						X
					</td>
				</tr>
			</tbody>
		</table>
		<br />
		<p class="text-sm text-gray-500 font-bold">Detalii livrare</p>
		<div class="flex -mx-4">
			<div class="input-field w-2/3 mx-4">
				<label class="active">Adresa livrare</label>
				<input type="text" class="validate" v-model="adresa_livrare" />
			</div>
			<div class="input-field w-1/3 mx-4">
				<select id="mod_livrare" v-model="mod_livrare">
					<option value="" disabled selected>Selecteaza</option>
					<option value="curierat">Curierat</option>
					<option value="transportator">Transportator</option>
					<option value="ridicare de catre client">
						Ridicare de catre client
					</option>
					<option value="regie proprie">Regie proprie</option>
				</select>
				<label>Modalitate livrare</label>
			</div>
		</div>
		<div class="flex -mx-4">
			<div class="input-field w-1/3 mx-4">
				<label>Mod ambalare</label>
				<input type="text" class="validate" v-model="mod_ambalare" />
			</div>
			<div class="input-field w-1/3 mx-4">
				<label class="active">Data livrare</label>
				<input type="date" class="validate" v-model="data_livrare" />
			</div>
			<div class="input-field w-1/3 mx-4">
				<label class="active">Responsabil</label>
				<input type="text" class="validate" v-model="responsabil" />
			</div>
		</div>
		<div class="flex justify-between mt-2">
			<a href="#!" class="modal-action modal-close btn grey">Inchide</a>
			<div class="btn-group">
				<button class="btn blue-grey" v-on:click="downloadPDF()">
					<i class="material-icons left">file_download</i>Descarca PDF
				</button>
				<button class="btn lighten-2" v-on:click="emailPDF()">
					<i class="material-icons left">email</i>Email PDF
				</button>
			</div>
		</div>
		<!-- <pre>{{client}}</pre> -->
	</div>
</template>

<script>
import images from "../../assets/oferta_images.js";
import jsPDF from "jspdf";
import fonts from "../../assets/fonts/fonts.js";
import moment from "moment";

export default {
	name: "FisaDepozit",
	data() {
		return {
			mod_ambalare: "",
			mod_livrare: "",
			data_livrare: "",
			responsabil: "Dani",
		};
	},
	props: ["matDepozit", "adresa_livrare", "client", "idComanda"],
	computed: {
		pdfName() {
			return "Fisa livrare comanda nr." + this.idComanda;
		},
	},
	methods: {
		deleteProductDepozit(index) {
			if (this.matDepozit.length > 1) {
				this.matDepozit.splice(index, 1);
			}
		},
		formValidation() {
			if (
				!this.mod_ambalare ||
				!this.mod_livrare ||
				!this.data_livrare ||
				!this.responsabil
			) {
				alert("Completati toate campurile");
				return;
			} else {
				return true;
			}
		},
		downloadPDF() {
			const doc = this.createFisaPDF();

			if (this.formValidation()) {
				doc.save(this.pdfName + ".pdf");
				M.Modal.getInstance(document.querySelector("#modalLivrare")).close();
			}
		},
		emailPDF() {
			const doc = this.createFisaPDF();

			if (this.formValidation()) {
				const pdf = btoa(doc.output());
				this.$http
					.post(
						process.env.VUE_APP_HUB_API_HOST + "email/vanzari/fisa_livrare",
						{
							data: pdf,
							id_oferta: this.idComanda,
							client: this.client.nume,
						},
						{
							headers: { x_api_key: process.env.VUE_APP_HUB_API_KEY },
						}
					)
					.then(function(response) {
						M.toast({ html: response.body, classes: "blue" });
					})
					.catch(function(error) {
						console.log(error);
						M.toast({
							html: "Eroare! Emailul nu a fost trimis",
							classes: "red darken-1",
						});
					});
				M.Modal.getInstance(document.querySelector("#modalLivrare")).close();
			}
		},
		createFisaPDF() {
			var logo = images.data.logo;
			var doc = new jsPDF();

			//
			// General Settings for Document
			// Font Family
			// add the font to jsPDF
			doc.addFileToVFS("Roboto-Regular.ttf", fonts.robotoRegular);
			doc.addFileToVFS("Roboto-Bold.ttf", fonts.robotoBold);
			doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
			doc.addFont("Roboto-Bold.ttf", "Roboto", "bold");

			// set font
			doc.setFont("Roboto", "normal");
			doc.setTextColor(100);
			doc.setFontSize(9);

			// Document header
			// Logo
			doc.addImage(logo, "JPEG", 8, 8, 27, 12);
			doc.setFont("Roboto", "normal");
			// Header info
			doc.setFont("Roboto", "bold");
			doc.text("CUI: ", 8, 30);
			doc.text("Nr. Reg. Com.: ", 8, 35);
			doc.text("Adresa: ", 8, 40);
			// doc.text("Email: ", 170, 30);
			// doc.text("Web: ", 170, 35);
			doc.setFontSize(10);
			doc.text("Cuprolli Manufacturing srl", 8, 25);
			doc.setFontSize(9);
			doc.setFont("Roboto", "normal");
			doc.text("RO 35671874", 15, 30);
			doc.text("J35/468/2016", 31, 35);
			doc.text("Str. Caprioarei, nr.11", 21, 40);
			doc.text("307200 Ghiroda, jud. Timis", 8, 45);
			// doc.text("office@cuprolli.ro", 180, 30);
			// doc.text("www.cuprolli.ro", 180, 35);

			// Document body
			doc.setFontSize(18);
			doc.setTextColor(243, 153, 46);
			doc.setFont("Roboto", "bold");
			doc.text("Fisa livrare pentru comanda nr. " + this.idComanda, 8, 60);
			// doc.setFontSize(14);
			doc.setTextColor(100);
			// doc.text("Client: " + this.client.nume, 8, 70);
			doc.setFontSize(12);
			doc.text("Adresa: ", 8, 78);
			doc.setFont("Roboto", "normal");
			let drop = 78;
			if (this.adresa_livrare.length >= 86) {
				let adresaSplit = doc.splitTextToSize(this.adresa_livrare, 150);
				doc.text(adresaSplit, 26, drop);
				drop = drop + 5;
			} else {
				doc.text(this.adresa_livrare, 26, drop);
			}
			// drop = drop + 8;
			// doc.setFont("Roboto", "bold");
			// doc.text("Telefon: ", 8, drop);
			// doc.setFont("Roboto", "normal");
			// doc.text(this.client.tel, 26, drop);

			let space = 100;

			doc.setFontSize(16);
			doc.setFont("Roboto", "bold");
			doc.text("PRODUSE", 8, space + 4);

			// Products table
			//
			if (this.matDepozit) {
				doc.setFont("Roboto", "bold");
				doc.setFontSize(12);
				doc.setTextColor(100);
				doc.text("Nr.", 8, space + 15);
				doc.text("Denumire produs", 15, space + 15);
				doc.text("Cantitate", 170, space + 15);
				doc.setDrawColor(150);
				doc.setLineWidth(0.4);
				doc.line(8, space + 17, 200, space + 17);
				space = space + 23;
				doc.setFont("Roboto", "normal");
				let nr = 0;
				this.matDepozit.forEach(function(produs) {
					nr = nr + 1;
					doc.text(nr + "", 9, space);
					doc.text(produs.denumire, 15, space);
					doc.text(produs.cantitate, 198, space, {
						align: "right",
					});
					doc.setLineWidth(0.2);
					doc.line(8, space + 3, 200, space + 3);
					space = space + 8;
				});
				space = space + 10;
			}

			doc.setFont("Roboto", "bold");
			doc.text("Mod ambalare: ", 8, space);
			// Split text if longer than page width
			doc.setFont("Roboto", "normal");
			if (this.mod_ambalare.length >= 86) {
				let ambalareSplit = doc.splitTextToSize(this.mod_ambalare, 150);
				doc.text(ambalareSplit, 40, space);
				space = space + 5;
			} else {
				doc.text(this.mod_ambalare, 40, space);
			}
			doc.setFont("Roboto", "bold");
			doc.text("Modalitate de livrare: ", 8, space + 8);
			doc.setFont("Roboto", "normal");
			doc.text(this.mod_livrare, 52, space + 8);
			doc.setFont("Roboto", "bold");
			doc.text("Data livrare: ", 8, space + 16);
			doc.setFont("Roboto", "normal");
			doc.text(
				moment(String(this.data_livrare)).format("DD.MM.YYYY"),
				35,
				space + 16
			);
			doc.setFont("Roboto", "bold");
			doc.text("Responsabil: ", 8, space + 24);
			doc.setFont("Roboto", "normal");
			doc.text(this.responsabil, 37, space + 24);

			return doc;
		},
	},
	created: function() {},
	mounted: function() {
		M.FormSelect.init(document.querySelectorAll("select"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table tr {
	border-bottom: none;
}
table tr.head {
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
table td {
	padding: 0 5px;
}
table input[type="text"]:not(.browser-default) {
	margin: 0;
}
td.delete {
	padding: 15px 15px 15px 0;
	cursor: pointer;
	color: #e65100;
}
</style>
