<template>
  <div>
    <div class="card-panel row">
      <table class="responsive-table manopera">
        <thead>
          <tr>
            <th class="center-align">Nr.</th>
            <th>Denumire</th>
            <th class="center-align">Nr. oameni</th>
            <th class="center-align">Nr. ore</th>
            <th class="center-align">Pret/ora <br /> - lei - </th>
            <!-- <th class="center-align">UM</th> -->
            <th class="center-align">Marja %</th>
            <th class="center-align">Valoare fara TVA</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(obj,index) in manopera_obj">
            <td class="width5">
              <input type="number" class="validate center-align" v-model="obj.nr" />
            </td>
            <td class="width35">
              <model-select id="manopera" :options="selectManopera" v-model="obj.tip" @input="setValoriManopera(index)"></model-select>
            </td>
            <td class="width10">
              <input type="number" step="1" class="validate center-align" v-model="obj.oameni"  v-on:change="totalM(index)"/>
            </td>
            <td class="width10">
              <input type="number" step="1" class="validate center-align" v-model="obj.ore" v-on:change="totalM(index)"/>
            </td>
            <td class="width10">
              <input class="center-align" v-model="obj.pret" readonly />
            </td>
            <!-- <td class="width10"><input class="center-align" v-model="obj.um" readonly></td> -->
            <td class="width10">
              <input type="number" step="0.1" value="1" placeholder="0" class="validate center-align" v-model="obj.marja" v-on:change="totalM(index)"/>
            </td>
            <td class="width15">
              <input class="center-align" placeholder="0.00" v-model="obj.pret_t" number readonly />
            </td>
            <td class="delete right" @click="deleteManoperaForm(index)">X</td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col s9">
          <br />
          <a class="btn blue-grey lighten-2" @click="addNewManoperaForm">Adauga manopera</a>
        </div>
        <table class="col s3">
          <tr>
            <td class="center-align bold">Manopera - Total fara TVA</td>
            <td class="center-align">
              <span>{{totalsM}}</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <button class="btn" @click="saveManopera()">Salveaza</button>
    <router-link v-if="tip == 'oferta'" class="btn blue-grey lighten-2" :to="'/oferte-productie/single/'+id">Anuleaza</router-link>
    <router-link v-else-if="tip == 'lucrare'" class="btn blue-grey lighten-2" :to="'/lucrari-productie/single/'+id">Anuleaza</router-link>
    <!-- <pre>{{tip}} {{id}}</pre> -->
    <!-- <pre>{{manopera_obj}}</pre> -->
  </div>
</template>

<script>
import { ModelSelect } from "vue-search-select";

export default {
  name: "EditManopera",
  data() {
    return {
      cost_fara_tvaM: "0.00",
      val_fara_tvaM: "0.00"
    };
  },
  props: ["manopera_obj", "tip", "id"],
  components: {
    ModelSelect
  },
  computed: {
    selectManopera() {
      return this.$store.getters.selectManopera;
    },
    totalsM: function() {
      let v = 0;
      let t = 0;
      this.manopera_obj.forEach(function(obj) {
        v = v + Number(obj.pret_v);
        t = t + Number(obj.pret_t);
      });
      this.cost_fara_tvaM = v.toFixed(2);
      this.val_fara_tvaM = t.toFixed(2);
      return this.val_fara_tvaM;
    }
  },
  methods: {
    addNewManoperaForm() {
      let nr = this.manopera_obj.length + 1;
      this.manopera_obj.push({
        nr: "" + nr + "",
        tip: "",
        oameni: "",
        ore: "",
        pret: "",
        um: "",
        pret_v: "",
        marja: "",
        pret_t: ""
      });
    },
    deleteManoperaForm(index) {
      if (this.manopera_obj.length > 1) {
        this.manopera_obj.splice(index, 1);
      }
    },
    setValoriManopera(index) {
      let currentObj = this.manopera_obj[index];
      let currentTip = this.selectManopera.find(
        tip => tip.value === currentObj.tip
      );
      this.manopera_obj[index].pret = currentTip.pret;
      this.manopera_obj[index].um = currentTip.um;
    },
    totalM(index) {
      let oameni = Number(this.manopera_obj[index].oameni);
      let ore = Number(this.manopera_obj[index].ore);
      let pret = Number(this.manopera_obj[index].pret);
      let total = oameni * ore * pret;
      this.manopera_obj[index].pret_v = total.toFixed(2);
      total = total + total * (Number(this.manopera_obj[index].marja) / 100);
      this.manopera_obj[index].pret_t = total.toFixed(2);
    },
    saveManopera() {
      let newManopera = {
        manopera_obj: JSON.stringify(this.manopera_obj),
        cost_fara_tvaM: this.cost_fara_tvaM,
        val_fara_tvaM: this.val_fara_tvaM,
        tip: this.tip,
        id: this.id
      };
      this.$store.dispatch("saveManopera", newManopera);
    }
  },
  created: function() {
    this.$store.dispatch("fetchManopera");
  },
  mounted: function() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
td.delete {
  cursor: pointer;
  color: #e65100;
}
.save {
  width: 100%;
  padding: 6px 30px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}
.save p {
  margin-right: 40px;
}
</style>
