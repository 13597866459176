<template>
	<li class="flex justify-between items-center">
		<div class="flex ">
			<div>
				<div class="font-bold">{{ firma.denumire_firma }}</div>
				<div>{{ firma.cui }} | {{ firma.regcom }}</div>
			</div>
			<div class="ml-10">
				<div>Adresa: {{ firma.adresa }}</div>
				<div>{{ firma.bank_account }} | {{ firma.bank }}</div>
			</div>
			<div class="ml-10">
				<div>{{ firma.person }}</div>
			</div>
		</div>
		<div class="flex">
			<i
				class="material-icons text-gray-200 px-2 py-1 mr-4 cursor-pointer"
				:class="{ 'text-green-500 transform rotate-45': isActive }"
				@click="markFirmaActiva"
				>push_pin</i
			>
			<i
				class="material-icons text-gray-500 px-2 py-1 cursor-pointer"
				@click="openEditModal"
				>edit</i
			>
		</div>
	</li>
</template>

<script>
export default {
	name: "FirmaMontaj",
	props: ["firma", "id_firma_activa"],
	data() {
		return {};
	},
	computed: {
		isActive() {
			return this.firma.id_firma === this.id_firma_activa;
		},
	},
	methods: {
		markFirmaActiva() {
			this.$store.dispatch("markFirmaActiva", {
				id_firma_activa: this.firma.id_firma,
			});
		},
		openEditModal() {
			console.log("Edit Modal Open");
		},
	},
};
</script>

<style scoped></style>
