var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.editor && _vm.editingMode)?_c('bubble-menu',{staticClass:"bubble-menu",attrs:{"tippy-options":{ duration: 100 },"editor":_vm.editor}},[_c('button',{class:{ 'is-active': _vm.editor.isActive('heading', { level: 1 }) },on:{"click":function($event){_vm.editor
				.chain()
				.focus()
				.toggleHeading({ level: 1 })
				.run()}}},[_vm._v(" Title1 ")]),_c('button',{class:{ 'is-active': _vm.editor.isActive('heading', { level: 2 }) },on:{"click":function($event){_vm.editor
				.chain()
				.focus()
				.toggleHeading({ level: 2 })
				.run()}}},[_vm._v(" Title2 ")]),_c('button',{staticClass:"cursor-default",attrs:{"disabled":""}},[_c('span',{staticClass:"px-2"})]),_c('button',{class:{ 'is-active': _vm.editor.isActive('orderedList') },on:{"click":function($event){_vm.editor
				.chain()
				.focus()
				.toggleOrderedList()
				.run()}}},[_vm._v(" List ")]),_c('button',{class:{ 'is-active': _vm.editor.isActive('bulletList') },on:{"click":function($event){_vm.editor
				.chain()
				.focus()
				.toggleBulletList()
				.run()}}},[_vm._v(" List "),_c('span',[_vm._v("•")]),_c('span',[_vm._v("•")]),_c('span',[_vm._v("•")])]),_c('button',{staticClass:"cursor-default",attrs:{"disabled":""}},[_c('span',{staticClass:"px-2"})]),_c('button',{staticClass:"cursor-default",attrs:{"disabled":""}},[_c('span',{staticClass:"px-2"})]),_c('button',{class:{ 'is-active': _vm.editor.isActive({ textAlign: 'left' }) },on:{"click":function($event){_vm.editor
				.chain()
				.focus()
				.setTextAlign('left')
				.run()}}},[_vm._v(" left ")]),_c('button',{class:{ 'is-active': _vm.editor.isActive({ textAlign: 'center' }) },on:{"click":function($event){_vm.editor
				.chain()
				.focus()
				.setTextAlign('center')
				.run()}}},[_vm._v(" center ")]),_c('button',{class:{ 'is-active': _vm.editor.isActive({ textAlign: 'right' }) },on:{"click":function($event){_vm.editor
				.chain()
				.focus()
				.setTextAlign('right')
				.run()}}},[_vm._v(" right ")])]):_vm._e(),_c('editor-content',{staticClass:"pb-10",attrs:{"editor":_vm.editor}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }