<template>
  <v-card class="py-8 px-6">
    <v-form ref="editClientForm" v-model="validClientForm" @submit.prevent="updateClientDetails">
      <div class="grid grid-cols-2 gap-x-4">
        <v-text-field v-if="!!+client.firma" label="CUI" text outlined dense :rules="[rules.required]"
          v-model="client.cui"></v-text-field>
        <v-text-field v-if="!!+client.firma" label="Reg Com" text outlined dense :rules="[rules.required]"
          v-model="client.reg_com"></v-text-field>
        <v-text-field :label="!!+client.firma ? 'Denumire' : 'Nume si prenume'" text outlined dense
          :rules="[rules.required]" v-model="client.nume"></v-text-field>
        <v-text-field v-if="!!+client.firma" label="Nume persoana" text outlined dense :rules="[rules.required]"
          v-model="client.persoana"></v-text-field>
        <v-text-field class="col-span-2" label="Adresa" text outlined dense v-model="client.adresa"></v-text-field>
        <v-text-field label="Email" text outlined dense :rules="[rules.email, rules.isEmailOrPhone]"
          v-model="client.email"></v-text-field>
        <v-text-field label="Telefon" text outlined dense :rules="[rules.phone, rules.isEmailOrPhone]"
          v-model="client.tel"></v-text-field>
      </div>
      <div class="flex flex-row justify-end gap-2 mt-4">
        <v-btn color="blue-grey" class="uppercase" dark large @click="$emit('cancelEdit')">Anuleaza</v-btn>
        <v-btn color="teal" dark class="uppercase" large type="submit">Salveaza</v-btn>
      </div>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: 'EditClient',
  props: {
    clientData: Object
  },
  data() {
    return {
      client: { ...this.clientData },
      validClientForm: false,
      rules: {
        required: value => !!value || 'Câmp obligatoriu',
        cui: value => !value || /^(RO)?[0-9]{2,9}$/.test(value) || 'CUI/CIF invalid',
        regcom: value => !value || /^([J|j]|[F|f]|[C|c])[0-9]{2}\/[0-9]+\/(19|20)([0-9]{2})$/.test(value) || 'Număr înregistrare incorect',
        email: value => !value || /.+@.+\..+/.test(value) || "Adresa email invalida",
        isEmailOrPhone: () => this.isEmailOrPhone || "Email sau telefon obligatoriu",
        phone: value => !value || /(\(?\+?\d{1,4}\)?\s?)?\d{6,12}/.test(value) || "Numar incorect",
      }
    }
  },
  computed: {
    isEmailOrPhone() {
      return !!this.client.tel || !!this.client.email
    },
  },
  methods: {
    async updateClientDetails() {
      const valid = await this.$refs.editClientForm.validate();

      if (valid) {
        let updClient = {
          nume: this.client.nume,
          cui: this.client.cui,
          reg_com: this.client.reg_com,
          adresa: this.client.adresa,
          oras: this.client.oras,
          persoana: this.client.persoana,
          email: this.client.email,
          cc_obj: JSON.stringify(this.client.cc_obj),
          tel: this.client.tel
        }

        let id = this.$route.params.id
        this.$store.dispatch('updClient', { object: updClient, id: id })
        this.$emit('cancelEdit')
      }
    }
  },
  created() {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
