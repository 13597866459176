<template>
    <div class="container">
        <div class="flex flex-row justify-between items-center my-6">
            <h4 class="text-4xl">Oferte Productie - Cereri boltari</h4>
        </div>
        <div v-if="cereri_constructie.length > 0" class="">
            <div class="flex justify-between items-end">
                <div class="w-1/2">
                    <input placeholder="Cauta oferta dupa client" v-model="filterInput" />
                </div>
                <div class="w-fit bg-white p-3 flex flex-col gap-2">
                    <p>Status client</p>
                    <div class="">
                        <span class="dot green"></span>
                        <span>cat mai repede posibil | in curand</span>
                    </div>
                    <div class="">
                        <span class="dot grey"></span>
                        <span>in termen de jumatete de an | deocamdata doar ma interesez</span>
                    </div>
                </div>
            </div>
            <div v-if="loading" class="progress">
                <div class="indeterminate"></div>
            </div>
            <div class="card-panel">
                <table class="highlight responsive-table">
                    <thead>
                        <tr>
                            <th>Nr.</th>
                            <th>Data oferta</th>
                            <th class="w-60">Client</th>
                            <th>Contact</th>
                            <th>Sunat?</th>
                            <th>Interes</th>
                            <th class="w-32">Judet</th>
                            <th>
                                Suprafata <br />
                                totala zidarie
                            </th>
                            <th>Pret total cu TVA</th>
                            <th class="w-16 text-center">Oferta boltari</th>
                            <th class="w-16 text-center">Oferta gard</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="cerere in filterBy(cereri_constructie, filterInput)" :key="cerere.id_bricks">
                            <td>
                                {{ cerere.id_bricks }}
                            </td>
                            <td>
                                {{ cerere.data | formatDate }}
                            </td>
                            <td>
                                {{ fullName(cerere.client_obj.surname, cerere.client_obj.name) }}
                                <span class="dot" :class="returnTimeframeColor(cerere.client_obj.timeframe)"></span>
                            </td>
                            <td class="text-left">
                                {{ cerere.client_obj.email }} <br>{{ cerere.client_obj.phone }}
                            </td>
                            <td>
                                <div v-if="cerere.called == '1'">
                                    <i style="cursor: pointer" class="material-icons green-text bold align-middle mr-2"
                                        @click="markCalled(cerere.id_bricks, 0)">done</i>
                                </div>
                                <div v-else>
                                    <i v-tooltip="{
            content: 'Schimba status sunat',
            trigger: 'hover',
            placement: 'top',
            classes: ['teal', 'lighten-3', 'pa-10'],
            offset: 10,
            delay: {
                show: 500,
                hide: 300,
            },
        }" class="material-icons grey-text" style="cursor: pointer"
                                        @click="markCalled(cerere.id_bricks, 1)">phone_callback</i>
                                </div>
                            </td>
                            <td class="w-40">
                                <div v-if="cerere.called == '1'" class="flex justify-between items-center">
                                    <span class="mr-2" :class="{
            'text-red-500': cerere.interes == 'nu e interesat',
            'text-green-500 font-bold': cerere.interes == 'e interesat',
        }">{{ cerere.interes }}</span>

                                    <i v-tooltip="{
            content: 'Schimba status interes',
            trigger: 'hover',
            placement: 'top',
            classes: ['teal', 'lighten-3', 'pa-10'],
            targetClasses: [
                'material-icons',
                'grey-text',
                'bold',
                'mr-4',
            ],
            offset: 10,
            delay: {
                show: 500,
                hide: 300,
            },
        }" style="cursor: pointer" @click="selectCurrentCerere(cerere.id_bricks)">edit</i>
                                    <i v-if="cerere.interes_details" style="cursor: pointer" v-tooltip="{
            content: `${cerere.interes_details}`,
            trigger: 'hover',
            placement: 'right',
            classes: ['grey', 'lighten-3', 'pa-10'],
            targetClasses: ['material-icons'],
            offset: 10,
            delay: {
                show: 500,
                hide: 300,
            },
        }">info</i>
                                </div>
                                <div v-else>
                                    <span> - </span>
                                </div>
                            </td>
                            <td>{{ cerere.client_obj.judet.text }}</td>
                            <td>{{ cerere.bricks_obj.suprafataBricks }} m²</td>
                            <td>{{ cerere.bricks_obj.totalPrice.toFixed(2) }} eur</td>
                            <td class="text-center">
                                <a :href="`https://hub.cuprolli.ro/docs/configurator/constructii/oferta_boltari_${cerere.id_bricks}_${cerere.client_obj.name}_${cerere.client_obj.surname}.pdf`
            " :download="`oferta_boltari_${cerere.id_bricks}_${cerere.client_obj.name}_${cerere.client_obj.surname}`
            " target="_blank">
                                    <i v-tooltip="{
            content: 'Descarca oferta boltari',
            trigger: 'hover',
            placement: 'top',
            classes: ['teal', 'lighten-3', 'pa-10'],
            targetClasses: [
                'material-icons',
                'btn-icon',
                'teal-text',
                'align-middle',
            ],
            offset: 10,
            delay: {
                show: 500,
                hide: 300,
            },
        }">cloud_download</i>
                                </a>
                            </td>
                            <td class="text-center">
                                <a :href="`https://hub.cuprolli.ro/docs/configurator/oferta_gard_${cerere.cerere_id}_${cerere.client_obj.name}_${cerere.client_obj.surname}.pdf`
            " :download="`oferta_gard_${cerere.cerere_id}_${cerere.client_obj.name}_${cerere.client_obj.surname}`
            " target="_blank">
                                    <i v-tooltip="{
            content: 'Descarca oferta gard',
            trigger: 'hover',
            placement: 'top',
            classes: ['teal', 'lighten-3', 'pa-10'],
            targetClasses: [
                'material-icons',
                'btn-icon',
                'teal-text',
                'align-middle'
            ],
            offset: 10,
            delay: {
                show: 500,
                hide: 300,
            },
        }">cloud_download</i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- <pre>{{ cereri_constructie }}</pre> -->
        <!--  Modal Details -->
        <div id="modal_interes" class="modal modal-small modal-interes">
            <div class="modal-content">
                <div>
                    <p class="font-bold mb-4">
                        Schimba statusul:
                    </p>
                    <div class="input-field">
                        <select v-model="currentInteresStatus">
                            <option value="" disabled>Alege o varianta</option>
                            <option value="e interesat">e interesat</option>
                            <option value="nu a raspuns">nu a raspuns</option>
                            <option value="revenim acum">revenim acum</option>
                            <option value="revenim in primavara">revenim in primavara</option>
                            <option value="nu e interesat">nu e interesat</option>
                        </select>
                    </div>
                    <div class="input-field">
                        <input id="details" type="text" class="validate" v-model="currentInteresDetails" />
                        <label for="details" class="active">Detalii suplimentare</label>
                    </div>
                </div>
                <div class="btn-group mt-4">
                    <button href="#!" class="btn modal-close" @click="saveInteresStatus()">
                        Salveaza
                    </button>
                    <a href="#!" class="modal-action modal-close btn blue-grey"
                        @click="resetSelectedValues()">Inchide</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "CereriOfertaBoltari",
    data() {
        return {
            filterInput: "",
            currentCerereID: null,
            currentInteresStatus: "",
            currentInteresDetails: "",
        };
    },
    computed: {
        cereri_constructie() {
            return this.$store.getters.oferte_constructii_configurator_gard;
        },
        loading() {
            return this.$store.getters.loading;
        }
    },
    watch: {

    },
    methods: {
        filterBy(list, value) {
            value = value.charAt(0).toLowerCase() + value.slice(1);
            return list.filter((cerere) => {
                return (
                    this.fullName(cerere.client_obj.surname, cerere.client_obj.name)
                        .toLowerCase()
                        .indexOf(value) > -1
                );
            });
        },
        fullName(firstname, lastname) {
            return `${firstname} ${lastname}`;
        },
        returnTimeframeColor(str) {
            if (str) {
                if (str == "cat mai repede posibil" || str == "in curand") {
                    return "green";
                } else {
                    return "grey";
                }
            }
        },
        markCalled(id, called) {
            this.$store.dispatch("markCalledCerereBoltari", {
                id: id,
                called: called
            });
        },
        selectCurrentCerere(id) {
            this.currentCerereID = id;
            const cerere = this.cereri_constructie.find((obj) => obj.id_bricks == id);
            this.currentInteresStatus = cerere.interes;
            this.currentInteresDetails = cerere.interes_details;
            const instance = M.Modal.getInstance(
                document.getElementById("modal_interes")
            );
            instance.open();
            M.FormSelect.init(document.querySelector("select"));
        },
        resetSelectedValues() {
            this.currentInteresStatus = "";
            this.currentInteresDetails = "";
        },
        saveInteresStatus() {
            this.$store.dispatch("updateBricksInteresStatus", {
                id: this.currentCerereID,
                interes: this.currentInteresStatus,
                details: this.currentInteresDetails,
            });
            this.resetSelectedValues();
        },
    },
    components: {
    },
    created: function () {
        this.$store.dispatch("fetchCereriConstructie");
    },
    mounted: function () {
        M.Modal.init(document.querySelectorAll(".modal"));
        M.FormSelect.init(document.querySelectorAll("select"));
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
