<template>
	<v-footer color="medium-grey" class="py-1 px-2">
		<div class="container flex justify-end px-4 2xl:px-0">
			<pre class="text-xs tracking-tighter text-gray-300">HUB cuprolli v.{{ version }}</pre>
		</div>
	</v-footer>
</template>

<script>
export default {
	name: "Login",
	data() {
		return {};
	},
	computed: {
		version() {
			return this.$store.getters.version;
		}
	}
};
</script>

<style scoped></style>
