<template>
	<div id="aliaje">
		<div class="row">
			<div class="flex justify-between items-center mb-4">
				<h6 class="text-base uppercase tracking-wider text-gray-500">
					Lista aliaje
				</h6>
				<button data-target="modal_add_aliaj" class="btn modal-trigger">
					Adauga aliaj
				</button>
			</div>
			<div class="col s12">
				<div class="col s9">
					<input placeholder="Cauta aliaj" v-model="filterInput" />
				</div>
				<table>
					<thead>
						<tr>
							<th class="width3">Nr.</th>
							<th>Denumire</th>
							<th>GS</th>
							<th>Pret</th>
							<th>Material</th>
						</tr>
					</thead>
					<tbody>
						<tr
							v-for="(aliaj, index) in filterBy(aliaje, filterInput)"
							:key="index"
						>
							<td>{{ index + 1 }}</td>
							<td>{{ aliaj.denumire_aliaj }}</td>
							<td>{{ aliaj.gs | NoData }}</td>
							<td>{{ (aliaj.pret + " eur / " + aliaj.um_pret) | NoData }}</td>
							<td>{{ aliaj.denumire_material }}</td>
							<!-- <td><button class="right" v-scroll-to="'#editAliaj'" @click="openUpdAliajForm(aliaj.id)">Edit</button></td> -->
							<td class="width75">
								<button
									data-target="modal_edit_aliaj"
									class="btn-text teal-text modal-trigger"
									@click="editAliaj(aliaj.id)"
								>
									Edit
								</button>
							</td>
							<td class="width75" @click="selectedAliaj(index)">
								<button
									data-target="modal_del_aliaj"
									class="right red-text modal-trigger"
								>
									X
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<!-- MODALS -->
		<div id="modal_add_aliaj" class="modal modal-small modal-add-aliaj">
			<div class="modal-content">
				<h5 class="text-xl mb-2">Adauga aliaj</h5>
				<form v-on:submit="addAliaj">
					<div class="input-field my-1">
						<label for="denumire_aliaj">Denumire</label>
						<input
							id="denumire_aliaj"
							type="text"
							class="validate"
							v-model="aliaj.denumire"
						/>
					</div>
					<label for="material">Material</label>
					<model-select
						id="material"
						:options="selectMateriale"
						class="mb-1"
						v-model="aliaj.material"
						placeholder="Selecteaza"
					></model-select>
					<div class="flex justify-between items-end">
						<div class="w-1/3 px-1 -mx-1">
							<label for="gs">Greutate specifica</label>
							<input
								id="gs"
								type="number"
								step="0.01"
								class="validate"
								v-model="aliaj.gs"
							/>
						</div>
						<div class="w-1/3 px-1 -mx-1">
							<label for="pret">Pret -eur-</label>
							<input
								id="pret"
								type="number"
								step="0.01"
								class="validate"
								v-model="aliaj.pret"
							/>
						</div>
						<div class="w-1/3 px-2 -mx-2 mb-2">
							<label for="um">/ UM</label>
							<model-select
								id="um"
								:options="ums"
								v-model="aliaj.um_pret"
							></model-select>
						</div>
					</div>
					<div class="btn-group mt-4">
						<button type="submit" class="btn">Salveaza</button>
						<a href="#!" class="modal-action modal-close btn blue-grey">
							Anuleaza
						</a>
					</div>
				</form>
			</div>
		</div>
		<div id="modal_edit_aliaj" class="modal modal-small modal-edit-aliaj">
			<div class="modal-content">
				<h5 class="text-xl mb-2">Modifica aliajul</h5>
				<form v-on:submit="updateAliaj">
					<label for="denumire_aliaj">Denumire</label>
					<input
						type="text"
						class="validate"
						placeholder="Denumire aliaj"
						v-model="updAliaj.denumire_aliaj"
					/>
					<label for="material">Material</label>
					<model-select
						id="material"
						class="mb-1"
						:options="selectMateriale"
						v-model="updAliaj.material"
						placeholder="Material"
					></model-select>
					<div class="flex justify-between items-end">
						<div class="w-1/3 px-1 -mx-1">
							<label for="gs">Greutate specifica</label>
							<input
								type="number"
								step="0.01"
								class="validate center-align"
								placeholder="indice Gs"
								v-model="updAliaj.gs"
							/>
						</div>
						<div class="w-1/3 px-2 -mx-2">
							<label for="pret">Pret -eur-</label>
							<input
								type="number"
								step="0.01"
								class="validate center-align"
								placeholder="pret"
								v-model="updAliaj.pret"
							/>
						</div>
						<div class="w-1/3 px-2 -mx-2 mb-2">
							<label for="um">/ UM</label>
							<model-select
								id="um"
								:options="ums"
								v-model="updAliaj.um_pret"
								placeholder="UM pret"
							></model-select>
						</div>
					</div>

					<div class="btn-group mt-4">
						<button type="submit" class="btn">Salveaza</button>
						<a href="#!" class="modal-action modal-close btn blue-grey"
							>Anuleaza</a
						>
					</div>
				</form>
			</div>
		</div>
		<div id="modal_del_aliaj" class="modal modal-small modal-del-aliaj">
			<div v-if="delIndex" class="modal-content">
				<h6>
					Sterge definitiv aliajul "{{ aliaje[delIndex].denumire_aliaj }}"
				</h6>
				<br />
				<button class="btn red" v-on:click="deleteAliaj()">Sterge</button>
				<a href="#!" class="modal-action modal-close btn blue-grey lighten-4"
					>Anuleaza</a
				>
			</div>
		</div>
		<!-- <pre>{{aliaje}}</pre> -->
		<!-- <pre>{{$data}}</pre> -->
	</div>
</template>

<script>
import { ModelSelect } from "vue-search-select";

export default {
	name: "SetariAliaje",
	data() {
		return {
			filterInput: "",
			visibleUpdateAliajForm: false,
			aliaj: {
				material: {},
			},
			updAliaj: {
				material: {},
			},
			delIndex: "",
		};
	},
	computed: {
		aliaje() {
			return this.$store.getters.aliaje;
		},
		ums() {
			return this.$store.getters.ums;
		},
		selectMateriale() {
			return this.$store.getters.selectMateriale;
		},
	},
	methods: {
		editAliaj(id) {
			let selectedAliaj = this.aliaje.find((aliaj) => {
				return aliaj.id == id;
			});
			this.updAliaj = {
				id: selectedAliaj.id,
				denumire_aliaj: selectedAliaj.denumire_aliaj,
				gs: selectedAliaj.gs,
				pret: selectedAliaj.pret,
				um_pret: selectedAliaj.um_pret,
				material: {
					value: selectedAliaj.denumire_material
						.toLowerCase()
						.replace(/\s/g, ""),
					text: selectedAliaj.denumire_material,
					id: selectedAliaj.id_material,
				},
			};
		},
		fetchAliaje() {
			this.$store.dispatch("fetchAliaje");
		},
		filterBy(list, value) {
			value = value.charAt(0).toLowerCase() + value.slice(1);
			return list.filter(function(aliaj) {
				return aliaj.denumire_aliaj.toLowerCase().indexOf(value) > -1;
			});
		},
		addAliaj(e) {
			if (
				!this.aliaj.denumire ||
				!this.aliaj.material ||
				!this.aliaj.gs ||
				!this.aliaj.pret ||
				!this.aliaj.um_pret
			) {
				M.toast({ html: "Completati toate campurile!" });
			} else {
				let newAliaj = {
					denumire: this.aliaj.denumire,
					material_id: this.aliaj.material.id,
					gs: this.aliaj.gs,
					pret: this.aliaj.pret,
					um_pret: this.aliaj.um_pret,
				};
				this.$store.dispatch("addAliaj", newAliaj);
				e.preventDefault();
				this.aliaj = {
					material: {},
				};
			}
			e.preventDefault();
		},
		updateAliaj(e) {
			let updAliaj = {
				id: this.updAliaj.id,
				denumire: this.updAliaj.denumire_aliaj,
				material_id: this.updAliaj.material.id,
				gs: this.updAliaj.gs,
				pret: this.updAliaj.pret,
				um_pret: this.updAliaj.um_pret,
			};
			this.$store.dispatch("updAliaj", updAliaj);
			this.visibleUpdateAliajForm = false;
			M.Modal.getInstance(document.querySelector(".modal-edit-aliaj")).close();
			this.updAliaj = {};
			e.preventDefault();
		},
		selectedAliaj(index) {
			this.delIndex = index;
		},
		deleteAliaj() {
			let id = this.aliaje[this.delIndex].id;
			this.$store.dispatch("deleteAliaj", id);
		},
	},
	created: function() {
		this.fetchAliaje();
		// this.$store.dispatch('fetchMateriale')
	},
	mounted: function() {
		M.Modal.init(document.querySelectorAll(".modal"));
		M.FormSelect.init(document.querySelector("select"));
	},
	components: {
		ModelSelect,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
td.delete {
	cursor: pointer;
	color: #e65100;
}
</style>
