// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from './App';
// Import Firebase Auth and Config file
import firebase from 'firebase/app';
import 'firebase/auth';
import firebaseConfig from './components/firebaseConfig';
import router from './router';
import vueResource from 'vue-resource';
import store from './stores/store';
import moment from 'moment';
import VueScrollTo from 'vue-scrollto';
import VTooltip from 'v-tooltip';
import '@/assets/css/tailwind.css'
import 'vue-search-select/dist/VueSearchSelect.css'
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import excel from 'vue-excel-export';
import vuetify from './plugins/vuetify';
import VueKonva from 'vue-konva';

Vue.use(VueScrollTo, {
  offset: -90,
});
Vue.use(vueResource);
Vue.use(VTooltip);
Vue.use(excel);
Vue.use(VueKonva);

Vue.config.productionTip = false

firebase.initializeApp(firebaseConfig);
let app;
firebase.auth().onAuthStateChanged(function (user) {
  if (!app) {
    app = new Vue({
      el: '#app',
      store,
      router,
      components: { App },
      template: '<App/>',
      vuetify,

      async beforeCreate() {
        // console.log(user);
        if (firebase.auth().currentUser) {
          await store.dispatch('fetchUser', user.email)
        }
      }
    })
  }
});

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY');
  }
});

Vue.filter('formatDateTime', function (value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY HH:mm');
  }
});

Vue.filter('formatMonth', function (value) {
  if (value) {
    return moment(String(value)).format('MMM YYYY');
  }
});

Vue.filter('formatMoney', function (value) {
  return Number(value).toFixed(2);
});

Vue.filter('NoData', function (value) {
  if (!value) {
    return '-'
  } else {
    return value
  }
});
