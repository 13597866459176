<template>
	<div>
		<div class="container mt-4">
			<h4 class="text-4xl text-center">Debitare</h4>
			<div class="homescreen">
				<div class="row">
					<router-link class="nav-link" to="/debitare/comenzi">
						<div class="card-panel col s12 m4 offset-m1">
							<i class="material-icons">dvr</i>
							<h5>Comenzi debitare</h5>
						</div>
					</router-link>
					<router-link class="nav-link" to="/debitare/stocuri">
						<div class="card-panel col s12 m4 offset-m2">
							<i class="material-icons">view_comfy</i>
							<h5>Holding</h5>
						</div>
					</router-link>
				</div>
				<div class="row">
					<router-link class="nav-link" to="/debitare/listadebitari">
						<div class="card-panel col s12 m4 offset-m1">
							<i class="material-icons">vertical_align_center</i>
							<h5>Lista debitari</h5>
						</div>
					</router-link>
					<router-link class="nav-link" to="/debitare/profile">
						<div class="card-panel col s12 m4 offset-m2">
							<i class="material-icons">tab</i>
							<h5>Profile</h5>
						</div>
					</router-link>
				</div>
				<div class="row">
					<router-link class="nav-link" to="/debitare/avize">
						<div class="card-panel col s12 m4 offset-m1">
							<i class="material-icons">description</i>
							<h5>Avize</h5>
						</div>
					</router-link>
					<router-link class="nav-link" to="/debitare/rapoarte">
						<div class="card-panel col s12 m4 offset-m2">
							<i class="material-icons">equalizer</i>
							<h5>Rapoarte</h5>
						</div>
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Debitare",
	data() {
		return {};
	},
	computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cat-title {
	color: #607d8b;
}
h1,
h2 {
	font-weight: normal;
}
.nav-link .card-panel {
	padding: 30px;
}
.nav-link h5,
.nav-link h6 {
	vertical-align: middle;
	margin: 0;
	display: inline-block;
}
.nav-link i {
	vertical-align: middle;
	font-size: 34px;
	margin-right: 10px;
	display: inline-block;
}
ul {
	list-style-type: none;
	padding: 0;
}
li {
	display: inline-block;
	margin: 0 10px;
}
.homescreen {
	margin-top: 50px;
}
.homescreen a {
	color: #42b983;
}
</style>
