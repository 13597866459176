<template>
  <div class="container">
    <h5 v-if="isNull" class="header">Adauga consumabile</h5>
    <h5 v-else class="header">Editeaza consumabile</h5>
    <EditConsumabile v-if="currentOferta" :consumabile_obj="isNull ? consumabile : currentOferta.consumabile_obj" :tip="'oferta'" :id="currentOferta.id_oferta"></EditConsumabile>
    <!-- <pre>{{currentOferta}}</pre> -->
  </div>
</template>

<script>
import EditConsumabile from "@/components/Productie/EditConsumabile";

export default {
  name: "OfertaConsumabile",
  data() {
    return {
      consumabile: [
        {
          nr: "1",
          denumire: "",
          cantitate: null,
          pret: null,
          um: "",
          pret_v: null,
          marja: null,
          pret_t: null
        }
      ]
    };
  },
  components: {
    EditConsumabile
  },
  computed: {
    currentOferta() {
      return this.$store.getters.currentOfertaProductie;
    },
    isNull() {
      if (this.currentOferta.consumabile_obj) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {},
  created: function() {
    this.$store.dispatch("fetchOfertaProductie", this.$route.params.id);
  },
  mounted: function() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
