<template>
	<div class="row">
		<div id="oameni" class="col s12">
			<div class="bg-white shadow p-8">
				<div v-if="loading" class="progress">
					<div class="indeterminate"></div>
				</div>
				<div v-else>
					<div class="row">
						<div class="col s6">
							<h6 class="grey lighten-4 title">Oameni echipa productie</h6>
						</div>
						<div class="col s6 btns-right">
							<button
								data-target="modal_add_persoana"
								class="btn right modal-trigger"
							>
								Adauga persoana
							</button>
						</div>
					</div>
					<table>
						<thead>
							<tr>
								<th class="width3">Nr.</th>
								<th>Nume</th>
								<th>Culoare calendar</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(persoana, index) in oameni_productie">
								<td>{{ index + 1 }}</td>
								<td>{{ persoana.name }}</td>
								<td>
									<span class="dot" :class="persoana.color"></span>
								</td>
								<td @click="selectedPersoana(index)">
									<button
										data-target="modal_del_persoana"
										class="right red-text modal-trigger"
									>
										X
									</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<!-- <pre>{{$data}}</pre> -->
		</div>
		<!-- MODALS -->
		<div id="modal_add_persoana" class="modal modal-small modal-add-persoana">
			<div class="modal-content">
				<h5>Adauga om in echipa de productie</h5>
				<form v-on:submit.prevent="addPersoana()">
					<div class="input-field">
						<input
							id="nume_persoana"
							type="text"
							class="validate"
							v-model="persoana.name"
						/>
						<label for="nume_persoana">Nume</label>
					</div>
					<h6>Alege culoare:</h6>
					<div
						v-for="(color, index) in availableColors"
						class="inline-div color-div"
						:class="{ selected: isActiveColor(index) }"
						@click.prevent="addColor(index)"
					>
						<span class="dot" :class="color"></span>{{ color }}
					</div>
					<div class="mtop-20">
						<button type="submit" class="btn">Salveaza</button>
						<a href="#!" class="modal-action modal-close btn blue-grey"
							>Anuleaza</a
						>
					</div>
				</form>
			</div>
		</div>
		<div id="modal_del_persoana" class="modal modal-delete modal-del-persoana">
			<div class="modal-content">
				<h6>Sterge definitiv persoana?</h6>
				<br />
				<button class="btn red" v-on:click="deletePersoana()">Sterge</button>
				<a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "SetariOameniProductie",
	data() {
		return {
			persoana: {},
			colors: [
				"light-green",
				"light-blue",
				"cyan",
				"teal",
				"purple",
				"red",
				"indigo",
			],
			selectedColor: -1,
			delIndex: "",
		};
	},
	computed: {
		loading() {
			return this.$store.getters.loading;
		},
		oameni_productie() {
			return this.$store.getters.oameni_productie;
		},
		availableColors() {
			// map used colors from oameni array
			let usedColors = this.oameni_productie.map((person) => {
				return person.color;
			});
			let availableColors = this.colors.filter((item) => {
				return !usedColors.includes(item);
			});
			return availableColors;
		},
	},
	components: {},
	methods: {
		addPersoana() {
			if (!this.persoana.name || !this.persoana.color) {
				M.toast({ html: "Completati toate campurile!", classes: "orange" });
			} else {
				this.$store.dispatch("addOmProductie", this.persoana);
				M.Modal.getInstance(
					document.querySelector(".modal-add-persoana")
				).close();
				this.persoana = {};
				this.selectedColor = -1;
			}
		},
		addColor(idx) {
			this.persoana.color = this.availableColors[idx];
			this.selectedColor = idx;
		},
		isActiveColor(idx) {
			if (idx == this.selectedColor) {
				return true;
			} else {
				return false;
			}
		},
		selectedPersoana(index) {
			this.delIndex = index;
		},
		deletePersoana() {
			let id = this.oameni_productie[this.delIndex].id_persoana;
			this.$store.dispatch("deleteOmProductie", id);
		},
	},
	created: function() {
		this.$store.dispatch("fetchOameniProductie");
	},
	mounted: function() {
		M.Modal.init(document.querySelector(".modal-add-persoana"));
		M.Modal.init(document.querySelector(".modal-del-persoana"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
span.dot {
	border-radius: 100px;
	width: 10px;
	height: 10px;
	display: inline-block;
	margin: 0 5px 0 15px;
}
.color-div {
	padding: 4px 10px 4px 0;
	margin: 0 6px 6px 0;
	border-radius: 4px;
	background-color: #f1f1f1;
	cursor: pointer;
}
.color-div.selected {
	background-color: #d9d9d9;
}
</style>
