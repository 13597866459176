<template>
	<div class="container">
		<div class="card-panel">
			<button
				data-target="modal_del_echipa"
				class="btn right red modal-trigger"
			>
				Sterge
			</button>
			<h5 class="header">Editeaza echipa</h5>
			<form v-on:submit="updateEchipa">
				<div class="row">
					<div class="col s4">
						<h6>Persoane echipa</h6>
						<div class="input-field">
							<input
								id="pers"
								type="text"
								class="validate"
								v-model="new_pers"
							/>
							<label for="pers">Adauga persoana</label>
							<span
								><button class="btn" @click.prevent="addPers">+</button></span
							>
						</div>
						<ul class="">
							<li v-for="(item, index) in echipa.echipa_nume" :key="index">
								{{ item
								}}<span class="delete" @click.prevent="deletePers(index)"
									>X</span
								>
							</li>
						</ul>
					</div>
					<div class="col s4">
						<div class="input-field">
							<select v-model="echipa.echipa_utilaj">
								<option value="" disabled selected>Selecteaza utilaj</option>
								<option value="Elumatec">Elumatec</option>
								<option value="Graule">Graule</option>
							</select>
							<label>Utilaj</label>
						</div>
					</div>
				</div>
				<br />
				<button type="submit" class="btn btn-success">Salveaza</button>
				<router-link class="btn blue-grey lighten-2" to="/debitare/echipe"
					>Anuleaza</router-link
				>
			</form>
			<div id="modal_del_echipa" class="modal modal-del-echipa">
				<div class="modal-content">
					<h6>Sterge definitiv echipa: {{ echipa.echipa_id }}?</h6>
					<br />
					<button class="btn red" v-on:click="deleteEchipa(echipa.echipa_id)">
						Sterge
					</button>
					<a href="#!" class="modal-action modal-close btn blue-grey"
						>Anuleaza</a
					>
				</div>
			</div>
		</div>
		<!-- <pre>{{$data}}</pre> -->
	</div>
</template>

<script>
export default {
	name: "EditEchipa",
	data() {
		return {
			echipa: {},
			new_pers: "",
		};
	},
	methods: {
		addPers() {
			if (this.new_pers !== "") {
				this.echipa.echipa_nume.push(this.new_pers);
				this.new_pers = "";
			}
		},
		deletePers(i) {
			this.echipa.echipa_nume.splice(i, 1);
		},
		fetchEchipa(id) {
			this.$http
				.get(
					"http://hub.cuprolli.ro/app_back/index.php/api/debitare/echipa/" + id
				)
				.then(function(response) {
					let echipa = response.body;
					echipa.echipa_nume = JSON.parse(echipa.echipa_nume);
					this.echipa = echipa;
				});
		},
		updateEchipa(e) {
			if (!this.echipa.echipa_nume) {
				M.toast({ html: "Completati persoanele!" });
			} else {
				let updEchipa = {
					echipa_utilaj: this.echipa.echipa_utilaj,
					echipa_nume: JSON.stringify(this.echipa.echipa_nume),
				};

				this.$http
					.put(
						"http://hub.cuprolli.ro/app_back/index.php/api/debitare/echipa/update/" +
							this.$route.params.id,
						updEchipa
					)
					.then(function() {
						this.$router.push({
							path: "/debitare/echipe",
							query: M.toast({ html: "Echipa actualizata!" }),
						});
					});

				e.preventDefault();
			}
			e.preventDefault();
		},
		deleteEchipa(id) {
			this.$http
				.delete(
					"http://hub.cuprolli.ro/app_back/index.php/api/debitare/echipe/delete/" +
						id
				)
				.then(function() {
					this.$router.push({
						path: "/debitare/echipe",
						query: M.toast({ html: "Echipa stearsa!" }),
					});
				});
		},
	},
	created: function() {
		this.fetchEchipa(this.$route.params.id);
	},
	mounted: function() {
		var m3 = document.querySelector(".modal-del-echipa");
		M.Modal.init(m3);
	},
	updated: function() {
		var m4 = document.querySelectorAll("select");
		M.FormSelect.init(m4);
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#pers {
	width: 60%;
}
li span.delete {
	cursor: pointer;
	margin-left: 10px;
	color: #e65100;
}
</style>
