<template>
  <div>
    <div class="card-panel row">
      <div class="col s6">
        <p>
          <label class="right-margin">
            <input type="checkbox" class="filled-in" checked="checked" v-model="vopsit_obj.pistolet.active"
              v-on:change="setPreturiVopsit()" />
            <span>Cu pistolet</span>
          </label>
          <label>
            <input type="checkbox" class="filled-in" v-model="vopsit_obj.camp.active"
              v-on:change="setPreturiVopsit()" />
            <span>Camp electrostatic</span>
          </label>
        </p>
      </div>
      <div class="col s3 offset-s3">
        <table class="totals">
          <tr>
            <td class="center-align bold">Vopsit - Total fara TVA</td>
            <td class="center-align">
              <span>{{ totalsV }}</span>
            </td>
          </tr>
        </table>
      </div>
      <div class="row">
        <div v-if="vopsit_obj.pistolet.active" class="col s12">
          <h6 class="title">Vopsit cu pistolet</h6>
          <div class="col s7">
            <div class="input-field col s3">
              <input id="litri" type="number" step="0.1" class="validate" v-model="vopsit_obj.pistolet.vopsea"
                v-on:change="calcVP()" /> litri
              <label for="litri">Litri vopsea</label>
            </div>
            <div class="input-field col s3">
              <input id="ora" type="number" step="1" class="validate" v-model="vopsit_obj.pistolet.manopera"
                v-on:change="calcVP()" /> ore
              <label for="ora">Ore manopera</label>
            </div>
            <div class="input-field col s3">
              <input id="marja1" type="number" step="0.1" value="1" class="validate center-align"
                v-model="vopsit_obj.pistolet.marja" v-on:change="calcVP()" />
              <label for="marja1">Marja %</label>
            </div>
            <div class="input-field col s3">
              <input class="center-align" v-model="vopsit_obj.pistolet.pret_t" readonly />
              <span class="grey-text">Valoare - lei -</span>
            </div>
            <div>
              <span class="grey-text">Pret vopsea: {{ vopsit_obj.pistolet.pret_vopsea }} lei / litru</span>
              <br />
              <span class="grey-text">Pret manopera: {{ vopsit_obj.pistolet.pret_manopera }} lei / ora</span>
            </div>
          </div>
        </div>
        <div v-if="vopsit_obj.camp.active" class="col s12">
          <h6 class="title">Vopsit in camp electrostatic</h6>
          <div class="col s6">
            <div class="input-field col s4">
              <input id="metri" type="number" step="0.01" class="validate" v-model="vopsit_obj.camp.metri"
                v-on:change="calcVCE()" />
              <label for="metri">Cantitate</label> m²
            </div>
            <div class="input-field col s4">
              <input id="marja2" type="number" step="0.1" value="1" class="validate center-align"
                v-model="vopsit_obj.camp.marja" v-on:change="calcVCE()" />
              <label for="marja2">Marja %</label>
            </div>
            <div class="input-field col s4">
              <input class="center-align" v-model="vopsit_obj.camp.pret_t" readonly />
              <span class="grey-text">Valoare - lei -</span>
            </div>
            <div>
              <span class="grey-text">Pret vopsit in camp electrostatic: {{ vopsit_obj.camp.pret_serv }} lei / m2</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="btn" @click="saveVopsit()">Salveaza</button>
    <router-link v-if="tip == 'oferta'" class="btn blue-grey lighten-2"
      :to="'/oferte-productie/single/' + id">Anuleaza</router-link>
    <router-link v-else-if="tip == 'lucrare'" class="btn blue-grey lighten-2"
      :to="'/lucrari-productie/single/' + id">Anuleaza</router-link>
    <!-- <pre>{{tip}} {{id}}</pre> -->
    <!-- <pre>{{vopsit_obj}}</pre> -->
  </div>
</template>

<script>
export default {
  name: "EditVopsit",
  data() {
    return {
      cost_fara_tvaV: "0.00",
      val_fara_tvaV: "0.00"
    };
  },
  props: ["vopsit_obj", "tip", "id"],
  components: {
  },
  computed: {
    preturiVopsit() {
      return this.$store.getters.preturiVopsit;
    },
    totalsV: function () {
      let v = 0;
      let t = 0;
      if (this.vopsit_obj.pistolet.active && this.vopsit_obj.camp.active) {
        v =
          Number(this.vopsit_obj.pistolet.pret_v) +
          Number(this.vopsit_obj.camp.pret_v);
        t =
          Number(this.vopsit_obj.pistolet.pret_t) +
          Number(this.vopsit_obj.camp.pret_t);
      } else if (this.vopsit_obj.pistolet.active) {
        v = Number(this.vopsit_obj.pistolet.pret_v);
        t = Number(this.vopsit_obj.pistolet.pret_t);
      } else if (this.vopsit_obj.camp.active) {
        v = Number(this.vopsit_obj.camp.pret_v);
        t = Number(this.vopsit_obj.camp.pret_t);
      }
      this.cost_fara_tvaV = v.toFixed(2);
      this.val_fara_tvaV = t.toFixed(2);
      return this.val_fara_tvaV;
    }
  },
  methods: {
    setPreturiVopsit() {
      if (this.vopsit_obj.pistolet.active || this.vopsit_obj.camp.active) {
        this.vopsit_obj.pistolet.pret_vopsea = this.preturiVopsit[0].pret;
        this.vopsit_obj.pistolet.pret_manopera = this.preturiVopsit[1].pret;
        this.vopsit_obj.camp.pret_serv = this.preturiVopsit[2].pret;
      }
    },
    calcVP() {
      let vopsea = Number(this.vopsit_obj.pistolet.vopsea);
      let ore = Number(this.vopsit_obj.pistolet.manopera);
      let pret_vopsea = Number(this.vopsit_obj.pistolet.pret_vopsea);
      let pret_manopera = Number(this.vopsit_obj.pistolet.pret_manopera);
      let total = vopsea * pret_vopsea + ore * pret_manopera;
      this.vopsit_obj.pistolet.pret_v = total.toFixed(2);
      total = total + total * (Number(this.vopsit_obj.pistolet.marja) / 100);
      this.vopsit_obj.pistolet.pret_t = total.toFixed(2);
    },
    calcVCE() {
      let ore = Number(this.vopsit_obj.camp.metri);
      let pret = Number(this.vopsit_obj.camp.pret_serv);
      let total = ore * pret;
      this.vopsit_obj.camp.pret_v = total.toFixed(2);
      total = total + total * (Number(this.vopsit_obj.camp.marja) / 100);
      this.vopsit_obj.camp.pret_t = total.toFixed(2);
    },
    saveVopsit() {
      let newVopsit = {
        vopsit_obj: JSON.stringify(this.vopsit_obj),
        cost_fara_tvaV: this.cost_fara_tvaV,
        val_fara_tvaV: this.val_fara_tvaV,
        tip: this.tip,
        id: this.id
      };
      this.$store.dispatch("saveVopsit", newVopsit);
    }
  },
  created: function () {
    this.$store.dispatch("fetchPreturiVopsit");
  },
  mounted: function () { }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
td.delete {
  cursor: pointer;
  color: #e65100;
}

.save {
  width: 100%;
  padding: 6px 30px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}

.save p {
  margin-right: 40px;
}
</style>
