<template lang="html">
	<div class="container">
		<div class="flex flex-row justify-between items-center my-4">
			<h4 class="text-4xl">Situatie bonus / vanzari</h4>
			<div class="btns ">
				<!-- <vue-monthly-picker class="month-picker" dateFormat="MMMM YYYY" v-model="selectedMonth" @selected="fetchBonusComenzi"></vue-monthly-picker> -->
				<!-- <button data-target="modal_status" class="btn blue-grey modal-trigger">Status</button> -->
				<!-- <router-link class="btn blue-grey lighten-2" to="/comenzi/finalizate">Comenzi finalizate</router-link> -->
				<!-- <router-link class="btn" to="/comenzi/add">Adauga comanda</router-link> -->
			</div>
		</div>
		<div v-if="bonus && bonus.calculat != 1" class="save blue-grey lighten-4">
			<div class="container warning mbottom-20">
				<p class="inline-title">
					<i class="material-icons left teal-text">warning</i
					><span class="bold">Atentie! - </span>Nu este calculat bonusul pe luna
					<span class="bold">{{ lastMonth }}</span>
				</p>
				<button class="btn-small right" @click="calcBonus">Calculeaza</button>
			</div>
		</div>
		<div class="row">
			<div class="col s6">
				<div class="card-panel">
					<h6 class="bold mbottom-20">Luna trecuta ({{ lastMonth }})</h6>
					<h6>Vanzari</h6>
					<ul>
						<li>
							<span class="bold">Numar comenzi: </span>{{ comenziAll_nr }}
						</li>
						<li>
							<span class="bold">Total vanzari: </span>{{ suma_vanzari }} ron
						</li>
					</ul>
					<br />
					<h6>Confectii metalice</h6>
					<ul>
						<li>
							<span class="bold">Numar comenzi: </span>{{ lucrariAll_nr }}
						</li>
						<li>
							<span class="bold">Valoare cumulata: </span>{{ suma_lucrari }} ron
						</li>
					</ul>
				</div>
			</div>
			<div class="col s6">
				<div class="card-panel target">
					<!-- <div class="row"> -->
					<h6 class="bold mbottom-20">
						Luna curenta ({{ currentMonth }}) - <span>Vanzari</span>
					</h6>
					<h6 class="col s12 center-align">Target</h6>
					<h6 class="col s12 center-align">|</h6>
					<div class="progress grey lighten-4">
						<!-- <div class="determinate" :class"{ 'red':notTarget }" :style="'width:'+progress"></div> -->
						<div
							class="determinate"
							:class="{ red: notTarget }"
							:style="'width:' + progress"
						></div>
					</div>
					<br />
					<p><span class="bold">Target: </span>{{ target }} ron fara TVA</p>
					<p>
						<span class="bold">Vanzari: </span>{{ current_vanzari }} ron fara
						TVA
					</p>
					<!-- </div> -->
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col s6">
				<div class="card-panel">
					<div class="input-field">
						<span>Selecteaza luna: </span>
						<vue-monthly-picker
							class="month-picker width40"
							:monthLabels="monthLabels"
							dateFormat="MMMM YYYY"
							v-model="selectedMonth"
							@selected="fetchBonusValues"
						></vue-monthly-picker>
					</div>
					<h6 class="bold mbottom-20">
						Bonus care se incaseaza in luna
						{{ selectedMonth.locale("ro").format("MMMM") }}
					</h6>
					<h6>Vanzari</h6>
					<ul>
						<li>
							<span class="bold">Bonus incasari avans: </span
							>{{ bonusAvans }} ron
						</li>
						<li>
							<span class="bold">Bonus incasari termen: </span
							>{{ bonusTermen }} ron
						</li>
						<li><span class="bold">Total bonus: </span>{{ bonusTotal }} ron</li>
					</ul>
					<br />
					<h6>Confectii metalice</h6>
					<ul>
						<li>
							<span class="bold">Bonus incasari avans: </span
							>{{ bonusAvansLucrari }} ron
						</li>
						<li>
							<span class="bold">Bonus incasari termen: </span
							>{{ bonusTermenLucrari }} ron
						</li>
						<li>
							<span class="bold">Total bonus: </span>{{ bonusTotalLucrari }} ron
						</li>
					</ul>
				</div>
			</div>
		</div>
		<!-- <pre>{{curs}}</pre> -->
		<!-- <pre>{{progress}}</pre> -->
		<!-- <pre>{{current_vanzari}}</pre> -->
		<!-- <pre>{{bonus}}</pre> -->
		<!-- <pre>{{$data}}</pre> -->
		<!-- <pre>{{comenzi}}</pre> -->
		<!-- <pre>{{comenziAll}}</pre> -->
		<!-- <pre>{{lucrari}}</pre> -->
		<!-- <pre>{{lucrariAll}}</pre> -->
		<!-- <pre>{{currentComenzi}}</pre> -->
		<!-- <pre>{{$store.state}}</pre> -->
	</div>
</template>

<script>
import moment from "moment";
import VueMonthlyPicker from "vue-monthly-picker";

export default {
	name: "Bonus",
	data() {
		return {
			selectedMonth: moment(),
			monthLabels: [
				"ian",
				"feb",
				"mar",
				"apr",
				"mai",
				"iun",
				"iul",
				"aug",
				"sep",
				"oct",
				"noi",
				"dec",
			],
			bonus_avans: [],
			bonus_termen: [],
			bonus_avans_lucrari: [],
			bonus_termen_lucrari: [],
			notTarget: true,
		};
	},
	components: {
		VueMonthlyPicker,
	},
	computed: {
		progress() {
			let p = Math.round(((this.current_vanzari / this.target) * 100) / 2);
			// console.log(p);
			if (p > 50) {
				this.notTarget = false;
			}
			return p + "%";
		},
		firstday() {
			return this.selectedMonth.startOf("month").format("YYYY-MM-DD");
		},
		lastday() {
			return this.selectedMonth.endOf("month").format("YYYY-MM-DD");
		},
		currentMonth() {
			return moment()
				.locale("ro")
				.format("MMMM");
		},
		firstdayLastMonth() {
			let a = moment()
				.startOf("month")
				.subtract(1, "days");
			return a.startOf("month").format("YYYY-MM-DD");
		},
		lastdayLastMonth() {
			let a = moment()
				.startOf("month")
				.subtract(1, "days");
			return a.format("YYYY-MM-DD");
		},
		lastMonth() {
			let a = moment();
			return a
				.startOf("month")
				.subtract(1, "days")
				.locale("ro")
				.format("MMMM");
		},
		user() {
			return this.$store.getters.loggedUser;
		},
		lucrari() {
			return this.$store.getters.oferte_productieBonus;
		},
		lucrariAll() {
			return this.$store.getters.oferte_productieByUser;
		},
		lucrariAll_nr() {
			return this.lucrariAll.length;
		},
		comenzi() {
			return this.$store.getters.comenziBonus;
		},
		comenziAll() {
			return this.$store.getters.comenziByUser;
		},
		comenziAll_nr() {
			return this.comenziAll.length;
		},
		currentComenzi() {
			return this.$store.getters.currentComenziByUser;
		},
		curs() {
			return this.$store.getters.curs;
		},
		current_vanzari() {
			let t = 0;
			let curs = this.curs;
			let s = 0;
			this.currentComenzi.forEach(function(comanda) {
				if (comanda.moneda === "eur") {
					s = Number(comanda.val_fara_tva) * curs;
				} else if (comanda.moneda === "ron") {
					s = Number(comanda.val_fara_tva);
				}
				t = t + s;
			});
			return t.toFixed(2);
		},
		suma_vanzari() {
			let t = 0;
			let curs = this.curs;
			let s = 0;
			this.comenziAll.forEach(function(comanda) {
				if (comanda.moneda === "eur") {
					s = Number(comanda.val_fara_tva) * curs;
				} else if (comanda.moneda === "ron") {
					s = Number(comanda.val_fara_tva);
				}
				t = t + s;
			});
			return t.toFixed(2);
		},
		suma_lucrari() {
			let t = 0;
			//let curs = this.curs
			// let s = 0;
			this.lucrariAll.forEach(function(lucrare) {
				let pp = Number(lucrare.val_fara_tvaP);
				let mm = Number(lucrare.val_fara_tvaM);
				let vv = Number(lucrare.val_fara_tvaV);
				let ss = Number(lucrare.val_fara_tvaS);
				let cc = Number(lucrare.val_fara_tvaC);
				let aa = Number(lucrare.val_fara_tvaA);
				let vanzari = pp + mm + vv + ss + cc + aa;
				// if (lucrare.moneda === 'eur') {
				//   s = Number(lucrare.val_fara_tva) * curs
				// } else if (lucrare.moneda === 'ron') {
				//   s = Number(lucrare.val_fara_tva)
				// }
				t = t + vanzari;
			});
			return t.toFixed(2);
		},
		target() {
			return this.$store.getters.target;
		},
		currency() {
			return this.$store.getters.currency;
		},
		p_bonus() {
			return this.$store.getters.p_bonus;
		},
		p_target() {
			let t = 0;
			if (this.suma_vanzari >= this.target) {
				t = this.target / this.suma_vanzari;
			}
			return t;
		},
		bonusAvans() {
			if (this.comenzi) {
				let t = 0;
				var self = this;
				this.comenzi.forEach(function(comanda) {
					if (
						comanda.data_avans >= self.firstday &&
						comanda.data_avans <= self.lastday &&
						comanda.ok_contract == 1 &&
						comanda.ok_factura == 1 &&
						comanda.ok_awb == 1
					) {
						t = t + Number(comanda.bonus_avans);
					}
				});
				return t.toFixed(2);
			}
		},
		bonusTermen() {
			if (this.comenzi) {
				let t = 0;
				var self = this;
				this.comenzi.forEach(function(comanda) {
					if (
						comanda.data_plata >= self.firstday &&
						comanda.data_plata <= self.lastday &&
						comanda.ok_contract == 1 &&
						comanda.ok_factura == 1 &&
						comanda.ok_awb == 1
					) {
						t = t + Number(comanda.bonus_termen);
					}
				});
				return t.toFixed(2);
			}
		},
		bonusTotal() {
			let t = 0;
			t = Number(this.bonusAvans) + Number(this.bonusTermen);
			return t.toFixed(2);
		},
		bonusAvansLucrari() {
			if (this.lucrari) {
				let t = 0;
				var self = this;
				this.lucrari.forEach(function(lucrare) {
					if (
						lucrare.data_avans >= self.firstday &&
						lucrare.data_avans <= self.lastday
					) {
						t = t + Number(lucrare.bonus_avans);
					}
				});
				return t.toFixed(2);
			}
		},
		bonusTermenLucrari() {
			if (this.lucrari) {
				let t = 0;
				var self = this;
				this.lucrari.forEach(function(lucrare) {
					if (
						lucrare.data_plata >= self.firstday &&
						lucrare.data_plata <= self.lastday
					) {
						t = t + Number(lucrare.bonus_termen);
					}
				});
				return t.toFixed(2);
			}
		},
		bonusTotalLucrari() {
			let t = 0;
			t = Number(this.bonusAvansLucrari) + Number(this.bonusTermenLucrari);
			return t.toFixed(2);
		},
		bonus() {
			return this.$store.getters.bonus;
		},
	},
	watch: {
		user: function() {
			this.fetchAllComenzi();
			this.fetchBonusComenzi();
			this.fetchAllLucrari();
			this.fetchBonusLucrari();
			this.fetchAllCurrentComenzi();
			this.fetchBonus();
		},
	},
	methods: {
		calcBonus() {
			let a = 0;
			let b = 0;
			let arrayA = [];
			let arrayB = [];
			let arrayC = [];
			let arrayD = [];
			let self = this;

			this.comenziAll.forEach(function(comanda) {
				if (comanda.moneda === "eur") {
					a = Number(comanda.suma_avans) * self.currency;
					b = Number(comanda.suma_termen) * self.currency;
				} else if (comanda.moneda === "ron") {
					a = Number(comanda.suma_avans);
					b = Number(comanda.suma_termen);
				}
				a = a / 1.19;
				b = b / 1.19;
				let suma_avans;
				let suma_termen;
				if (self.p_target == 0) {
					suma_avans = 0;
					suma_termen = 0;
				} else {
					suma_avans = (a - self.p_target * a).toFixed(2);
					suma_termen = (b - self.p_target * b).toFixed(2);
				}
				let bonus_a = ((suma_avans * self.p_bonus.avans) / 100).toFixed(2);
				let bonus_b = ((suma_termen * self.p_bonus.termen) / 100).toFixed(2);
				arrayA.push(bonus_a);
				arrayB.push(bonus_b);
				self.$store.dispatch("addComandaBonus", {
					comanda_id: comanda.id_comanda,
					tip_plata: "avans",
					bonus: bonus_a,
				});
				self.$store.dispatch("addComandaBonus", {
					comanda_id: comanda.id_comanda,
					tip_plata: "termen",
					bonus: bonus_b,
				});
			});

			this.lucrariAll.forEach(function(lucrare) {
				let pp = Number(lucrare.val_fara_tvaP);
				let mm = Number(lucrare.val_fara_tvaM);
				let vv = Number(lucrare.val_fara_tvaV);
				let ss = Number(lucrare.val_fara_tvaS);
				let cc = Number(lucrare.val_fara_tvaC);
				let aa = Number(lucrare.val_fara_tvaA);
				let vanzari = pp + mm + vv + ss + cc + aa;
				let suma_avans_lucrari = ((vanzari * lucrare.p_avans) / 100).toFixed(2);
				let suma_termen_lucrari = (vanzari - suma_avans_lucrari).toFixed(2);

				// if (lucrare.moneda === 'eur') {
				//   a = Number(suma_avans_lucrari) * self.currency
				//   b = Number(suma_termen_lucrari) * self.currency
				// } else if (lucrare.moneda === 'ron') {
				//   a = Number(suma_avans_lucrari)
				//   b = Number(suma_termen_lucrari)
				// }
				//not EUR

				let bonus_c = (
					(suma_avans_lucrari * self.p_bonus.avans_lucrari) /
					100
				).toFixed(2);
				let bonus_d = (
					(suma_termen_lucrari * self.p_bonus.termen_lucrari) /
					100
				).toFixed(2);
				arrayC.push(bonus_c);
				arrayD.push(bonus_d);
				self.$store.dispatch("addLucrareBonus", {
					lucrare_id: lucrare.id_lucrare,
					tip_plata: "avans",
					bonus: bonus_c,
				});
				self.$store.dispatch("addLucrareBonus", {
					lucrare_id: lucrare.id_lucrare,
					tip_plata: "termen",
					bonus: bonus_d,
				});
			});

			this.bonus_avans = arrayA;
			this.bonus_termen = arrayB;
			this.bonus_avans_lucrari = arrayC;
			this.bonus_termen_lucrari = arrayD;
			this.addBonus();
		},
		addBonus() {
			this.$store.dispatch("addBonus", {
				data_bonus: this.firstdayLastMonth,
				total_vanzari: this.suma_vanzari,
				total_lucrari: this.suma_lucrari,
				calculat: 1,
				date1: this.firstdayLastMonth,
				date2: this.lastdayLastMonth,
				user_id: this.user.id_user,
			});
		},
		fetchBonus() {
			this.$store.dispatch("fetchBonus", {
				data: this.firstdayLastMonth,
				user_id: this.user.id_user,
			});
		},
		fetchBonusComenzi() {
			this.$store.dispatch("fetchComenziBonus", {
				date1: this.firstday,
				date2: this.lastday,
				user_id: this.user.id_user,
			});
		},
		fetchAllComenzi() {
			this.$store.dispatch("fetchComenziByUser", {
				date1: this.firstdayLastMonth,
				date2: this.lastdayLastMonth,
				user_id: this.user.id_user,
			});
		},
		fetchAllCurrentComenzi() {
			this.$store.dispatch("fetchAllCurrentComenziByUser", {
				date1: this.firstday,
				date2: this.lastday,
				user_id: this.user.id_user,
			});
		},
		fetchBonusLucrari() {
			this.$store.dispatch("fetchOferteProductieBonus", {
				date1: this.firstday,
				date2: this.lastday,
				user_id: this.user.id_user,
			});
		},
		fetchAllLucrari() {
			this.$store.dispatch("fetchOferteProductieByUser", {
				date1: this.firstdayLastMonth,
				date2: this.lastdayLastMonth,
				user_id: this.user.id_user,
			});
		},
		fetchBonusValues() {
			this.fetchBonusComenzi();
			this.fetchBonusLucrari();
		},
		fetchCurrency() {
			this.$store.dispatch("fetchCurrency");
		},
	},
	created: function() {
		this.fetchBonusComenzi();
		this.fetchAllComenzi();
		this.fetchBonusLucrari();
		this.fetchAllLucrari();
		this.fetchAllCurrentComenzi();
		this.fetchCurrency();
		if (this.user) {
			this.fetchBonus();
		}
	},
};
</script>

<style lang="css" scoped>
.warning button {
	margin-top: 9px;
}
.mbottom-20 {
	margin-bottom: 20px;
}
</style>
