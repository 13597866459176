<template>
  <div class="container">
    <div class="input-field filter-input col s3">
      <input placeholder="Cauta oferta dupa client" v-model="filterInput" />
    </div>
    <div v-if="loading" class="progress">
      <div class="indeterminate"></div>
    </div>
    <div v-else class="card-panel">
      <table class="highlight responsive-table">
        <thead>
          <tr>
            <th>Nr.</th>
            <th>Data oferta</th>
            <th>Denumire lucrare</th>
            <th>Client</th>
            <th>Intocmit de</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="oferta in filterBy(oferte_productie, filterInput)">
            <td>{{ oferta.id_oferta }}</td>
            <td>{{ oferta.data | formatDate }}</td>
            <td>{{ oferta.denumire_lucrare }}</td>
            <td>{{ oferta.nume }}</td>
            <td>{{ oferta.username }}</td>
            <td class="center-align">
              <i v-if="oferta.status_lucrare == 1" class="material-icons green-text">work</i>
            </td>
            <td>
              <router-link v-if="oferta.noua === '1'" router-link class="btn right btn-small"
                v-bind:to="'/oferte-productie/single/' + oferta.id_oferta">Adauga Detalii</router-link>
              <router-link v-else class="btn blue-grey lighten-2 right btn-small"
                v-bind:to="'/oferte-productie/single/' + oferta.id_oferta">Detalii</router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <pre>{{oferte_productie}}</pre> -->
    <!-- <pre>{{$data}}</pre> -->
  </div>
</template>

<script>
export default {
  name: "OferteProductieGeneral",
  data() {
    return {
      filterInput: "",
    };
  },
  computed: {
    oferte_productie() {
      return this.$store.getters.oferte_productie_general;
    },
    loading() {
      return this.$store.getters.loading;
    },
    user() {
      return this.$store.getters.loggedUser;
    },
  },
  methods: {
    filterBy(list, value) {
      value = value.charAt(0).toLowerCase() + value.slice(1);
      return list.filter((oferta) => {
        if (this.user.rol == "partener" || this.user.rol == "user") {
          return (
            oferta.nume.toLowerCase().indexOf(value) > -1 &&
            oferta.username == this.user.username
          );
        } else {
          return oferta.nume.toLowerCase().indexOf(value) > -1;
        }
      });
    },
  },
  created: function () {
    this.$store.dispatch("fetchOferteProductieGeneral");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
