<template>
	<div>
		<table>
			<thead>
				<tr class="font-bold">
					<td class="font-normal uppercase">Greutate</td>
					<td>Pret debitare</td>
					<td></td>
					<td></td>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="(item, index) in preturiDebitare"
					:key="index"
					class="hover:bg-gray-100"
				>
					<td class="font-bold">&lt; {{ item.toWeight }} kg</td>
					<td class="w-44">
						<input
							v-if="debitareEditMode == item.toWeight"
							type="number"
							step="1"
							placeholder="eur"
							class="validate base-input"
							v-model="item.pret_debitare"
						/>
						<span v-else>{{ item.pret_debitare }} eur</span>
					</td>
					<td></td>
					<td></td>
					<td class="w-14 select-none">
						<div v-if="debitareEditMode == item.toWeight">
							<span
								class="hover:text-green-500 cursor-pointer"
								@click="savePrices(item)"
								><i class="material-icons">save</i></span
							>
							<span
								class="hover:text-green-500 cursor-pointer"
								@click="debitareEditMode = ''"
								><i class="material-icons hover:text-red-500">close</i></span
							>
						</div>
						<span
							v-else
							class="hover:text-green-500 cursor-pointer"
							@click="editMode(item.toWeight)"
							><i class="material-icons">edit</i></span
						>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
export default {
	name: "SetariPreturiDebitare",
	components: {},
	data() {
		return {
			debitareEditMode: "",
		};
	},
	computed: {
		preturiDebitare() {
			return this.$store.getters.preturiDebitare;
		},
	},
	watch: {},
	methods: {
		editMode(weightStep) {
			this.debitareEditMode = weightStep;
		},
		savePrices(item) {
			this.$store.dispatch("updPreturiDebitare", item);
			this.debitareEditMode = "";
		},
	},
	created: function() {
		this.$store.dispatch("fetchPreturiDebitare");
	},
};
</script>

<style scoped></style>
