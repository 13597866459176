<template>
	<div class="container">
		<router-link class="btn blue-grey lighten-2 back-btn left" to="/debitare"
			>Inapoi</router-link
		>
		<div class="btns right">
			<button class="btn blue-grey darken-1" @click="avizeaza()">
				<i class="material-icons left">description</i>Aviz Nou
			</button>
			<button class="btn grey darken-1" @click="generatePDF()">
				<i class="material-icons left">file_download</i>Holding PDF
			</button>
			<router-link class="btn" to="/debitare/stocprofile/add"
				>Adauga profile in stoc</router-link
			>
		</div>
		<h4>Holding</h4>
		<br />
		<div class="row">
			<div class="col s12">
				<ul class="tabs">
					<li class="tab">
						<a class="active" href="#tab1">Stoc Profile</a>
					</li>
					<li class="tab">
						<a href="#tab2">Stoc Materiale Debitate</a>
					</li>
					<li class="tab">
						<a href="#tab3">Stoc Scrap</a>
					</li>
				</ul>
				<br />
			</div>
			<div id="tab1" class="col s12">
				<StocProfile
					v-if="listaStocProfile"
					:listaStocProfile="listaStocProfile"
					@selectProfile="selectedProfile = $event"
				></StocProfile>
			</div>
			<div id="tab2" class="col s12">
				<StocMateriale
					v-if="listaMateriale"
					:listaMateriale="listaMateriale"
					@selectMat="selectedMateriale = $event"
				></StocMateriale>
			</div>
			<div id="tab3" class="col s12">
				<StocScrap
					v-if="listaSaciScrap && listaPacheteScrap"
					:listaSaciScrap="listaSaciScrap"
					:listaPacheteScrap="listaPacheteScrap"
					:stocPachete="stocPachete"
					:stocSaci="stocSaci"
					:stocSpan="stocSpan"
					@selectSaci="selectedSaci = $event"
					@selectPachete="selectedPachete = $event"
					@selectSpan="selectedSpan = $event"
				></StocScrap>
			</div>
		</div>
		<div class="row">
			<div class="col s4">
				<!-- <pre>STOCURI selected Profile: {{selectedProfile}}</pre> -->
				<!-- <pre>STOCURI selected Materiale: {{selectedMateriale}}</pre> -->
				<!-- <pre>STOCURI selected Pachete: {{selectedPachete}}</pre> -->
				<!-- <pre>STOCURI selected Saci: {{selectedSaci}}</pre> -->
				<!-- <pre>STOCURI selected Span: {{selectedSpan}}</pre> -->
				<!-- <pre>{{stocProfile}}</pre> -->
				<!-- <pre>{{stocSpan}}</pre> -->
			</div>
			<div class="col s4">
				<!-- <pre>{{listaMateriale}}</pre> -->
				<!-- <pre>{{listaPacheteScrap}}</pre> -->
				<!-- <pre>{{listaSaciScrap}}</pre> -->
				<!-- <pre>{{addTipProfil}}</pre> -->
			</div>
			<div class="col s4">
				<!-- <pre>{{listaDeb}}</pre> -->
				<!-- <pre>{{listaStocProfile}}</pre> -->
			</div>
		</div>
	</div>
</template>

<script>
import moment from "moment";
import StocProfile from "@/components/Debitare/StocProfile";
import StocMateriale from "@/components/Debitare/StocMateriale";
import StocScrap from "@/components/Debitare/StocScrap";
import jsPDF from "jspdf";

export default {
	name: "Stocuri",
	data() {
		return {
			filterInput: "",
			today: moment(),
			selectedProfile: [],
			selectedMateriale: [],
			selectedPachete: {},
			selectedSaci: {},
			selectedSpan: {},
		};
	},
	computed: {
		stocProfile() {
			return this.$store.getters.stocProfile;
		},
		listaMateriale() {
			// return non-caching getter with ()
			return this.$store.getters.listaMaterialeStoc();
		},
		listaPacheteScrap() {
			return this.$store.getters.listaScrapPacheteStoc;
		},
		listaSaciScrap() {
			return this.$store.getters.listaScrapSaciStoc;
		},
		// listaDeb() {
		//   return this.$store.getters.listaDebitari
		// },
		listaStocProfile() {
			// Need of store actions: fetchstocProfile and fetchListaDebitari to populate the getter
			return this.$store.getters.listaStocProfile;
		},
		stocPachete() {
			return this.$store.getters.pachete;
		},
		stocSaci() {
			return this.$store.getters.saci;
		},
		stocSpan() {
			return this.$store.getters.span;
		},
		weightBucScrap() {
			let kg = 0;
			this.listaPacheteScrap.forEach((item) => {
				kg =
					kg +
					this.greutate(
						item.lungime_finala,
						item.profil_greutate_ml,
						item.buc_scrap
					);
			});
			return kg;
		},
		weightScrap() {
			let kg = 0;
			this.listaSaciScrap.forEach((item) => {
				kg =
					kg +
					this.greutate(item.cota_scrap, item.profil_greutate_ml, item.scrap);
			});
			return kg;
		},
		weightSpan() {
			let kg = 1000;
			let buc = this.stocSpan.buc;
			let w = kg * buc;
			return w;
		},
	},
	components: {
		StocProfile,
		StocMateriale,
		StocScrap,
	},
	watch: {
		// listaDebitari: {
		//     handler: function (val, oldVal) {
		//       this.addTipProfil
		//     },
		//     deep: true
		// }
	},
	methods: {
		greutate(cota, gml, buc) {
			return Math.round((((cota / 1000) * gml) / 1000) * buc);
		},
		isEmpty(obj) {
			for (var key in obj) {
				if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
			}
			return true;
		},
		avizeaza() {
			if (
				this.selectedMateriale.length == 0 &&
				this.selectedProfile.length == 0 &&
				this.isEmpty(this.selectedPachete) &&
				this.isEmpty(this.selectedSaci) &&
				this.isEmpty(this.selectedSpan)
			) {
				alert("Selectati cel putin o pozitie!");
			} else {
				let arr_mat = [];
				let arr_profile = [];
				let obj_pachete_scrap = {};
				let obj_saci_scrap = {};
				let obj_saci_span = {};
				let listaMateriale = this.listaMateriale;
				let listaProfile = this.listaStocProfile;

				this.selectedMateriale.forEach((id) => {
					// let material = listaMateriale[idx] - old
					let material = listaMateriale.find(function(item) {
						return item.id == id;
					});

					let pachete = Math.trunc(material.cantitate / material.profil_bucpac);
					let rem = material.cantitate % material.profil_bucpac;

					let str;
					if (pachete != 0) {
						str = pachete + " x " + material.profil_bucpac + "buc";
						if (rem != 0) {
							str = str.concat(",  1 x " + rem + "buc");
						}
					} else {
						str = "1 x " + rem + "buc";
					}

					let status = "";
					if (material.cantitate_finala <= material.cantitate) {
						status = "completa";
					} else {
						status = "incompleta";
					}

					let obj = {
						comanda_id: material.comanda_id,
						denumire_produs:
							material.profil_tip +
							"     " +
							material.data_com_deb.slice(2, 4) +
							"   " +
							material.comanda_obj.nr +
							"   " +
							material.comanda_obj.pos +
							"   " +
							material.comanda_obj.subpos +
							"     L=" +
							material.lungime_finala +
							"mm",
						cantitate: material.cantitate,
						pachete: str,
						status: status,
						scrap: material.buc_scrap,
					};
					arr_mat.push(obj);
				});

				this.selectedProfile.forEach((id) => {
					// let profil = listaProfile[idx] - old
					let profil = listaProfile.find(function(item) {
						return item.id == id;
					});

					let obj = {
						stoc_id: profil.stoc_id,
						denumire_produs:
							profil.profil_tip + "     L=" + profil.cota_profil + "mm",
						cantitate: profil.cantitate,
						greutate: this.greutate(
							profil.cota_profil,
							profil.profil_greutate_ml,
							profil.cantitate
						),
					};
					arr_profile.push(obj);
				});

				if (this.selectedPachete.nr != undefined) {
					let obj1 = {
						denumire_produs: "Pachete scrap",
						cantitate: this.selectedPachete.nr,
						buc: this.selectedPachete.buc,
						denumire: this.selectedPachete.denumire,
						greutate: this.selectedPachete.kg,
						comenzi: this.selectedPachete.comenzi,
					};
					obj_pachete_scrap = obj1;
				}
				if (this.selectedSaci.nr != undefined) {
					let obj2 = {
						denumire_produs: "Saci scrap",
						cantitate: this.selectedSaci.nr,
						buc: this.selectedSaci.buc,
						denumire: this.selectedSaci.denumire,
						greutate: this.selectedSaci.kg,
						comenzi: this.selectedSaci.comenzi,
					};
					obj_saci_scrap = obj2;
				}
				if (this.selectedSpan.nr != undefined) {
					let obj3 = {
						denumire_produs: "Saci span",
						cantitate: this.selectedSpan.nr,
						buc: this.selectedSpan.buc,
						denumire: this.selectedSpan.denumire,
						greutate: this.selectedSpan.kg,
					};
					obj_saci_span = obj3;
				}

				this.$store.dispatch("addAvizMat", {
					data: this.today.format("YYYY-MM-DD"),
					obj_mat: arr_mat,
					obj_profile: arr_profile,
					obj_pachete_scrap: obj_pachete_scrap,
					obj_saci_scrap: obj_saci_scrap,
					obj_saci_span: obj_saci_span,
				});
			}
		},
		filterComenzi(comenzi, deb) {
			return comenzi.filter(function(item) {
				// return idx > (deb.length - 1)
				let com = deb.find((obj) => {
					return obj.nr == item.nr;
				});

				return com == undefined;
			});
		},
		generatePDF() {
			//generatePDF(de){
			let de = 1;
			let pdfName = "Holding_certificate";
			var doc = new jsPDF();
			var pageNr = 1;

			doc.setFont("arial");
			doc.setFontStyle("normal");
			doc.setFontSize(9);
			doc.setTextColor(100);

			//Document header
			doc.setFontStyle("bold");
			doc.text("CUI: ", 10, 20);
			doc.text("No. ONRC: ", 10, 25);
			doc.text("Address: ", 10, 30);
			doc.setFontSize(10);
			doc.setFontStyle("bold");
			doc.text("Cuprolli Manufacturing srl", 10, 15);
			doc.setFontSize(9);
			doc.setFontStyle("normal");
			doc.text("RO35671874", 18, 20);
			doc.text("J35/468/2016", 27, 25);
			doc.text("Str. Caprioarei, nr.11, Ghiroda, jud. Timis", 25, 30);

			// doc.setFontStyle('bold')
			// doc.text("Furnizor: ", 130, 15)
			// doc.text("CUI: ", 130, 20)
			// doc.text("Nr. ONRC: ", 130, 25)
			// doc.text("Sediu/Pct. lucru: ", 130, 30)
			// doc.setFontStyle('normal')
			// doc.text("Hydro Extrusion srl", 145, 15)
			// doc.text("RO16344329", 138, 20)
			// doc.text("J02/682/2004", 147, 25)
			// doc.text("Chisineu-Cris, jud. Arad", 156, 30)
			// doc.text("Str. Tudor Vladimirescu, FN, DN79/A", 130, 35)

			//center v line
			// doc.line(105, 40, 105, 60)

			doc.setFontSize(13);
			doc.setFontStyle("bold");
			doc.text("HOLDING CERTIFICATE ", 78, 48);
			doc.setFontSize(9);
			doc.setFontStyle("normal");
			doc.setTextColor(100);
			doc.text(
				"We herewith confirm, that the following goods are stored at our premises as of " +
					this.today.format("DD.MM.YYYY"),
				10,
				62
			);
			doc.setFontStyle("bold");
			doc.text("Material description: ", 10, 67);
			doc.setFontStyle("normal");
			doc.text("Aluminium extruded profiles", 43, 67);

			let space = 78;

			// Profile stoc table

			if (this.listaStocProfile.length > 0) {
				doc.setFontStyle("bold");
				doc.text("STOCK: PROFILES ", 10, space);

				//Table head
				doc.text("Nr.", 10, space + 8);
				doc.text("Section no.", 18, space + 8);
				// doc.text("Order no.", 70, space+8)
				doc.text("Status (ok/scrap)", 123, space + 8);
				doc.text("Pieces", 160, space + 8);
				doc.text("Net weight", 182, space + 8);
				doc.setDrawColor(150);
				doc.setLineWidth(0.4);
				doc.line(10, space + 10, 200, space + 10);
				space = space + 15;
				//Table lines (content)
				doc.setFontStyle("normal");
				let nr = 1;
				this.listaStocProfile.forEach((profil) => {
					doc.text(String(nr), 11, space);
					doc.text(
						String(profil.profil_tip + "  L = " + profil.cota_profil + "mm  "),
						19,
						space
					);

					let space2 = space;
					if (!profil.comenzi && profil.comenzi_obj) {
						profil.comenzi_obj.forEach((comanda) => {
							doc.text(
								String(
									comanda.an +
										"   " +
										comanda.nr +
										"   " +
										comanda.pos +
										"   " +
										comanda.subpos
								),
								71,
								space2
							);
							space2 = space2 + 5;
						});
						space2 = space2 - 5;
					} else if (
						profil.comenzi &&
						profil.comenzi_obj &&
						profil.comenzi.length < profil.comenzi_obj.length
					) {
						let comenzi = this.filterComenzi(
							profil.comenzi_obj,
							profil.comenzi
						);
						comenzi.forEach((comanda) => {
							doc.text(
								String(
									comanda.an +
										"   " +
										comanda.nr +
										"   " +
										comanda.pos +
										"   " +
										comanda.subpos
								),
								71,
								space2
							);
							space2 = space2 + 5;
						});
						space2 = space2 - 5;
					} else {
						doc.text("Profil ramas in stoc - fara comanda", 71, space);
					}

					doc.text("OK", 133, space);
					doc.text(String(profil.cantitate), 162, space);
					doc.text(
						String(
							this.greutate(
								profil.cota_profil,
								profil.profil_greutate_ml,
								profil.cantitate
							) + " kg"
						),
						184,
						space
					);
					doc.setLineWidth(0.2);
					space = space2;
					doc.line(10, space + 3, 200, space + 3);
					nr++;
					space = space + 8;

					if (space > 270) {
						doc.setFontStyle("normal");
						doc.text("continue on next page", 10, 280);
						doc.text("- " + String(pageNr) + " -", 100, 290);
						doc.addPage();
						pageNr = pageNr + 1;
						space = 15;
					}
				});
				space = space + 15;
			}

			// Materiale debitate stoc table

			if (this.listaMateriale.length > 0) {
				doc.setFontStyle("bold");
				doc.text("STOCK: CUTTED PROFILES ", 10, space);

				//Table head
				doc.text("Nr.", 10, space + 8);
				doc.text("Section no.", 18, space + 8);
				doc.text("Order no.", 70, space + 8);
				doc.text("Status (ok/scrap)", 123, space + 8);
				doc.text("Pieces", 160, space + 8);
				doc.text("Net weight", 182, space + 8);
				doc.setDrawColor(150);
				doc.setLineWidth(0.4);
				doc.line(10, space + 10, 200, space + 10);
				space = space + 15;
				//Table lines (content)
				doc.setFontStyle("normal");
				let nr = 1;
				this.listaMateriale.forEach((profil) => {
					doc.text(String(nr), 11, space);
					doc.text(
						String(
							profil.profil_tip + "  L = " + profil.lungime_finala + "mm  "
						),
						19,
						space
					);
					doc.text(
						String(
							profil.data_com_deb.slice(2, 4) +
								"   " +
								profil.comanda_obj.nr +
								"   " +
								profil.comanda_obj.pos +
								"   " +
								profil.comanda_obj.subpos
						),
						71,
						space
					);
					doc.text("OK", 133, space);
					doc.text(String(profil.cantitate), 162, space);
					doc.text(
						String(
							this.greutate(
								profil.lungime_finala,
								profil.profil_greutate_ml,
								profil.cantitate
							) + " kg"
						),
						184,
						space
					);
					doc.setLineWidth(0.2);
					doc.line(10, space + 3, 200, space + 3);
					nr++;
					space = space + 8;

					if (space > 270) {
						doc.setFontStyle("normal");
						doc.text("continue on next page", 10, 280);
						doc.text("- " + String(pageNr) + " -", 100, 290);
						doc.addPage();
						pageNr = pageNr + 1;
						space = 15;
					}
				});
				space = space + 15;
			}

			// Scrap stoc table
			doc.setFontStyle("bold");
			doc.text("STOCK: SCRAP ", 10, space);

			if (this.listaPacheteScrap) {
				//Table head
				doc.text("Nr.", 10, space + 8);
				doc.text("Section no.", 18, space + 8);
				// doc.text("Order no.", 70, space+8)
				doc.text("Status (ok/scrap)", 123, space + 8);
				doc.text("Pieces", 160, space + 8);
				doc.text("Net weight", 182, space + 8);
				doc.setDrawColor(150);
				doc.setLineWidth(0.4);
				doc.line(10, space + 10, 200, space + 10);
				space = space + 15;
				//Table lines (content)
				doc.setFontStyle("normal");
				let nr = 1;

				// this.listaSaciScrap.forEach(profil => {
				//   doc.text(String(nr), 11, space)
				//   doc.text(String(profil.profil_tip+'  L = '+profil.cota_scrap+'mm  '), 19, space)
				//   doc.text(String(profil.data_com_deb.slice(2, 4)+'   '+profil.comanda_obj.nr+'   '+profil.comanda_obj.pos+'   '+profil.comanda_obj.subpos), 71, space)
				//   doc.text('SCRAP', 133, space)
				//   doc.text(String(profil.scrap), 162, space)
				//   doc.text(String(this.greutate(profil.cota_scrap, profil.profil_greutate_ml, profil.scrap)+' kg'), 184, space)
				//   doc.setLineWidth(0.2)
				//   doc.line(10, space+3, 200, space+3)
				//   nr++
				//   space = space + 8
				//
				//   if (space > 250) {
				//     doc.setFontStyle("normal")
				//     doc.text("continue on next page", 10, 280)
				//     doc.text("- "+String(pageNr)+" -", 100, 290)
				//     doc.addPage();
				//     pageNr = pageNr+1
				//     space = 15;
				//   }
				// })

				// add pachete scrap
				if (this.stocPachete.buc != 0) {
					doc.text(String(nr), 11, space);
					doc.text("Pachete scrap", 19, space);
					doc.text("SCRAP", 130, space);
					doc.text(String(this.stocPachete.buc), 162, space);
					doc.text(String(this.weightBucScrap) + " kg", 184, space);
					doc.line(10, space + 3, 200, space + 3);
					nr++;
					space = space + 8;
				}

				// add saci scrap
				if (this.stocSaci.buc != 0) {
					doc.text(String(nr), 11, space);
					doc.text("Saci scrap", 19, space);
					doc.text("SCRAP", 130, space);
					doc.text(String(this.stocSaci.buc), 162, space);
					// doc.text(String(this.weightScrap) + " kg", 184, space);
					doc.text("800 kg", 184, space);
					doc.line(10, space + 3, 200, space + 3);
					nr++;
					space = space + 8;
				}

				// add saci span
				if (this.stocSpan.buc != 0) {
					doc.text(String(nr), 11, space);
					doc.text("Saci span", 19, space);
					doc.text("SCRAP", 130, space);
					doc.text(String(this.stocSpan.buc), 162, space);
					// doc.text(String(this.weightSpan) + " kg", 184, space);
					doc.text("500 kg", 184, space);
					doc.line(10, space + 3, 200, space + 3);
					nr++;
					space = space + 8;
				}
			}

			space = space + 10;

			// doc.text(String(space), 10, space)

			if (space > 200) {
				doc.setFontStyle("normal");
				doc.text("continue on next page", 10, 280);
				doc.text("- " + String(pageNr) + " -", 100, 290);
				doc.addPage();
				pageNr = pageNr + 1;
				space = 10;
			}

			doc.setFontSize(8);
			doc.text(
				"We confirm we hold the above material to your order.",
				10,
				space
			);
			let str = doc.splitTextToSize(
				"We acknowledge you have full title to and immediate right of possession of the material described and it is deliverable only to your order pursuant to your written instructions.",
				185
			);
			doc.text(str, 10, space + 5);
			doc.text(
				"We agree neither to part with possession of, nor to change or otherwise encumber the goods or to give any third party any rights in the goods.",
				10,
				space + 13
			);
			doc.text(
				"We agree that on your first request we will provide you with the originals of all documents representing the goods.",
				10,
				space + 18
			);
			doc.text(
				"We will not take or omit to take any action which will or may prejudice in any way your interest in the goods as described herein.",
				10,
				space + 23
			);

			str = doc.splitTextToSize(
				"We confirm that the material will be kept safe, secure, in good condition, segregated from any other goods and in areas clearly marked and identifying the material as your property",
				185
			);
			doc.text(str, 10, space + 28);
			str = doc.splitTextToSize(
				"We acknowledge that you or your duty appointed representative(s) have full right of access to inspect and/or remove the goods and all rights necessary for that purpose.",
				185
			);
			doc.text(str, 10, space + 36);
			doc.text(
				"We are fully authorised and entitled to issue this Certificate and to undertake the obligations contained herein.",
				10,
				space + 44
			);
			doc.text(
				"This Holding Certificate shall be governed by any construed in accordance with the substantive laws of Romania.",
				10,
				space + 49
			);

			space = space + 60;

			doc.setFontStyle("bold");
			doc.setFontSize(9);
			doc.text("This is a computer-generated document.", 10, space + 5);
			doc.setFontStyle("normal");
			doc.text(
				"Generated at: " + this.today.format("DD.MM.YYYY HH:mm"),
				10,
				space + 10
			);

			doc.text("- " + String(pageNr) + " -", 100, 290);

			// Print PDF
			if (de == 1) {
				doc.save(pdfName + ".pdf");
			}
			//else if (de == 2){
			//   var pdf = btoa(doc.output());
			//   // Send doc to php script for emailing forward
			//   this.$http.post('http://hub.cuprolli.ro/app_back/mail.php', {data: pdf, client_mail: this.oferta.email})
			//     .then(function(response){
			//       M.toast({html: 'Oferta trimisa!'})
			//     })
			// }
		},
		fetchStocProfile() {
			this.$store.dispatch("fetchStocProfile");
		},
		fetchListaDeb() {
			this.$store.dispatch("fetchDebitari");
			this.$store.dispatch("fetchListaDebitari");
		},
		fetchStocScrap() {
			this.$store.dispatch("fetchStocScrap");
		},
	},
	created: function() {
		this.fetchStocProfile();
		this.fetchListaDeb();
		this.fetchStocScrap();
	},
	mounted: function() {
		M.Tabs.init(document.querySelector(".tabs"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
