<template>
	<div class="row">
		<div id="furnizori" class="col s12">
			<div class="bg-white shadow p-8">
				<div v-if="loading" class="progress">
					<div class="indeterminate"></div>
				</div>
				<div v-else>
					<div class="row">
						<div class="col s6">
							<h6 class="grey lighten-4 title">Lista furnizori</h6>
						</div>
						<div class="col s6 btns-right">
							<button
								data-target="modal_add_furnizor"
								class="btn right modal-trigger"
							>
								Adauga furnizor
							</button>
						</div>
					</div>
					<table>
						<thead>
							<tr>
								<th class="width3">Nr.</th>
								<th>Denumire</th>
								<th>Email</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(furnizor, index) in furnizori">
								<td>{{ index + 1 }}</td>
								<td>{{ furnizor.denumire_furnizor }}</td>
								<td>{{ furnizor.email_furnizor }}</td>
								<td @click="selectedFurnizor(index)">
									<button
										data-target="modal_del_furnizor"
										class="right red-text modal-trigger"
									>
										X
									</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<!-- <pre>{{$data}}</pre> -->
		</div>
		<!-- MODALS -->
		<div id="modal_add_furnizor" class="modal modal-small modal-add-furnizor">
			<div class="modal-content">
				<h5>Adauga furnizor</h5>
				<form v-on:submit="addFurnizor">
					<div class="input-field">
						<input
							id="denumire_furnizor"
							type="text"
							class="validate"
							v-model="furnizor.denumire"
						/>
						<label for="denumire_furnizor">Denumire</label>
					</div>
					<div class="input-field">
						<input
							id="email_furnizor"
							type="email"
							class="validate"
							v-model="furnizor.email"
						/>
						<label for="email_furnizor">Email</label>
					</div>
					<button type="submit" class="btn">Salveaza</button>
					<a href="#!" class="modal-action modal-close btn blue-grey"
						>Anuleaza</a
					>
				</form>
			</div>
		</div>
		<div id="modal_del_furnizor" class="modal modal-delete modal-del-furnizor">
			<div class="modal-content">
				<h6>Sterge definitiv furnizorul</h6>
				<br />
				<button class="btn red" v-on:click="deleteFurnizor()">Sterge</button>
				<a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
			</div>
		</div>
		<!-- MODALS END -->
	</div>
</template>

<script>
export default {
	name: "SetariFurnizori",
	data() {
		return {
			furnizor: {},
			delIndex: "",
		};
	},
	computed: {
		loading() {
			return this.$store.getters.loading;
		},
		furnizori() {
			return this.$store.getters.furnizori;
		},
	},
	methods: {
		fetchFurnizori() {
			this.$store.dispatch("fetchFurnizori");
		},
		addFurnizor(e) {
			if (!this.furnizor.denumire || !this.furnizor.email) {
				M.toast({ html: "Completati toate campurile!" });
			} else {
				let newFurnizor = {
					denumire_furnizor: this.furnizor.denumire,
					email_furnizor: this.furnizor.email,
				};
				this.$store.dispatch("addFurnizor", newFurnizor);
				e.preventDefault();
				this.furnizor = {};
			}
			e.preventDefault();
		},
		selectedFurnizor(index) {
			this.delIndex = index;
		},
		deleteFurnizor() {
			let id = this.furnizori[this.delIndex].id_furnizor;
			this.$store.dispatch("deleteFurnizor", id);
		},
	},
	created: function() {
		this.fetchFurnizori();
	},
	mounted: function() {
		M.Modal.init(document.querySelector(".modal-add-furnizor"));
		M.Modal.init(document.querySelector(".modal-del-furnizor"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
td.delete {
	cursor: pointer;
	color: #e65100;
}
</style>
