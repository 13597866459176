<template>
  <div class="container">
    <h5 v-if="isNull" class="header">Adauga vopsire</h5>
    <h5 v-else class="header">Editeaza vopsire</h5>
    <EditVopsit v-if="currentOferta" :vopsit_obj="isNull ? vopsit : currentOferta.vopsit_obj" :tip="'oferta'" :id="currentOferta.id_oferta"></EditVopsit>
    <!-- <pre>{{currentOferta}}</pre> -->
  </div>
</template>

<script>
import EditVopsit from "@/components/Productie/EditVopsit";

export default {
  name: "OfertaVopsit",
  data() {
    return {
      vopsit: {
        pistolet: {
          active: false,
          vopsea: null,
          manopera: null,
          pret_vopsea: null,
          pret_manopera: null,
          pret_v: null,
          marja: null,
          pret_t: null
        },
        camp: {
          active: false,
          metri: null,
          pret_serv: null,
          pret_v: null,
          marja: null,
          pret_t: null
        }
      }
    };
  },
  components: {
    EditVopsit
  },
  computed: {
    currentOferta() {
      return this.$store.getters.currentOfertaProductie;
    },
    isNull() {
      if (this.currentOferta.vopsit_obj) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {},
  created: function() {
    this.$store.dispatch("fetchOfertaProductie", this.$route.params.id);
  },
  mounted: function() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
