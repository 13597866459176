<template>
	<div class="container">
		<router-link class="btn blue-grey lighten-2 back-btn left" to="/debitare"
			>Inapoi</router-link
		>
		<div class="btns right"></div>
		<h4>Rapoarte debitare</h4>
		<div class="row"></div>
	</div>
</template>

<script>
export default {
	name: "ComenziDeb",
	data() {
		return {};
	},
	computed: {},
	components: {},
	watch: {},
	methods: {},
	created: function() {},
	mounted: function() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
