<template>
	<div id="produse">
		<div class="row">
			<div class="flex justify-between items-center mb-4">
				<h6 class="text-base uppercase tracking-wider text-gray-500">
					Lista produse
				</h6>
				<button data-target="modal_add_produs" class="btn modal-trigger">
					Adauga produs
				</button>
			</div>
			<div class="col s12 mbottom-20">
				<table>
					<thead>
						<tr>
							<th class="width3">Nr.</th>
							<th>Denumire</th>
							<th>UM</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(produs, index) in listaProduse" :key="index">
							<td>{{ index + 1 }}</td>
							<td>
								<span>{{ produs.denumire_produs }}</span>
							</td>
							<td>
								<span>{{ produs.um_produs }}</span>
							</td>
							<!-- <td><button class="right" v-scroll-to="'#editProdus'" @click="openUpdProdusForm(index)">Edit</button></td> -->
							<td class="width75">
								<button
									data-target="modal_edit_produs"
									class="btn-text teal-text modal-trigger"
									@click="editProdus(index)"
								>
									Edit
								</button>
							</td>
							<td class="width75" @click="selectedProdus(index)">
								<button
									data-target="modal_del_produs"
									class="right red-text modal-trigger"
								>
									X
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<!-- MODALS -->
		<div id="modal_add_produs" class="modal modal-small modal-add-produs">
			<div class="modal-content">
				<h5 class="text-xl mb-2">Adauga produs</h5>
				<form v-on:submit="addProdus">
					<div class="input-field">
						<input
							id="denumire_produs"
							type="text"
							class="validate"
							v-model="produs.denumire"
						/>
						<label for="denumire_produs">Denumire</label>
					</div>
					<div style="margin-bottom: 30px;">
						<model-select
							id="um"
							:options="ums"
							v-model="produs.um_produs"
							placeholder="UM"
						></model-select>
					</div>
					<div class="btn-group mt-4">
						<button type="submit" class="btn">Salveaza</button>
						<a href="#!" class="modal-action modal-close btn blue-grey">
							Anuleaza
						</a>
					</div>
				</form>
			</div>
		</div>
		<div id="modal_edit_produs" class="modal modal-small modal-edit-produs">
			<div class="modal-content">
				<h5 class="text-xl mb-2">Editeaza produsul</h5>
				<form v-on:submit="updateProdus">
					<label for="denumire_produs">Denumire</label>
					<input
						type="text"
						class="validate"
						placeholder="Denumire aliaj"
						v-model="updProdus.denumire_produs"
					/>
					<div style="margin-bottom: 30px;">
						<model-select
							id="um"
							:options="ums"
							v-model="updProdus.um_produs"
							placeholder="UM"
						></model-select>
					</div>
					<div class="btn-group mt-4">
						<button type="submit" class="btn">Salveaza</button>
						<a href="#!" class="modal-action modal-close btn blue-grey">
							Anuleaza
						</a>
					</div>
				</form>
			</div>
		</div>
		<div id="modal_del_produs" class="modal modal-delete modal-del-produs">
			<div v-if="delIndex" class="modal-content">
				<h6>
					Sterge definitiv produsul "{{
						listaProduse[delIndex].denumire_produs
					}}"
				</h6>
				<br />
				<button class="btn red" v-on:click="deleteProdus()">Sterge</button>
				<a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
			</div>
		</div>
		<!-- <pre>{{$data}}</pre> -->
		<!-- <pre>{{listaProduse}}</pre> -->
	</div>
</template>

<script>
import { ModelSelect } from "vue-search-select";

export default {
	name: "SetariProduse",
	data() {
		return {
			visibleUpdateProdusForm: false,
			produs: {},
			updProdus: {},
			delIndex: "",
		};
	},
	computed: {
		listaProduse() {
			return this.$store.getters.listaProduse;
		},
		ums() {
			return this.$store.getters.ums;
		},
	},
	components: {
		ModelSelect,
	},
	methods: {
		editProdus(index) {
			let currentProdus = this.listaProduse[index];
			this.updProdus = {
				id_produs: currentProdus.id_produs,
				denumire_produs: currentProdus.denumire_produs,
				um_produs: currentProdus.um_produs,
			};
		},
		fetchListaProduse() {
			this.$store.dispatch("fetchListaProduse");
		},
		addProdus(e) {
			if (!this.produs.denumire) {
				M.toast({ html: "Completati toate campurile!" });
			} else {
				let newProdus = {
					denumire_produs: this.produs.denumire,
					um_produs: this.produs.um_produs,
				};
				this.$store.dispatch("addProdus", newProdus);
				e.preventDefault();
				this.produs = {};
			}
			e.preventDefault();
		},
		updateProdus(e) {
			let updProdus = {
				id_produs: this.updProdus.id_produs,
				denumire_produs: this.updProdus.denumire_produs,
				um_produs: this.updProdus.um_produs,
			};
			// console.log(updProdus);
			this.$store.dispatch("updProdus", updProdus);
			this.visibleUpdateProdusForm = false;
			M.Modal.getInstance(document.querySelector(".modal-edit-produs")).close();
			this.updProdus = {};
			e.preventDefault();
		},
		selectedProdus(index) {
			this.delIndex = index;
		},
		deleteProdus() {
			let id = this.listaProduse[this.delIndex].id_produs;
			this.$store.dispatch("deleteProdus", id);
		},
	},
	created: function() {
		this.fetchListaProduse();
	},
	mounted: function() {
		M.Modal.init(document.querySelectorAll(".modal"));
		// M.Modal.init(document.querySelector(".modal-del-produs"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
td.delete {
	cursor: pointer;
	color: #e65100;
}
</style>
