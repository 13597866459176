<template>
	<div>
		<bubble-menu class="bubble-menu" :tippy-options="{ duration: 100 }" :editor="editor"
			v-if="editor && editingMode">
			<button @click="
				editor
					.chain()
					.focus()
					.toggleHeading({ level: 1 })
					.run()
				" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
				Title1
			</button>
			<button @click="
				editor
					.chain()
					.focus()
					.toggleHeading({ level: 2 })
					.run()
				" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
				Title2
			</button>
			<button disabled class="cursor-default">
				<span class="px-2"></span>
			</button>
			<button @click="
				editor
					.chain()
					.focus()
					.toggleOrderedList()
					.run()
				" :class="{ 'is-active': editor.isActive('orderedList') }">
				List
			</button>
			<button @click="
				editor
					.chain()
					.focus()
					.toggleBulletList()
					.run()
				" :class="{ 'is-active': editor.isActive('bulletList') }">
				List <span>&#8226;</span><span>&#8226;</span><span>&#8226;</span>
			</button>
			<button disabled class="cursor-default">
				<span class="px-2"></span>
			</button>

			<button disabled class="cursor-default">
				<span class="px-2"></span>
			</button>
			<button @click="
				editor
					.chain()
					.focus()
					.setTextAlign('left')
					.run()
				" :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">
				left
			</button>
			<button @click="
				editor
					.chain()
					.focus()
					.setTextAlign('center')
					.run()
				" :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">
				center
			</button>
			<button @click="
				editor
					.chain()
					.focus()
					.setTextAlign('right')
					.run()
				" :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">
				right
			</button>
		</bubble-menu>
		<editor-content :editor="editor" class="pb-10" />
	</div>
</template>

<script>
import { Editor, EditorContent, BubbleMenu } from "@tiptap/vue-2";
import { StarterKit } from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";

export default {
	components: {
		EditorContent,
		BubbleMenu,
	},

	props: {
		editingMode: {
			type: Boolean,
		},
		value: {
			type: Object,
			default: () => { },
		},
	},

	data() {
		return {
			editor: null,
		};
	},

	watch: {
		editingMode(value) {
			this.editor.setOptions({ editable: value });
		},
		value(value) {
			// HTML
			// const isSame = this.editor.getHTML() === value;

			// JSON
			const isSame = this.editor.getJSON().toString() === value.toString();

			if (isSame) {
				return;
			}

			this.editor.commands.setContent(this.value, false);
		},
	},
	// methods: {
	// 	fetchJSON() {
	// 		const json = this.editor.getJSON();
	// 		console.log(json);
	// 	},
	// },
	mounted() {
		this.editor = new Editor({
			extensions: [
				StarterKit,
				TextAlign.configure({
					types: ['heading', 'paragraph'],
				}),
				Text
			],
			content: this.value,
			editable: false,
			onUpdate: () => {
				// HTML
				// this.$emit("input", this.editor.getHTML());

				// JSON
				this.$emit("input", this.editor.getJSON());
			},
		});
	},
	beforeDestroy() {
		this.editor.destroy();
	},
};
</script>

<style scoped>
/* ::v-deep { */
/* Basic editor styles */
/* } */

::v-deep .ProseMirror>*+* {
	margin-top: 0.75em;
}

::v-deep .ProseMirror code {
	@apply bg-gray-200 text-gray-600 text-sm px-2;
}

::v-deep .ProseMirror h1 {
	font-size: 18px;
	font-weight: bold;
	text-transform: uppercase;
}

::v-deep .ProseMirror h2 {
	font-size: 16px;
	font-weight: bold;
	text-transform: uppercase;
}

::v-deep .ProseMirror ul {
	@apply ml-4;
}

::v-deep .ProseMirror ul li {
	@apply list-disc;
}

.bubble-menu {
	display: flex;
	background-color: #0d0d0d;
	padding: 0.2rem;
	border-radius: 0.5rem;
	max-width: 500px;
}

.bubble-menu button {
	border: none;
	background: none;
	color: #fff;
	font-size: 0.85rem;
	font-weight: 500;
	padding: 0 0.2rem;
	opacity: 0.6;
}

.bubble-menu button:hover,
.bubble-menu button.is-active {
	opacity: 1;
}
</style>
