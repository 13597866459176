<template>
  <div class="container">
    <router-link class="btn blue-grey lighten-2 back-btn left" to="/debitare">Inapoi</router-link>
    <div class="btns right">
      <router-link class="btn" to="/debitare/echipa/add">Adauga echipa</router-link>
    </div>
    <h4>Echipe debitare</h4>
    <br>
    <div class="card-panel">
      <table class="highlight responsive-table">
        <thead>
          <tr>
            <th>Nr</th>
            <th>Echipa</th>
            <th>Utilaj</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="echipa in echipe">
            <td>{{echipa.echipa_id}}</td>
            <td><span v-for="item in echipa.echipa_nume">{{item+", " }}</span></td>
            <td>{{echipa.echipa_utilaj}}</td>
            <td><router-link class="btn blue-grey lighten-2 right" v-bind:to="'/debitare/echipa/edit/'+echipa.echipa_id">Editeaza</router-link></td>
          </tr>
        </tbody>
      </table>

      <!-- <pre>{{$data}}</pre> -->

    </div>
  </div>
</template>

<script>
export default {
  name: 'Echipe',
  data () {
    return {
      echipe: []
    }
  },
  methods: {
    fetchEchipe() {
      this.$http.get('http://hub.cuprolli.ro/app_back/index.php/api/debitare/echipe')
        .then(function(response){
          let echipe = response.body;
          echipe.forEach(function(obj){
            obj.echipa_nume = JSON.parse(obj.echipa_nume)
           })
          this.echipe = echipe;
        });
    }
  },
  created: function(){
    this.fetchEchipe();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
