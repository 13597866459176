<template>
    <v-btn v-if="item.is_sent_oferta == '1'" icon text @click="markSentOferta(0)">
        <v-icon color="teal">mdi-send-check</v-icon>
    </v-btn>
    <v-tooltip v-else color="teal" right>
        <template v-slot:activator="{ on, attrs }">
            <v-btn icon text @click="markSentOferta(1)">
                <v-icon color="grey" dark v-bind="attrs" v-on="on">
                    mdi-send
                </v-icon>
            </v-btn>
        </template>
        <span>Oferta trimisa?</span>
    </v-tooltip>
</template>

<script>
export default {
    props: {
        item: Object
    },
    data() {
        return {}
    },
    methods: {
        markSentOferta(isOferta) {
            this.$store.dispatch("markSentOfertaCerereGard", {
                id: this.item.id_cerere,
                is_sent_oferta: isOferta,
            });
        },
    }
}
</script>