<template>
  <!-- Custom Product row template -->
  <tbody>
    <tr>
      <td class="">
        <input
          type="number"
          class="validate center-align"
          v-model="customProdus.nr"
        />
      </td>
      <td colspan="3">
        <input type="text" v-model="customProdus.denumire" />
      </td>
      <td colspan="3">
        <input type="text" v-model="customProdus.dimensiune_text" />
      </td>
      <td class="">
        <model-select
          id="umm"
          :options="ums"
          v-model="customProdus.um"
          placeholder="UM"
        ></model-select>
      </td>
      <td class="">
        <input
          type="number"
          step="0.1"
          value="1"
          placeholder="1"
          class="validate center-align"
          v-model="customProdus.cantitate"
          v-on:input="totalC()"
        />
      </td>
      <td colspan="2"></td>
      <td class="">
        <input
          type="number"
          step="0.01"
          value="0.00"
          placeholder="0.00"
          class="validate center-align"
          v-model="customProdus.pret_u"
          v-on:input="totalC()"
        />
      </td>
      <td>
        <input
          type="text"
          class="center-align"
          placeholder="0.00"
          v-model="customProdus.pret_t"
          readonly
        />
      </td>
      <td>
        <span
          class="hover:text-red-500 cursor-pointer"
          @click="$emit('deleteCustomProductForm', index)"
          ><i class="material-icons delete">close</i></span
        >
      </td>
    </tr>
    <tr class="extra-info">
      <td colspan="4"></td>
      <td colspan="3">
        <div class="input-field mx-1 mt-0">
          <input
            :id="'detalii_' + customProdus.nr"
            type="text"
            class="validate center-align"
            v-model="customProdus.detalii"
          />
          <label :for="'detalii_' + customProdus.nr">Detalii</label>
        </div>
      </td>
      <td colspan="2">
        <div class="input-field mx-1 mt-0">
          <input
            :id="'termen_' + customProdus.nr"
            type="text"
            class="validate center-align"
            v-model="customProdus.t_livrare"
          />
          <label :for="'termen_' + customProdus.nr">Termen livrare</label>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
import { ModelSelect } from "vue-search-select";

export default {
  name: "ProductCustom",
  components: {
    ModelSelect,
  },
  props: {
    customProdus: Object,
    index: Number,
  },
  computed: {
    ums() {
      return this.$store.getters.ums;
    },
  },
  data() {
    return {};
  },
  methods: {
    totalC() {
      let total =
        Number(this.customProdus.cantitate) *
        Number(this.customProdus.pret_u).toFixed(2);
      let tax = total * 0.19;

      this.customProdus.pret_t = total.toFixed(2);
      this.customProdus.tva = tax.toFixed(2);
    },
  },
  created: function() {},
};
</script>

<style></style>
