<template>
	<div>
		<table>
			<thead>
				<tr class="font-bold">
					<td class="font-normal">PRETURI ALUMINIU</td>
					<td>Pret baza</td>
					<td>1050</td>
					<td>5083</td>
					<td>5754</td>
					<td>6082</td>
					<td>5005</td>
					<td>7075</td>
					<td>6060</td>
					<td>3105</td>
					<td>2017</td>
					<td>2007</td>
				</tr>
			</thead>
			<tbody>
				<tr
					v-for="produs in preturiVanzari"
					:key="produs.id"
					class="hover:bg-gray-100"
				>
					<td class="font-bold">{{ produs.denumire }}</td>
					<td class="w-20">
						<input
							v-if="produsEditMode == produs.denumire"
							placeholder="eur"
							class="validate base-input"
							v-model="produs.pret_baza"
						/>
						<span v-else>{{ produs.pret_baza }} eur</span>
					</td>

					<td class="w-20">
						<input
							v-if="
								produs.adaos_aliaje != null &&
									produsEditMode == produs.denumire &&
									findAliajIdx(produs, 1050) != -1
							"
							placeholder="%"
							class="validate suffix"
							v-model="produs.adaos_aliaje[findAliajIdx(produs, 1050)].adaos"
						/>
						<span>{{ aliajPrice(produs, 1050) }}</span>
					</td>
					<td class="w-20">
						<input
							v-if="
								produs.adaos_aliaje != null &&
									produsEditMode == produs.denumire &&
									findAliajIdx(produs, 5083) != -1
							"
							placeholder="%"
							class="validate suffix"
							v-model="produs.adaos_aliaje[findAliajIdx(produs, 5083)].adaos"
						/>
						<span>{{ aliajPrice(produs, 5083) }}</span>
					</td>
					<td class="w-20">
						<input
							v-if="
								produs.adaos_aliaje != null &&
									produsEditMode == produs.denumire &&
									findAliajIdx(produs, 5754) != -1
							"
							placeholder="%"
							class="validate suffix"
							v-model="produs.adaos_aliaje[findAliajIdx(produs, 5754)].adaos"
						/>
						<span>{{ aliajPrice(produs, 5754) }}</span>
					</td>
					<td class="w-20">
						<input
							v-if="
								produs.adaos_aliaje != null &&
									produsEditMode == produs.denumire &&
									findAliajIdx(produs, 6082) != -1
							"
							placeholder="%"
							class="validate suffix"
							v-model="produs.adaos_aliaje[findAliajIdx(produs, 6082)].adaos"
						/>
						<span>{{ aliajPrice(produs, 6082) }}</span>
					</td>
					<td class="w-20">
						<input
							v-if="
								produs.adaos_aliaje != null &&
									produsEditMode == produs.denumire &&
									findAliajIdx(produs, 5005) != -1
							"
							placeholder="%"
							class="validate suffix"
							v-model="produs.adaos_aliaje[findAliajIdx(produs, 5005)].adaos"
						/>
						<span>{{ aliajPrice(produs, 5005) }}</span>
					</td>
					<td class="w-20">
						<input
							v-if="
								produs.adaos_aliaje != null &&
									produsEditMode == produs.denumire &&
									findAliajIdx(produs, 7075) != -1
							"
							placeholder="%"
							class="validate suffix"
							v-model="produs.adaos_aliaje[findAliajIdx(produs, 7075)].adaos"
						/>
						<span>{{ aliajPrice(produs, 7075) }}</span>
					</td>
					<td class="w-20">
						<input
							v-if="
								produs.adaos_aliaje != null &&
									produsEditMode == produs.denumire &&
									findAliajIdx(produs, 6060) != -1
							"
							placeholder="%"
							class="validate suffix"
							v-model="produs.adaos_aliaje[findAliajIdx(produs, 6060)].adaos"
						/>
						<span>{{ aliajPrice(produs, 6060) }}</span>
					</td>
					<td class="w-20">
						<input
							v-if="
								produs.adaos_aliaje != null &&
									produsEditMode == produs.denumire &&
									findAliajIdx(produs, 3105) != -1
							"
							placeholder="%"
							class="validate suffix"
							v-model="produs.adaos_aliaje[findAliajIdx(produs, 3105)].adaos"
						/>
						<span>{{ aliajPrice(produs, 3105) }}</span>
					</td>
					<td class="w-20">
						<input
							v-if="
								produs.adaos_aliaje != null &&
									produsEditMode == produs.denumire &&
									findAliajIdx(produs, 2017) != -1
							"
							placeholder="%"
							class="validate suffix"
							v-model="produs.adaos_aliaje[findAliajIdx(produs, 2017)].adaos"
						/>
						<span>{{ aliajPrice(produs, 2017) }}</span>
					</td>
					<td class="w-20">
						<input
							v-if="
								produs.adaos_aliaje != null &&
									produsEditMode == produs.denumire &&
									findAliajIdx(produs, 2007) != -1
							"
							placeholder="%"
							class="validate suffix"
							v-model="produs.adaos_aliaje[findAliajIdx(produs, 2007)].adaos"
						/>
						<span>{{ aliajPrice(produs, 2007) }}</span>
					</td>
					<td class="w-14 select-none">
						<div v-if="produsEditMode == produs.denumire">
							<span
								class="hover:text-green-500 cursor-pointer"
								@click="savePrices(produs)"
								><i class="material-icons">save</i></span
							>
							<span
								class="hover:text-green-500 cursor-pointer"
								@click="produsEditMode = ''"
								><i class="material-icons hover:text-red-500">close</i></span
							>
						</div>
						<span
							v-else
							class="hover:text-green-500 cursor-pointer"
							@click="editMode(produs.denumire)"
							><i class="material-icons">edit</i></span
						>
					</td>
				</tr>
			</tbody>
		</table>
		<div class="h-14 mt-10 flex justify-start items-center bg-gray-100 px-4">
			<div class="flex items-center">
				<span class="mr-4">Formula calcul pret vanzari:</span>
				<span>( <span class="italic">pret_baza</span></span>
				<span class="px-2">*</span>
				<span v-if="visibleEditFormula" class="w-14 inline-block">
					<input
						class="validate -mb-2"
						type="number"
						step=".01"
						v-model="coeficient"
					/>
				</span>
				<span v-else>
					<span class="font-bold">{{ formulaVanzari.coeficient }}</span>
					)
				</span>
				<span class="px-2">+</span>
				<span class="">( 100</span>
				<span class="px-2">/</span>
				<span><span class="italic">kg_oferta</span> )</span>
			</div>
			<div class="px-4">
				<div v-if="visibleEditFormula">
					<span
						class="hover:text-green-500 cursor-pointer"
						@click="saveFormula()"
						><i class="material-icons">save</i></span
					>
					<span
						class="hover:text-green-500 cursor-pointer"
						@click="visibleEditFormula = false"
						><i class="material-icons">close</i></span
					>
				</div>
				<span
					v-else
					class="hover:text-green-500 cursor-pointer"
					@click="visibleEditFormula = true"
					><i class="material-icons">edit</i></span
				>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "SetariVanzari",
	data() {
		return {
			visibleEditFormula: false,
			coeficient: "",
			produsEditMode: "",
		};
	},
	computed: {
		preturiVanzari() {
			return this.$store.getters.preturiVanzari;
		},
		formulaVanzari() {
			return this.$store.getters.formulaVanzari;
		},
	},
	watch: {
		formulaVanzari(obj) {
			this.coeficient = obj.coeficient;
		},
	},
	components: {},
	methods: {
		saveFormula() {
			this.$store.dispatch("updFormulaVanzari", {
				coeficient: this.coeficient,
			});
			this.visibleEditFormula = false;
		},
		savePrices(produs) {
			this.$store.dispatch("updPreturiVanzari", produs);
			this.produsEditMode = "";
		},
		editMode(denumire) {
			this.produsEditMode = denumire;
		},
		findAliajIdx(produs, aliaj) {
			const isSameAliaj = (element) => element.aliaj == aliaj;
			let idx = produs.adaos_aliaje.findIndex(isSameAliaj);
			return idx;
		},
		aliajPrice(produs, aliaj) {
			let add_arr = undefined;
			if (produs.adaos_aliaje) {
				add_arr = produs.adaos_aliaje;
			}
			let add;

			if (typeof add_arr !== "undefined") {
				add = produs.adaos_aliaje.find((item) => item.aliaj == aliaj);
			}

			if (typeof add != "undefined") {
				let price = produs.pret_baza * (1 + add.adaos / 100);
				return price.toFixed(2) + " eur";
			} else {
				return "x";
			}
		},
	},
	created: function() {
		this.$store.dispatch("fetchFormulaVanzari");
		this.$store.dispatch("fetchPreturiVanzari");
	},
};
</script>

<style scoped>
input.suffix::after {
	content: "%";
	width: 20px;
	height: 20px;
}
.base-input {
	@apply mb-7 !important;
}
</style>
