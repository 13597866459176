<template>
	<div class="container">
		<div class="section">
			<router-link
				v-if="comanda && comanda.stare === 'activa'"
				class="btn blue-grey lighten-2 back-btn left"
				to="/comenzi"
				>Inapoi</router-link
			>
			<router-link
				v-else
				class="btn blue-grey lighten-2 back-btn left"
				to="/comenzi/finalizate"
				>Inapoi</router-link
			>
			<h5 class="inline-title">Comanda vanzari nr. {{ comanda.id_comanda }}</h5>
			<div class="btns right">
				<button
					v-if="comanda.data_avans === null || comanda.data_plata === null"
					data-target="modal-payment"
					class="btn grey darken-1 modal-trigger"
				>
					<i class="material-icons left">monetization_on</i>Incasare
				</button>
				<button
					v-if="
						comanda.contract == 0 || comanda.factura == 0 || comanda.awb == 0
					"
					data-target="modal-docs"
					class="btn blue-grey lighten-2 modal-trigger"
				>
					<i class="material-icons left">publish</i>Documente
				</button>
				<button
					v-if="comanda.email"
					data-target="modal-status"
					class="btn blue-grey modal-trigger"
				>
					<i class="material-icons left">email</i>Trimite status
				</button>
				<button
					v-if="comanda.stare != 'finalizata'"
					data-target="modal-final"
					class="btn cyan darken-1 modal-trigger"
				>
					<i class="material-icons left">check</i>Finalizeaza
				</button>
				<router-link
					class="btn"
					v-bind:to="'/comenzi/edit/' + comanda.id_comanda"
					><i class="material-icons left">edit</i>Editeaza</router-link
				>
				<!-- More... dropdown -->
				<span class="dropdown-trigger btn-icon-padding" data-target="dropdown">
					<i class="material-icons">more_vert</i>
				</span>
				<ul id="dropdown" class="dropdown-content">
					<li>
						<a data-target="modal_delete" class="modal-trigger" href="#!">
							<i class="material-icons left">delete_forever</i>Sterge
						</a>
					</li>
				</ul>
			</div>
		</div>
		<br />
		<div class="card-panel">
			<div class="row">
				<div class="col s4">
					<ul>
						<li>
							<h6>{{ comanda.nume }}</h6>
						</li>
						<li>
							Data comanda:
							<span class="bold">{{ comanda.data | formatDate }}</span>
						</li>
					</ul>
				</div>
				<div class="col s2 right-align">
					<ul>
						<li>
							<span class="bold">Valoare: </span> {{ comanda.suma }}
							{{ comanda.moneda }} <br />
						</li>
						<li>
							<span class="bold">Valoare fara TVA: </span>
							{{ comanda.val_fara_tva }} {{ comanda.moneda }}
						</li>
					</ul>
				</div>
				<div class="col s2 right-align offset-s1">
					<p v-if="comanda.stare === 'activa'" class="status green">
						Comanda {{ comanda.stare }}
					</p>
					<p v-else class="status blue-grey">Comanda {{ comanda.stare }}</p>
				</div>
				<div class="col s2 right-align offset-s1">
					<strong
						><p>
							Ultimul status trimis
							{{ comanda.ultimul_status | formatDateTime | NoData }}
						</p></strong
					>
				</div>
			</div>
			<div class="divider"></div>
			<table class="highlight responsive-table">
				<thead>
					<tr>
						<th>Nr.</th>
						<th>Produs</th>
						<th>Cantitate</th>
						<th>Valoare - fara TVA</th>
						<th>Data livrare</th>
						<th>Data livrare actualizata</th>
						<th>Status livrare</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(produs, indexProdus) in comanda.produse_obj"
						:key="indexProdus"
					>
						<td class="width3">{{ produs.nr }}</td>
						<!-- <td class="width40">{{produs.denumire+' '+produs.material+' '+produs.dimensiune+', aliaj: '+produs.aliaj}}</td> -->
						<td class="width40">
							<span v-if="produs.denumire.value">{{
								produs.denumire.value
							}}</span>
							<span v-else>{{ produs.denumire }}</span>
							<span>{{ produs.material }}</span
							><!--
              --><span v-if="produs.detalii">{{ " - " + produs.detalii }}</span
							><!--
              --><span v-if="produs.dimensiune">{{
								", " + produs.dimensiune
							}}</span
							><!--
              --><span>{{ ", aliaj: " + produs.aliaj }}</span>
						</td>
						<td>{{ produs.cantitate + " " + produs.um }}</td>
						<td>{{ produs.pret_t }} {{ comanda.moneda }}</td>
						<td>{{ produs.data_livrare | formatDate | NoData }}</td>
						<td
							style="color: #4caf50 ;"
							v-if="produs.status_livrare === 'livrare anulata'"
						>
							-
						</td>
						<td
							style="color: #4caf50 ;"
							v-else-if="produs.data_actualizata === produs.data_livrare"
						>
							{{ produs.data_actualizata | formatDate | NoData }}
						</td>
						<td style="color: #e53935 ;" v-else>
							{{ produs.data_actualizata | formatDate | NoData }}
						</td>
						<td
							class="bold"
							style="color: #4caf50 ;"
							v-if="produs.status_livrare === 'livrare finalizata'"
						>
							{{ produs.status_livrare | NoData }}
						</td>
						<td
							class="bold"
							style="color: #e53935 ;"
							v-else-if="produs.status_livrare === 'livrare anulata'"
						>
							{{ produs.status_livrare | NoData }}
						</td>
						<td v-else>{{ produs.status_livrare | NoData }}</td>
						<td>{{ produs.furnizor }}</td>
					</tr>
				</tbody>
			</table>
			<br />
			<span v-if="comanda.obs" class="bold">Detalii: </span>{{ comanda.obs }}
		</div>
		<div class="row footer">
			<div class="col s4">
				<div class="card-panel documents">
					<div class="row">
						<h6 class="bold">Documente</h6>
						<p
							v-if="
								comanda.contract == 0 &&
									comanda.factura == 0 &&
									comanda.awb == 0
							"
						>
							Nu exista documente incarcate.
						</p>
						<ul class="links">
							<li v-if="comanda.contract == 1">
								<span class="col s8"
									>Contract:
									<a href="#" @click="newTab(contract.docURL)">{{
										contract.name
									}}</a>
								</span>
								<span class="col s4 right">
									<button
										v-if="user.rol === 'admin' && comanda.ok_contract == 0"
										class="btn-icon"
										@click="checkDoc('contract')"
									>
										<i class="icon-btn green-text material-icons">check</i>
									</button>
									<i
										v-else-if="comanda.ok_contract == 1"
										class="doc-icon green-text material-icons"
										>check_circle</i
									>
									<button
										v-if="comanda.ok_contract == 0 || user.rol === 'admin'"
										class="btn-icon"
										@click="deleteDoc('contract', contract.id_doc)"
									>
										<i class="icon-btn red-text material-icons">close</i>
									</button>
								</span>
							</li>
							<li v-if="comanda.factura == 1">
								<span class="col s8"
									>Factura:
									<a href="#" @click="newTab(factura.docURL)">{{
										factura.name
									}}</a>
								</span>
								<span class="col s4 right">
									<button
										v-if="user.rol === 'admin' && comanda.ok_factura == 0"
										class="btn-icon"
										@click="checkDoc('factura')"
									>
										<i class="icon-btn green-text material-icons">check</i>
									</button>
									<i
										v-else-if="comanda.ok_factura == 1"
										class="doc-icon green-text material-icons"
										>check_circle</i
									>
									<button
										v-if="comanda.ok_factura == 0 || user.rol === 'admin'"
										class="btn-icon"
										@click="deleteDoc('factura', factura.id_doc)"
									>
										<i class="icon-btn red-text material-icons">close</i>
									</button>
								</span>
							</li>
							<li v-if="comanda.awb == 1">
								<span class="col s8"
									>Doc. transport:
									<a href="#" @click="newTab(awb.docURL)">{{ awb.name }}</a>
								</span>
								<span class="col s4 right">
									<button
										v-if="user.rol === 'admin' && comanda.ok_awb == 0"
										class="btn-icon"
										@click="checkDoc('awb')"
									>
										<i class="icon-btn green-text material-icons">check</i>
									</button>
									<i
										v-else-if="comanda.ok_awb == 1"
										class="doc-icon green-text material-icons"
										>check_circle</i
									>
									<button
										v-if="comanda.ok_awb == 0 || user.rol === 'admin'"
										class="btn-icon"
										@click="deleteDoc('awb', awb.id_doc)"
									>
										<i class="icon-btn red-text material-icons">close</i>
									</button>
								</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="col s4">
				<div class="card-panel">
					<h6 class="bold">Detalii plata</h6>
					<span class="bold">Avans: </span>{{ comanda.suma_avans }}
					{{ comanda.moneda }} <br />
					<span class="bold">La termen: </span>{{ comanda.suma_termen }}
					{{ comanda.moneda }} <br />
					<span class="bold">Termen de plata: </span>{{ termen_plata }} <br />
					<ul class="in-block">
						<li v-if="comanda.data_avans">
							<div class="col s10">
								<span class="bold">Avans incasat la: </span>
								{{ comanda.data_avans | formatDate }}
							</div>
							<div class="col s1 offset-s1 right">
								<button class="btn-icon" @click="deletePayment('avans')">
									<i class="icon-btn red-text material-icons">close</i>
								</button>
							</div>
						</li>
						<li v-if="comanda.data_plata">
							<div class="col s10">
								<span class="bold">Rest de plata incasat la: </span>
								{{ comanda.data_plata | formatDate }}
							</div>
							<div class="col s1 offset-s1 right">
								<button
									v-if="comanda.data_plata"
									class="btn-icon"
									@click="deletePayment('termen')"
								>
									<i class="icon-btn red-text material-icons">close</i>
								</button>
							</div>
						</li>
					</ul>
				</div>
			</div>
			<div class="col s4">
				<div class="card-panel">
					<h6 class="bold">Detalii bonus</h6>
					<ul>
						<li v-if="comanda.bonus_avans">
							<span class="bold">Bonus avans: </span>
							{{ comanda.bonus_avans }} ron
						</li>
						<li v-if="comanda.bonus_termen">
							<span class="bold">Bonus la termen: </span>
							{{ comanda.bonus_termen }} ron
						</li>
						<li v-if="bonus_total">
							<span class="bold">Total bonus: </span> {{ bonus_total }} ron
						</li>
					</ul>
				</div>
				<p>
					<i class="material-icons left info">info</i>Bonusul aferent se
					contabilizeaza doar la incasarea sumelor, si este conditionat de
					validitatea documentelor incarcate si indeplinirea target-ului din
					totalul vanzarilor.
				</p>
			</div>
		</div>

		<!-- MODALS -->
		<!--  Modal payment -->
		<div id="modal-payment" class="modal modal-payment modal-small">
			<div class="modal-content">
				<h6 class="bold caps-text">Incasare plata</h6>
				<p class="bold">Selecteaza tipul de incasare:</p>
				<div class="radio-btns mbottom-20">
					<label v-if="comanda.suma_avans != 0 && comanda.data_avans === null">
						<input
							name="group1"
							type="radio"
							value="avans"
							v-model="tipPlata"
						/>
						<span>Avans: {{ comanda.suma_avans }} {{ comanda.moneda }}</span>
					</label>
					<label v-if="comanda.suma_termen != 0 && comanda.data_plata === null">
						<input
							name="group1"
							type="radio"
							value="termen"
							v-model="tipPlata"
						/>
						<span
							>La termen: {{ comanda.suma_termen }} {{ comanda.moneda }}</span
						>
					</label>
				</div>
				<br />
				<div class="input-field">
					<input id="data" type="date" class="validate" v-model="dataPlata" />
					<label for="data">Data platii</label>
				</div>
				<button class="btn modal-close" v-on:click="addPayment()">
					<i class="material-icons left">monetization_on</i>Salveaza
				</button>
				<a href="#!" class="modal-action modal-close btn blue-grey">Inchide</a>
			</div>
		</div>

		<!--  Modal Docs -->
		<div id="modal-docs" class="modal modal-docs modal-small">
			<div class="modal-content">
				<h6 class="bold caps-text">Incarca document</h6>
				<p class="bold">Selecteaza tipul de document:</p>
				<div class="radio-btns mbottom-20">
					<label v-if="comanda.contract == 0">
						<input
							name="group1"
							type="radio"
							value="contract"
							v-model="tipDoc"
						/>
						<span>Contract</span>
					</label>
					<label v-if="comanda.factura == 0">
						<input
							name="group1"
							type="radio"
							value="factura"
							v-model="tipDoc"
						/>
						<span>Factura</span>
					</label>
					<label v-if="comanda.awb == 0">
						<input name="group1" type="radio" value="awb" v-model="tipDoc" />
						<span>AWB / CMR</span>
					</label>
				</div>
				<p>Poti incarca fisiere PDF cu o marime maxima de 900 KB</p>
				<div class="mbottom-20">
					<label for="file">Alege fisier</label>
					<input
						type="file"
						id="file"
						ref="file"
						v-on:change="handleFileUpload()"
					/>
				</div>
				<p v-if="file">Dimensiune fisier: {{ fileSize }} KB</p>

				<button class="btn modal-close" v-on:click="submitFile()">
					<i class="material-icons left">publish</i>Incarca
				</button>
				<a href="#!" class="modal-action modal-close btn blue-grey">Inchide</a>
			</div>
		</div>

		<!--  Modal Status Comanda -->
		<div id="modal-status" class="modal modal-status modal-small">
			<div class="modal-content">
				<h6>Trimite status</h6>
				<p>Client: {{ comanda.nume }}</p>
				<p>Email: {{ comanda.email }}</p>
				<div class="btn-group">
					<button href="#!" class="btn modal-close" v-on:click="sendStatus()">
						<i class="material-icons left">email</i>Trimite
					</button>
					<a href="#!" class="modal-action modal-close btn blue-grey"
						>Inchide</a
					>
				</div>
			</div>
		</div>

		<!--  Modal Finalizeaza Comanda -->
		<div id="modal-final" class="modal modal-final modal-small">
			<div class="modal-content">
				<h6 class="bold">Finalizeaza comanda?</h6>
				<p>
					Toate reperele din comanda vor avea statusul de livrare - "livrare
					finalizata"
				</p>
				<br />
				<button class="btn" v-on:click="finalizareComanda()">
					Finalizeaza
				</button>
				<a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
			</div>
		</div>

		<!--  Modal Delete Comanda -->
		<div id="modal_delete" class="modal modal-delete">
			<div class="modal-content">
				<h6>Sterge definitiv comanda: {{ comanda.id_comanda }}?</h6>
				<br />
				<button class="btn red" v-on:click="deleteComanda(comanda.id_comanda)">
					Sterge
				</button>
				<a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
			</div>
		</div>

		<div>
			<!-- <pre>{{$data}}</pre> -->
			<!-- <pre>{{comanda}}</pre> -->
			<!-- <pre>{{$store.state}}</pre> -->
		</div>
	</div>
</template>

<script>
import moment from "moment";
export default {
	name: "DetaliiComanda",
	data() {
		return {
			file: "",
			tipDoc: "",
			tipPlata: "",
			dataPlata: moment().format("YYYY-MM-DD"),
		};
	},
	computed: {
		user() {
			return this.$store.getters.loggedUser;
		},
		comanda() {
			return this.$store.getters.comanda;
		},
		contract() {
			return this.$store.getters.contract;
		},
		factura() {
			return this.$store.getters.factura;
		},
		awb() {
			return this.$store.getters.awb;
		},
		fileSize() {
			return Math.round(this.file.size / 1000);
		},
		termen_plata() {
			return moment(this.comanda.data, "YYYY-MM-DD")
				.add(this.comanda.t_plata, "days")
				.format("DD/MM/YYYY");
		},
		bonus_total() {
			return (
				Number(this.comanda.bonus_avans) + Number(this.comanda.bonus_termen)
			).toFixed(2);
		},
	},
	watch: {
		comanda: function() {
			this.fetchDoc("contract");
			this.fetchDoc("factura");
			this.fetchDoc("awb");
		},
	},
	methods: {
		dimensiune_text(index) {
			let produs = this.comanda.produse_obj[index];
			let str = produs.dimL;
			if (produs.dimH) {
				str += " x " + produs.dimH;
			}
			if (produs.dimG) {
				str += " x " + produs.dimG;
			}
			if (str == null || str == "") {
				return "";
			} else {
				if (!produs.dimH && !produs.dimG) {
					str = "D " + str;
				}
				return str + " mm";
			}
		},
		addPayment() {
			if (this.tipPlata === "") {
				alert("Selectati tipul platii!");
			} else {
				this.$store.dispatch("addPayment", {
					comanda_id: this.comanda.id_comanda,
					tip_plata: this.tipPlata,
					data_plata: this.dataPlata,
				});
			}
		},
		deletePayment(tip) {
			this.$store.dispatch("addPayment", {
				comanda_id: this.comanda.id_comanda,
				tip_plata: tip,
			});
		},
		checkDoc(tip) {
			let ccId;
			if (tip === "contract") {
				ccId = this.comanda.id_client;
			} else {
				ccId = this.comanda.id_comanda;
			}
			this.$store.dispatch("checkDoc", {
				tip_doc: tip,
				cc_id: ccId,
				comanda_id: this.comanda.id_comanda,
				activ: 1,
			});
		},
		handleFileUpload() {
			this.file = this.$refs.file.files[0];
		},
		submitFile() {
			if (!this.file || !this.tipDoc) {
				alert("Adaugati un fisier si selectati tipul documentului");
			} else {
				let formData = new FormData();
				formData.append("file", this.file);
				let ccId;
				if (this.tipDoc === "contract") {
					ccId = this.comanda.id_client;
				} else {
					ccId = this.comanda.id_comanda;
				}
				this.$store.dispatch("submitFile", {
					data: formData,
					cc_id: ccId,
					comanda_id: this.comanda.id_comanda,
					tip_doc: this.tipDoc,
				});
				this.file = "";
				this.$refs.file.value = null;
				this.tipDoc = "";
			}
		},
		newTab(url) {
			window.open(url, "_blank");
			return false;
		},
		fetchDoc(tip) {
			let ccId;
			if (tip === "contract") {
				ccId = this.comanda.id_client;
			} else {
				ccId = this.comanda.id_comanda;
			}
			this.$store.dispatch("fetchDoc", { cc_id: ccId, tip_doc: tip });
		},
		deleteDoc(tip, doc_id) {
			let ccId;
			if (tip === "contract") {
				ccId = this.comanda.id_client;
			} else {
				ccId = this.comanda.id_comanda;
			}
			this.$store.dispatch("deleteDoc", {
				id_doc: doc_id,
				tip_doc: tip,
				cc_id: ccId,
				comanda_id: this.comanda.id_comanda,
			});
		},
		finalizareComanda() {
			this.comanda.produse_obj.forEach(function(produs) {
				produs.status_livrare = "livrare finalizata";
			});

			this.$store.dispatch("finalizeazaComanda", {
				object: {
					stare: "finalizata",
					produse_obj: JSON.stringify(this.comanda.produse_obj),
				},
				id: this.comanda.id_comanda,
			});
		},
		fetchComanda(id) {
			this.$store.dispatch("fetchComanda", id);
		},
		deleteComanda(id) {
			this.$store.dispatch("deleteComanda", id);
		},
		sendStatus() {
			let comanda = this.comanda;
			let produse = this.comanda.produse_obj;

			var self = this;

			// produse.forEach(function(produs){
			//   delete produs.denumiri
			//   delete produs.aliaje
			//   delete produs.pret_u
			//   delete produs.pret_t
			//   delete produs.tva
			//  })

			let table = `<table style="min-width: 438px; border: 1px solid grey; font-size: 13px; border-collapse: collapse;">
      <thead>
      <tr style="text-align: left; background-color: #f3f4f8;">
      <th style="border: 1px solid black; padding: 10px;">Nr.</th>
      <th style="border: 1px solid black; padding: 10px;">Produs</th>
      <th style="border: 1px solid black; padding: 10px;">Cantitate</th>
      <th style="border: 1px solid black; padding: 10px; width: 68px;">Data livrare</th>
      <th style="border: 1px solid black; padding: 10px; width: 68px;">Data livrare actualizata</th>
      <th style="border: 1px solid black; padding: 10px;">Status livrare</th>
      </tr>
      </thead>
      <tbody>`;

			produse.forEach(function(produs, index) {
				let detalii = produs.detalii;
				if (detalii != "") {
					detalii = " - " + detalii;
				}
				let dim = self.dimensiune_text(index);
				if (dim == "") {
					dim = produs.dimensiune;
				}
				let data_livrare = produs.data_livrare;
				if (data_livrare == undefined || data_livrare == null) {
					data_livrare = "-";
				}
				let data_actualizata = produs.data_actualizata;
				if (data_actualizata == undefined || data_actualizata == null) {
					data_actualizata = "-";
				}
				let status_livrare = produs.status_livrare;
				if (status_livrare == undefined || status_livrare == null) {
					status_livrare = "-";
				}

				table +=
					`
        <tr>
        <td style="border: 1px solid black; text-align: center; padding: 4px;">` +
					produs.nr +
					`</td>

        <td style="border: 1px solid black; padding: 4px;">`;

				if (produs.denumire.value) {
					table +=
						"" +
						produs.denumire.value +
						" " +
						produs.material +
						detalii +
						", " +
						produs.dimensiune +
						", aliaj: " +
						produs.aliaj;
				} else {
					table +=
						"" +
						produs.denumire +
						" " +
						produs.material +
						detalii +
						" " +
						produs.dimensiune +
						", aliaj: " +
						produs.aliaj;
				}

				table +=
					`</td>

        <td style="border: 1px solid black; text-align: right; padding: 4px;">` +
					produs.cantitate +
					" " +
					produs.um +
					`</td>
        <td style="border: 1px solid black; text-align: center; padding: 4px;">` +
					data_livrare +
					`</td>`;
				if (produs.status_livrare === "livrare anulata") {
					table += `<td style="border: 1px solid black; text-align: center; padding: 4px;">-</td>`;
				} else {
					if (produs.data_livrare === produs.data_actualizata) {
						table +=
							`<td style="border: 1px solid black; text-align: center; color: #4caf50; padding: 4px;">` +
							data_actualizata +
							`</td>`;
					} else {
						table +=
							`<td style="border: 1px solid black; text-align: center; color: #e53935; padding: 4px;">` +
							data_actualizata +
							`</td>`;
					}
				}
				if (produs.status_livrare === "livrare finalizata") {
					table +=
						`<td style="border: 1px solid black; color: #4caf50; padding: 4px; font-weight: 700;">` +
						status_livrare +
						`</td>`;
				} else if (produs.status_livrare === "livrare anulata") {
					table +=
						`<td style="border: 1px solid black; color: #e53935; padding: 4px; font-weight: 700;">` +
						status_livrare +
						`</td>`;
				} else {
					table +=
						`<td style="border: 1px solid black; padding: 4px; font-weight: 700;">` +
						status_livrare +
						`</td>`;
				}
				table += `</tr>`;
			});

			table +=
				`<tr><td colspan="6" style="border: 1px solid black; padding: 4px;"><span style="font-weight: 700;">Stare comanda: </span>` +
				comanda.stare +
				`</td></tr>`;
			if (comanda.obs) {
				table +=
					`<tr><td colspan="6" style="padding: 4px;"><span style="font-weight: 700;">Detalii: </span>` +
					comanda.obs +
					`</td></tr>`;
			}

			table += `</tbody></table>`;

			let mailComanda = {
				nr_comanda: this.comanda.id_comanda,
				client_mail: this.comanda.email,
				data_comanda: moment(String(this.comanda.data)).format("DD/MM/YYYY"),
				table: table,
				obs: this.comanda.obs,
			};
			// Send status to php script for emailing forward
			this.$http
				.post(
					process.env.VUE_APP_HUB_API_HOST + "email/vanzari/comanda_status",
					mailComanda,
					{
						headers: { x_api_key: process.env.VUE_APP_HUB_API_KEY },
					}
				)
				.then(function(response) {
					this.comanda.ultimul_status = moment()
						.format()
						.slice(0, 19)
						.replace("T", " ");
					let ultimulStatus = {
						ultimul_status: this.comanda.ultimul_status,
					};

					let id = this.$route.params.id;
					this.$store.dispatch("updComandaStatus", {
						object: ultimulStatus,
						id: id,
					});

					M.toast({ html: response.body, classes: "blue" });
				})
				.catch(function(error) {
					console.log(error.body);
					M.toast({
						html: "Eroare! Emailul nu a fost trimis",
						classes: "red darken-1",
					});
				});
		},
	},
	created: function() {
		this.fetchComanda(this.$route.params.id);
	},
	mounted: function() {
		M.Dropdown.init(document.querySelectorAll(".dropdown-trigger"), {
			constrainWidth: false,
		});
		M.Modal.init(document.querySelector(".modal-payment"));
		M.Modal.init(document.querySelector(".modal-docs"));
		M.Modal.init(document.querySelector(".modal-status"));
		M.Modal.init(document.querySelector(".modal-final"));
		M.Modal.init(document.querySelector(".modal-delete"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loader::before {
	content: "";
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.7);
}
.preloader-wrapper {
	position: fixed;
	z-index: 999;
	overflow: show;
	margin: auto;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
.documents .row {
	padding: 0 10px;
}
.doc-icon {
	font-size: 18px;
	vertical-align: top;
	margin: 2px 15px 0 15px;
}
.mbottom-20 {
	margin-bottom: 20px;
}
ul.in-block {
	display: inline-block;
}
ul.in-block li > div {
	padding: 0 10px 0 0;
}
span.delete {
	cursor: pointer;
	color: #e65100;
}
ul.links li {
	height: 22px;
}
ul.links li span:first-child {
	padding: 0 15px 5px 0;
}
</style>
