
export const mag_pubele_ad = {
    print_mag_pubele_ad( doc, space, images ) {

        space += 10;

        doc.setLineWidth(0.3);
        doc.setLineDash([1, 1], 0);
        doc.line(8, space, 200, space);
        space += 5;
        doc.setLineDash([], 0);
        doc.addImage(images.data.magazii_pubele, "JPEG", 100, space, 100, 44);
        space = space + 7;
        doc.setFont("Roboto", "bold");
        doc.setFontSize(10);
        // doc.text([
        //     "MAGAZIE DIN ALUMINIU", 
        //     "PENTRU DEPOZITARE PUBELE", 
        // ], 10, space, {
        //     lineHeightFactor: 1.4,
        // });
        doc.text("MAGAZIE DIN ALUMINIU PENTRU DEPOZITARE PUBELE", 10, space);
        doc.setFontSize(8);
        doc.setFont("Roboto", "normal");

        doc.text("COMANDA ONLINE", 10, space + 14);
        doc.text("--> https://garduri-aluminiu.ro/magazii-pubele", 10, space + 20);

        doc.text([
            "Are capacitatea de a masca 2 pubele pentru deșeuri de 240 litri sau mai mici.", 
            "Este realizată din profile de aluminiu vopsite în câmp electrostatic, având o rezistență ridicată la intemperii.", 
            "Lățimea lamelelor este de 60 mm, iar spațiul dintre lamele de 35 mm asigură o aerisire corespunzătoare."
        ], 10, space + 34, {
            lineHeightFactor: 1.4,
        });
    }
}