import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
      themes: {
        light: { ...colors,
          primary: '#ff8635',
          'main-grey': '#6c7a8c',
          'medium-grey': '#555a64',
          'light-grey': '#a0abc0',
          'dark-grey': '#3c444c',
          'bg-grey': '#efefef',
          'bg-light-grey': '#f2f4f8',
          'blue-grey': '#607d8b',
          accent: '#8c9eff',
          error: '#b71c1c',
        },
      },
    },
  });
