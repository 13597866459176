<template>
  <div>
    <div class="card-panel row">
      <table v-if="produse_obj" class="responsive-table produse">
        <thead>
          <tr>
            <th class="center-align">Nr.</th>
            <th>Material</th>
            <th>Denumire produs</th>
            <th>Aliaj</th>
            <th colspan="3">Dimensiuni - mm -</th>
            <th class="center-align">UM</th>
            <th class="center-align">Cantitate</th>
            <th class="center-align">Pret</th>
            <th class="center-align">Marja %</th>
            <th class="center-align">Valoare fara TVA</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(produs, index) in produse_obj">
            <td class="width3">
              <input type="number" class="validate center-align" v-model="produs.nr" />
            </td>
            <td class="width10">
              <model-select id="material" :options="materiale" v-model="produs.material" placeholder="Material"
                @input="setAliaje(index)"></model-select>
            </td>
            <td class="width15">
              <model-select id="denumire" :options="denumiri" v-model="produs.denumire" placeholder="Alege produs"
                @input="setUm(index)"></model-select>
            </td>
            <td class="width15">
              <model-select id="aliaj" :options="produs.aliaje" v-model="produs.aliaj" placeholder="Aliaj"
                @input="setValoriProduse(index)"></model-select>
            </td>
            <td class="width75">
              <input
                v-if="produs.denumire.value !== 'Bara rotunda' && produs.denumire.value !== 'Bara hexagonala' && !arr_teviR.includes(produs.denumire.value)"
                type="number" placeholder="Latime" class="validate" v-model.number="produs.dimL"
                v-on:change="totalP(index)" />
              <input v-else type="number" placeholder="Diametru" class="validate" v-model="produs.dimL"
                v-on:change="totalP(index)" />
            </td>
            <td class="width75">
              <input v-if="arr_table.includes(produs.denumire.value)" type="number" placeholder="Lungime"
                class="validate" v-model.number="produs.dimH" v-on:change="totalP(index)" />
              <input
                v-else-if="arr_bareP.includes(produs.denumire.value) || arr_teviP.includes(produs.denumire.value) || arr_profileLT.includes(produs.denumire.value) || produs.denumire.value === 'Profil U'"
                type="number" placeholder="Inaltime" class="validate" v-model.number="produs.dimH"
                v-on:change="totalP(index)" />
            </td>
            <td class="width75">
              <input
                v-if="produs.denumire.value !== 'Bara rotunda' && produs.denumire.value !== 'Bara hexagonala' && !arr_bareP.includes(produs.denumire.value)"
                type="number" step="0.01" placeholder="Grosime" class="validate" v-model.number="produs.dimG"
                v-on:change="totalP(index)" />
            </td>
            <td class="width5">
              <input type="text" class="center-align" v-model="produs.um" readonly />
            </td>
            <td class="width75">
              <input type="number" step="0.01" value="1" placeholder="0" class="validate center-align"
                v-model="produs.cantitate" v-on:change="totalP(index)" />
            </td>
            <td class="width5">
              <input class="center-align" v-model="produs.pret" v-on:change="totalP(index)" />
            </td>
            <td class="width75">
              <input type="number" step="0.1" value="1" placeholder="0" class="validate center-align"
                v-model="produs.marja" v-on:change="totalP(index)" />
            </td>
            <td class="width75">
              <input class="center-align" placeholder="0.00" v-model="produs.pret_t" number readonly />
            </td>
            <td class="width3 delete right" @click="deleteProductForm(index)">X</td>
          </tr>
        </tbody>
      </table>
      <div class="row">
        <div class="col s9">
          <br />
          <a class="btn blue-grey lighten-2" @click="addNewProductForm">Adauga produs</a>
        </div>
        <table v-if="produse_obj" class="col s3">
          <tr>
            <td class="center-align bold">Total fara TVA</td>
            <td class="center-align">
              <span>{{ totalsP }}</span>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <button class="btn" @click="saveMateriePrima()">Salveaza</button>
    <router-link v-if="tip == 'oferta'" class="btn blue-grey lighten-2"
      :to="'/oferte-productie/single/' + id">Anuleaza</router-link>
    <router-link v-else-if="tip == 'lucrare'" class="btn blue-grey lighten-2"
      :to="'/lucrari-productie/single/' + id">Anuleaza</router-link>
    <!-- <pre>{{tip}} {{id}}</pre> -->
    <!-- <pre>{{produse_obj}}</pre> -->
  </div>
</template>

<script>
import { ModelSelect } from "vue-search-select";
// import firebase from 'firebase/app'

export default {
  name: "EditMateriePrima",
  data() {
    return {
      cost_fara_tvaP: "0.00",
      val_fara_tvaP: "0.00"
    };
  },
  props: ["produse_obj", "tip", "id"],
  components: {
    ModelSelect
  },
  computed: {
    materiale() {
      return this.$store.getters.selectMateriale;
    },
    ums() {
      return this.$store.getters.ums;
    },
    denumiri() {
      return this.$store.getters.denumiri;
    },
    arr_benzi() {
      return this.$store.getters.arr_benzi;
    },
    arr_bareP() {
      return this.$store.getters.arr_bareP;
    },
    arr_table() {
      return this.$store.getters.arr_table;
    },
    arr_teviR() {
      return this.$store.getters.arr_teviR;
    },
    arr_teviP() {
      return this.$store.getters.arr_teviP;
    },
    arr_profileLT() {
      return this.$store.getters.arr_profileLT;
    },
    totalsP: function () {
      let v = 0;
      let t = 0;
      this.produse_obj.forEach(function (produs) {
        v = v + Number(produs.pret_v);
        t = t + Number(produs.pret_t);
      });
      this.cost_fara_tvaP = v.toFixed(2);
      this.val_fara_tvaP = t.toFixed(2);
      return this.val_fara_tvaP;
    }
  },
  methods: {
    addNewProductForm() {
      let nr = this.produse_obj.length + 1;
      this.produse_obj.push({
        nr: "" + nr + "",
        material_id: "",
        denumire: {},
        aliaj: "",
        gspec: "",
        dimL: null,
        dimH: null,
        dimG: null,
        cantitate: "",
        cantitate_kg: "",
        um: "",
        pret: "",
        pret_v: "",
        marja: "",
        pret_t: "",
        aliaje: []
      });
    },
    deleteProductForm(index) {
      if (this.produse_obj.length > 1) {
        this.produse_obj.splice(index, 1);
      }
    },
    setUm(index) {
      this.produse_obj[index].um = this.produse_obj[index].denumire.um_produs;
    },
    setAliaje(index) {
      let material = this.produse_obj[index].material;
      this.produse_obj[index].aliaje = this.$store.getters.selectAliaje(
        material
      );
    },
    setValoriProduse(index) {
      let currentProduct = this.produse_obj[index];
      let currentAliaj = currentProduct.aliaje.find(
        aliaj => aliaj.value === currentProduct.aliaj
      );
      this.produse_obj[index].gspec = currentAliaj.gs;
      this.produse_obj[index].pret = currentAliaj.pret;
    },
    totalP(index) {
      let currentProduct = this.produse_obj[index];
      let gs = currentProduct.gspec;
      let dimL = currentProduct.dimL;
      let dimH = currentProduct.dimH;
      let dimG = currentProduct.dimG;
      let qty = currentProduct.cantitate;
      // let marja = currentProduct.marja;
      var res;

      if (this.arr_benzi.includes(currentProduct.denumire.value)) {
        res = (dimL * dimG * gs) / 1000;
      } else if (this.arr_bareP.includes(currentProduct.denumire.value)) {
        res = (dimL * dimH * gs) / 1000;
      } else if (currentProduct.denumire.value === "Platbanda") {
        res = (dimL * dimG * gs) / 1000;
      } else if (currentProduct.denumire.value === "Bara rotunda") {
        res = (0.785 * dimL * dimL * gs) / 1000;
      } else if (currentProduct.denumire.value === "Bara hexagonala") {
        res = (0.866 * dimL * dimL * gs) / 1000;
      } else if (this.arr_table.includes(currentProduct.denumire.value)) {
        res = ((dimL * dimH) / 1000) * gs * (dimG / 1000);
      } else if (this.arr_teviR.includes(currentProduct.denumire.value)) {
        res = 0.785 * (4 * dimL * dimG - 4 * dimG * dimG) * (gs / 1000);
      } else if (this.arr_teviP.includes(currentProduct.denumire.value)) {
        res = ((2 * dimL + 2 * dimH - 4 * dimG) / 1000) * dimG * gs;
      } else if (this.arr_profileLT.includes(currentProduct.denumire.value)) {
        res = ((dimL + dimH - dimG) / 1000) * dimG * gs;
      } else if (currentProduct.denumire.value === "Profil U") {
        res = ((dimL + 2 * dimH - 2 * dimG) / 1000) * dimG * gs;
      }

      let total = res * Number(qty) * Number(this.produse_obj[index].pret);
      this.produse_obj[index].cantitate_kg = res.toFixed(2);
      this.produse_obj[index].pret_v = total.toFixed(2);
      total = total + total * (Number(this.produse_obj[index].marja) / 100);
      this.produse_obj[index].pret_t = total.toFixed(2);
    },
    saveMateriePrima() {
      let newMateriePrima = {
        produse_obj: JSON.stringify(this.produse_obj),
        cost_fara_tvaP: this.cost_fara_tvaP,
        val_fara_tvaP: this.val_fara_tvaP,
        tip: this.tip,
        id: this.id
      };
      this.$store.dispatch("saveMateriePrima", newMateriePrima);
    }
  },
  created: function () {
    this.$store.dispatch("fetchMateriale");
    this.$store.dispatch("fetchListaProduse");
    this.$store.dispatch("fetchAliaje");
  },
  mounted: function () { }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
td.delete {
  cursor: pointer;
  color: #e65100;
}

.save {
  width: 100%;
  padding: 6px 30px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}

.save p {
  margin-right: 40px;
}
</style>
