<template>
	<div class="container">
		<!-- MODALS -->
		<!-- Modal Delete -->
		<div id="modal_delete" class="modal modal-delete modal-small">
			<div class="modal-content">
				<h6 class="text-lg mb-2">Sterge definitiv actul aditional?</h6>
				<div class="btn-group mt-4">
					<button class="btn modal-close bg-red-600 hover:bg-red-500 focus:bg-red-700"
						@click="deleteActAditional()">
						Sterge
					</button>
					<a class="modal-action modal-close btn blue-grey">Anuleaza</a>
				</div>
			</div>
		</div>
		<div class="flex flex-col lg:flex-row justify-between items-center my-6">
			<div class="flex justify-start items-center px-4 lg:px-0 mb-4 lg:mb-0">
				<router-link class="btn back-btn blue-grey lighten-2"
					:to="'/productie/contracte/single/' + $route.params.id">Inapoi</router-link>
				<h5 class="inline-title text-xl mx-6">
					Contract productie nr.
					<span class="font-bold">{{ $route.params.id }}</span>
					- Act aditional
				</h5>
			</div>
			<div class="btn-group">
				<button class="btn bg-gray-600 hover:bg-gray-500 focus:bg-gray-700 dropdown btn-icon-padding"
					data-target="pdf_dropdown" :disabled="editingMode">
					<i class="material-icons left">description</i>PDF
				</button>
				<ul id="pdf_dropdown" class="dropdown-content">
					<li>
						<a href="#!" v-on:click="downloadPDF()">
							<i class="material-icons left">file_download</i>Descarca PDF
						</a>
					</li>
					<!-- <li>
						<a data-target="modal_email" class="modal-trigger" href="#!">
							<i class="material-icons left">email</i>Email PDF
						</a>
					</li> -->
				</ul>
				<button v-if="editingMode" class="btn" @click="saveAditionalContent">
					<i class="material-icons left">save</i>Salveaza
				</button>
				<button v-if="editingMode" class="btn blue-grey" @click="toggleEditMode">
					<i class="material-icons left">close</i>Anuleaza
				</button>
				<button v-else class="btn" @click="toggleEditMode">
					<i class="material-icons left">edit</i>Editeaza
				</button>
				<button class="btn bg-red-600 hover:bg-red-500 focus:bg-red-700 modal-trigger"
					data-target="modal_delete">
					<i class="material-icons">delete_forever</i>
				</button>
			</div>
		</div>
		<div>
			<editor v-if="contract.id_aditional && aditionalExtra" v-model="aditional_text" :editingMode="editingMode"
				class="h-screen bg-white p-6 mb-8 overflow-y-scroll border-2 shadow-inner"
				:class="{ 'border-red-500': editingMode }" />
		</div>
		<div class="debug">
			<!-- <pre class="content"><code>{{ document_text }}</code></pre> -->
			<!-- <pre class="content"><code>{{ aditionalExtra }}</code></pre> -->
		</div>
	</div>
</template>

<script>
import moment from "moment";
import Editor from "@/components/Editor";
import { pdf_contract } from "@/pdf_functions/pdf_contract.js";

export default {
	name: "AditionalContractProductie",
	components: {
		Editor,
	},
	data() {
		return {
			editingMode: false,
			modifiedAditional: {},
			andText: {
				type: "paragraph",
				attrs: {
					textAlign: "left",
				},
				content: [
					{
						type: "text",
						text: "SI",
					},
				],
			},
			orText: {
				type: "paragraph",
				attrs: {
					textAlign: "left",
				},
				content: [
					{
						type: "text",
						text: "SAU",
					},
				],
			},
		};
	},
	computed: {
		loading() {
			return this.$store.getters.loading;
		},
		contract() {
			return this.$store.getters.contractProductie;
		},
		aditionalExtra() {
			return this.$store.getters.aditionalExtra
		},
		firma_montaj_contract() {
			if (this.contract.detalii.firma_montaj) {
				return this.$store.getters.firma_montaj_contract(
					this.contract.detalii.firma_montaj
				);
			} else {
				return this.$store.getters.firma_montaj_activa;
			}
		},
		executant_firma_text() {
			return this.$store.getters.executant_firma_text;
		},
		montaj_firma_text() {
			if (this.firma_montaj_contract) {
				return `2. S.C. ${this.firma_montaj_contract.denumire_firma.toUpperCase()} S.R.L., cu sediul in ${this.firma_montaj_contract.adresa
					} inregistrata in Registrul Comertului sub numarul ${this.firma_montaj_contract.regcom
					}, cod fiscal ${this.firma_montaj_contract.cui} (${Number(this.firma_montaj_contract.vat) ? "platitor" : "neplatitor"
					} de TVA), cont bancar: ${this.firma_montaj_contract.bank_account}, ${this.firma_montaj_contract.bank
					}, telefon 0732823808, reprezentata legal prin ${this.firma_montaj_contract.person
					}, in calitate de MONTATOR`;
			}
		},
		montaj_firma_text1() {
			if (this.firma_montaj_contract) {
				return `2. S.C. CUPROLLI MONTAJE S.R.L., cu sediul in Dumbravita, Str. Mikszath Kalman 18/20, Jud. Timis, inregistrata in Registrul Comertului sub numarul J35/4258/2021, cod fiscal 45109254 (neplatitor de TVA), cont bancar: RO83BTRLRONCRT0CE8963901, Banca Transilvania, telefon 0732823808, reprezentata legal prin Rosu Octavian, in calitate de MONTATOR`;
			}
		},
		montaj_firma_text2() {
			if (this.firma_montaj_contract) {
				return `2. S.C. CUPROLLI SERV S.R.L., cu sediul in Dumbravita, Str. Mikszath Kalman 18/20, Jud. Timis, inregistrata in Registrul Comertului sub numarul J35/3370/2021, cod fiscal 44771643 (neplatitor de TVA), cont bancar: RO52BTRLRONCRT0615069901, Banca Transilvania, telefon 0732823808, reprezentata legal prin Rosu Octavian, in calitate de MONTATOR`;
			}
		},
		montaj_firma_text3() {
			if (this.firma_montaj_contract) {
				return `2. S.C. CUPROLLI TRADING S.R.L., cu sediul in Dumbravita, Str. Mikszath Kalman 18/20, Jud. Timis, inregistrata in Registrul Comertului sub numarul J35/2578/2019, cod fiscal 41281760 (neplatitor de TVA), cont bancar: RO87BTRLRONCRT0478696001, Banca Transilvania, telefon 0732823808, reprezentata legal prin Rosu Octavian, in calitate de MONTATOR`;
			}
		},
		isMontajSeparat() {
			return this.contract.detalii.montaj_separat;
		},
		adresa_client() {
			let adresa = "";
			if (this.contract.persoana) {
				adresa = `cu sediul in ${this.contract.adresa}, inregistrata la Registrul Comertului, sub numarul ${this.contract.reg_com}, cod fiscal ${this.contract.cui}, reprezentata legal prin ${this.contract.persoana},`;
			} else {
				adresa = `cu domiciliul in ${this.contract.detalii.adresa
					}, identificat cu ${this.act_client}, ${this.contract.detalii.cnp
						? "CNP " + this.contract.detalii.cnp + ", "
						: ""
					}`;
			}
			return adresa;
		},
		persoana() {
			return {
				type: "paragraph",
				attrs: {
					textAlign: "right",
				},
				content: [
					{
						type: "text",
						text: `${this.contract.persoana}`,
					},
				],
			};
		},
		montator_title() {
			return {
				type: "paragraph",
				attrs: {
					textAlign: "left",
				},
				content: [
					{
						type: "text",
						text: "MONTATOR",
						marks: [{ type: "bold" }],
					},
				],
			};
		},
		montator_name() {
			if (this.firma_montaj_contract) {
				return {
					type: "paragraph",
					attrs: {
						textAlign: "left",
					},
					content: [
						{
							type: "text",
							text: `S.C. ${this.firma_montaj_contract.denumire_firma.toUpperCase()} S.R.L.`,
						},
					],
				};
			}
		},
		montator_person() {
			if (this.firma_montaj_contract) {
				return {
					type: "paragraph",
					attrs: {
						textAlign: "left",
					},
					content: [
						{
							type: "text",
							text: `${this.firma_montaj_contract.person}`,
						},
					],
				};
			}
		},
		exemplareContract() {
			return this.contract.detalii.montaj_separat ? "3 (trei)" : "2 (doua)";
		},
		module_gard() {
			let arr_modules = [];
			this.aditionalExtra.gard_obj.forEach((modul) => {
				let item = {
					type: "listItem",
					content: [
						{
							type: "paragraph",
							attrs: {
								textAlign: "left",
							},
							content: [
								{
									type: "text",
									text: `${this.denumireModulGard(
										modul.tip,
										modul.tip_poarta_mare
									)} - ${modul.buc} buc cu dimensiunile L x h de ${modul.latime
										} x ${modul.inaltime} mm;`,
								},
							],
						},
					],
				};
				arr_modules.push(item);
			});
			return arr_modules;
		},
		module_custom() {
			let arr_modules = [];
			if (this.aditionalExtra.alte_obj) {
				this.aditionalExtra.alte_obj.forEach((modul) => {
					let item = {
						type: "listItem",
						content: [
							{
								type: "paragraph",
								attrs: {
									textAlign: "left",
								},
								content: [
									{
										type: "text",
										text: `${modul.denumire} - ${modul.cantitate} ${modul.um}`,
									},
								],
							},
						],
					};
					arr_modules.push(item);
				});
			}

			return arr_modules;
		},
		aditional_text: {
			get() {
				if (this.contract.aditional_content) {
					return this.contract.aditional_content;
				} else {
					return {
						type: "doc",
						content: [
							{
								type: "heading",
								attrs: {
									textAlign: "center",
									level: 1,
								},
								content: [
									{
										type: "text",
										text: `ACT ADITIONAL NR. ${this.contract.id_aditional
											} / ${this.$options.filters.formatDate(
												this.contract.data_aditional
											)}`,
									},
								],
							},
							{
								type: "heading",
								attrs: {
									textAlign: "center",
									level: 2,
								},
								content: [
									{
										type: "text",
										text: "la CONTRACTUL DE EXECUTIE LUCRARI",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "center",
								},
								content: [
									{
										type: "text",
										text: `nr: ${this.contract.id_contract} / ${moment(
											String(this.contract.data)
										).format("DD.MM.YYYY")}`,
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left",
								},
							},
							{
								type: "heading",
								attrs: {
									textAlign: "left",
									level: 2,
								},
								content: [
									{
										type: "text",
										text: "In baza acordului de vointa al partilor",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left",
								},
								content: [
									{
										type: "text",
										text: "INTRE:",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left",
								},
								content: [
									{
										type: "text",
										text: this.executant_firma_text,
									},
								],
							},
							this.isMontajSeparat ? this.andText : { type: "blockquote" },
							this.isMontajSeparat
								? this.formatParagraph(this.montaj_firma_text1, "left")
								: { type: "blockquote" },
							this.isMontajSeparat ? this.orText : { type: "blockquote" },
							this.isMontajSeparat
								? this.formatParagraph(this.montaj_firma_text2, "left")
								: { type: "blockquote" },
							this.isMontajSeparat ? this.orText : { type: "blockquote" },
							this.isMontajSeparat
								? this.formatParagraph(this.montaj_firma_text3, "left")
								: { type: "blockquote" },
							this.andText,
							{
								type: "paragraph",
								attrs: {
									textAlign: "left",
								},
								content: [
									{
										type: "text",
										text: `${this.contract.detalii.montaj_separat ? "3" : "2"
											}. ${this.contract.nume.toUpperCase()}, ${this.adresa_client
											} telefon: ${this.contract.tel}, email: ${this.contract.email
											} in calitate de BENEFICIAR`,
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left",
								},
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left",
								},
								content: [
									{
										type: "text",
										text: `Prin prezentul act aditional la contractul de executie lucrari nr: ${this.contract.id_contract
											} / ${moment(String(this.contract.data)).format(
												"DD.MM.YYYY"
											)} se aduc urmatoarele modificari:`,
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left",
								},
								content: [
									{
										type: "text",
										text:
											"Art.1. Beneficiarul are finalizata partea de lucrare care cade in sarcina sa si executantul poate lua masuratorile finale.",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left",
								},
								content: [
									{
										type: "text",
										text: `Art.2. Oferta nr. ${this.contract.oferta_id} din data de ${moment(String(this.contract.oferta_data)).format("DD.MM.YYYY")} si pretul de ${(Number(this.contract.val_fara_tvaG) + Number(this.contract.val_fara_tvaA)).toFixed(2)} euro, pe baza careia a fost intocmit contractul, va fi inlocuita cu oferta nr. ${this.aditionalExtra.new_offer_id} din data ${moment(String(this.aditionalExtra.date_new_offer)).format("DD.MM.YYYY")} si pretul de ${(Number(this.aditionalExtra.val_fara_tvaG) + Number(this.aditionalExtra.val_fara_tvaA)).toFixed(2)} euro, care va fi parte integranta din contract si care va fi calculata conform elementelor cu dimensiunile de mai jos, dimensiuni ce sunt calculate in urma masuratorilor finale din data de ....................... .`,
									},
								],
							},
							{
								type: "bulletList",
								content: this.module_gard,
							},
							{
								type: "bulletList",
								content: this.module_custom,
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left",
								},
								content: [
									{
										type: "text",
										text: `Art.3. Pretul pentru metru patrat va ramane acelasi ca in oferta initiala singurul lucru care se va schimba vor fi dimensiunile care vor fi calculate conform ultimelor masuratori de unde va rezulta pretul final.`,
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left",
								},
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left",
								},
								content: [
									{
										type: "text",
										text: `Prezentul act adiţional a fost încheiat astazi ${moment(
											String(this.contract.data_aditional)
										).format("DD.MM.YYYY")} in ${this.exemplareContract
											} exemplare, cate unul pentru fiecare parte.`,
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left",
								},
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left",
								},
								content: [
									{
										type: "text",
										marks: [
											{
												type: "bold",
											},
										],
										text: "EXECUTANT",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left",
								},
								content: [
									{
										type: "text",
										text: "S.C. CUPROLLI MANUFACTURING S.R.L.",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left",
								},
								content: [
									{
										type: "text",
										text: "Rosu Octavian",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "right",
								},
								content: [
									{
										type: "text",
										marks: [
											{
												type: "bold",
											},
										],
										text: "BENEFICIAR",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "right",
								},
								content: [
									{
										type: "text",
										text: `${this.contract.nume.toUpperCase()}`,
									},
								],
							},
							this.contract.persoana ? this.persoana : { type: "blockquote" },
							{
								type: "paragraph",
								attrs: {
									textAlign: "left",
								},
							},
							this.contract.detalii.montaj_separat
								? this.montator_title
								: { type: "blockquote" },
							this.contract.detalii.montaj_separat
								? this.montator_name
								: { type: "blockquote" },
							this.contract.detalii.montaj_separat
								? this.montator_person
								: { type: "blockquote" },
						],
					};
				}
			},
			set(newVal) {
				this.modifiedAditional = newVal;
			},
		},
	},

	watch: {},

	methods: {
		toggleEditMode() {
			this.editingMode = !this.editingMode;
		},
		denumireModulGard(str, tip_poarta) {
			if (str == "Panou") {
				str += " gard";
			}
			if (str == "Poarta_mica") {
				str += "_pietonala";
			}
			if (str == "Poarta_mare") {
				str += `_${tip_poarta}`;
			}
			let newStr = str.replace(/_/g, " ");
			return newStr;
		},
		formatParagraph(text_content, alignment, text_type) {
			return {
				type: "paragraph",
				attrs: {
					textAlign: alignment,
				},
				content: [
					{
						type: "text",
						text: text_content,
						...(text_type && { marks: [{ type: text_type }] }),
					},
				],
			};
		},
		async downloadPDF() {
			const doc = await pdf_contract.createContractPDF(
				this.aditional_text.content,
				this.contract.detalii.montaj_separat,
				this.firma_montaj_contract.denumire_firma
			);
			let filename = `Act aditional la contractul nr. ${this.contract.id_contract} din ${this.contract.data} - ${this.contract.nume}.pdf`;
			doc.save(filename);
		},
		saveAditionalContent() {
			const payload = {
				id_aditional: this.contract.id_aditional,
				id_contract: this.contract.id_contract,
				content: JSON.stringify(this.modifiedAditional),
			};
			this.$store.dispatch("updateAditionalContent", payload);
			this.editingMode = !this.editingMode;
		},
		deleteActAditional() {
			this.$store.dispatch("deleteActAditional", {
				id_aditional: this.contract.id_aditional,
				id_contract: this.contract.id_contract,
			});
		},
	},
	created: async function () {
		await this.$store.dispatch("fetchActAditionalExtraDetails", this.$route.params.id);
	},
	mounted: function () {
		M.Modal.init(document.querySelectorAll(".modal"));
		M.Dropdown.init(document.querySelector(".dropdown"), {
			constrainWidth: false,
		});
	},
	updated: function () {
		M.Dropdown.init(document.querySelector(".dropdown"), {
			constrainWidth: false,
		});
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" scoped>
.debug {
	padding: 1rem 0 0;
}

.debug pre {
	border-radius: 5px;
	color: #333;
}

.debug code {
	display: block;
	white-space: pre-wrap;
	font-size: 0.8rem;
	padding: 0.75rem 1rem;
	background-color: #e9ecef;
	color: #495057;
}
</style>
