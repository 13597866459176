<template>
	<div class="container">
		<div v-if="loading" class="progress container mtop-20">
			<div class="indeterminate"></div>
		</div>

		<div v-else>
			<!-- HEADER -->
			<div class="flex flex-col lg:flex-row justify-between items-center my-6">
				<div class="flex justify-start items-center px-4 lg:px-0 mb-4 lg:mb-0">
					<router-link class="btn back-btn blue-grey lighten-2" to="/lucrari-productie">Inapoi</router-link>
					<h5 class="inline-title text-xl mx-6">
						Detalii lucrare productie nr.
						<span class="font-bold">{{ lucrare.id_lucrare }}</span>
					</h5>
				</div>
				<div class="btn-group flex justify-start lg:justify-end items-center flex-wrap px-4">
					<!-- More... dropdown -->
					<span class="dropdown-trigger btn-icon-padding" data-target="dropdown">
						<i class="material-icons">more_vert</i>
					</span>
					<ul id="dropdown" class="dropdown-content">
						<li>
							<a data-target="modal_delete" class="modal-trigger" href="#!">
								<i class="material-icons left">delete_forever</i>Sterge
							</a>
						</li>
					</ul>
				</div>
			</div>

			<!-- SUMMARY CARDS-->
			<div class="flex flex-col lg:flex-row gap-4 mb-6">
				<div class="bg-white w-1/3 px-6 py-6 shadow mb-1 lg:mb-0 lg:mr-2">

					<div class="flex flex-col">
						<span class="text-gray-500 mb-2">Detalii client</span>
						<span class="font-bold">
							{{ lucrare.nume }}
						</span>
						<span v-if="lucrare.persoana">{{ lucrare.persoana }}</span>
						<span>{{ lucrare.email }}</span>
						<span>{{ lucrare.tel }}</span>
					</div>
				</div>
				<div class="bg-white w-1/3 px-6 py-6 shadow mb-1 lg:mb-0 lg:mr-2">
					<div class="flex flex-col">
						<div>
							<span class="text-gray-500">Adaugat in lucrari la: </span>
							<span>{{ lucrare.data | formatDate }}</span>
						</div>
						<div class="mb-2">
							<span class="text-gray-500">Oferta intocmita de: </span>
							<span>{{ lucrare.username }}</span>
						</div>
						<div>
							<span class="text-gray-500">Cost lucrare: </span>
							<span class=" bold">{{ cost }} {{ moneda }}</span>
						</div>
						<div>
							<span class="text-gray-500">Valoare totala: </span>
							<span class=" bold">{{ grand_total }} {{ moneda }}</span>
						</div>
					</div>
				</div>

			</div>

			<hr class="my-6">

			<div class="flex flex-row gap-4">
				<div class="w-1/4 bg-white px-6 py-6 shadow mb-4">
					<h4 class="font-bold mb-3">Oameni productie</h4>
					<div v-if="lucrare.echipa && lucrare.echipa.length > 0">
						<div class="flex flex-row gap-2">
							<div>Oameni:</div>
							<div v-for="persoana in lucrare.echipa">
								<span class="dot" :class="persoana.color"></span>{{ persoana.name }}
							</div>
						</div>
						<div class="mt-3">
							<a class="btn-text teal-text inline-div modal-trigger"
								@click.prevent="openModal('modal-oameni')">Modifica oameni</a>
						</div>
					</div>
					<div v-else>
						<div class="flex items-center gap-2 bg-yellow-100 p-2">
							<i class="material-icons text-yellow-500">warning</i>
							<span>Nu sunt selectati oamenii din productie</span>

						</div>
						<div class="mt-3">
							<a class="btn-text teal-text inline-div modal-trigger"
								@click.prevent="openModal('modal-oameni')">Adauga
								oameni</a>
						</div>
					</div>
				</div>
				<div class="w-3/4 flex flex-row gap-4">
					<div class="w-1/2 bg-white px-6 py-6 shadow mb-4">
						<h4 class="font-bold mb-3">
							Programare productie
						</h4>
						<div class="mb-4">
							<span v-if="lucrare.start && lucrare.end" class="bold">{{ lucrare.start | formatDate }}
								-
								{{ endLucrareDate(lucrare.end) }}</span>
							<div v-else class="flex items-center gap-2 bg-red-100 p-2">
								<i class="material-icons text-red-500">warning</i>
								<span>Nu este programata</span>
							</div>
						</div>
						<div>

							<div v-if="visibleExTimeForm" class="flex flex-row gap-4">
								<div class="flex flex-row gap-1">
									<i class="material-icons">access_time</i>timp necesar:
									<div class="w-20">
										<input id="ex-time" type="number" step="1" class="h-8 validate"
											v-model="newExTime" />
									</div>
								</div>
								<a class="btn-text editbtn teal-text" @click="saveExTime">Salveaza</a>
								<a class="btn-text editbtn blue-grey-text" @click="visibleExTimeForm = false">Anuleaza</a>
							</div>
							<div v-else class="flex flex-row gap-4 mb-4">
								<div class="flex flex-row gap-1">
									<i class="material-icons">access_time</i>timp necesar:
									<span class="bold">{{ lucrare.t_executie }} zile</span>
								</div>
								<span class="btn-text teal-text cursor-pointer" @click="showExTimeForm">Editeaza</span>
							</div>
						</div>
						<div v-if="lucrare.start && lucrare.end" class="mb-4">
							<div class="flex flex-row gap-1">
								<i class="material-icons">access_time</i>programat:
								<span :class="[{ 'green-text': onTime }, 'red-text']" class="bold">{{ dayDifference }}
									zile</span>
							</div>

						</div>
						<div class="flex flex-row items-center gap-6">
							<div>
								<a v-if="lucrare.start && lucrare.end" data-target="modal_program"
									class="btn-text teal-text modal-trigger" href="#!">Modifica Programarea</a>
								<a v-else data-target="modal_program" class="btn-text teal-text inline-div modal-trigger"
									href="#!">Programeaza</a>
							</div>
							<a data-target="modal_calendar" class="btn-text teal-text modal-trigger" href="#!"
								@click="refreshCalendar">Vezi calendar
							</a>
						</div>
					</div>


					<div class="w-1/2 bg-white px-6 py-6 shadow mb-4">
						<h4 class="font-bold mb-3">Programare montaj</h4>
					</div>
				</div>
			</div>
		</div>

		<div class="bg-white px-6 py-6 shadow mt-8 mb-4">
			<div class="flex flex-col lg:flex-row justify-between">
				<h5 class="text-xl">Module gard</h5>
				<!-- <router-link class="btn" :class="lucrare.gard_obj ? 'blue-grey' : ''" v-bind:to="'/oferte-productie/gard/' + lucrare.id_lucrare
								">
								<i class="material-icons left">{{
									lucrare.gard_obj ? "edit" : "add"
								}}</i>module gard
							</router-link> -->
			</div>

			<p v-if="!lucrare.gard_obj">Nu exista detalii</p>
			<table v-else class="responsive-table">
				<thead>
					<tr>
						<th class="center-align">Nr.</th>
						<th>Tip | Model</th>
						<th>Dimensiuni ( l x h )</th>
						<th class="center-align">Cost / buc</th>
						<th class="center-align">Cantitate</th>
						<th class="center-align">Cost total</th>
						<th class="center-align">Marja %</th>
						<th class="center-align">Pret cu TVA</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(modul, index) in lucrare.gard_obj">
						<td class="center-align">{{ index + 1 }}</td>
						<td>
							{{ denumireModulGard(modul.tip, modul.tip_poarta_mare) }}
							{{
								modul.pp_included ? " cu poarta pietonala integrata" : ""
							}}
							| {{ modul.denumire_model }} - {{ modul.nr_profile }} x
							{{ modul.latime_profil }}mm ( {{ modul.distanta_gol }}mm gol
							)
						</td>
						<td>
							{{ modul.latime }} x {{ modul.inaltime }} mm |
							{{ Number(modul.suprafata).toFixed(2) }} m²
						</td>
						<td class="center-align">{{ modul.cost_modul }} eur</td>
						<td class="center-align">{{ modul.buc }}</td>
						<td class="center-align">{{ modul.cost_total }} eur</td>
						<td class="center-align">{{ modul.marja | NoData }} %</td>
						<td class="center-align">{{ modul.pret_modul }} eur</td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td class="right-align bold">Cost total</td>
						<td class="center-align bold">
							{{ lucrare.cost_fara_tvaG }} eur
						</td>
						<td class="right-align bold">Total cu TVA</td>
						<td class="center-align bold">
							{{ lucrare.val_fara_tvaG }} eur
						</td>
					</tr>
				</tfoot>
			</table>
		</div>

		<!-- <section v-if="!loading">
			<div class="container mtop-20 bg-gray-200">
				<div class="row">
					<div class="col s7">
						<h6 class="inline-div">Denumire lucrare:</h6>
						<span class="inline-div" v-if="visibleEditName">
							<input type="text" class="validate" style="width: 400px" v-model="lucrare.denumire_lucrare" />
							<a class="btn-text teal-text inline-div" @click="saveName()">Salveaza</a>
						</span>
						<span v-else>
							<h6 class="bold inline-div">{{ lucrare.denumire_lucrare }}</h6>
							<a class="btn-text teal-text left-margin right-margin" @click="openEditNameForm()">Modifica</a>
						</span>
					</div>
					<div class="col s2 offset-s1">
						<div v-if="visibleEditBuc">
							<div class="col s5">
								<input id="buc" type="number" step="1" class="validate" v-model="lucrare.buc_lucrare" />
							</div>
							<div class="col s7">
								<a class="btn-text editbtn teal-text" @click="saveBuc()">Salveaza</a>
							</div>
						</div>
						<div v-else>
							Bucati:
							<h6 class="inline-div right-margin">
								{{ lucrare.buc_lucrare }} buc
							</h6>
							<a class="btn-text teal-text" @click="openEditBucForm()">Modifica</a>
						</div>
					</div>
					<div class="col s2">
						<div class="radio-btns col s12" style="margin: .66rem 0 .46rem 0;">
							<span>Moneda:</span>
							<label>
								<input name="group1" type="radio" value="ron" v-model="moneda" />
								<span>RON</span>
							</label>
						</div>
					</div>
				</div>
			</div>
		</section> -->



		<div class="container my-10">
			<div class="row">
				<div class="col s3">
					<!-- <pre>{{ lucrare }}</pre> -->
				</div>
				<div class="col s3">
					<!-- <pre>{{ oameni_productie }}</pre> -->
					<!-- <pre>{{$data}}</pre> -->
				</div>
				<div class="col s3">
					<!-- <pre>{{ newEchipa }}</pre> -->
				</div>
			</div>
			<!-- <pre>{{$data}}</pre> -->
		</div>

		<!-- MODALS -->
		<!-- Modal programare lucrare -->
		<div id="modal_program" class="modal modal-small modal-program">
			<div class="modal-content">
				<h6 class="bold">Programeaza productia</h6>
				<br />
				<div class="row">
					<div class="input-field">
						<input id="data" type="date" class="validate" v-model="lucrare.start" />
						<label for="data">Data inceput</label>
					</div>
					<div v-if="lucrare.start && lucrare.end" class="input-field">
						<input id="termen" type="number" step="1" class="validate" v-model="new_termen" />
						<label for="termen">Timp executie (zile)</label>
					</div>
					<div v-else>
						<p>Timp executie: {{ lucrare.t_executie }} zile</p>
					</div>
				</div>
				<div class="flex flex-row gap-2">
					<button v-if="lucrare.start && lucrare.end" class="btn" v-on:click="editCalendarLucrare()">
						Salveaza
					</button>
					<button v-else class="btn" v-on:click="addCalendarLucrare()">
						Salveaza
					</button>
					<a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
				</div>
			</div>
		</div>

		<!-- Modal calendar -->
		<div id="modal_calendar" class="modal modal-medium modal-calendar">
			<div class="modal-content">
				<CalendarProductie ref="caledarComponent" :noTitle="true"></CalendarProductie>
			</div>
		</div>

		<!-- Modal oameni -->
		<div id="modal_oameni" class="modal modal-small modal-oameni">
			<div class="modal-content">
				<h6 class="bold">Selecteaza echipa</h6>
				<br />
				<div v-for="( persoana, index ) in  oameni_productie " class="inline-div color-div"
					:class="{ selected: persoana.selected }" @click.prevent="setPersoanaLucrare(index)">
					<span class="dot" :class="persoana.color"></span>{{ persoana.name }}
				</div>
				<div>{{ echipaLength }} persoane selectate</div>
				<div class="flex flex-row gap-2 mt-6">
					<button class="btn" v-on:click="addOameniLucrare()">Salveaza</button>
					<a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
				</div>
			</div>
		</div>

		<!-- Modal delete -->
		<div id="modal_delete" class="modal modal-delete modal-del-lucrare">
			<div class="modal-content">
				<h6>Sterge definitiv lucrarea nr. {{ lucrare.id_lucrare }}?</h6>
				<br />
				<button class="btn red" v-on:click="deleteLucrare(lucrare.id_lucrare)">
					Sterge
				</button>
				<a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
			</div>
		</div>
	</div>
</template>

<script>
// import moment from "moment";
import moment from "moment-business-days";
import CalendarProductie from "@/components/LucrariProductie/CalendarProductie";

export default {
	name: "DetaliiLucrare",
	data() {
		return {
			visibleEditName: false,
			visibleEditBuc: false,
			new_termen: "",
			moneda: "eur",
			cost: "",
			total: "",
			tax: "",
			newEchipa: [],
			visibleExTimeForm: false
		};
	},
	computed: {
		lucrare() {
			return this.$store.getters.currentLucrare;
		},
		loading() {
			return this.$store.getters.loading;
		},
		curs() {
			return this.$store.getters.curs;
		},
		oameni_productie() {
			let echipa = this.$store.getters.oameni_productie;
			if (echipa.length > 0) {
				if (this.lucrare.echipa) {
					return this.currentLucrareEchipa(echipa);
				} else {
					this.lucrare.echipa = [];
					return echipa;
				}
			}
		},
		echipaLength() {
			if (this.newEchipa) {
				return this.newEchipa.length;
			} else {
				return "0";
			}
		},
		dayDifference() {
			let diff = moment(this.lucrare.start, "YYYY-MM-DD").businessDiff(
				moment(this.lucrare.end, "YYYY-MM-DD")
			);
			return diff;
		},
		onTime() {
			if (this.dayDifference <= this.lucrare.t_executie) {
				return true;
			} else {
				return false;
			}
		},
		grand_total() {
			let cost = Number(this.lucrare.cost_fara_tvaG);
			let total = Number(this.lucrare.val_fara_tvaG);
			this.cost = cost.toFixed(2);
			this.total = total.toFixed(2);
			return total
		},
		cost_eur() {
			let cost = this.cost / this.curs;
			return cost.toFixed(2);
		},
		tax_eur() {
			let tax = this.tax / this.curs;
			return tax.toFixed(2);
		},
		total_eur() {
			let total = this.total / this.curs;
			return total.toFixed(2);
		},
		grand_total_eur() {
			let gtotal = this.grand_total / this.curs;
			return gtotal.toFixed(2);
		},
	},
	methods: {
		// helpers
		denumireModulGard(str, tip_poarta) {
			if (str == "Panou") {
				str += " gard";
			}
			if (str == "Poarta_mica") {
				str += "_pietonala";
			}
			if (str == "Poarta_mare") {
				str += `_${tip_poarta}`;
			}
			let newStr = str.replace(/_/g, " ");
			return newStr;
		},
		// 
		openEditNameForm() {
			this.visibleEditName = true;
		},
		saveName() {
			let newName = {
				denumire: this.lucrare.denumire_lucrare,
			};
			let id = this.$route.params.id;
			this.$store.dispatch("changeNameOfertaProductie", {
				object: newName,
				id: id,
			});
			this.visibleEditName = false;
		},
		openEditBucForm() {
			this.visibleEditBuc = true;
		},
		saveBuc() {
			let id = this.$route.params.id;
			this.$store.dispatch("changeBucOfertaProductie", {
				buc: this.lucrare.buc_lucrare,
				id: id,
			});
			this.visibleEditBuc = false;
		},
		showExTimeForm() {
			this.newExTime = this.lucrare.t_executie
			this.visibleExTimeForm = true
		},
		saveExTime() {
			let id = this.$route.params.id;
			this.$store.dispatch("changeExTimeLucrareProductie", {
				t_executie: this.newExTime,
				id: id,
			});
			this.visibleExTimeForm = false
		},
		openModal(modalName) {
			this.newEchipa = this.lucrare.echipa.slice();
			const modalClass = '.' + modalName;
			M.Modal.getInstance(document.querySelector(modalClass)).open();
		},
		endLucrareDate(date) {
			let newDate = moment(date, "YYYY-MM-DD")
				.businessSubtract(1)
				.format("DD/MM/YYYY");
			return newDate;
		},
		getEndBusinessDate(start, days) {
			moment.updateLocale("ro", {
				workingWeekdays: [1, 2, 3, 4, 5],
			});
			return moment(start, "YYYY-MM-DD")
				.businessAdd(Number(days))
				.format("YYYY-MM-DD");
		},
		currentLucrareEchipa(echipa) {
			this.lucrare.echipa.forEach((persoana) => {
				let omIndex = echipa.findIndex((item) => {
					return item.id_persoana == persoana.id_persoana;
				});
				echipa[omIndex].selected = true;
			});
			return echipa;
		},
		setPersoanaLucrare(idx) {
			if (this.oameni_productie[idx].selected) {
				let echipaIndex = this.newEchipa.findIndex((item) => {
					return item.id_persoana == this.oameni_productie[idx].id_persoana;
				});
				this.newEchipa.splice(echipaIndex, 1);
				this.oameni_productie[idx].selected = false;
			} else {
				this.newEchipa.push(this.oameni_productie[idx]);
				this.oameni_productie[idx].selected = true;
			}
		},
		addOameniLucrare() {
			this.$store.dispatch("updateLucrareEchipa", {
				id: this.lucrare.id_lucrare,
				echipa: this.newEchipa,
			});
			this.newEchipa = []
		},
		addCalendarLucrare() {
			if (!this.lucrare.start || !this.lucrare.t_executie) {
				M.toast({
					html: "Completati data de inceput!",
					classes: "orange",
				});
			} else {
				let title = this.lucrare.denumire_lucrare + " - " + this.lucrare.nume;
				let start_date = this.lucrare.start;
				let end_date = this.getEndBusinessDate(
					this.lucrare.start,
					this.lucrare.t_executie
				);
				let url = "/lucrari-productie/single/" + this.lucrare.id_lucrare;

				let newEvent = {
					title: title,
					start: start_date,
					end: end_date,
					url: url,
					color: "#e8eaf6",
					id_lucrare: this.lucrare.id_lucrare,
				};

				this.$store.dispatch("addCalendarLucrare", newEvent);
				M.Modal.getInstance(document.querySelector(".modal-program")).close();
			}
		},
		editCalendarLucrare() {
			if (!this.lucrare.start || !this.new_termen) {
				M.toast({
					html: "Completati data de inceput!",
					classes: "orange",
				});
			} else {
				let start_date = this.lucrare.start;
				let end_date = this.getEndBusinessDate(
					this.lucrare.start,
					this.new_termen
				);
				let color = "#e8eaf6";

				if (this.new_termen > this.lucrare.t_executie) {
					color = "#ffcdd2";
				} else if (this.new_termen < this.lucrare.t_executie) {
					color = "#c8e6c9";
				}

				let updateEvent = {
					id: this.lucrare.id_lucrare,
					start: start_date,
					end: end_date,
					color: color,
				};

				this.$store.dispatch("editCalendarLucrare", updateEvent);
				M.Modal.getInstance(document.querySelector(".modal-program")).close();
			}
		},
		refreshCalendar() {
			let calendarApi = this.$refs.caledarComponent.$refs.fullCalendar.getApi();
			setTimeout(function () {
				calendarApi.render();
			}, 10);
		},
		deleteLucrare(id) {
			this.$store.dispatch("deleteLucrare", id);
		},
	},
	components: {
		CalendarProductie,
	},
	created: function () {
		this.$store.dispatch("fetchLucrare", this.$route.params.id);
		this.$store.dispatch("fetchCurrency");
		this.$store.dispatch("fetchOameniProductie");
	},
	mounted: function () {
		M.Dropdown.init(document.querySelectorAll(".dropdown-trigger"), {
			constrainWidth: false,
		});
		M.Modal.init(document.querySelectorAll(".modal"));
	},
	updated: function () {
		M.Dropdown.init(document.querySelectorAll(".dropdown-trigger"), {
			constrainWidth: false,
		});
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mtop-14 {
	margin-top: 14px;
}

span.dot {
	border-radius: 100px;
	width: 10px;
	height: 10px;
	display: inline-block;
	margin: 0 5px 0 15px;
}

.color-div {
	padding: 4px 10px 4px 0;
	margin: 0 6px 6px 0;
	border-radius: 4px;
	background-color: #f1f1f1;
	cursor: pointer;
	user-select: none;
}

.color-div.selected {
	background-color: #d9d9d9;
}

.second-btn {
	margin-left: 50px;
}

#dropdown {
	transform: translate(0, -30px);
}
</style>
