<template>
	<!-- Product row template -->
	<tbody>
		<tr>
			<td>
				<input
					type="number"
					class="validate center-align"
					v-model="produs.nr"
				/>
			</td>
			<td>
				<model-select
					id="material"
					:options="materiale"
					v-model="produs.material"
					placeholder="Material"
				></model-select>
			</td>
			<td>
				<model-select
					id="denumire"
					:options="denumiri"
					v-model="produs.denumire.value"
					placeholder="Alege produs"
				></model-select>
			</td>
			<td>
				<model-select
					id="aliaj"
					:options="aliaje"
					v-model="produs.aliaj"
					placeholder="Aliaj"
				></model-select>
			</td>

			<td class="w-24">
				<input
					v-if="
						produs.denumire.value !== 'Bara rotunda' &&
							produs.denumire.value !== 'Bara hexagonala' &&
							!arr_teviR.includes(produs.denumire.value)
					"
					type="number"
					step="0.01"
					placeholder="Latime"
					class="validate"
					v-model.number="produs.dimL"
				/>
				<input
					v-else
					type="number"
					step="0.01"
					placeholder="Diametru"
					class="validate"
					v-model="produs.dimL"
				/>
			</td>
			<td class="w-24">
				<input
					v-if="arr_table.includes(produs.denumire.value)"
					type="number"
					step="0.01"
					placeholder="Lungime"
					class="validate"
					v-model.number="produs.dimH"
				/>
				<input
					v-else-if="
						arr_bareP.includes(produs.denumire.value) ||
							arr_teviP.includes(produs.denumire.value) ||
							arr_profileLT.includes(produs.denumire.value) ||
							produs.denumire.value === 'Profil U' ||
							produs.denumire.value === 'Profil I' ||
							produs.denumire.value === 'Profil U vopsit' ||
							produs.denumire.value === 'Profil I vopsit'
					"
					type="number"
					step="0.01"
					placeholder="Inaltime"
					class="validate"
					v-model.number="produs.dimH"
				/>
			</td>
			<td class="w-24">
				<input
					v-if="
						produs.denumire.value !== 'Bara rotunda' &&
							produs.denumire.value !== 'Bara hexagonala' &&
							!arr_bareP.includes(produs.denumire.value)
					"
					type="number"
					step="0.01"
					placeholder="Grosime"
					class="validate"
					v-model.number="produs.dimG"
				/>
			</td>
			<td>
				<model-select
					id="um"
					:options="units"
					v-model="produs.um"
					placeholder="UM"
					@input="resetValues()"
				></model-select>
			</td>
			<td>
				<input
					type="number"
					step="0.1"
					value="1"
					placeholder="1"
					class="validate center-align"
					v-model="produs.cantitate"
				/>
			</td>
			<td>
				<input
					type="text"
					class="center-align"
					placeholder="0.00"
					:value="weight"
					readonly
				/>
			</td>
			<td>
				<input
					type="number"
					step="0.01"
					value="0.00"
					placeholder="0.00"
					class="validate center-align"
					v-model="produs.pret_kg"
				/>
			</td>
			<td>
				<input
					type="text"
					placeholder="0.00"
					class="center-align"
					:value="unitPrice"
					readonly
				/>
			</td>
			<td>
				<input
					type="text"
					class="center-align"
					placeholder="0.00"
					:value="total"
					number
					readonly
				/>
			</td>
			<td>
				<span
					class="hover:text-red-500 cursor-pointer"
					@click="$emit('deleteProductForm', index)"
					><i class="material-icons delete">close</i></span
				>
			</td>
		</tr>
		<tr class="extra-info">
			<td colspan="4"></td>
			<td colspan="3">
				<div class="input-field mx-1 mt-0">
					<input
						:id="'detalii_' + produs.nr"
						type="text"
						class="validate center-align"
						v-model="produs.detalii"
					/>
					<label :for="'detalii_' + produs.nr">Detalii</label>
				</div>
			</td>
			<td colspan="2">
				<div class="input-field mx-1 mt-0">
					<input
						:id="'termen_' + produs.nr"
						type="text"
						class="validate center-align"
						v-model="produs.t_livrare"
					/>
					<label :for="'termen_' + produs.nr">Termen livrare</label>
				</div>
			</td>
			<td colspan="2">
				<label class="right mr-10">
					<input type="checkbox" v-model="produs.modified" />
					<span>Modificat</span>
				</label>
			</td>
		</tr>
		<td></td>
		<pre>
			<!-- {{ arr_profile_preturi }} -->
		</pre>
	</tbody>
</template>

<script>
import { ModelSelect } from "vue-search-select";

export default {
	name: "Product",
	components: {
		ModelSelect,
	},
	props: {
		firstAddMode: Boolean,
		produs: Object,
		preturiVanzari: Array,
		formulaVanzari: Object,
		index: Number,
		totalWeight: String,
	},
	computed: {
		materiale() {
			return this.$store.getters.selectMateriale;
		},
		denumiri() {
			return this.$store.getters.denumiri;
		},
		ums() {
			return this.$store.getters.ums;
		},
		aliaje() {
			let aliajeList = this.$store.getters.selectAliaje(this.produs.material);
			this.produs.aliaje = aliajeList;
			return aliajeList;
		},
		arr_bareP() {
			return this.$store.getters.arr_bareP;
		},
		arr_benzi() {
			return this.$store.getters.arr_benzi;
		},
		arr_profileLT() {
			return this.$store.getters.arr_profileLT;
		},
		arr_table() {
			return this.$store.getters.arr_table;
		},
		arr_teviP() {
			return this.$store.getters.arr_teviP;
		},
		arr_teviR() {
			return this.$store.getters.arr_teviR;
		},
		arr_profile_preturi() {
			return this.$store.getters.arr_profile_preturi;
		},
		arr_table_preturi() {
			return this.$store.getters.arr_table_preturi;
		},
		arr_profile_vopsite_preturi() {
			return this.$store.getters.arr_profile_vopsite_preturi;
		},
		units() {
			let list = [];
			if (this.arr_benzi.includes(this.produs.denumire.value)) {
				let arr = ["m2", "ml", "kg"];
				list = this.ums.filter((um) => {
					return arr.includes(um.value);
				});
			} else if (this.arr_table.includes(this.produs.denumire.value)) {
				let arr = ["m2", "buc", "kg"];
				list = this.ums.filter((um) => {
					return arr.includes(um.value);
				});
			} else if (
				this.arr_bareP.includes(this.produs.denumire.value) ||
				this.arr_teviR.includes(this.produs.denumire.value) ||
				this.arr_teviP.includes(this.produs.denumire.value) ||
				this.arr_profileLT.includes(this.produs.denumire.value) ||
				this.produs.denumire.value == "Platbanda" ||
				this.produs.denumire.value == "Platbanda vopsita" ||
				this.produs.denumire.value == "Bara rotunda" ||
				this.produs.denumire.value == "Bara hexagonala" ||
				this.produs.denumire.value == "Profil U" ||
				this.produs.denumire.value == "Profil U vopsit" ||
				this.produs.denumire.value == "Profil I" ||
				this.produs.denumire.value == "Profil I vopsit"
			) {
				let arr = ["ml", "kg"];
				list = this.ums.filter((um) => {
					return arr.includes(um.value);
				});
			} else {
				list = this.ums;
			}
			this.produs.ums = list;
			// this.produs.um = "";
			return list;
		},
		currentGS() {
			let currentAliaj = this.produs.aliaje.find(
				(aliaj) => aliaj.value === this.produs.aliaj
			);
			if (currentAliaj) {
				this.produs.gs = currentAliaj.gs;
				return currentAliaj.gs;
			} else {
				return 0;
			}
		},
		res() {
			let gs = this.currentGS;
			let dimL = this.produs.dimL;
			let dimH = this.produs.dimH;
			let dimG = this.produs.dimG;

			let res = 0;

			if (this.arr_benzi.includes(this.produs.denumire.value)) {
				if (this.produs.um == "m2") {
					res = (1000 * dimG * gs) / 1000;
				} else {
					res = (dimL * dimG * gs) / 1000;
				}
			} else if (this.arr_bareP.includes(this.produs.denumire.value)) {
				res = (dimL * dimH * gs) / 1000;
			} else if (
				this.produs.denumire.value === "Platbanda" ||
				this.produs.denumire.value === "Platbanda vopsita"
			) {
				res = (dimL * dimG * gs) / 1000;
			} else if (this.produs.denumire.value === "Bara rotunda") {
				res = (0.785 * dimL * dimL * gs) / 1000;
			} else if (this.produs.denumire.value === "Bara hexagonala") {
				res = (0.866 * dimL * dimL * gs) / 1000;
			} else if (this.arr_table.includes(this.produs.denumire.value)) {
				if (this.produs.um == "m2") {
					res = 1000 * gs * (dimG / 1000);
				} else {
					res = ((dimL * dimH) / 1000) * gs * (dimG / 1000);
				}
			} else if (this.arr_teviR.includes(this.produs.denumire.value)) {
				res = 0.785 * (4 * dimL * dimG - 4 * dimG * dimG) * (gs / 1000);
			} else if (this.arr_teviP.includes(this.produs.denumire.value)) {
				res = ((2 * dimL + 2 * dimH - 4 * dimG) / 1000) * dimG * gs;
			} else if (this.arr_profileLT.includes(this.produs.denumire.value)) {
				res = ((dimL + dimH - dimG) / 1000) * dimG * gs;
			} else if (
				this.produs.denumire.value === "Profil U" ||
				this.produs.denumire.value === "Profil U vopsit"
			) {
				res = ((dimL + 2 * dimH - 2 * dimG) / 1000) * dimG * gs;
			} else if (
				this.produs.denumire.value === "Profil I" ||
				this.produs.denumire.value === "Profil I vopsit"
			) {
				res = ((2 * dimL + dimH - 2 * dimG) / 1000) * dimG * gs;
			}

			return res;
		},
		weight() {
			let weight = 0;

			if (this.produs.um == "kg") {
				weight = Number(this.produs.cantitate).toFixed(2);
			} else {
				weight = (Number(this.produs.cantitate) * this.res).toFixed(2);
			}
			this.produs.greutate = weight;
			this.$emit("weightChanged", weight);
			return weight;
		},
		unitPrice() {
			let pret_u =
				(this.weight * Number(this.produs.pret_kg)) /
				Number(this.produs.cantitate);
			this.produs.pret_u = pret_u.toFixed(2);
			return pret_u.toFixed(2);
		},
		total() {
			let t = Number(this.produs.cantitate) * Number(this.unitPrice);
			let tax = t * 0.19;
			this.produs.tva = tax.toFixed(2);
			this.produs.pret_t = t.toFixed(2);
			return t.toFixed(2);
		},
	},
	data() {
		return {};
	},
	watch: {
		totalWeight() {
			if (this.firstAddMode) {
				this.setPrice();
			}
		},
	},
	methods: {
		setPrice() {
			let aliaj = this.produs.aliaj.substring(0, 4);
			let preturi = this.preturiVanzari.find((pretProdus) => {
				if (this.produs.denumire.value == "Teava rotunda - diametru mare") {
					return pretProdus.denumire == "Tevi rotunde - diametru mare";
				} else if (
					this.arr_profile_vopsite_preturi.includes(this.produs.denumire.value)
				) {
					return pretProdus.denumire == "Profile vopsite";
				} else if (
					this.arr_profile_preturi.includes(this.produs.denumire.value)
				) {
					return pretProdus.denumire == "Profile";
				} else if (
					this.arr_table_preturi.includes(this.produs.denumire.value)
				) {
					return pretProdus.denumire == "Tabla";
				} else {
					return pretProdus.denumire == this.produs.denumire.value;
				}
			});
			let adaosAliaj = preturi.adaos_aliaje.find((item) => item.aliaj == aliaj);
			if (typeof adaosAliaj === "undefined") {
				M.toast({
					html: "Completeaza aliajul",
					classes: "bg-yellow-600",
				});
				return;
			}

			let recommendedPrice =
				preturi.pret_baza *
					(1 + adaosAliaj.adaos / 100) *
					this.formulaVanzari.coeficient +
				100 / this.totalWeight;
			this.produs.pret_kg = Math.round(recommendedPrice * 100) / 100;
		},
		resetValues() {
			this.produs.cantitate = "";
			this.produs.greutate = "";
		},

		totalP() {
			// this needs to be moved to fire on load
			this.produs.dim_str = this.dimString(
				this.produs.denumire.value,
				dimL,
				dimH,
				dimG
			);
			this.isModified(this.produs);
		},
		dimString(denumire, dimL, dimH, dimG) {
			let str = "";

			if (this.arr_table.includes(denumire)) {
				str = dimG + " x " + dimL + " x " + dimH + " mm";
			} else if (this.arr_benzi.includes(denumire)) {
				str = dimG + " x " + dimL + " mm";
			} else if (this.arr_bareP.includes(denumire)) {
				str = dimH + " x " + dimL + " mm";
			} else if (
				this.arr_teviR.includes(denumire) ||
				denumire === "Platbanda" ||
				denumire === "Platbanda vopsita"
			) {
				str = dimL + " x " + dimG + " mm";
			} else if (
				this.arr_teviP.includes(denumire) ||
				this.arr_profileLT.includes(denumire)
			) {
				str = dimL + " x " + dimH + " x " + dimG + " mm";
			} else if (denumire === "Profil U" || denumire === "Profil U vopsit") {
				str = dimH + " x " + dimL + " x " + dimH + " x " + dimG + " mm";
			} else if (denumire === "Profil I" || denumire === "Profil I vopsit") {
				str = dimL + " x " + dimH + " x " + dimL + " x " + dimG + " mm";
			} else if (
				denumire === "Bara rotunda" ||
				denumire === "Bara hexagonala"
			) {
				str = "D " + dimL + " mm";
			}

			return str;
		},
		isModified(produs) {
			let mod = false;
			if (produs.dim_text && produs.dim_text != produs.dim_str) {
				mod = true;
			}
			produs.modified = mod;
		},
	},
};
</script>

<style scoped>
table input:read-only {
	background-color: #f2f4f8;
}
</style>
