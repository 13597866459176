<template>
  <div class="container">
    <!-- Modal delete Aviz -->
    <div id="modal_delete" class="modal modal-delete modal-delete-aviz">
      <div class="modal-content">
        <h6>Sterge definitiv avizul nr. {{ aviz.nr_aviz }}?</h6>
        <br />
        <button class="btn red" v-on:click="deleteAviz(aviz.id_aviz)">
          Sterge
        </button>
        <a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
      </div>
    </div>
    <!--  end modal -->
    <router-link
      class="btn blue-grey lighten-2 back-btn left"
      to="/debitare/avize"
      >Inapoi</router-link
    >
    <div class="btns right">
      <button
        class="btn grey darken-1"
        :class="btn_state"
        @click="createPDF(1)"
      >
        <i class="material-icons left">file_download</i>PDF
      </button>
      <span class="space"></span>
      <button data-target="modal_delete" class="btn red modal-trigger">
        Sterge
      </button>
    </div>
    <h4 class="header">Detalii aviz</h4>
    <br />
    <div class="card-panel">
      <div class="row">
        <div class="col s2">
          <div v-if="detalii_open" class="input-field">
            <input
              id="nr"
              type="number"
              class="validate"
              :placeholder="nr_placeholder"
              v-model="nr_aviz"
            />
            <label for="nr">Nr. aviz</label>
          </div>
          <div v-else>
            <span>
              Nr. aviz:
              <span class="bold">{{ aviz.nr_aviz | NoData }}</span>
            </span>
          </div>
        </div>
        <div class="col s2">
          <div v-if="detalii_open" class="input-field">
            <input
              id="delegat"
              type="text"
              class="validate"
              v-model="delegat"
            />
            <label for="delegat">Delegat</label>
          </div>
          <div v-else>
            <span>
              Delegat:
              <span class="bold">{{ aviz.delegat | NoData }}</span>
            </span>
          </div>
        </div>
        <div class="col s3">
          <div v-if="detalii_open" class="input-field">
            <input
              id="nr_auto"
              type="text"
              class="validate"
              v-model="nr_auto"
            />
            <label for="nr_auto">Nr. inmatriculare</label>
          </div>
          <div v-else>
            <span>
              Nr. inmatriculare:
              <span class="bold">{{ aviz.nr_auto | NoData }}</span>
            </span>
          </div>
        </div>
        <div class="col s2">
          <div v-if="detalii_open" class="input-field">
            <input id="data" type="date" class="validate" v-model="aviz.data" />
            <label for="data">Data aviz</label>
          </div>
          <div v-else>
            <span>
              Data:
              <span class="bold">{{ aviz.data | formatDate }}</span>
            </span>
          </div>
        </div>
        <div class="col s1">
          <div v-if="detalii_open" class="input-field">
            <input id="ora" type="text" class="validate" v-model="ora" />
            <label for="ora">Ora</label>
          </div>
          <div v-else>
            <span>
              Ora:
              <span class="bold">{{ aviz.ora | NoData }}</span>
            </span>
          </div>
        </div>
        <div class="col s2">
          <div v-if="detalii_open" class>
            <button class="btn" @click="addDetalii">Salveaza</button>
          </div>
          <div v-else>
            <button class="btn" @click="openDetaliiForm">Modifica</button>
          </div>
        </div>
      </div>
      <div class="col s3 offset-s2 info">
        <i class="material-icons">info</i>
        <span
          >Completeaza toate datele de mai sus pentru a putea genera
          avizul.</span
        >
      </div>
    </div>
    <div class="card-panel">
      <div v-if="aviz.obj_mat != undefined && aviz.obj_mat.length > 0">
        <h6>MATERIALE DEBITATE</h6>
        <table class="highlight responsive-table">
          <thead>
            <tr>
              <th class="width3">Nr.</th>
              <th class="width40">Denumire produs</th>
              <th>Cantitate</th>
              <th>Pachete</th>
              <th>Buc scrap</th>
              <th>Stadiu comanda</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(material, index) in aviz.obj_mat">
              <td>{{ index + 1 }}</td>
              <td>{{ material.denumire_produs }}</td>
              <td>{{ material.cantitate }} buc</td>
              <td>{{ material.pachete }}</td>
              <td>{{ material.scrap | NoData }}</td>
              <td>{{ material.status }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <div v-if="aviz.obj_profile != undefined && aviz.obj_profile.length > 0">
        <h6>PROFILE</h6>
        <table class="highlight responsive-table">
          <thead>
            <tr>
              <th class="width3">Nr.</th>
              <th class="width40">Denumire produs</th>
              <th>Cantitate</th>
              <th>Greutate</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(profil, index) in aviz.obj_profile">
              <td>{{ index + 1 }}</td>
              <td>{{ profil.denumire_produs }}</td>
              <td>{{ profil.cantitate }} buc</td>
              <td>{{ profil.greutate }} kg</td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <div
        v-if="
          (aviz.obj_pachete_scrap != undefined &&
            aviz.obj_pachete_scrap.cantitate != undefined) ||
            (aviz.obj_saci_scrap != undefined &&
              aviz.obj_saci_scrap.cantitate != undefined) ||
              (aviz.obj_saci_span != undefined &&
                aviz.obj_saci_span.cantitate != undefined)
        "
      >
        <h6>SCRAP</h6>
        <table class="highlight responsive-table">
          <thead>
            <tr>
              <th class="width40">Denumire produs</th>
              <th>Cantitate</th>
              <th>Greutate</th>
            </tr>
          </thead>
          <tbody v-if="aviz.obj_pachete_scrap.cantitate != undefined">
            <tr>
              <td>{{ aviz.obj_pachete_scrap.denumire_produs }}</td>
              <td>{{ aviz.obj_pachete_scrap.cantitate }} buc</td>
              <td>{{ aviz.obj_pachete_scrap.greutate }} kg</td>
            </tr>
          </tbody>
          <tbody v-if="aviz.obj_saci_scrap.cantitate != undefined">
            <tr>
              <td>{{ aviz.obj_saci_scrap.denumire_produs }}</td>
              <td>{{ aviz.obj_saci_scrap.cantitate }} buc</td>
              <td>{{ aviz.obj_saci_scrap.greutate }} kg</td>
            </tr>
          </tbody>
          <tbody v-if="aviz.obj_saci_span.cantitate != undefined">
            <tr>
              <td>{{ aviz.obj_saci_span.denumire_produs }}</td>
              <td>{{ aviz.obj_saci_span.cantitate }} buc</td>
              <td>{{ aviz.obj_saci_span.greutate }} kg</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col s4">
        <!-- <pre>{{aviz}}</pre> -->
        <!-- <pre>{{nr_placeholder}}</pre> -->
      </div>
      <div class="col s4"></div>
      <div class="col s4">
        <!-- <pre>{{btn_state}}</pre> -->
        <!-- <pre>{{$data}}</pre> -->
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import jsPDF from "jspdf";

export default {
  name: "Avize",
  data() {
    return {
      today: moment(),
      nr_aviz: "",
      nr_auto: "AR 01 AEB, AR 09 AEB",
      delegat: "Mager Aurel",
      ora: "-",
      detalii_open: false,
      table_no: 1,
      pdf_btn_state: "disable",
    };
  },
  computed: {
    aviz() {
      return this.$store.getters.aviz;
    },
    nr_placeholder() {
      return this.aviz.id_aviz;
    },
    btn_state() {
      if (
        this.aviz.nr_aviz == "" ||
        this.aviz.nr_aviz == null ||
        this.aviz.ora == ""
      ) {
        return "disabled";
      } else {
        return "enable";
      }
    },
  },
  watch: {
    // 'aviz.nr_aviz': function (val) {
    //   console.log('Watch:'+ val);
    //   if (val == null || val == '') {
    //     this.pdf_btn_state = 'disabled'
    //   } else {
    //     this.pdf_btn_state = 'enable'
    //   }
    // }
  },
  methods: {
    addDetalii() {
      if (
        this.nr_aviz == "" ||
        this.nr_aviz == null ||
        this.ora == "-" ||
        this.ora == ""
      ) {
        M.toast({ html: "Completati toate campurile!", classes: "blue-grey" });
      } else {
        let nr = this.nr_auto.toUpperCase();
        this.$store.dispatch("updAvizAuto", {
          id: this.aviz.id_aviz,
          nr_aviz: this.nr_aviz,
          nr_auto: nr,
          delegat: this.delegat,
          data: this.aviz.data,
          ora: this.ora,
        });
        // this.aviz.nr_auto = this.nr_auto
        this.detalii_open = false;
      }
    },
    openDetaliiForm() {
      this.nr_auto = this.aviz.nr_auto;
      this.delegat = this.aviz.delegat;
      this.ora = this.aviz.ora;
      this.detalii_open = true;
    },
    createPDF(de) {
      let arr = this.aviz;
      let nr_aviz = arr.nr_aviz;
      let pdfName =
        "Aviz_nr" +
        nr_aviz +
        "_" +
        moment(arr.data, "YYYY-MM-DD").format("DD_MM_YYYY");
      var doc = new jsPDF();
      var pageNr = 1;

      doc.setFont("arial");
      doc.setFontStyle("normal");
      doc.setFontSize(9);
      doc.setTextColor(100);

      //Document header
      doc.setFontStyle("bold");
      doc.text("Furnizor: ", 10, 15);
      doc.text("CUI: ", 10, 20);
      doc.text("Nr. ONRC: ", 10, 25);
      doc.text("Sediu/Pct. lucru: ", 10, 30);
      doc.setFontStyle("normal");
      doc.text("Cuprolli Manufacturing srl", 25, 15);
      doc.text("RO35671874", 18, 20);
      doc.text("J35/468/2016", 27, 25);
      doc.text("Dumbravita / Ghiroda", 36, 30);
      doc.text("Str. Caprioarei, nr.11, jud. Timis", 10, 35);

      doc.setFontStyle("bold");
      doc.text("Cumparator: ", 130, 15);
      doc.text("CUI: ", 130, 20);
      doc.text("Nr. ONRC: ", 130, 25);
      doc.text("Sediu/Pct. lucru: ", 130, 30);
      doc.setFontStyle("normal");
      doc.text("Hydro Extrusion srl", 150, 15);
      doc.text("RO16344329", 138, 20);
      doc.text("J02/682/2004", 147, 25);
      doc.text("Chisineu-Cris, jud. Arad", 156, 30);
      doc.text("Str. Tudor Vladimirescu, FN, DN79/A", 130, 35);

      //center v line
      //doc.line(105, 40, 105, 60)

      doc.setFontSize(13);
      doc.text("AVIZ DE INSOTIRE A MARFII", 75, 60);
      doc.setFontSize(9);
      doc.setTextColor(100);
      doc.text("Seria CM", 90, 65);
      doc.text("Nr. " + nr_aviz, 110, 65);
      doc.text(
        "Data: " + moment(arr.data, "YYYY-MM-DD").format("DD.MM.YYYY"),
        93,
        70
      );

      let space = 86;

      // Products table
      if (arr.obj_mat.length > 0) {
        doc.setFontStyle("bold");
        doc.text("MATERIALE DEBITATE ", 10, space);
        space = space + 10;
        //Table head
        doc.text("Nr.", 10, space);
        doc.text("Denumire produs", 18, space);
        doc.text("U.M.", 100, space);
        doc.text("Cantitate", 113, space);
        doc.text("Pachete + scrap", 135, space);
        doc.text("Stadiu comanda", 175, space);
        doc.setDrawColor(150);
        doc.setLineWidth(0.4);
        doc.line(10, space + 2, 200, space + 2);
        //Table lines (content)
        space = space + 7;
        doc.setFontStyle("normal");
        let nr = 1;
        arr.obj_mat.forEach((item) => {
          doc.text(String(nr), 11, space);
          doc.text(item.denumire_produs, 19, space);
          doc.text("buc", 101, space);
          doc.text(String(item.cantitate), 117, space);
          doc.text(item.pachete, 135, space);
          doc.text(item.status, 182, space);
          if (item.scrap) {
            doc.text("Scrap: " + item.scrap + " buc", 135, space + 5);
            space = space + 5;
          }
          doc.setLineWidth(0.2);
          doc.line(10, space + 3, 200, space + 3);
          nr++;
          space = space + 8;

          if (space > 270) {
            doc.setFontStyle("normal");
            doc.text("continuare pe pagina urmatoare", 10, 280);
            doc.text("- " + String(pageNr) + " -", 100, 290);
            doc.addPage();
            pageNr = pageNr + 1;
            space = 15;
          }
        });
      }

      space = space + 10;

      // Profile table
      if (arr.obj_profile.length > 0) {
        doc.setFontStyle("bold");
        doc.text("PROFILE ", 10, space);
        space = space + 10;
        //Table head
        doc.text("Nr.", 10, space);
        doc.text("Denumire produs", 18, space);
        doc.text("U.M.", 100, space);
        doc.text("Cantitate", 113, space);
        doc.text("Greutate", 145, space);
        doc.setDrawColor(150);
        doc.setLineWidth(0.4);
        doc.line(10, space + 2, 200, space + 2);
        //Table lines (content)
        space = space + 7;
        doc.setFontStyle("normal");
        let nr = 1;
        arr.obj_profile.forEach((item) => {
          doc.text(String(nr), 11, space);
          doc.text(item.denumire_produs, 19, space);
          doc.text("buc", 101, space);
          doc.text(String(item.cantitate), 117, space);
          doc.text(item.greutate + " kg", 146, space);
          doc.setLineWidth(0.2);
          doc.line(10, space + 3, 200, space + 3);
          nr++;
          space = space + 8;

          if (space > 270) {
            doc.setFontStyle("normal");
            doc.text("continuare pe pagina urmatoare", 10, 280);
            doc.text("- " + String(pageNr) + " -", 100, 290);
            doc.addPage();
            pageNr = pageNr + 1;
            space = 15;
          }
        });
      }

      space = space + 10;

      // Scrap table
      if (arr.obj_pachete_scrap.cantitate || arr.obj_saci_scrap.cantitate) {
        doc.setFontStyle("bold");
        doc.text("SCRAP ", 10, space);
        space = space + 10;
        //Table head
        doc.text("Nr.", 10, space);
        doc.text("Denumire produs", 18, space);
        doc.text("U.M.", 100, space);
        doc.text("Cantitate", 113, space);
        doc.text("Greutate", 145, space);
        doc.setDrawColor(150);
        doc.setLineWidth(0.4);
        doc.line(10, space + 2, 200, space + 2);
        //Table lines (content)
        space = space + 7;
        doc.setFontStyle("normal");
        let nr = 1;
        if (arr.obj_pachete_scrap.cantitate) {
          doc.text(String(nr), 11, space);
          doc.text(arr.obj_pachete_scrap.denumire_produs, 19, space);
          doc.text("buc", 101, space);
          doc.text(String(arr.obj_pachete_scrap.cantitate), 117, space);
          doc.text(arr.obj_pachete_scrap.greutate + " kg", 146, space);
          doc.setLineWidth(0.2);
          doc.line(10, space + 3, 200, space + 3);
          nr++;
          space = space + 8;
        }
        if (arr.obj_saci_scrap.cantitate) {
          doc.text(String(nr), 11, space);
          doc.text(arr.obj_saci_scrap.denumire_produs, 19, space);
          doc.text("buc", 101, space);
          doc.text(String(arr.obj_saci_scrap.cantitate), 117, space);
          doc.text(arr.obj_saci_scrap.greutate + " kg", 146, space);
          doc.setLineWidth(0.2);
          doc.line(10, space + 3, 200, space + 3);
          nr++;
          space = space + 8;
        }
        if (arr.obj_saci_span.cantitate) {
          doc.text(String(nr), 11, space);
          doc.text(arr.obj_saci_span.denumire_produs, 19, space);
          doc.text("buc", 101, space);
          doc.text(String(arr.obj_saci_span.cantitate), 117, space);
          doc.text(arr.obj_saci_span.greutate + " kg", 146, space);
          doc.setLineWidth(0.2);
          doc.line(10, space + 3, 200, space + 3);
          nr++;
          space = space + 8;
        }

        if (space > 270) {
          doc.setFontStyle("normal");
          doc.text("continuare pe pagina urmatoare", 10, 280);
          doc.text("- " + String(pageNr) + " -", 100, 290);
          doc.addPage();
          pageNr = pageNr + 1;
          space = 15;
        }
      }

      // doc.text(String(space), 10, space)
      space = space + 10;

      doc.text("Delegat: " + arr.delegat, 10, space);
      doc.text("Mijloc de transport: auto nr: " + arr.nr_auto, 10, space + 5);
      doc.text(
        "Expedierea s-a efectuat in prezenta noastra la data de : " +
          moment(arr.data, "YYYY-MM-DD").format("DD.MM.YYYY") +
          ", ora: " +
          arr.ora,
        10,
        space + 10
      );

      // Print PDF
      if (de == 1) {
        doc.save(pdfName + ".pdf");
      }
      //else if (de == 2){
      //   var pdf = btoa(doc.output());
      //   // Send doc to php script for emailing forward
      //   this.$http.post('http://hub.cuprolli.ro/app_back/mail.php', {data: pdf, client_mail: this.oferta.email})
      //     .then(function(response){
      //       M.toast({html: 'Oferta trimisa!'})
      //     })
      // }
    },
    deleteAviz(id) {
      let arr_mat = [];
      let arr_profile = [];
      let arr_pachete_scrap = [];
      let arr_saci_scrap = [];

      this.aviz.obj_mat.forEach((item) => {
        arr_mat.push(item.comanda_id);
      });
      this.aviz.obj_profile.forEach((item) => {
        arr_profile.push(item.stoc_id);
      });
      if (this.aviz.obj_pachete_scrap.comenzi != undefined) {
        this.aviz.obj_pachete_scrap.comenzi.forEach((item) => {
          arr_pachete_scrap.push(item);
        });
      }
      if (this.aviz.obj_saci_scrap.comenzi != undefined) {
        this.aviz.obj_saci_scrap.comenzi.forEach((item) => {
          arr_saci_scrap.push(item);
        });
      }

      this.$store.dispatch("deleteAvizMat", {
        id_aviz: id,
        arr_mat: arr_mat,
        arr_profile: arr_profile,
        arr_pachete_scrap: arr_pachete_scrap,
        arr_saci_scrap: arr_saci_scrap,
      });
    },
    fetchAviz(id) {
      this.$store.dispatch("fetchAviz", id);
    },
  },
  created: function() {
    this.fetchAviz(this.$route.params.id);
  },
  mounted: function() {
    M.Modal.init(document.querySelector(".modal-delete"));
  },
  updated: function() {
    M.updateTextFields();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.space {
  margin-right: 40px;
}
.info i {
  vertical-align: middle;
  font-size: 20px;
  margin: -3px 5px 0 0;
}
</style>
