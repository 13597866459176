<template>
	<div>
		<h4 class="text-lg font-bold">Alege furnizor unic</h4>
		<model-select :options="furnizori" v-model="oneFurnizor" placeholder="Alege furnizor"
			@input="passFurnizorUnic"></model-select>
		<div class="text-base font-bold text-center my-4">SAU</div>
		<h4 class="text-lg font-bold">Adauga furnizorii pentru fiecare pozitie</h4>
		<table class="responsive-table materiale-table mb-6">
			<thead>
				<tr class="head">
					<th class="center-align">Nr.</th>
					<th>Denumire produs</th>
					<th>Cantitate</th>
					<th class="width20">Furnizor</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(produs, index) in matDepozit" :key="produs.nr">
					<td class="width5 center-align">{{ index + 1 }}</td>
					<td>{{ produs.denumire }}</td>
					<td>{{ produs.cantitate }}</td>
					<td>
						<model-select id="furnizor" :options="furnizori" v-model="produs.furnizor"
							placeholder="Alege furnizor"></model-select>
						<!-- <span v-else>{{produs.furnizor}}</span> -->
					</td>
				</tr>
			</tbody>
		</table>
		<div class="flex justify-start gap-4">
			<button href="#!" class="btn modal-close" @click="$emit('comandaNoua')">
				Comanda noua
			</button>
			<a class="modal-action modal-close btn grey" @click="$emit('closeComandaModal')">Anuleaza</a>
		</div>
	</div>
</template>

<script>
import { ModelSelect } from "vue-search-select";

export default {
	name: "ComandaNoua",
	data() {
		return {
			oneFurnizor: "",
		};
	},
	components: {
		ModelSelect,
	},
	props: ["matDepozit"],
	computed: {
		furnizori() {
			return this.$store.getters.furnizoriVanzari;
		},
	},
	watch: {},
	methods: {
		passFurnizorUnic() {
			this.$emit("furnizorUnic", this.oneFurnizor);
		},
	},
	created: function () { },
	mounted: function () { },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
