var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[(_vm.listaStocProfile.length > 0)?_c('div',{staticClass:"input-field filter-input col s7"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filterInput),expression:"filterInput"}],attrs:{"placeholder":"Cauta profil"},domProps:{"value":(_vm.filterInput)},on:{"input":function($event){if($event.target.composing)return;_vm.filterInput=$event.target.value}}})]):_vm._e(),_vm._m(0)]),_c('div',{staticClass:"card-panel"},[_c('table',{staticClass:"highlight responsive-table"},[(_vm.listaStocProfile.length > 0)?_c('thead',[_vm._m(1)]):_c('thead',[_c('p',{staticClass:"bold"},[_vm._v("Nu exista profile in stoc")])]),_c('tbody',_vm._l((_vm.filterBy(_vm.listaStocProfile, _vm.filterInput)),function(profil,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(profil.profil_tip))]),_c('td',[_vm._v(_vm._s(profil.cota_profil)+" mm")]),_c('td',[_vm._v(_vm._s(profil.cantitate)+" buc")]),_c('td',[_vm._v(" "+_vm._s(_vm.greutate( profil.cota_profil, profil.profil_greutate_ml, profil.cantitate ))+" kg ")]),(!profil.comenzi && profil.comenzi_obj)?_c('td',_vm._l((profil.comenzi_obj),function(item){return _c('div',{key:item.nr},[_vm._v(" "+_vm._s(item.nr)+" | "+_vm._s(item.pos)+" | "+_vm._s(item.subpos)+" ")])}),0):(
								profil.comenzi &&
									profil.comenzi_obj &&
									profil.comenzi.length < profil.comenzi_obj.length
							)?_c('td',_vm._l((_vm.filterComenzi(
									profil.comenzi_obj,
									profil.comenzi
								)),function(item){return _c('div',{key:item.nr},[_vm._v(" "+_vm._s(item.nr)+" | "+_vm._s(item.pos)+" | "+_vm._s(item.subpos)+" ")])}),0):_c('td',[_vm._v("Ramas pe stoc fara comanda")]),_c('td',[_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],attrs:{"type":"checkbox"},domProps:{"value":profil.id,"checked":Array.isArray(_vm.selected)?_vm._i(_vm.selected,profil.id)>-1:(_vm.selected)},on:{"change":[function($event){var $$a=_vm.selected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=profil.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.selected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.selected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.selected=$$c}},function($event){return _vm.$emit('selectProfile', _vm.selected)}]}}),_c('span')])])])}),0)])]),_c('div',{staticClass:"row"})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col s3 offset-s2 info"},[_c('i',{staticClass:"material-icons"},[_vm._v("info")]),_c('h6',[_vm._v("Profile livrate - materie prima")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{staticClass:"width3"},[_vm._v("Nr.")]),_c('th',{staticClass:"width15"},[_vm._v("Tip profil")]),_c('th',[_vm._v("Lungime")]),_c('th',[_vm._v("Cantitate")]),_c('th',[_vm._v("Greutate neta")]),_c('th',[_vm._v("Comanda")]),_c('th',[_vm._v("Incarca pe aviz")])])
}]

export { render, staticRenderFns }