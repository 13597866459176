<template>
  <div>
    <div class="card-panel row">
      <div class="col s10">
        <table class="responsive-table consumabile">
          <thead>
            <tr>
              <th class="center-align">Nr.</th>
              <th>Denumire</th>
              <th class="center-align">Cantitate</th>
              <th class="center-align">
                Pret/um
                <br />- lei -
              </th>
              <th class="center-align">UM</th>
              <th class="center-align">Marja %</th>
              <th class="center-align">Valoare fara TVA</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(obj,index) in consumabile_obj">
              <td class="width5">
                <input type="number" class="validate center-align" v-model="obj.nr" />
              </td>
              <td class="width35">
                <model-select
                  id="consumabil"
                  :options="selectConsumabile"
                  v-model="obj.denumire"
                  placeholder="Denumire produs"
                  @input="setValoriConsumabile(index)"
                ></model-select>
              </td>
              <td class="width10">
                <input
                  type="number"
                  step="1"
                  class="validate center-align"
                  v-model="obj.cantitate"
                  v-on:change="totalC(index)"
                />
              </td>
              <td class="width10">
                <input class="center-align" v-model="obj.pret" readonly />
              </td>
              <td class="width10">
                <input class="center-align" v-model="obj.um" readonly />
              </td>
              <td class="width10">
                <input
                  type="number"
                  step="0.1"
                  value="1"
                  placeholder="0"
                  class="validate center-align"
                  v-model="obj.marja"
                  v-on:change="totalC(index)"
                />
              </td>
              <td class="width15">
                <input class="center-align" placeholder="0.00" v-model="obj.pret_t" number readonly />
              </td>
              <td class="delete right" @click="deleteConsumabileForm(index)">X</td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col s9">
            <br />
            <a class="btn blue-grey lighten-2" @click="addNewConsumabileForm">Adauga produs</a>
          </div>
          <table class="col s3">
            <tr>
              <td class="center-align bold">Total fara TVA</td>
              <td class="center-align">
                <span>{{totalsC}}</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <button class="btn" @click="saveConsumabile()">Salveaza</button>
    <router-link v-if="tip == 'oferta'" class="btn blue-grey lighten-2" :to="'/oferte-productie/single/'+id">Anuleaza</router-link>
    <router-link v-else-if="tip == 'lucrare'" class="btn blue-grey lighten-2" :to="'/lucrari-productie/single/'+id">Anuleaza</router-link>
    <!-- <pre>{{tip}} {{id}}</pre> -->
    <!-- <pre>{{consumabile_obj}}</pre> -->
  </div>
</template>

<script>
import { ModelSelect } from "vue-search-select";

export default {
  name: "EditConsumabile",
  data() {
    return {
      cost_fara_tvaC: "0.00",
      val_fara_tvaC: "0.00"
    };
  },
  props: ["consumabile_obj", "tip", "id"],
  components: {
    ModelSelect
  },
  computed: {
    selectConsumabile() {
      return this.$store.getters.selectConsumabile;
    },
    totalsC: function() {
      let v = 0;
      let t = 0;
      this.consumabile_obj.forEach(function(obj) {
        v = v + Number(obj.pret_v);
        t = t + Number(obj.pret_t);
      });
      this.cost_fara_tvaC = v.toFixed(2);
      this.val_fara_tvaC = t.toFixed(2);
      return this.val_fara_tvaC;
    }
  },
  methods: {
    addNewConsumabileForm() {
      let nr = this.consumabile_obj.length + 1;
      this.consumabile_obj.push({
        nr: "" + nr + "",
        denumire: "",
        cantitate: null,
        pret: null,
        um: "",
        pret_v: null,
        marja: null,
        pret_t: null
      });
    },
    deleteConsumabileForm(index) {
      if (this.consumabile_obj.length > 1) {
        this.consumabile_obj.splice(index, 1);
      }
    },
    setValoriConsumabile(index) {
      let currentObj = this.consumabile_obj[index];
      let currentConsumabil = this.selectConsumabile.find(
        consumabil => consumabil.value === currentObj.denumire
      );
      this.consumabile_obj[index].pret = currentConsumabil.pret;
      this.consumabile_obj[index].um = currentConsumabil.um;
    },
    totalC(index) {
      let cantitate = Number(this.consumabile_obj[index].cantitate);
      let pret = Number(this.consumabile_obj[index].pret);
      let total = cantitate * pret;
      this.consumabile_obj[index].pret_v = total.toFixed(2);
      total = total + total * (Number(this.consumabile_obj[index].marja) / 100);
      this.consumabile_obj[index].pret_t = total.toFixed(2);
    },
    saveConsumabile() {
      let newConsumabile = {
        consumabile_obj: JSON.stringify(this.consumabile_obj),
        cost_fara_tvaC: this.cost_fara_tvaC,
        val_fara_tvaC: this.val_fara_tvaC,
        tip: this.tip,
        id: this.id
      };
      this.$store.dispatch("saveConsumabile", newConsumabile);
    }
  },
  created: function() {
    this.$store.dispatch("fetchConsumabile");
  },
  mounted: function() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
td.delete {
  cursor: pointer;
  color: #e65100;
}
.save {
  width: 100%;
  padding: 6px 30px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
}
.save p {
  margin-right: 40px;
}
</style>
