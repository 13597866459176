<template>
    <div class="flex gap-1">
        <v-btn v-if="item.completed == '0'" icon text color="teal" @click.stop="selectCurrentReturnItemDate()">
            <v-icon>mdi-calendar-badge</v-icon>
        </v-btn>
        <v-btn icon text color="teal" @click.stop="selectCurrentReturnItemStatus()">
            <v-icon>mdi-swap-horizontal</v-icon>
        </v-btn>
        <v-btn v-if="item.completed == '1' || ['admin'].includes(userRole)" icon text color="teal" @click="deleteItem">
            <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    props: {
        item: Object,
        userRole: String
    },
    data() {
        return {
        }
    },
    computed: {

    },
    methods: {
        selectCurrentReturnItemDate() {
            this.$emit('openDateModal', { id: this.item.id, return_date: this.item.return_date })

        },
        selectCurrentReturnItemStatus() {
            this.$emit('openStatusModal', { id: this.item.id, completed: this.item.completed, obs: this.item.obs })
        },
        deleteItem() {
            this.$store.dispatch("deleteReturnItem", this.item.id);
        }
    }
}
</script>