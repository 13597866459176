<template>
	<div>
		<table class="responsive-table produse">
			<thead>
				<tr>
					<th class="w-10 center-align">Nr.</th>
					<th class="w-44">Material</th>
					<th class="w-56">Denumire produs</th>
					<th class="w-56">Aliaj</th>
					<th colspan="3">Dimensiuni - mm -</th>
					<th class="w-20 center-align">UM</th>
					<th class="w-28 center-align">Cantitate</th>
					<th class="w-20 center-align">
						Greutate
						<br />- kg -
					</th>
					<th class="w-28 center-align">
						Pret unitar
						<br />
						- {{ oferta.moneda }} / kg -
					</th>
					<th class="w-28 center-align">
						Pret unitar
						<br />
						- {{ oferta.moneda }} / UM -
					</th>
					<th class="w-28 center-align">
						Val. fara
						<br />
						TVA - {{ oferta.moneda }} -
					</th>
					<th></th>
				</tr>
			</thead>
			<Product v-for="(produs, index) in produse" :key="produs.nr" :produs="produs" :index="index"
				:firstAddMode="initialAdd" :totalWeight="totalWeight" :preturiVanzari="preturiVanzari"
				:formulaVanzari="formulaVanzari" @deleteProductForm="deleteProductForm" />
			<tbody v-show="produse.length > 0" class="bg-gray-200">
				<tr>
					<td colspan="7"></td>
					<td colspan="2" class="right-align">Greutate totala:</td>
					<td colspan="2">{{ totalWeight }} kg</td>
					<td colspan="3"></td>
				</tr>
			</tbody>
			<ProductCustom v-for="(customProdus, index) in produseCustom" :key="customProdus.nr"
				:customProdus="customProdus" :index="index" @deleteCustomProductForm="deleteCustomProductForm" />
		</table>
		<div class="flex justify-end items-end mt-4">
			<div class="flex btn-group">
				<a class="btn bg-gray-500" @click="addNewProductForm">Adauga produs</a>
				<a class="btn bg-gray-400 darken-1" @click="addNewCustomProductForm">Adauga produs custom</a>
			</div>
		</div>
		<!-- <pre>{{ preturiVanzari }}</pre> -->
	</div>
</template>

<script>
import Product from "@/components/Oferte/AddOferte/Product";
import ProductCustom from "@/components/Oferte/AddOferte/ProductCustom";

export default {
	name: "AddProducts",
	components: {
		Product,
		ProductCustom,
	},
	props: {
		initialAdd: Boolean,
		oferta: Object,
		produse: Array,
		produseCustom: Array,
	},
	computed: {
		nextTableNumber() {
			return this.produse.length + this.produseCustom.length + 1;
		},
		preturiVanzari() {
			return this.$store.getters.preturiVanzari;
		},
		formulaVanzari() {
			return this.$store.getters.formulaVanzari;
		},
		totalWeight() {
			let tWeight = 0;
			this.produse.forEach((produs) => {
				tWeight += Number(produs.greutate);
			});
			return tWeight.toFixed(2);
		},
	},
	data() {
		return {};
	},
	watch: {
		totalWeight(value) {
			this.$emit("weightChanged", value);
		},
	},
	methods: {
		reAssignTableNr() {
			this.produse.forEach((produs, index) => {
				produs.nr = index + 1;
			});
			this.produseCustom.forEach((produs, index) => {
				produs.nr = this.produse.length + index + 1;
			});
		},
		addNewProductForm() {
			this.produse.push({
				nr: this.nextTableNumber,
				material: "",
				denumire: {},
				aliaj: "",
				gs: "",
				gspec: "",
				detalii: "",
				dimL: null,
				dimH: null,
				dimG: null,
				ums: [],
				um: "",
				cantitate: "",
				greutate: "",
				pret: "",
				pret_kg: "",
				pret_t: "",
				pret_u: "",
				tva: "",
				t_livrare: "",
				aliaje: [],
				modified: false,
			});
			this.reAssignTableNr();
		},
		deleteProductForm(index) {
			this.produse.splice(index, 1);
			this.reAssignTableNr();
		},
		addNewCustomProductForm() {
			this.produseCustom.push({
				nr: this.nextTableNumber,
				denumire: "",
				dimensiune_text: "",
				um: "",
				cantitate: "",
				pret_t: "",
				pret_u: "",
				tva: "",
				detalii: "",
				t_livrare: "",
			});
			this.reAssignTableNr();
		},
		deleteCustomProductForm(index) {
			this.produseCustom.splice(index, 1);
			this.reAssignTableNr();
		},
	},
	created: function () {
		this.$store.dispatch("fetchMateriale");
		this.$store.dispatch("fetchListaProduse");
	},
};
</script>

<style scoped></style>
