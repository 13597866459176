<template>
	<div class="container">
		<!-- MODALS -->
		<!-- Modal Email -->
		<div id="modal_email" class="modal modal-email modal-small">
			<div class="modal-content">
				<h6 class="text-lg mb-2">Trimite contractul prin email</h6>
				<p>Email client:</p>
				<div class="btn-group mt-4">
					<button href="#!" class="btn modal-close" v-on:click="emailOfertaPDF()">
						<i class="material-icons left">email</i>Trimite PDF
					</button>
					<a class="modal-action modal-close btn blue-grey">Inchide</a>
				</div>
			</div>
		</div>
		<!-- Modal Aditional -->
		<div id="modal_aditional" class="modal modal-aditional modal-small">
			<div class="modal-content">
				<h6 class="text-lg font-bold mb-2">Genereaza act aditional</h6>
				<form @submit.prevent="addNewActAditional()">
					<div class="input-field">
						<input id="new-offer-id" type="text" class="validate" v-model="newOfferId"
							pattern="^[0-9]{1,5}$" required />
						<label for="new-offer-id">Numar oferta noua</label>
					</div>
					<div class="btn-group mt-4">
						<button class="btn" type="submit">
							Genereaza
						</button>
						<a class="modal-action modal-close btn blue-grey">Inchide</a>
					</div>
				</form>
			</div>
		</div>
		<!-- HEADER -->
		<div class="flex flex-col lg:flex-row justify-between items-center my-6">
			<div class="flex justify-start items-center px-4 lg:px-0 mb-4 lg:mb-0">
				<router-link class="btn back-btn blue-grey lighten-2" to="/productie/contracte">Inapoi</router-link>
				<h5 class="inline-title text-xl mx-6">
					Contract productie nr.
					<span class="font-bold">{{ $route.params.id }}</span>
				</h5>
			</div>
			<div class="btn-group flex justify-start lg:justify-end items-center flex-wrap px-4">
				<!-- PDF dropdown -->
				<div class="btn-group">
					<button></button>
					<router-link v-if="contract.id_aditional"
						class="btn bg-blue-600 hover:bg-blue-500 focus:bg-blue-700 btn-icon-padding" v-bind:to="'/productie/contracte/single/' + $route.params.id + '/aditional'
							">
						<i class="material-icons left">post_add</i>Act aditional
					</router-link>
					<button v-else
						class="btn bg-blue-600 hover:bg-blue-500 focus:bg-blue-700 btn-icon-padding modal-trigger"
						data-target="modal_aditional">
						<i class="material-icons left">post_add</i>Act aditional
					</button>
					<button
						class="btn bg-gray-600 hover:bg-gray-500 focus:bg-gray-700 dropdown-trigger btn-icon-padding"
						data-target="pdf" :disabled="editingMode">
						<i class="material-icons left">description</i>PDF
					</button>
					<ul id="pdf" class="dropdown-content">
						<li>
							<a href="#!" v-on:click="downloadPDF()">
								<i class="material-icons left">file_download</i>Descarca PDF
							</a>
						</li>
						<li>
							<a data-target="modal_email" class="modal-trigger" href="#!">
								<i class="material-icons left">email</i>Email PDF
							</a>
						</li>
					</ul>
					<button v-if="editingMode" class="btn" @click="saveContractContent">
						<i class="material-icons left">save</i>Salveaza
					</button>
					<button v-if="editingMode" class="btn blue-grey" @click="toggleEditMode">
						<i class="material-icons left">close</i>Anuleaza
					</button>
					<button v-else class="btn" @click="toggleEditMode">
						<i class="material-icons left">edit</i>Editeaza
					</button>
				</div>
			</div>
		</div>
		<!-- MAIN SECTION -->
		<div v-if="contract.text_content" class="mb-4">
			<i class="material-icons left text-yellow-500">warning</i>
			Contract editat fata de forma initiala!
		</div>
		<div v-if="!loading" class="flex justify-center">
			<editor v-if="firma_montaj_contract && contract.id_contract && contract.id_contract == $route.params.id"
				v-model="document_text" :editingMode="editingMode"
				class="h-screen bg-white p-6 mb-8 overflow-y-scroll border-2 shadow-inner"
				:class="{ 'border-red-500': editingMode }" />
		</div>
		<div class="debug">
			<!-- <pre class="content"><code>{{ document_text }}</code></pre> -->
			<!-- <pre class="content"><code>{{ contract }}</code></pre> -->
		</div>
	</div>
</template>

<script>
import moment from "moment";
import Editor from "@/components/Editor";
import { pdf_contract } from "@/pdf_functions/pdf_contract.js";

export default {
	name: "DetaliiContractProductie",
	components: {
		Editor,
	},
	data() {
		return {
			editingMode: false,
			newOfferId: null,
			modifiedDoc: null,
			andText: {
				type: "paragraph",
				attrs: {
					textAlign: "left"
				},
				content: [
					{
						type: "text",
						text: "SI",
					},
				],
			},
			orText: {
				type: "paragraph",
				attrs: {
					textAlign: "left"
				},
				content: [
					{
						type: "text",
						text: "SAU",
					},
				],
			},
			montator_title_text: "MONTATOR",
		};
	},
	computed: {
		loading() {
			return this.$store.getters.loading;
		},
		today() {
			return this.$store.getters.today;
		},
		contract() {
			return this.$store.getters.contractProductie;
		},
		firma_montaj_contract() {
			if (this.contract.detalii) {
				if (this.contract.detalii.firma_montaj) {
					return this.$store.getters.firma_montaj_contract(
						this.contract.detalii.firma_montaj
					);
				} else {
					return this.$store.getters.firma_montaj_activa;
				}
			}
		},
		executant_firma_text() {
			return this.$store.getters.executant_firma_text;
		},
		montaj_firma_text() {
			if (this.firma_montaj_contract) {
				return `2. S.C. ${this.firma_montaj_contract.denumire_firma.toUpperCase()} S.R.L., cu sediul in ${this.firma_montaj_contract.adresa
					} inregistrata in Registrul Comertului sub numarul ${this.firma_montaj_contract.regcom
					}, cod fiscal ${this.firma_montaj_contract.cui} (${Number(this.firma_montaj_contract.vat) ? "platitor" : "neplatitor"
					} de TVA), cont bancar: ${this.firma_montaj_contract.bank_account}, ${this.firma_montaj_contract.bank
					}, reprezentata legal prin ${this.firma_montaj_contract.person
					}, in calitate de MONTATOR`;
			}
		},
		montaj_firma_text1() {
			if (this.firma_montaj_contract) {
				return `2. S.C. CUPROLLI MONTAJE S.R.L., cu sediul in Dumbravita, Str. Mikszath Kalman 18/20, Jud. Timis, inregistrata in Registrul Comertului sub numarul J35/4258/2021, cod fiscal 45109254 (neplatitor de TVA), cont bancar: RO83BTRLRONCRT0CE8963901, Banca Transilvania, reprezentata legal prin Rosu Octavian, in calitate de MONTATOR`;
			}
		},
		montaj_firma_text2() {
			if (this.firma_montaj_contract) {
				return `2. S.C. CUPROLLI SERV S.R.L., cu sediul in Dumbravita, Str. Mikszath Kalman 18/20, Jud. Timis, inregistrata in Registrul Comertului sub numarul J35/3370/2021, cod fiscal 44771643 (neplatitor de TVA), cont bancar: RO52BTRLRONCRT0615069901, Banca Transilvania, reprezentata legal prin Rosu Octavian, in calitate de MONTATOR`;
			}
		},
		montaj_firma_text3() {
			if (this.firma_montaj_contract) {
				return `2. S.C. CUPROLLI TRADING S.R.L., cu sediul in Dumbravita, Str. Mikszath Kalman 18/20, Jud. Timis, inregistrata in Registrul Comertului sub numarul J35/2578/2019, cod fiscal 41281760 (neplatitor de TVA), cont bancar: RO87BTRLRONCRT0478696001, Banca Transilvania, reprezentata legal prin Rosu Octavian, in calitate de MONTATOR`;
			}
		},
		montator_name() {
			if (this.firma_montaj_contract) {
				return {
					type: "paragraph",
					attrs: {
						textAlign: "left"
					},
					content: [
						{
							type: "text",
							text: `S.C. ${this.firma_montaj_contract.denumire_firma.toUpperCase()} S.R.L.`,
						},
					],
				};
			}
		},
		montator_person() {
			if (this.firma_montaj_contract) {
				return {
					type: "paragraph",
					attrs: {
						textAlign: "left"
					},
					content: [
						{
							type: "text",
							text: `${this.firma_montaj_contract.person}`,
						},
					],
				};
			}
		},
		isMontajSeparat() {
			return this.contract.detalii.montaj_separat;
		},
		persoana() {
			return {
				type: "paragraph",
				attrs: {
					textAlign: "right",
				},
				content: [
					{
						type: "text",
						text: `${this.contract.persoana}`,
					},
				],
			};
		},
		act_client() {
			let act = "";
			if (this.contract.detalii.alt_document) {
				act += this.contract.detalii.alt_document;
			} else {
				act +=
					"C.I. serie " +
					this.contract.detalii.serie_ci +
					" nr. " +
					this.contract.detalii.numar_ci;
			}
			return act;
		},
		adresa_client() {
			let adresa = "";
			if (this.contract.persoana) {
				adresa = `cu sediul in ${this.contract.adresa}, inregistrata la Registrul Comertului, sub numarul ${this.contract.reg_com}, cod fiscal ${this.contract.cui}, reprezentata legal prin ${this.contract.persoana},`;
			} else {
				adresa = `cu domiciliul in ${this.contract.detalii.adresa
					}, identificat cu ${this.act_client}, ${this.contract.detalii.cnp
						? "CNP " + this.contract.detalii.cnp + ", "
						: ""
					}`;
			}
			return adresa;
		},
		suma_contract() {
			return (
				Number(this.contract.val_fara_tvaG) +
				Number(this.contract.val_fara_tvaA)
			);
		},
		suma_avans() {
			let sum = (this.suma_contract * this.contract.c_plata) / 100;
			return sum.toFixed(2);
		},
		suma_avans2() {
			if (this.contract.c_plata2) {
				let sum = (this.suma_contract * this.contract.c_plata2) / 100;
				return sum.toFixed(2);
			} else {
				return null
			}
		},
		suma_rest() {
			let sum = this.suma_contract - this.suma_avans;
			if (this.suma_avans2) {
				sum -= this.suma_avans2
			}
			return sum.toFixed(2);
		},
		plata1() {
			let upfrontPayments = [
				{
					type: "listItem",
					content: [
						{
							type: "paragraph",
							attrs: {
								textAlign: "left"
							},
							content: [
								{
									type: "text",
									text: `${(this.suma_avans / 1.19).toFixed(2)} euro + TVA, respectiv ${this.suma_avans} euro (TVA inclus) vor fi platiti la semnarea contractului catre EXECUTANT (CUPROLLI MANUFACTURING SRL), in contul bancar indicat pe factura,`,
								},
							],
						},
					],
				}
			]
			if (this.contract.c_plata2) {
				upfrontPayments.push({
					type: "listItem",
					content: [
						{
							type: "paragraph",
							attrs: {
								textAlign: "left"
							},
							content: [
								{
									type: "text",
									text: `${(this.suma_avans2 / 1.19).toFixed(2)} euro + TVA, respectiv ${this.suma_avans2} euro (TVA inclus) vor fi platiti la efectuarea masuratorilor catre EXECUTANT (CUPROLLI MANUFACTURING SRL), in contul bancar indicat pe factura,`,
								},
							],
						},
					],
				})
			}

			return upfrontPayments
		},
		plata2() {
			if (this.contract.detalii.montaj_separat) {
				return (
					this.suma_rest +
					` euro urmand a fi achitati la receptia finala a lucrarii, catre MONTATOR sau catre EXECUTANT, in functie de caz, in contul bancar indicat pe factura.`
				);
			} else {
				return (
					(this.suma_rest / 1.19).toFixed(2) +
					" euro + TVA, respectiv " +
					this.suma_rest +
					" euro (TVA inclus) urmand a fi achitati la receptia finala a lucrarii, catre EXECUTANT (CUPROLLI MANUFACTURING SRL), in contul bancar indicat pe factura."
				);
			}
		},
		module_gard() {
			let arr_modules = [];
			this.contract.gard_obj.forEach((modul) => {
				let item = {
					type: "listItem",
					content: [
						{
							type: "paragraph",
							attrs: {
								textAlign: "left"
							},
							content: [
								{
									type: "text",
									text: `${this.denumireModulGard(
										modul.tip,
										modul.tip_poarta_mare,
										modul.pp_included
									)} - ${modul.buc} buc cu dimensiunile L x h de ${modul.latime
										} x ${modul.inaltime} mm;`,
								},
							],
						},
					],
				};
				arr_modules.push(item);
			});
			return arr_modules;
		},
		module_custom() {
			let arr_modules = [];
			if (this.contract.alte_obj) {
				this.contract.alte_obj.forEach((modul) => {
					let item = {
						type: "listItem",
						content: [
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text: `${modul.denumire} - ${modul.cantitate} ${modul.um}`,
									},
								],
							},
						],
					};
					arr_modules.push(item);
				});
			}

			return arr_modules;
		},
		p1_termen_executie() {
			if (this.contract.detalii.finisaj_finalizat) {
				return `Conform informatiei furnizare de catre beneficiar la data intocmirii acestui contract, partea de lucrare ce cade in sarcina sa, si anume, partea de zidarie, beton, stalpi de metal, etc., pe care se vor prinde reperele din aluminiu ce vor fi confectionate de executant, este complet finisata si se pot face masuratorile finale. In acest caz termenul de executie al acestor repere este de ${this.contract.t_executie} zile de la data creditarii contului beneficiarului cu suma stabilita la art VI.`;
			} else {
				return `Avand in vedere ca, la data intocmirii acestui contract, beneficiarul nu are finalizata partea de lucrare ce cade in sarcina sa, si anume, partea de zidarie, beton, stalpi de metal, etc., pe care se vor prinde reperele din aluminiu ce vor fi confectionate de executant, termenul de executie a acestor repere este de ${this.contract.t_executie} zile de la data in care executantul va putea sa faca masuratorile finale.`;
			}
		},
		p2_termen_executie() {
			if (this.contract.detalii.finisaj_finalizat) {
				return `Daca in momentul deplasarii pentru efectuarea masuratorilor, executantul constata ca beneficiarul nu are finalizata partea de lucrare ce cade in sarcina sa, se va intocmi un act aditional in care se va metiona acest lucru iar termenul de executie va fi de ${this.contract.t_executie} zile de la data in care se vor putea face masuratorile finale.`;
			} else {
				return `In acest sens se va intocmi un act aditional la contract in care se va mentiona faptul ca beneficiarul are finalizata partea de lucrare ce cade in sarcina sa si executantul poate lua masuratorile finale.`;
			}
		},
		exemplareContract() {
			return this.isMontajSeparat ? "3 (trei)" : "2 (doua)";
		},
		document_text: {
			get() {
				if (this.contract.text_content) {
					return this.contract.text_content;
				} else {
					return {
						type: "doc",
						content: [
							{
								type: "heading",
								attrs: {
									textAlign: "center",
									level: 1,
								},
								content: [
									{
										type: "text",
										text: "CONTRACT DE EXECUTIE LUCRARI",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "center"
								},
								content: [
									{
										type: "text",
										text: `Nr: ${this.contract.id_contract} / ${moment(
											String(this.contract.data)
										).format("DD.MM.YYYY")}`,
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
							},
							{
								type: "heading",
								attrs: {
									textAlign: "left",
									level: 2,
								},
								content: [
									{
										type: "text",
										text: "I. PARTILE CONTRACTANTE",
									},
								],
							},
							this.formatParagraph(this.executant_firma_text, "left"),
							this.isMontajSeparat ? this.andText : { type: "blockquote" },
							this.isMontajSeparat
								? this.formatParagraph(this.montaj_firma_text1, "left")
								: { type: "blockquote" },
							this.isMontajSeparat ? this.orText : { type: "blockquote" },
							this.isMontajSeparat
								? this.formatParagraph(this.montaj_firma_text2, "left")
								: { type: "blockquote" },
							this.isMontajSeparat ? this.orText : { type: "blockquote" },
							this.isMontajSeparat
								? this.formatParagraph(this.montaj_firma_text3, "left")
								: { type: "blockquote" },
							this.andText,
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text: `${this.isMontajSeparat ? "3" : "2"
											}. ${this.contract.nume.toUpperCase()}, ${this.adresa_client
											} telefon: ${this.contract.tel}, email: ${this.contract.email
											} in calitate de BENEFICIAR`,
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
							},
							{
								type: "heading",
								attrs: {
									textAlign: "left",
									level: 2,
								},
								content: [
									{
										type: "text",
										text: "II. OBIECTUL CONTRACTULUI",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text: `Obiectul contractului il reprezinta executarea unei lucrari denumita generic ${this.contract.detalii.denumire.toUpperCase()}, conform ofertei nr. ${this.contract.oferta_id
											} / ${moment(String(this.contract.oferta_data)).format(
												"DD.MM.YYYY"
											)}`,
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text: `EXECUTANTUL se obliga sa execute,${this.contract.detalii.montaj_separat
											? " MONTATORUL se obliga"
											: ""
											} sa monteze, sa testeze si sa finalizeze lucrarea, iar BENEFICIARUL se obliga sa receptioneze si sa achite contravaloarea lucrarii ce face obiectul prezentului contract.`,
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text: `Locatia executarii lucrarii ${this.contract.detalii.denumire.toUpperCase()}: ${this.contract.detalii.alta_locatie
											? this.contract.detalii.locatie
											: this.contract.detalii.adresa
											}.`,
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
							},
							{
								type: "heading",
								attrs: {
									textAlign: "left",
									level: 2,
								},
								content: [
									{
										type: "text",
										text: "III. DESCRIEREA LUCRARII",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text: `Elementele componente ale lucrarii denumite ${this.contract.detalii.denumire.toUpperCase()} sunt:`,
									},
								],
							},
							{
								type: "bulletList",
								content: this.module_gard,
							},
							{
								type: "bulletList",
								content: this.module_custom,
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text: `Toate elementele vor fi vopsite in camp electrostatic culoarea ${this.contract.color}, conform specificatiei beneficiarului.`,
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text: `Gardul va fi livrat cu o placuta cu stema companiei producatoare pe una dintre portile gardului achizitionat. Placuta va fi amplasata pe poarta pietonala sau, in absenta acesteia, pe o alta poarta sau modul al gardului conform deciziei companiei producatoare.`,
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
							},
							{
								type: "heading",
								attrs: {
									textAlign: "left",
									level: 2,
								},
								content: [
									{
										type: "text",
										text: "IV. ETAPELE EXECUTIEI",
									},
								],
							},
							{
								type: "orderedList",
								attrs: {
									start: 1,
								},
								content: [
									{
										type: "listItem",
										content: [
											{
												type: "paragraph",
												attrs: {
													textAlign: "left"
												},
												content: [
													{
														type: "text",
														text:
															"1. Efectuarea masuratorilor la fata locului.",
													},
												],
											},
										],
									},
									{
										type: "listItem",
										content: [
											{
												type: "paragraph",
												attrs: {
													textAlign: "left"
												},
												content: [
													{
														type: "text",
														text:
															"2. Confectionarea gardului in atelier: prelucrare, debitare, gaurire, vopsire.",
													},
												],
											},
										],
									},
									{
										type: "listItem",
										content: [
											{
												type: "paragraph",
												attrs: {
													textAlign: "left"
												},
												content: [
													{
														type: "text",
														text:
															"3. Montajul gardului la adresa indicata de beneficiar pana la punctul de functionalitate completa.",
													},
												],
											},
										],
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text:
											"Masuratorile in baza carora se executa gardul precum si montajul acestuia cad in sarcina executantului. Executantul se obliga sa efectueze masuratorile la fata locului si este direct responsabil de acestea.",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text:
											"Dupa efectuarea masuratorilor finale de catre executant oferta de pret va fi refacuta (pretul pentru metru patrat va ramane acelasi ca in oferta initiala) si se va intocmi un act aditional la contract in care se va specifica noul pret. ",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
							},
							{
								type: "heading",
								attrs: {
									textAlign: "left",
									level: 2,
								},
								content: [
									{
										type: "text",
										text: "V. TERMEN DE EXECUTIE",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text:
											"Efectuarea masuratorilor la fata locului se va face in momentul in care partea de lucrare ce cade in sarcina beneficiarului, si anume, partea de zidarie, beton, stalpi de metal, etc., pe care se vor aplica reperele din aluminiu ce vor fi confectionate de executant, este complet finisata si nu se vor mai aduce modificari ulterioare.",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text:
											"In caz contrar, costul pentru o a doua deplasare va cadea in sarcina beneficiarului. Pretul se va stabili de catre executant in functie de locatia unde va trebui sa fie facuta deplasarea, si va fi stabilit print-un act aditional la contract.",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text: `${this.p1_termen_executie}`,
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text: `${this.p2_termen_executie}`,
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
							},
							{
								type: "heading",
								attrs: {
									textAlign: "left",
									level: 2,
								},
								content: [
									{
										type: "text",
										text: "VI. PRETUL SI MODALITATI DE PLATA",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text: `Valoarea intregii lucrari contractate este de ${(this.suma_contract).toFixed(2)} euro.`,
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text: `Plata se va face in doua etape, dupa cum urmeaza:`,
									},
								],
							},
							{
								type: "bulletList",
								attrs: {
									textAlign: "left"
								},
								content: [
									// {
									// 	type: "listItem",
									// 	content: [
									// 		{
									// 			type: "paragraph",
									// 			attrs: {
									// 				textAlign: "left"
									// 			},
									// 			content: [
									// 				{
									// 					type: "text",
									// 					text: `${(this.suma_avans / 1.19).toFixed(
									// 						2
									// 					)} euro + TVA, respectiv ${this.suma_avans
									// 						} euro (TVA inclus) vor fi platiti la semnarea contractului catre EXECUTANT (CUPROLLI MANUFACTURING SRL), in contul bancar indicat pe factura,`,
									// 				},
									// 			],
									// 		},
									// 	],
									// },
									...this.plata1,
									{
										type: "listItem",
										content: [
											{
												type: "paragraph",
												attrs: {
													textAlign: "left"
												},
												content: [
													{
														type: "text",
														text: `${this.plata2}`,
													},
												],
											},
										],
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text:
											"Pentru neachitarea facturii la termenul de scadenta, beneficiarul datoreaza penalitati de intarziere de 10 euro / zi.",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text:
											"Pentru nerespectarea termenului de executie si montaj, executantul datoreaza penalitati de intarziere de 10 euro / zi.",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text:
											"Penalitatile de intarziere pentru neachitarea facturii la termen, se calculeaza din ziua imediat urmatoare expirarii termenului de scadenta al facturii si pana la data stingerii sumei datorate.",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text:
											"Penalitatile de intarziere pentru nerespectarea termenului de executie si montaj, se calculeaza din ziua imediat urmatoare expirarii termenului de executie si montaj si pana la data finalizarii complete a lucrarii.",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text:
											"In cazul in care, la semnarea procesului verbal de predare-primire beneficiarul mentioneaza anumite neconformitati cu privire la produs sau la montajul acestuia, se poate retine pana la finalizarea completa a lucrarii 20% din valoarea facturii restante, dar nu mai mult de 500 euro.",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text:
											"Facturarea se face in lei la cursul de vanzare din ziua respectiva al Bancii Transilvania.",
									},
								],
							},

							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text:
											"Plata se face prin ordin de plata sau depunere numerar in contul specificat pe factura.",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
							},
							{
								type: "heading",
								attrs: {
									textAlign: "left",
									level: 2,
								},
								content: [
									{
										type: "text",
										text: "VII. TERMEN DE REMEDIERE",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text:
											"Termenul de remediere a unor defectiuni aparute din cauza executantului ulterior montajului, este de maximum 30 zile lucratoare de la data transmiterii solicitarii de catre beneficiar.",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text:
											"Daca se constata ca defectiunea s-a produs din cauza beneficiarului, cheltuielile vor intra in sarcina acestuia.",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
							},
							{
								type: "heading",
								attrs: {
									textAlign: "left",
									level: 2,
								},
								content: [
									{
										type: "text",
										text: "VIII. GARANTIA",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text:
											"Se acorda garantie pentru vopsea pe o perioada de 5 ani pentru defecte de material sau aplicare, iar pentru accesorii si lucrare se acorda garantie pe o perioadă de 24 luni de la data receptionarii lucrarii.",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
							},
							{
								type: "heading",
								attrs: {
									textAlign: "left",
									level: 2,
								},
								content: [
									{
										type: "text",
										text: "IX. PRELUCRAREA DATELOR PERSONALE",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text:
											"In temeiul prezentului contract si al Regulamentului UE nr. 679/2016, partile iau la cunostinta si accepta faptul ca una din ele ar putea colecta anumite date cu caracter personal ale reprezentantilor celeilalte (reprezentantul legal precum si alte persoane care furnizeaza asemenea date), cum ar fi: nume, adrese de e-mail si numere de telefon mobil.",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text:
											"Stocarea datelor personale obtinute in baza prezentului contract se va face in format electronic, cat si in format listat pe suport de hartie.",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text:
											"Durata de stocare a datelor este limitata la ceea ce este necesar pentru scopurile de procesare si vor fi pastrate pe perioada de valabilitate a contractului dupa care vor fi sterse.",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text:
											"Datele cu caracter personal colectate de catre parti nu vor fi transferate catre alte terte persoane, fizice sau juridice.",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text:
											"Oricare din cele doua parti are dreptul de a solicita rectificarea, completarea, restrictionarea prelucrarii sau stergerea datelor cu caracter personal.",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text: `Prezentul contract a fost incheiat astazi ${moment(
											String(this.contract.data)
										).format("DD.MM.YYYY")}  in ${this.exemplareContract
											} exemplare, cate unul pentru fiecare parte.`,
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										marks: [
											{
												type: "bold",
											},
										],
										text: "EXECUTANT",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text: "S.C. CUPROLLI MANUFACTURING S.R.L.",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
								content: [
									{
										type: "text",
										text: "Rosu Octavian",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "right",
								},
								content: [
									{
										type: "text",
										marks: [
											{
												type: "bold",
											},
										],
										text: "BENEFICIAR",
									},
								],
							},
							{
								type: "paragraph",
								attrs: {
									textAlign: "right",
								},
								content: [
									{
										type: "text",
										text: `${this.contract.nume.toUpperCase()}`,
									},
								],
							},
							this.contract.persoana ? this.persoana : { type: "blockquote" },
							{
								type: "paragraph",
								attrs: {
									textAlign: "left"
								},
							},
							this.isMontajSeparat
								? this.formatParagraph(this.montator_title_text, "left", "bold")
								: { type: "blockquote" },
						],
					};
				}
			},
			set(newVal) {
				this.modifiedDoc = newVal;
			},
		},
	},

	watch: {},

	methods: {
		denumireModulGard(str, tip_poarta, pp_included) {
			if (str == "Panou") {
				str += " gard";
			}
			if (str == "Poarta_mica") {
				str += "_pietonala";
			}
			if (str == "Poarta_mare") {
				str += `_${tip_poarta}`;
			}
			if (pp_included) {
				str += " cu poarta pietonala integrata";
			}
			let newStr = str.replace(/_/g, " ");
			return newStr;
		},
		formatParagraph(text_content, alignment, text_type) {
			return {
				type: "paragraph",
				attrs: {
					textAlign: alignment,
				},
				content: [
					{
						type: "text",
						text: text_content,
						...(text_type && { marks: [{ type: text_type }] }),
					},
				],
			};
		},
		addNewActAditional() {
			let newActAditional = {
				data: this.today,
				contract_id: this.contract.id_contract,
				new_offer_id: this.newOfferId
			};
			this.$store.dispatch("addActAditional", newActAditional);
			const instance = M.Modal.getInstance(
				document.getElementById("modal_aditional")
			);
			instance.close();
		},
		saveContractContent() {
			const payload = {
				id: this.$route.params.id,
				content: JSON.stringify(this.modifiedDoc),
			};
			this.$store.dispatch("updateContractContent", payload);
			this.editingMode = !this.editingMode;
		},
		toggleEditMode() {
			this.editingMode = !this.editingMode;
		},
		async downloadPDF() {
			const doc = await pdf_contract.createContractPDF(
				this.document_text.content,
				this.isMontajSeparat,
				this.firma_montaj_contract.denumire_firma
			);
			let filename = `Contract nr. ${this.contract.id_contract} din ${this.contract.data} - ${this.contract.nume}.pdf`;
			doc.save(filename);
		},
	},
	created: function () {
		this.$store.dispatch("fetchContractProductie", this.$route.params.id);
		this.$store.dispatch("fetchFirmeMontaj");
	},
	mounted: function () {
		M.Modal.init(document.querySelectorAll(".modal"));
	},
	updated: function () {
		M.Dropdown.init(document.querySelectorAll(".dropdown-trigger"), {
			constrainWidth: false,
		});
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css" scoped>
.debug {
	padding: 1rem 0 0;
}

.debug pre {
	border-radius: 5px;
	color: #333;
}

.debug code {
	display: block;
	white-space: pre-wrap;
	font-size: 0.8rem;
	padding: 0.75rem 1rem;
	background-color: #e9ecef;
	color: #495057;
}
</style>
