<template>
	<div id="materiale">
		<div v-if="loading" class="progress">
			<div class="indeterminate"></div>
		</div>
		<div v-else class="row">
			<div class="flex justify-between items-center mb-4">
				<h6 class="text-base uppercase tracking-wider text-gray-500">
					Lista materiale
				</h6>
				<button
					data-target="modal_add_material"
					class="btn right modal-trigger"
				>
					Adauga material
				</button>
			</div>
			<div class="col s12 mbottom-20">
				<table>
					<thead>
						<tr>
							<th class="width3">Nr.</th>
							<th>Denumire</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(material, index) in materiale" :key="index">
							<td>{{ index + 1 }}</td>
							<td>{{ material.denumire_material }}</td>
							<td class="width75" @click="selectedMaterial(index)">
								<button
									data-target="modal_del_material"
									class="right red-text modal-trigger"
								>
									X
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div id="modal_add_material" class="modal modal-small modal-add-material">
			<div class="modal-content">
				<h5 class="text-xl mb-2">Adauga material</h5>
				<form v-on:submit="addMaterial">
					<div class="input-field">
						<input
							id="denumire_material"
							type="text"
							class="validate"
							v-model="material.denumire"
						/>
						<label for="denumire_material">Denumire</label>
					</div>
					<div class="btn-group mt-4">
						<button type="submit" class="btn">Salveaza</button>
						<a href="#!" class="modal-action modal-close btn blue-grey">
							Anuleaza
						</a>
					</div>
				</form>
			</div>
		</div>
		<div id="modal_del_material" class="modal modal-delete modal-del-material">
			<div v-if="delIndex" class="modal-content">
				<h6>
					Sterge definitiv materialul "{{
						materiale[delIndex].denumire_material
					}}"
				</h6>
				<br />
				<button class="btn red" v-on:click="deleteMaterial()">Sterge</button>
				<a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
			</div>
		</div>
		<!-- <pre>{{$store.state}}</pre> -->
		<!-- <pre>{{$data}}</pre> -->
	</div>
</template>

<script>
export default {
	name: "SetariMateriale",
	data() {
		return {
			material: {},
			delIndex: "",
		};
	},
	computed: {
		loading() {
			return this.$store.getters.loading;
		},
		materiale() {
			return this.$store.getters.materiale;
		},
	},
	methods: {
		fetchMateriale() {
			this.$store.dispatch("fetchMateriale");
		},
		addMaterial(e) {
			if (!this.material.denumire) {
				M.toast({ html: "Completati toate campurile!" });
			} else {
				let newMaterial = {
					denumire_material: this.material.denumire,
				};
				this.$store.dispatch("addMaterial", newMaterial);
				e.preventDefault();
				this.material = {};
			}
			e.preventDefault();
		},
		selectedMaterial(index) {
			this.delIndex = index;
		},
		deleteMaterial() {
			let id = this.materiale[this.delIndex].id_material;
			this.$store.dispatch("deleteMaterial", id);
		},
	},
	created: function() {
		this.fetchMateriale();
	},
	mounted: function() {
		M.Modal.init(document.querySelector(".modal-add-material"));
		M.Modal.init(document.querySelector(".modal-del-material"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
td.delete {
	cursor: pointer;
	color: #e65100;
}
</style>
