<template>
	<div v-if="comanda" class="container">
		<form v-on:submit="updateComanda">
			<div class="document-header row">
				<h4 class="header col s4">Editeaza comanda</h4>
				<div class="col s4">
					<div class="input-field">
						<input id="client" type="text" v-model="comanda.nume" readonly />
						<label class="active" for="client_id">Nume client</label>
					</div>
				</div>
				<div class="col s4">
					<div class="input-field col s3">
						<select id="moneda" v-model="comanda.moneda">
							<option value="eur">EUR</option>
							<option value="ron">RON</option>
						</select>
						<label for="moneda">Moneda</label>
					</div>
					<div class="input-field col s5">
						<input id="data" type="date" v-model="comanda.data" readonly />
						<label for="data">Data</label>
					</div>
					<div class="input-field col s4">
						<input id="user" type="text" v-model="comanda.username" readonly />
						<label class="active" for="user">Intocmit de</label>
					</div>
				</div>
			</div>
			<!-- document header -->
			<div class="card-panel">
				<div class="document-body">
					<h5 class="header">Produse</h5>
					<table class="responsive-table produse">
						<thead>
							<tr>
								<th class="center-align">Nr.</th>
								<th>Material</th>
								<th>Denumire produs</th>
								<th>Aliaj</th>
								<th colspan="3">Dimensiuni - mm -</th>
								<th class="center-align">UM</th>
								<th class="center-align">Cantitate</th>
								<th class="center-align">
									Greutate
									<br />- kg -
								</th>
								<th class="center-align">
									Pret unitar
									<br />
									- {{ comanda.moneda }} / kg -
								</th>
								<th class="center-align">
									Pret unitar
									<br />
									- {{ comanda.moneda }} / UM -
								</th>
								<th class="center-align">
									Val. fara
									<br />
									TVA - {{ comanda.moneda }} -
								</th>
								<th></th>
							</tr>
						</thead>
						<tbody
							v-for="(produs, index) in comanda.produse_obj"
							:key="produs.nr"
						>
							<tr>
								<td class="width3">
									<input
										type="number"
										class="validate center-align"
										v-model="produs.nr"
									/>
								</td>
								<td class="width10">
									<model-select
										id="material"
										:options="materiale"
										v-model="produs.material"
										placeholder="Material"
										@input="setAliaje(index)"
									></model-select>
								</td>
								<td class="width15">
									<model-select
										id="denumire"
										:options="denumiri"
										v-model="produs.denumire"
										placeholder="Alege produs"
										@input="setUm(index)"
									></model-select>
								</td>
								<td class="width15">
									<model-select
										id="aliaj"
										:options="produs.aliaje"
										v-model="produs.aliaj"
										placeholder="Aliaj"
										@input="setValoriProduse(index)"
									></model-select>
								</td>
								<td class="width6">
									<input
										v-if="
											produs.denumire.value !== 'Bara rotunda' &&
												produs.denumire.value !== 'Bara hexagonala' &&
												!arr_teviR.includes(produs.denumire.value)
										"
										type="number"
										step="0.01"
										placeholder="Latime"
										class="validate"
										v-model.number="produs.dimL"
										v-on:input="totalP(index)"
									/>
									<input
										v-else
										type="number"
										step="0.01"
										placeholder="Diametru"
										class="validate"
										v-model="produs.dimL"
										v-on:input="totalP(index)"
									/>
								</td>
								<td class="width6">
									<input
										v-if="arr_table.includes(produs.denumire.value)"
										type="number"
										step="0.01"
										placeholder="Lungime"
										class="validate"
										v-model.number="produs.dimH"
										v-on:input="totalP(index)"
									/>
									<input
										v-else-if="
											arr_bareP.includes(produs.denumire.value) ||
												arr_teviP.includes(produs.denumire.value) ||
												arr_profileLT.includes(produs.denumire.value) ||
												produs.denumire.value === 'Profil U'
										"
										type="number"
										step="0.01"
										placeholder="Inaltime"
										class="validate"
										v-model.number="produs.dimH"
										v-on:input="totalP(index)"
									/>
								</td>
								<td class="width6">
									<input
										v-if="
											produs.denumire.value !== 'Bara rotunda' &&
												produs.denumire.value !== 'Bara hexagonala' &&
												!arr_bareP.includes(produs.denumire.value)
										"
										type="number"
										step="0.01"
										placeholder="Grosime"
										class="validate"
										v-model.number="produs.dimG"
										v-on:input="totalP(index)"
									/>
								</td>
								<td class="width5">
									<model-select
										id="um"
										:options="produs.ums ? produs.ums : ums"
										v-model="produs.um"
										placeholder="UM"
										@input="totalP(index)"
									></model-select>
								</td>
								<td class="width75">
									<input
										type="number"
										step="0.1"
										value="1"
										placeholder="1"
										class="validate center-align"
										v-model="produs.cantitate"
										v-on:input="totalP(index)"
									/>
								</td>
								<td class="width5">
									<input
										type="text"
										class="center-align"
										placeholder="0.00"
										v-model="produs.greutate"
										readonly
									/>
								</td>
								<td class="width75">
									<input
										type="number"
										step="0.01"
										value="0.00"
										placeholder="0.00"
										class="validate center-align"
										v-model="produs.pret_kg"
										v-on:input="totalP(index)"
									/>
								</td>
								<td class="width75">
									<input
										type="text"
										placeholder="0.00"
										class="center-align"
										v-model="produs.pret_u"
										readonly
									/>
								</td>
								<td class="width75">
									<input
										type="text"
										class="center-align"
										placeholder="0.00"
										v-model="produs.pret_t"
										number
										readonly
									/>
								</td>
								<td class="delete" @click="deleteProductForm(index)">X</td>
							</tr>
							<tr>
								<td colspan="3"></td>
								<td>
									<label for="detalii">Detalii</label>
									<input
										id="detalii"
										type="text"
										class="validate"
										v-model="produs.detalii"
									/>
								</td>
								<td colspan="3">
									<label for="data_livrare">Data livrare</label>
									<input
										id="data_livrare"
										type="date"
										class="validate"
										v-model="produs.data_livrare"
										placeholder="Data livrare"
									/>
								</td>
								<td colspan="2">
									<label for="data_actualizata">Data livrare actualizata</label>
									<input
										id="data_actualizata"
										type="date"
										class="validate"
										v-model="produs.data_actualizata"
										placeholder="Data actualizata"
									/>
								</td>
								<td colspan="2">
									<label for="st3">Status livrare</label>
									<model-select
										id="st3"
										:options="st_livrare"
										v-model="produs.status_livrare"
									></model-select>
								</td>
								<td colspan="2">
									<label for="furnizor">Furnizor</label>
									<model-select
										id="furnizori"
										:options="furnizori"
										v-model="produs.furnizor"
									></model-select>
								</td>
							</tr>
						</tbody>
						<div v-if="comanda.custom_obj != undefined">
							<tbody
								v-for="(customProdus, index) in comanda.custom_obj"
								:key="index"
							>
								<tr>
									<td class="width3">
										<input
											type="number"
											class="validate center-align"
											v-model="customProdus.nr"
										/>
									</td>
									<td colspan="3">
										<input type="text" v-model="customProdus.denumire" />
									</td>
									<td colspan="3">
										<input type="text" v-model="customProdus.dimensiune_text" />
									</td>
									<td class="width5">
										<model-select
											id="umm"
											:options="ums"
											v-model="customProdus.um"
											placeholder="UM"
										></model-select>
									</td>
									<td class="width75">
										<input
											type="number"
											step="0.1"
											value="1"
											placeholder="1"
											class="validate center-align"
											v-model="customProdus.cantitate"
											v-on:input="totalC(index)"
										/>
									</td>
									<td colspan="2"></td>
									<td class="width75">
										<input
											type="number"
											step="0.01"
											value="0.00"
											placeholder="0.00"
											class="validate center-align"
											v-model="customProdus.pret_u"
											v-on:input="totalC(index)"
										/>
									</td>
									<td>
										<input
											type="text"
											class="center-align"
											placeholder="0.00"
											v-model="customProdus.pret_t"
											readonly
										/>
									</td>
									<td
										class="delete right"
										@click="deleteCustomProductForm(index)"
									>
										X
									</td>
								</tr>
								<tr>
									<td colspan="3"></td>
									<td>
										<label for="detalii">Detalii</label>
										<input
											id="detalii"
											type="text"
											class="validate"
											v-model="customProdus.detalii"
										/>
									</td>
									<td colspan="3">
										<label for="data_livrare">Data livrare</label>
										<input
											id="data_livrare"
											type="date"
											class="validate"
											v-model="customProdus.data_livrare"
											placeholder="Data livrare"
										/>
									</td>
									<td colspan="2">
										<label for="data_actualizata"
											>Data livrare actualizata</label
										>
										<input
											id="data_actualizata"
											type="date"
											class="validate"
											v-model="customProdus.data_actualizata"
											placeholder="Data actualizata"
										/>
									</td>
									<td colspan="2">
										<label for="st3">Status livrare</label>
										<model-select
											id="st3"
											:options="st_livrare"
											v-model="customProdus.status_livrare"
										></model-select>
									</td>
									<td colspan="2">
										<label for="furnizor">Furnizor</label>
										<model-select
											id="furnizori"
											:options="furnizori"
											v-model="customProdus.furnizor"
										></model-select>
									</td>
								</tr>
							</tbody>
						</div>
						<tbody
							v-if="comanda.transport_obj != null && comanda.transport_obj.form"
							class="transport-form mtop-20"
						>
							<tr>
								<td colspan="4">
									<input
										type="text"
										class="validate"
										v-model="comanda.transport_obj.name"
									/>
								</td>
								<td colspan="3"></td>
								<td class="center-align">{{ comanda.transport_obj.um }}</td>
								<td class="center-align">
									<input
										type="number"
										class="validate center-align"
										v-model="comanda.transport_obj.cantitate"
									/>
								</td>
								<td colspan="2"></td>
								<td>
									<input
										type="number"
										step="0.01"
										class="validate center-align"
										v-model="comanda.transport_obj.pret"
									/>
								</td>
								<td>
									<input
										type="text"
										class="validate center-align"
										placeholder="0.00"
										v-model="valTransport"
										readonly
									/>
								</td>
								<td class="delete" @click="hideTransportForm()">X</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="row">
					<div class="col s9">
						<br />
						<a
							class="btn blue-grey lighten-2 btn-small"
							@click="addNewProductForm"
							>Adauga produs</a
						>
						<a class="btn blue-grey darken-1" @click="addNewCustomProductForm"
							>Adauga produs custom</a
						>
						<a
							v-if="
								comanda.transport_obj == null || !comanda.transport_obj.form
							"
							class="btn grey darken-1"
							@click="showTransportForm"
							>Adauga cost transport</a
						>
					</div>
					<table class="col s3">
						<tr>
							<td class="center-align bold">Total fara TVA</td>
							<td class="center-align">
								<span>{{ totals }} {{ comanda.moneda }}</span>
							</td>
						</tr>
						<tr>
							<td class="center-align bold">Valoare TVA</td>
							<td class="center-align">
								<span>{{ tax_total }} {{ comanda.moneda }}</span>
							</td>
						</tr>
						<tr>
							<td class="center-align bold">Total cu TVA</td>
							<td class="center-align">
								<span>{{ grand_total() }} {{ comanda.moneda }}</span>
							</td>
						</tr>
					</table>
				</div>
			</div>
			<div class="row footer">
				<div class="card-panel">
					<h6>Detalii suplimentare</h6>
					<textarea
						id="texarea"
						class="materialize-textarea"
						v-model="comanda.obs"
					></textarea>
				</div>
				<div class="col s6">
					<div class="card-panel">
						<h6>Detalii plata</h6>
						<div class="row">
							<div class="col s3">
								<label for="avans">Avans (%)</label>
								<input
									id="avans"
									type="number"
									step="1"
									class="validate center-align"
									v-model="comanda.p_avans"
								/>
								<span>%</span>
							</div>
							<div class="col s3">
								<label for="termen_plata">Termen de plata (zile)</label>
								<input
									id="termen_plata"
									type="number"
									step="1"
									class="validate center-align"
									v-model="comanda.t_plata"
								/>
								<span>zile</span>
							</div>
							<div class="col s5 offset-s1">
								<span class="bold">Valoare avans: </span
								>{{ this.comanda.suma_avans }} {{ comanda.moneda }}<br /><br />
								<span class="bold">Valoare la termen: </span
								>{{ this.comanda.suma_termen }} {{ comanda.moneda }}<br /><br />
								<span class="bold">Termen de plata: </span
								>{{ termen | formatDate }}
							</div>
						</div>
					</div>
				</div>
				<div class="col s6">
					<div class="card-panel">
						<h6>Transport comanda</h6>
						<br />
						<div class="radio-btns">
							<label>
								<input
									name="group1"
									type="radio"
									value="curier"
									v-model="comanda.transport"
								/>
								<span>Curier / Transportator</span>
							</label>
							<label>
								<input
									name="group1"
									type="radio"
									value="regie-proprie"
									v-model="comanda.transport"
								/>
								<span>Regie proprie</span>
							</label>
						</div>
					</div>
				</div>
			</div>
			<div class="btns right">
				<a href="javascript:history.go(-1)" class="btn-large grey">Anuleaza</a>
				<button type="submit" class="btn-large">Salveaza</button>
			</div>
			<!-- <pre>{{$data}}</pre> -->
			<!-- <pre>{{comanda}}</pre> -->
		</form>
	</div>
</template>

<script>
import { ModelSelect } from "vue-search-select";
import moment from "moment";

export default {
	name: "EditComanda",
	data() {
		return {
			st_livrare: [
				{ value: "in asteptare", text: "In asteptare" },
				{ value: "in curs de livrare", text: "In curs de livrare" },
				{ value: "livrare finalizata", text: "Livrare finalizata" },
				{ value: "livrare anulata", text: "Livrare anulata" },
			],
		};
	},
	computed: {
		comanda() {
			return this.$store.getters.comanda;
		},
		materiale() {
			return this.$store.getters.selectMateriale;
		},
		ums() {
			return this.$store.getters.ums;
		},
		denumiri() {
			return this.$store.getters.denumiri;
		},
		arr_benzi() {
			return this.$store.getters.arr_benzi;
		},
		arr_bareP() {
			return this.$store.getters.arr_bareP;
		},
		arr_table() {
			return this.$store.getters.arr_table;
		},
		arr_teviR() {
			return this.$store.getters.arr_teviR;
		},
		arr_teviP() {
			return this.$store.getters.arr_teviP;
		},
		arr_profileLT() {
			return this.$store.getters.arr_profileLT;
		},
		valTransport() {
			if (this.comanda.transport_obj != null) {
				let val =
					this.comanda.transport_obj.cantitate *
					this.comanda.transport_obj.pret;
				this.comanda.transport_obj.val = val;
				return val;
			}
		},
		furnizori() {
			return this.$store.getters.furnizoriVanzari;
		},
		totals: function() {
			let t = 0;
			if (this.comanda.produse_obj) {
				this.comanda.produse_obj.forEach(function(produs) {
					t = t + Number(produs.pret_t);
				});
			}
			if (
				this.comanda.custom_obj != undefined &&
				this.comanda.custom_obj.length > 0
			) {
				this.comanda.custom_obj.forEach(function(produs) {
					t = t + Number(produs.pret_t);
				});
			}
			if (
				this.comanda.transport_obj != null &&
				this.comanda.transport_obj.form
			) {
				t = t + Number(this.valTransport);
			}
			this.comanda.val_fara_tva = t.toFixed(2);
			return this.comanda.val_fara_tva;
		},
		tax_total: function() {
			let t = 0;
			if (this.comanda.produse_obj) {
				this.comanda.produse_obj.forEach(function(produs) {
					t = t + Number(produs.tva);
				});
			}
			if (
				this.comanda.custom_obj != undefined &&
				this.comanda.custom_obj.length > 0
			) {
				this.comanda.custom_obj.forEach(function(produs) {
					t = t + Number(produs.tva);
				});
			}
			if (
				this.comanda.transport_obj != null &&
				this.comanda.transport_obj.form
			) {
				t = t + Number(this.valTransport) * 0.19;
			}
			this.comanda.val_tva = t.toFixed(2);
			return this.comanda.val_tva;
		},
		termen() {
			if (this.comanda) {
				let date = moment(this.comanda.data);
				let data_plata = date
					.add(this.comanda.t_plata, "days")
					.format("YYYY-MM-DD");
				return data_plata;
			}
		},
	},
	watch: {
		"comanda.suma": function() {
			this.avans();
		},
		"comanda.p_avans": function() {
			this.avans();
		},
	},
	methods: {
		dimensiune_text(produs) {
			let str = "";
			if (produs.denumire.value == "Profil U") {
				str = produs.dimH;
			} else {
				str = produs.dimL;
			}
			if (produs.denumire.value == "Profil U") {
				str += " x " + produs.dimL;
			}
			if (produs.dimH) {
				str += " x " + produs.dimH;
			}
			if (produs.dimG) {
				str += " x " + produs.dimG;
			}
			if (str == null || str == "") {
				return "";
			} else {
				if (!produs.dimH && !produs.dimG) {
					str = "D " + str;
				}
				return str + " mm";
			}
		},
		avans() {
			this.comanda.suma_avans = (
				(this.comanda.suma * this.comanda.p_avans) /
				100
			).toFixed(2);
			this.comanda.suma_termen = (
				this.comanda.suma - this.comanda.suma_avans
			).toFixed(2);
		},
		setAliaje(index) {
			let material = this.comanda.produse_obj[index].material;
			this.comanda.produse_obj[index].aliaje = this.$store.getters.selectAliaje(
				material
			);
		},
		setUm(index) {
			let list = [];
			if (
				this.arr_benzi.includes(this.comanda.produse_obj[index].denumire.value)
			) {
				let arr = ["m2", "ml", "kg"];
				list = this.ums.filter((um) => {
					return arr.includes(um.value);
				});
			} else if (
				this.arr_table.includes(this.comanda.produse_obj[index].denumire.value)
			) {
				let arr = ["m2", "buc", "kg"];
				list = this.ums.filter((um) => {
					return arr.includes(um.value);
				});
			} else if (
				this.arr_bareP.includes(
					this.comanda.produse_obj[index].denumire.value
				) ||
				this.arr_teviR.includes(
					this.comanda.produse_obj[index].denumire.value
				) ||
				this.arr_teviP.includes(
					this.comanda.produse_obj[index].denumire.value
				) ||
				this.arr_profileLT.includes(
					this.comanda.produse_obj[index].denumire.value
				) ||
				this.comanda.produse_obj[index].denumire.value == "Platbanda" ||
				this.comanda.produse_obj[index].denumire.value == "Bara rotunda" ||
				this.comanda.produse_obj[index].denumire.value == "Bara hexagonala" ||
				this.comanda.produse_obj[index].denumire.value == "Profil U"
			) {
				let arr = ["ml", "kg"];
				list = this.ums.filter((um) => {
					return arr.includes(um.value);
				});
			} else {
				list = this.ums;
			}
			this.comanda.produse_obj[index].ums = list;
		},
		setValoriProduse(index) {
			let currentProduct = this.comanda.produse_obj[index];
			let currentAliaj = currentProduct.aliaje.find(
				(aliaj) => aliaj.value === currentProduct.aliaj
			);
			this.comanda.produse_obj[index].gs = currentAliaj.gs;
			this.comanda.produse_obj[index].pret = currentAliaj.pret;
		},
		grand_total() {
			let t = Number(this.comanda.val_fara_tva) + Number(this.comanda.val_tva);
			this.comanda.suma = t.toFixed(2);
			return this.comanda.suma;
		},
		totalP(index) {
			let currentProduct = this.comanda.produse_obj[index];
			let dimL = currentProduct.dimL;
			let dimH = currentProduct.dimH;
			let dimG = currentProduct.dimG;

			var res;

			if (currentProduct.gs != "") {
				let gs = currentProduct.gs;

				if (this.arr_benzi.includes(currentProduct.denumire.value)) {
					if (currentProduct.um == "m2") {
						res = (1000 * dimG * gs) / 1000;
					} else {
						res = (dimL * dimG * gs) / 1000;
					}
				} else if (this.arr_bareP.includes(currentProduct.denumire.value)) {
					res = (dimL * dimH * gs) / 1000;
				} else if (currentProduct.denumire.value === "Platbanda") {
					res = (dimL * dimG * gs) / 1000;
				} else if (currentProduct.denumire.value === "Bara rotunda") {
					res = (0.785 * dimL * dimL * gs) / 1000;
				} else if (currentProduct.denumire.value === "Bara hexagonala") {
					res = (0.866 * dimL * dimL * gs) / 1000;
				} else if (this.arr_table.includes(currentProduct.denumire.value)) {
					if (currentProduct.um == "m2") {
						res = 1000 * gs * (dimG / 1000);
					} else {
						res = ((dimL * dimH) / 1000) * gs * (dimG / 1000);
					}
				} else if (this.arr_teviR.includes(currentProduct.denumire.value)) {
					res = 0.785 * (4 * dimL * dimG - 4 * dimG * dimG) * (gs / 1000);
				} else if (this.arr_teviP.includes(currentProduct.denumire.value)) {
					res = ((2 * dimL + 2 * dimH - 4 * dimG) / 1000) * dimG * gs;
				} else if (this.arr_profileLT.includes(currentProduct.denumire.value)) {
					res = ((dimL + dimH - dimG) / 1000) * dimG * gs;
				} else if (currentProduct.denumire.value === "Profil U") {
					res = ((dimL + 2 * dimH - 2 * dimG) / 1000) * dimG * gs;
				}
			} else {
				res = currentProduct.gspec;
			}

			var weight;
			if (currentProduct.um == "kg") {
				weight = Number(currentProduct.cantitate);
			} else {
				weight = Number(currentProduct.cantitate) * res;
			}

			let umPrice =
				(weight.toFixed(2) * Number(currentProduct.pret_kg)) /
				Number(currentProduct.cantitate);
			let total = Number(currentProduct.cantitate) * umPrice.toFixed(2);
			let tax = total * 0.19;

			this.comanda.produse_obj[index].dimensiune = this.dimensiune_text(
				this.comanda.produse_obj[index]
			);
			// this.isModified(this.comanda.produse_obj[index]);

			this.comanda.produse_obj[index].greutate = weight.toFixed(2);
			this.comanda.produse_obj[index].pret_t = total.toFixed(2);
			this.comanda.produse_obj[index].pret_u = umPrice.toFixed(2);
			this.comanda.produse_obj[index].tva = tax.toFixed(2);
		},
		dimString(denumire, dimL, dimH, dimG) {
			let str = "";

			if (this.arr_table.includes(denumire)) {
				str = dimG + " x " + dimL + " x " + dimH + " mm";
			} else if (this.arr_benzi.includes(denumire)) {
				str = dimG + " x " + dimL + " mm";
			} else if (this.arr_bareP.includes(denumire)) {
				str = dimH + " x " + dimL + " mm";
			} else if (
				this.arr_teviR.includes(denumire) ||
				denumire === "Platbanda"
			) {
				str = dimL + " x " + dimG + " mm";
			} else if (
				this.arr_teviP.includes(denumire) ||
				this.arr_profileLT.includes(denumire) ||
				denumire === "Profil U"
			) {
				str = dimL + " x " + dimH + " x " + dimG + " mm";
			} else if (
				denumire === "Bara rotunda" ||
				denumire === "Bara hexagonala"
			) {
				str = "D " + dimL + " mm";
			}

			return str;
		},
		totalC(index) {
			if (
				this.comanda.custom_obj != undefined &&
				this.comanda.custom_obj.length > 0
			) {
				let total =
					Number(this.comanda.custom_obj[index].cantitate) *
					Number(this.comanda.custom_obj[index].pret_u).toFixed(2);
				let tax = total * 0.19;

				this.comanda.custom_obj[index].pret_t = total.toFixed(2);
				this.comanda.custom_obj[index].tva = tax.toFixed(2);
			}
		},
		addNewProductForm() {
			let nr = this.comanda.produse_obj.length + 1;
			this.comanda.produse_obj.push({
				nr: "" + nr + "",
				material: "",
				denumire: {},
				aliaj: "",
				gs: "",
				gspec: "",
				detalii: "",
				dimL: null,
				dimH: null,
				dimG: null,
				ums: [],
				um: "",
				cantitate: "",
				greutate: "",
				pret: "",
				pret_kg: "",
				pret_t: "",
				pret_u: "",
				tva: "",
				data_livrare: "",
				data_actualizata: "",
				status_livrare: "in asteptare",
				t_livrare: "",
				aliaje: [],
			});
		},
		deleteProductForm(index) {
			if (this.comanda.produse_obj.length > 1) {
				this.comanda.produse_obj.splice(index, 1);
			}
		},
		addNewCustomProductForm() {
			let nr =
				Number(this.comanda.produse_obj.length) +
				Number(this.comanda.custom_obj.length) +
				1;
			this.comanda.custom_obj.push({
				nr: "" + nr + "",
				denumire: "",
				dimensiune_text: "",
				um: "",
				cantitate: "",
				pret_t: "",
				pret_u: "",
				tva: "",
				detalii: "",
				data_livrare: "",
				data_actualizata: "",
				status_livrare: "in asteptare",
				furnizor: "",
			});
		},
		deleteCustomProductForm(index) {
			this.comanda.custom_obj.splice(index, 1);
		},
		showTransportForm() {
			if (this.comanda.transport_obj == null) {
				this.comanda.transport_obj = {
					form: true,
					name: "Transport",
					um: "buc",
					cantitate: 1,
					pret: 0,
					val: 0,
				};
			}
		},
		hideTransportForm() {
			this.comanda.transport_obj = null;
		},
		fetchComanda(id) {
			this.$store.dispatch("fetchComanda", id);
		},
		fetchMateriale() {
			this.$store.dispatch("fetchMateriale");
		},
		fetchListaProduse() {
			this.$store.dispatch("fetchListaProduse");
		},
		fetchAliaje() {
			this.$store.dispatch("fetchAliaje");
		},
		finalizareComanda() {
			let final = true;
			this.comanda.produse_obj.forEach(function(produs) {
				if (
					produs.status_livrare === "in asteptare" ||
					produs.status_livrare === "in curs de livrare"
				) {
					final = false;
				}
			});
			if (final === true) {
				this.comanda.stare = "finalizata";
			} else {
				this.comanda.stare = "activa";
			}
		},
		updateComanda(e) {
			this.finalizareComanda();

			if (!this.comanda.produse_obj || !this.comanda.moneda) {
				M.toast({ html: "Completati toate campurile!" });
			} else {
				let updComanda = {
					stare: this.comanda.stare,
					moneda: this.comanda.moneda,
					suma: this.comanda.suma,
					val_fara_tva: this.comanda.val_fara_tva,
					val_tva: this.comanda.val_tva,
					p_avans: this.comanda.p_avans,
					suma_avans: this.comanda.suma_avans,
					suma_termen: this.comanda.suma_termen,
					t_plata: this.comanda.t_plata,
					transport: this.comanda.transport,
					obs: this.comanda.obs,
					produse_obj: JSON.stringify(this.comanda.produse_obj),
					custom_obj: JSON.stringify(this.comanda.custom_obj),
					transport_obj: JSON.stringify(this.comanda.transport_obj),
				};

				let id = this.$route.params.id;
				this.$store.dispatch("updComanda", { object: updComanda, id: id });

				e.preventDefault();
			}
			e.preventDefault();
		},
	},
	components: {
		ModelSelect,
	},
	created: function() {
		this.fetchComanda(this.$route.params.id);
		this.fetchMateriale();
		this.fetchListaProduse();
		this.fetchAliaje();
	},
	updated: function() {
		M.updateTextFields();
		M.FormSelect.init(document.querySelectorAll("select"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input-field.obs {
	margin-top: 30px;
}
table.produse tbody tr {
	border: none;
}
td.delete {
	cursor: pointer;
	color: #e65100;
}
.td_number span {
	font-size: 16px;
	padding: 8px 30px;
	border-bottom: 1px solid #9e9e9e;
}
table input:read-only {
	background-color: #f2f4f8;
}
.radio-btns {
	margin-bottom: 12px;
}
</style>
