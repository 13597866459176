<template>
  <div class="container">
    <router-link class="btn blue-grey lighten-2 back-btn left" to="/debitare">Inapoi</router-link>
    <div class="btns right">
    </div>
    <h4>Lista Debitari</h4>
    <div class="row">
      <div class="input-field filter-input col s7" v-if="debitari.length > 0">
        <input placeholder="Cauta debitare dupa comanda" v-model="filterInput">
      </div>
    </div>
    <div class="card-panel">
      <table class="highlight responsive-table">
        <thead v-if="debitari.length > 0">
          <tr>
            <th class="width3">Nr.</th>
            <th class="width15">Data debitare</th>
            <th>Profil</th>
            <th>Comanda</th>
            <th>Cota livrata</th>
            <th>Cota debitata</th>
            <th>Cantitate</th>
            <th>Scrap bucati</th>
            <th>Scrap</th>
          </tr>
        </thead>
        <thead v-else>
          <p class="bold">Nu exista materiale in stoc</p>
        </thead>
        <tbody>
          <tr v-for="debitare, index in filterBy(debitari, filterInput)">
            <td>{{index + 1}}</td>
            <td>{{debitare.data_deb | formatDate}}</td>
            <td>{{debitare.profil_tip }}</td>
            <td>{{debitare.comanda_obj.nr+' | '+debitare.comanda_obj.pos+' | '+debitare.comanda_obj.subpos}}</td>
            <td>{{debitare.cota_livrata}} mm</td>
            <td>{{debitare.lungime_finala}} mm</td>
            <td>{{debitare.cantitate}} buc</td>
            <td v-if='debitare.buc_scrap == 0'>0</td>
            <td v-else>{{debitare.buc_scrap}} buc x {{debitare.lungime_finala}} mm</td>
            <td>{{debitare.scrap}} buc x {{debitare.cota_scrap}} mm</td>
            <td><a class="modal-trigger delete-icon" href="#deletedebitare" v-on:click="markDebitareDelete(debitare.id_debitare)"><i class="material-icons right grey-text">delete_forever</i></a></td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--  Modal begin -->
    <div id="deletedebitare" class="modal modal-delete modal-del-debitare">
      <div class="modal-content">
        <h6>Sterge definitiv debitarea?</h6><br>
        <button class="btn red" v-on:click="deleteDebitare(selectdelDebitare)">Sterge</button>
        <a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
      </div>
    </div>
    <div class="row">
      <div class="col s6">
        <!-- <pre>{{debitari}}</pre> -->
      </div>
      <div class="col s6">
        <!-- <pre>{{listaDeb}}</pre> -->
      </div>
    </div>
    <!--  Modal end -->
    <!-- <pre>{{$data}}</pre> -->
  </div>
</template>

<script>

export default {
  name: 'ListaDebitari',
  data () {
    return {
      filterInput: '',
      selectdelDebitare: '',
    }
  },
  computed: {
    debitari() {
      return this.$store.getters.listaDebitari
    }
    // listaDeb() {
    //   return this.$store.getters.listaDebitari
    // }
  },
  methods: {
    filterBy(list, value){
      value = value.charAt(0).toLowerCase() + value.slice(1);
      return list.filter(function(debitare){
        return debitare.comanda_obj.nr.toLowerCase().indexOf(value) > -1;
      });
    },
    markDebitareDelete(id) {
      this.selectdelDebitare = id
    },
    fetchDebitari() {
      this.$store.dispatch('fetchDebitari')
    },
    deleteDebitare(id) {
      this.$store.dispatch('deleteDebitare', id)
      this.selectdelDebitare = ''
    }
  },
  created: function() {
    this.fetchDebitari()
  },
  mounted: function() {
    M.Modal.init(document.querySelector('.modal-del-debitare'));
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.delete-icon i:hover {
    color: red !important;
}
</style>
