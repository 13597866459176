<template>
	<div class="flex flex-col lg:flex-row -mx-4 2xl:mx-0">
		<div class="h-fit login lg:h-screen w-full lg:w-1/3">
			<div class="bg-white h-full flex flex-col justify-center text-center p-8">
				<div class="py-4">
					<h4 class="text-4xl">Login</h4>
				</div>
				<form class="py-4">
					<div class="input-field">
						<i class="material-icons prefix">email</i>
						<input type="text" id="email" v-model="email" />
						<label for="email">Email</label>
					</div>
					<div class="input-field">
						<i class="material-icons prefix">lock</i>
						<input type="password" id="password" v-model="password" v-on:keyup.enter="signIn" />
						<label for="password">Password</label>
					</div>
				</form>
				<button class="btn btn-large" v-on:click="signIn">Login</button>
			</div>
		</div>
		<div class="flex justify-center items-center w-full lg:w-2/3">
			<div class="max-w-2xl bg-white rounded-xl shadow overflow-hidden">
				<img src="/afir.jpg">
			</div>
		</div>
	</div>
</template>

<script>
import firebase from "firebase/app";

export default {
	name: "Login",
	data() {
		return {
			email: "",
			password: "",
		};
	},
	methods: {
		signIn(e) {
			firebase
				.auth()
				.signInWithEmailAndPassword(this.email, this.password)
				.then(
					() => {
						this.$router.go({ path: this.$router.path });
					},
					(err) => {
						alert(err.message);
					}
				);
			e.preventDefault();
		},
	},
};
</script>

<style scoped>
.login {
	max-width: 516px;
	height: calc(100svh - 64px);
}
</style>
