<template>
    <div>
        <span :class="'text-' + statusColor">
            {{ returnDate | formatDate }}
        </span>
    </div>
</template>

<script>
import moment from "moment";
// import parseISO from 'date-fns/parseISO'
// import { compareAsc } from "date-fns";
// import { addDays } from "date-fns";

export default {
    props: {
        colored: Boolean,
        returnDate: String,
        today: String
    },
    data() {
        return {
            // today: new Date().toISOString().substring(0, 10)
        }
    },
    computed: {
        statusColor() {
            const returnDate = moment(this.returnDate)
            let color = 'black'

            if (this.colored && returnDate.isBefore(moment(this.today))) {
                color = 'red-500 font-medium'
            }
            if (this.colored && returnDate.isSame(moment(this.today), 'day')) {
                color = 'yellow-500 font-medium'
            }
            return color
        }
    },
    methods: {

    }
}
</script>