<template>
	<div :class="{ container: !noTitle }">
		<h4 v-if="!noTitle" class="text-4xl text-center my-4">
			Programare Lucrari Productie
		</h4>
		<div :class="{ 'card-panel': !noTitle }">
			<FullCalendar
				ref="fullCalendar"
				defaultView="dayGridMonth"
				eventColor="#e8eaf6"
				eventTextColor="#000000"
				:plugins="calendarPlugins"
				:buttonText="calendarButtonText"
				:locale="calendarLocale"
				:hiddenDays="calendarHiddenDays"
				:events="calendarEvents"
				:eventRender="eventRender"
				:eventMouseEnter="hoverEvent"
			/>
		</div>
		<!-- <pre>{{calendarEvents}}</pre> -->
	</div>
</template>

<script>
import firebase from "firebase/app";
import moment from "moment";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";

export default {
	name: "CalendarProductie",
	data() {
		return {
			user_email: firebase.auth().currentUser.email,
			today: moment(),
			calendarPlugins: [dayGridPlugin],
			calendarButtonText: {
				today: "astazi",
			},
			calendarLocale: "ro",
			calendarHiddenDays: [0, 6],
		};
	},
	props: ["noTitle"],
	computed: {
		calendarEvents() {
			return this.$store.getters.calendarEvents;
		},
	},
	methods: {
		hoverEvent(arg) {
			alert(arg.event.title);
		},
		eventRender(info) {
			let team = info.event.extendedProps.echipa;
			let parentDiv = info.el.children[0];
			if (team != null) {
				// Add empty div for team display
				parentDiv.insertAdjacentHTML("beforeend", '<div class="team"></div>');
				let teamDiv = parentDiv.querySelector(".team");
				// Add each person to team div
				team.forEach(function(person) {
					teamDiv.insertAdjacentHTML(
						"beforeend",
						`<div class="inline-div"><span class="dot ${person.color}"></span>${person.name}</div>`
					);
				});
			}
		},
	},
	components: {
		FullCalendar,
	},
	created: function() {
		this.$store.dispatch("fetchCalendarLucrari");
	},
	// mounted: function() {

	// }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="css">
.fc-event-time,
.fc-event-title {
	padding: 0 1px;
	white-space: nowrap;
}
.fc-title {
	white-space: normal;
}
.fc-day-grid-event .fc-content {
	white-space: normal;
}
div.team {
	display: inline-block;
}
span.dot {
	border-radius: 100px;
	width: 10px;
	height: 10px;
	display: inline-block;
	margin: 0 5px 0 15px;
}
</style>
