<template>
	<div>
		<v-menu offset-y>
			<template v-slot:activator="{ on, attrs }">
				<v-btn color="green darken-2" class="uppercase" dark v-bind="attrs" v-on="on">
					<v-icon>mdi-currency-usd</v-icon>
					Facturi
				</v-btn>
			</template>
			<v-list>
				<v-list-item link @click="visibleUpfrontInvoiceModal = true">
					<v-icon class="mr-1">mdi-invoice-send-outline</v-icon>
					Factura avans
				</v-list-item>
				<v-list-item link @click="visibleProformaInvoiceModal = true">
					<v-icon class="mr-1">mdi-invoice-text-check-outline</v-icon>
					Factura proforma
				</v-list-item>
			</v-list>
		</v-menu>
		<v-dialog v-model="visibleUpfrontInvoiceModal" max-width="600px">
			<v-card class="rounded">
				<v-form ref="generateInvoiceForm" v-model="validInvoiceForm" @submit.prevent="sendToAccounting">
					<v-card-title>Solicita factura fiscala</v-card-title>
					<v-card-subtitle>Trimite oferta catre contabilitate pentru facturare</v-card-subtitle>
					<v-card-text>
						<!-- <p v-if="oferta.status_factura_avans" class="cyan white-text alert-msg"> -->

						<div v-if="statusUpfrontInvoice" class="flex flex-row justify-start items-center gap-2">
							<span class="font-semibold">Trimis la:</span>
							<v-chip color="green" dark>
								<span class="font-semibold">{{ statusUpfrontInvoice | formatDateTime | NoData
									}}</span>
							</v-chip>
						</div>
						<div>
							<v-radio-group row v-model="typeInvoice">
								<template v-slot:label>
									<div class="mr-2 font-semibold">Tip factura</div>
								</template>
								<v-radio value="avans" label="Avans" color="teal"></v-radio>
								<v-radio value="finala" label="Finala" color="teal"></v-radio>
							</v-radio-group>
						</div>
						<div class="mb-4">
							<v-radio-group v-if="typeInvoice == 'finala'" row v-model="upfrontInvoice"
								:rules="[rules.requiredValue]">
								<template v-slot:label>
									<div class="mr-2 font-semibold">Exista factura avans?</div>
								</template>
								<v-radio :value="false" label="Nu" color="teal"></v-radio>
								<v-radio :value="true" label="Da" color="teal"></v-radio>
							</v-radio-group>
						</div>
						<div class="mb-4">
							<v-text-field v-model="obs" label="Observatii" outlined></v-text-field>
						</div>
					</v-card-text>
					<div class="flex flex-row justify-end gap-2 pr-4 pb-4">
						<v-btn color="blue-grey" class="uppercase" dark
							@click="visibleUpfrontInvoiceModal = false">Anuleaza</v-btn>
						<v-btn color="teal" dark class="uppercase" type="submit">Trimite</v-btn>
					</div>
				</v-form>
			</v-card>
		</v-dialog>
		<v-dialog v-model="visibleProformaInvoiceModal" max-width="500px">
			<v-card class="rounded">
				<v-form ref="generateProformaForm" v-model="validProformaForm" @submit.prevent="generateProforma">
					<v-card-title>
						<v-alert v-if="proformaDate" text dense type="info">
							Factura proforma a fost deja generata
						</v-alert>
						<div v-else>
							Genereaza factura proforma
						</div>
					</v-card-title>
					<v-card-text v-if="proformaDate">
						<div class="text-lg flex gap-4 mb-4">
							<div class="text-lg flex justofy-start items-center gap-1 flex-grow-0">
								<span>Ultima generare:</span>
								<span class="font-semibold">{{ proformaDate | formatDate | NoData }}</span>
							</div>
							<v-btn color="teal" dark text @click="$emit('updateProforma')">
								<v-icon class="mr-1">mdi-invoice-text-check</v-icon>
								Genereaza din nou
							</v-btn>
						</div>
						<v-alert v-if="!clientEmail" dense text type="error" icon="mdi-email">
							Clientul nu are adresa de email completata!
						</v-alert>
					</v-card-text>
					<v-card-text v-else>
						<div class="flex flex-col">
							<v-label class="mb-3">Procent valoare proforma</v-label>
							<v-text-field v-model="proforma_percent" :rules="[rules.required, rules.percent]" outlined
								class="w-36" suffix="%"></v-text-field>
						</div>
						<div class="text-lg flex flex-row items-center mb-2">
							<v-label class="mr-2">Suma:</v-label>
							<div>
								<span class="font-semibold">{{ proforma_value.toFixed(2) }} ron</span> din totalul
								de <span class="font-semibold">{{ ofertaGrandTotal }} ron</span>
							</div>
						</div>
					</v-card-text>
					<div v-if="proformaDate" class="flex flex-row justify-start gap-2 px-4 pb-4">
						<v-btn color="blue-grey" class="uppercase" dark
							@click="visibleProformaInvoiceModal = false">Anuleaza</v-btn>
						<v-btn color="blue" class="uppercase" dark @click="$emit('downloadProforma')">
							<v-icon class="mr-1">mdi-download</v-icon>
							Descarca PDF
						</v-btn>
						<v-btn color="teal" dark class="uppercase" :disabled="!clientEmail"
							@click="$emit('emailProforma')">
							<v-icon class="mr-1">mdi-email</v-icon>
							Trimite PDF
						</v-btn>
					</div>
					<div v-else class="flex flex-row justify-start gap-2 px-4 pb-4">
						<v-btn color="blue-grey" class="uppercase" dark
							@click="visibleProformaInvoiceModal = false">Anuleaza</v-btn>
						<v-btn color="teal" dark class="uppercase" type="submit">
							<v-icon class="mr-1">mdi-invoice-text-check</v-icon>
							Genereaza
						</v-btn>
					</div>
				</v-form>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>

export default {
	props: {
		proformaDate: String,
		clientEmail: String,
		ofertaGrandTotal: String,
		statusUpfrontInvoice: String
	},
	data() {
		return {
			visibleUpfrontInvoiceModal: false,
			typeInvoice: "avans",
			upfrontInvoice: null,
			invoiceMsg: "",
			obs: "avans 100%",
			validInvoiceForm: false,
			visibleProformaInvoiceModal: false,
			validProformaForm: false,
			proforma_percent: 100,
			rules: {
				required: value => !!value || 'Câmp obligatoriu',
				percent: value => !value || /^100$|^[1-9]([0-9])?$/.test(value) || 'Procent invalid',
				requiredValue: value => value != null || 'Câmp obligatoriu'
			}
		};
	},
	components: {},
	computed: {
		proforma_value() {
			return Number(this.ofertaGrandTotal) * (this.proforma_percent / 100);
		}
	},
	watch: {
		typeInvoice(value) {
			if (value == "finala") {
				this.obs = "100%";
			} else if (value == "avans") {
				this.upfrontInvoice = null;
				this.obs = "avans 100%";
			}
		},
		upfrontInvoice(value) {
			if (value) {
				this.invoiceMsg = "Este factura de avans";
			} else if (!value) {
				this.invoiceMsg = "NU este factura de avans";
			} else {
				this.invoiceMsg = "";
			}
		},
	},
	methods: {
		async sendToAccounting() {
			const validInvoice = await this.$refs.generateInvoiceForm.validate();
			if (validInvoice) {
				const newInvoice = {
					typeInvoice: this.typeInvoice,
					upfrontInvoice: this.upfrontInvoice,
					invoiceMsg: this.invoiceMsg,
					obs: "avans 100%",
				}
				this.$emit('sendAccountingEmail', newInvoice)
				this.visibleUpfrontInvoiceModal = false
			}
		},
		async generateProforma() {
			const validPercent = await this.$refs.generateProformaForm.validate();
			if (validPercent) {
				this.$emit('generateProforma', this.proforma_percent)
				this.visibleProformaInvoiceModal = false
			} else {
				alert('Verificati procentul introdus')
			}
		}
	},
	created() { },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
