<template>
	<div class="container">
		<form v-on:submit="addProfil">
			<h5 class="header">Adauga profil nou</h5>
			<div class="card-panel">
				<div class="row">
					<div class="input-field col s2">
						<input id="tip" type="text" class="validate" v-model="profil.tip" />
						<label for="tip">Denumire profil</label>
					</div>
					<div class="input-field col s2">
						<input
							id="greutateML"
							type="number"
							step=".01"
							class="validate"
							v-model="profil.greutate_ml"
						/>
						<label for="greutateML">Greutate / ML (g)</label>
					</div>
					<div class="input-field col s2">
						<input
							id="bucpac"
							type="number"
							class="validate"
							v-model="profil.bucpac"
						/>
						<label for="bucpac">Buc / pachet</label>
					</div>
				</div>
				<div class="row">
					<div class="col s4">
						<h6>Adauga cota</h6>
						<div class="input-field col s6">
							<input
								id="cota"
								type="text"
								class="validate"
								v-model="new_cota.cota"
							/>
							<label for="cota">cota (mm)</label>
						</div>
						<div class="input-field col s5">
							<input
								id="pret"
								type="number"
								step="0.01"
								class="validate"
								v-model="new_cota.pret"
							/>
							<label for="pret">pret (eur)</label>
						</div>
						<div class="input-field col s1">
							<span
								><button class="btn" @click.prevent="addCota">+</button></span
							>
						</div>
					</div>
					<ul class="cote col s7 offset-s1">
						<h6>Cote:</h6>
						<span v-if="cote === undefined || cote.length == 0"
							>Adaugati cote</span
						>
						<li v-for="(item, index) in cote">
							{{ item.cota }} mm - {{ item.pret }} eur
							<span class="delete" @click.prevent="deleteCota(index)">X</span>
						</li>
					</ul>
				</div>
			</div>
			<div class="btns right">
				<a
					href="javascript:history.go(-1)"
					class="btn-large blue-grey lighten-2"
					>Anuleaza</a
				>
				<button type="submit" class="btn-large">Salveaza</button>
			</div>
			<!-- <pre>{{$data}}</pre> -->
		</form>
	</div>
</template>

<script>
export default {
	name: "AddProfil",
	data() {
		return {
			profil: {},
			cote: [],
			new_cota: {},
		};
	},
	methods: {
		addCota() {
			if (this.new_cota.cota !== "" || this.new_cota.pret !== "") {
				this.cote.push(this.new_cota);
				function sortNumber(a, b) {
					return a.cota - b.cota;
				}
				this.cote.sort(sortNumber);
				this.new_cota = {};
			}
		},
		deleteCota(i) {
			this.cote.splice(i, 1);
		},
		addProfil(e) {
			if (
				!this.profil.tip ||
				!this.cote ||
				!this.profil.greutate_ml ||
				!this.profil.bucpac
			) {
				M.toast({ html: "Completati toate campurile!" });
			} else {
				let newProfil = {
					profil_tip: this.profil.tip,
					profil_cote: JSON.stringify(this.cote),
					profil_greutate_ml: this.profil.greutate_ml,
					profil_bucpac: this.profil.bucpac,
				};
				this.$store.dispatch("addProfil", newProfil);
				this.$router.push({ path: "/debitare/profile" });
				e.preventDefault();
			}
			e.preventDefault();
		},
	},
	components: {},
	mounted: function() {
		// M.FormSelect.init(document.querySelectorAll('select'));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul.cote {
	display: block;
	margin: 0;
}
ul.cote span {
	color: #aaaaaa;
	font-size: 12px;
}
ul li {
	display: inline-block;
	background-color: #f1f1f1;
	border-radius: 4px;
	margin: 0 10px 10px 0;
	padding: 2px 10px;
}
li span.delete {
	cursor: pointer;
	margin-left: 10px;
	color: #e65100;
}
</style>
