<template>
  <div class="container">
    <h5 v-if="isNull" class="header">Adauga manopera</h5>
    <h5 v-else class="header">Editeaza manopera</h5>
    <EditManopera v-if="currentOferta" :manopera_obj="isNull ? manopera : currentOferta.manopera_obj" :tip="'oferta'" :id="currentOferta.id_oferta"></EditManopera>
    <!-- <pre>{{currentOferta}}</pre> -->
  </div>
</template>

<script>
import EditManopera from "@/components/Productie/EditManopera";

export default {
  name: "OfertaManopera",
  data() {
    return {
      manopera: [
        {
          nr: "1",
          tip: "",
          oameni: "",
          ore: "",
          pret: "",
          um: "",
          pret_v: "",
          marja: "",
          pret_t: ""
        }
      ]
    };
  },
  components: {
    EditManopera
  },
  computed: {
    currentOferta() {
      return this.$store.getters.currentOfertaProductie;
    },
    isNull() {
      if (this.currentOferta.manopera_obj) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {},
  created: function() {
    this.$store.dispatch("fetchOfertaProductie", this.$route.params.id);
  },
  mounted: function() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
