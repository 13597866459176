export const languages = {
    ro: {
        header: {
            company: "Cuprolli Manufacturing srl",
            vatIdLabel: "CUI",
            vatId: "RO 35671874",
            regComLabel: "RegCom",
            regCom: "J35/468/2016",
            addressLabel: "Adresa",
            address1: "Strada Caprioarei nr.11",
            address2: "307200 Ghiroda, jud.Timis",
            address3: "Romania",
            email: "office@i-struct.ro",
            webLabel: "Web",
            web1: "https://i-struct.ro",
            web2: "https://garduri-aluminiu.ro"
        },
        cifLabel: "CUI/CIF:",
        emailLabel: "Email",
        phoneLabel: "Tel",
        toLabel: "Catre",
        byLabel: "Intocmit de",
        noLabel: "Nr",
        offerTitle: "OFERTA DE PRET",
        quoteTitle: "DEVIZ",
        imgDisclaimer: "Poza are caracter exemplificativ",
        moduleLabels: {
            piece: "buc.",
            dimensions: "Dimensiuni (l x h)",
            area: "Suprafata",
            slatWidth: "Latime profil",
            noProfiles: "Nr. profile",
            slatDistance: "Distanta intre profile",
            pedestrianGateWidth: "Latime poarta pietonala",
            priceArea: "Pret / m²",
            priceSingle: "Pret unitar",
            priceTotal: "Valoare totala"
        },
        totalsLabels: {
            currency: "euro",
            total: "TOTAL",
            vatIncluded: "TVA inclus",
            color: "CULOARE",
            montageIncluded: "MONTAJ INCLUS"
        },
        features: {
            featuresTitle: "CARACTERISTICI",
            projectsTitle: "PROIECTE REALIZATE",
            projectsLink: "https://garduri-aluminiu.ro/proiecte-realizate",
            list: ["- profile din aluminiu", "- vopsit in camp electrostatic", "- vopsea aplicata termic", "- prindere mecanica cu popnituri vopsite electrostatic", "- fara sudura"]
        },
        extraDetails: {
            title: "Detalii suplimentare",
            payment: "Conditii plata",
            upfrontPayment: "avans",
            extraPayment: "la masuratori",
            afterPayment: "dupa finalizarea montajului",
            execution: "Timp executie",
            days: "zile",
            years: "ani",
            warranty: "Garantie lucrare",
            validOffer: "Valabilitate oferta",
            montageDate: "Programare lucrare",
            finalDate: "Finalizare lucrare",
            paymentDisclaimer: "* Valoarea finala a facturii va fi calculata in lei la cursul de schimb al Bancii Transilvania din data facturarii."
        },
        nextPage: "Continuare pe pagina urmatoare"
    },
    at: {
        header: {
            company: "Cuprolli Manufacturing srl",
            vatIdLabel: "USt-IdNr",
            vatId: "35671874",
            regComLabel: "Handelsregister",
            regCom: "J35/468/2016",
            addressLabel: "Adresse",
            address1: "Strada Caprioarei nr.11",
            address2: "307200 Ghiroda, jud.Timis",
            address3: "Rumänien",
            email: "export@i-struct.eu",
            webLabel: "Web",
            web1: "https://i-struct.at",
            web2: ""
        },
        cifLabel: "USt-IdNr",
        emailLabel: "E-Mail",
        phoneLabel: "Tel",
        toLabel: "An",
        byLabel: "Vorgestellt von",
        noLabel: "Nr",
        offerTitle: "PREISANGEBOT",
        quoteTitle: "VORANSCHLAG",
        imgDisclaimer: "Das Foto dient nur zur Veranschaulichung",
        moduleLabels: {
            piece: "St.",
            dimensions: "Abmessungen (B x H)",
            area: "Fläche",
            slatWidth: "Profilbreite",
            noProfiles: "Anzahl Profile",
            slatDistance: "Abstand zwischen den Profilen",
            pedestrianGateWidth: "Breite des Fußgängertors",
            priceArea: "Preis / m²",
            priceSingle: "Einheitspreis",
            priceTotal: "Gesamtbetrag"
        },
        totalsLabels: {
            currency: "€",
            total: "GESAMT",
            vatIncluded: "Inklusive MwSt.",
            color: "FARBE",
            montageIncluded: "INKLUSIVE MONTAGE"
        },
        features: {
            featuresTitle: "MERKMALE",
            projectsTitle: "ABGESCHLOSSENE PROJEKTE",
            projectsLink: "https://i-struct.at/projekte",
            list: ["- Alluminiumprofile", "- Elektrostatische Lackierung", "- Thermisch applizierte Farbe", "- Mechanische Befestigung", "- Ohne Schweißen"]
        },
        extraDetails: {
            title: "Weitere Einzelheiten",
            payment: "Zahlungsbedingungen",
            upfrontPayment: "Anzahlung",
            extraPayment: "zu den Messungen",
            afterPayment: "nach Abschluss der montage.",
            execution: "Ausführungszeit",
            days: "Tage",
            years: "Jahre",
            warranty: "Arbeitsgarantie",
            validOffer: "Gültigkeit des Angebots",
            montageDate: "Arbeitsprogrammierung",
            finalDate: "Fertigstellung der Arbeiten",
            paymentDisclaimer: ""
        },
        nextPage: "Fortsetzung auf nächster Seite",
    },
    en: {

    }
}