<template>
	<div class="container">
		<form v-on:submit.prevent="addDebitare">
			<div class="document-header row">
				<h5 class="header col s5">Adauga debitare</h5>
				<div class="col s3 offset-s4">
					<div class="input-field">
						<input id="data" type="date" class="validate" v-model="today" />
						<label for="data">Data</label>
					</div>
				</div>
				<div v-if="!isEmpty(comanda)">
					<p>
						Comanda:
						<span class="bold"
							>{{ comanda.comanda_obj.nr }} | {{ comanda.comanda_obj.pos }} |
							{{ comanda.comanda_obj.subpos }}</span
						>
					</p>
					<p>
						Cantitate ramasa de debitat:
						<span class="bold"
							>{{ comanda.cantitate }} buc x
							{{ comanda.lungime_finala }} mm</span
						>
					</p>
					<p>
						Cota livrata:
						<span class="bold">{{ comanda.cota_livrata }} mm </span>
					</p>
				</div>
			</div>
			<!-- document header -->

			<div class="row">
				<h6 class="mbottom-10">Debitare din:</h6>
				<div class="col s6 mtop-10 mbottom-10">
					<!-- <h6 class="inline-title right-margin">Debitare din: </h6> -->
					<label class="right-margin">
						<input
							name="group1"
							type="radio"
							:value="false"
							v-model="otherProfil"
							checked
						/>
						<span>{{ comanda.cota_livrata }} mm - {{ cantLivrata }} buc</span>
					</label>
					<label>
						<input
							name="group1"
							type="radio"
							:value="true"
							v-model="otherProfil"
						/>
						<span>Alta cota</span>
					</label>
					<!-- <label>
            <input type="checkbox" v-model="otherProfil"/>
            <span>Debitare din alta lungime</span>
          </label> -->
				</div>
				<div class="col s6">
					<model-list-select
						v-if="otherProfil"
						id="profil"
						:list="coteProfilStoc"
						option-text="cantitate"
						option-value="cota_profil"
						:custom-text="profil_name"
						v-model="debitare.profil"
						placeholder="Profil | Cota | Buc"
					></model-list-select>
				</div>
			</div>
			<p>
				Buc / profil: <span class="bold">{{ bucPerProfil }} buc</span>
			</p>
			<div class="card-panel">
				<div class="document-body row">
					<div class="input-field col s5">
						<input
							id="qty"
							type="number"
							step="1"
							v-model="debitare.cantitate"
							v-on:change="check_qty()"
						/>
						<label for="qty">Cantitate debitata</label>
					</div>
					<div class="input-field col s5 offset-s1">
						<input
							id="scrap"
							type="number"
							step="1"
							v-model="debitare.buc_scrap"
							v-on:change="check_qty()"
						/>
						<label for="scrap">Buc scrap</label>
					</div>
				</div>
				<!-- document body -->
				<p>
					Profile folosite:
					<span v-if="cantDebitare" class="bold">{{ cantDebitare }} buc</span>
				</p>
			</div>
			<!-- card panel -->
			<div class="btns right">
				<a
					href="#!"
					class="modal-action modal-close btn blue-grey"
					@click="clearFields()"
					>Anuleaza</a
				>
				<button type="submit" class="btn">Salveaza</button>
			</div>
		</form>
		<br />
		<div class="row">
			<div class="col s6">
				<!-- <pre>{{$data}}</pre> -->
				<!-- <pre>{{comanda}}</pre> -->
			</div>
			<div class="col s6">
				<!-- <pre>{{coteProfilStoc}}</pre> -->
			</div>
		</div>
	</div>
</template>

<script>
import { ModelListSelect } from "vue-search-select";
import moment from "moment";

export default {
	name: "AddDebitare",
	data() {
		return {
			today: moment().format("YYYY-MM-DD"),
			otherProfil: false,
			debitare: {
				cantitate: "",
				buc_scrap: "",
				cant_ok: true,
				profil: {},
			},
		};
	},
	props: ["comanda"],
	computed: {
		profileStoc() {
			return this.$store.getters.selectStocProfile;
		},
		coteProfilStoc() {
			return this.profileStoc.filter((profil) => {
				return profil.profil_id == this.comanda.profil_id;
			});
		},
		cantLivrata() {
			let profil = this.coteProfilStoc.find((profil) => {
				return profil.cota_profil == this.comanda.cota_livrata;
			});
			if (profil == undefined) {
				return 0;
			} else {
				return profil.cantitate;
			}
		},
		bucPerProfil() {
			if (this.debitare.profil.cota_profil && this.otherProfil) {
				let n = Math.trunc(
					Number(this.debitare.profil.cota_profil) /
						Number(this.comanda.lungime_finala)
				);
				this.debitare.buc_profil = n;
				return n;
			} else {
				let m = this.comanda.buc_profil;
				this.debitare.buc_profil = m;
				return m;
			}
		},
		cantDebitare() {
			let nr =
				Number(this.debitare.cantitate) + Number(this.debitare.buc_scrap);
			let buc = this.debitare.buc_profil;
			// return Math.ceil(nr/this.debitare.buc_profil)
			return nr / buc;
		},
	},
	components: {
		ModelListSelect,
	},
	watch: {
		otherProfil: function(value) {
			if (!value) {
				this.debitare.profil = {};
			}
		},
	},
	methods: {
		profil_name(item) {
			return `${item.profil_tip} - ${item.cota_profil}mm - ${item.cantitate}buc`;
		},
		check_qty() {
			let a = this.cantDebitare;
			let b = this.cantLivrata;

			if (!this.isEmpty(this.debitare.profil)) {
				b = Number(this.debitare.profil.cantitate);
			}

			if (a > b) {
				this.debitare.cant_ok = false;
				alert("Nu este suficient stoc! Modificati!");
			} else {
				this.debitare.cant_ok = true;
			}
		},
		addDebitare(e) {
			if (this.debitare.cant_ok === false) {
				M.toast({ html: "Stocul nu este suficient!", classes: "red" });
			} else if (this.otherProfil && this.isEmpty(this.debitare.profil)) {
				M.toast({
					html: "Selectati profilul si cota de debitare!",
					classes: "red",
				});
			} else if (
				this.debitare.cantitate === "" ||
				this.debitare.buc_scrap === ""
			) {
				M.toast({ html: "Completati toate campurile!" });
			} else {
				let debitare = this.debitare;
				let intreg = debitare.buc_profil;
				let nr_scrap =
					(Number(debitare.cantitate) + Number(debitare.buc_scrap)) / intreg;

				let cota_livrata = this.comanda.cota_livrata;
				let cota_scrap = Math.trunc(
					Number(this.comanda.cota_livrata) %
						Number(this.comanda.lungime_finala)
				);

				if (!this.isEmpty(this.debitare.profil)) {
					cota_livrata = debitare.profil.cota_profil;
					cota_scrap = Math.trunc(
						Number(debitare.profil.cota_profil) %
							Number(this.comanda.lungime_finala)
					);
				}

				let newDebitare = {
					data_deb: this.today,
					comanda_id: this.comanda.comanda_id,
					profil_id: this.comanda.profil_id,
					cota_livrata: cota_livrata,
					cota_scrap: cota_scrap,
					cantitate: debitare.cantitate,
					buc_scrap: debitare.buc_scrap,
					scrap: nr_scrap,
				};

				this.debitare.cantitate = "";
				this.debitare.buc_scrap = "";
				this.$store.dispatch("addDebitare", newDebitare);
				this.clearFields();
				this.$router.push({ query: M.toast({ html: "Debitare adaugata!" }) });
			}
			e.preventDefault();
		},
		clearFields() {
			this.debitare.profil = {};
			this.otherProfil = false;
		},
		isEmpty(obj) {
			for (var key in obj) {
				// if (obj.hasOwnProperty(key)) return false;
				if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
			}
			return true;
		},
		fetchStocProfile() {
			this.$store.dispatch("fetchStocProfile");
		},
		fetchListaDeb() {
			this.$store.dispatch("fetchListaDebitari");
		},
	},
	created: function() {
		this.fetchStocProfile();
		this.fetchListaDeb();
	},
	updated: function() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row .col {
	padding: 0;
}
table.debitari tbody tr {
	border: none;
}
td.delete {
	cursor: pointer;
	color: #e65100;
}
</style>
