<template>
	<div class="container">
		<router-link class="btn blue-grey lighten-2 back-btn left" to="/debitare"
			>Inapoi</router-link
		>
		<div class="btns right">
			<button class="btn grey darken-1" @click="changeStatus()">
				Muta in active
			</button>
			<json-excel
				class="btn blue-grey"
				:fetch="prepareCSVData"
				type="csv"
				name="etichete.csv"
				><i class="material-icons left">file_download</i>Etichete</json-excel
			>
		</div>
		<h4>Comenzi finalizate</h4>
		<div v-if="listaDebitari.length > 0">
			<div class="row">
				<div
					class="input-field filter-input col s7"
					v-if="comenziDeb.length > 0"
				>
					<input
						placeholder="Cauta dupa nr. comanda sau nume profil"
						v-model="filterInput"
					/>
				</div>
				<div class="col s5 legend">
					<ul class="right">
						<li class="bold">
							<status-indicator intermediary></status-indicator
							><label>incompleta</label>
						</li>
						<li class="bold">
							<status-indicator positive></status-indicator><label>ok</label>
						</li>
					</ul>
				</div>
			</div>
			<div v-if="loading" class="progress">
				<div class="indeterminate"></div>
			</div>
			<!-- /// -->
			<div
				v-for="(comanda, index) in filterBy(comenziDeb, filterInput)"
				:key="index"
				class="card-panel row comanda"
				v-bind:class="{
					'box-status-green': comanda.status == 'ok',
					'box-status-blue': comanda.status == 'noua',
					'box-status-red': comanda.status == 'urgent',
					'box-status-orange': comanda.status == 'incompleta',
				}"
			>
				<div class="col s2 no-left-pad">
					<div>
						<span class="blue-grey-text">Comanda: </span
						><span class="bold"
							>{{ comanda.comanda_obj.nr }} | {{ comanda.comanda_obj.pos }} |
							{{ comanda.comanda_obj.subpos }}</span
						>
					</div>
					<div class="mbottom-10">
						<span class="blue-grey-text">Data: </span
						><span>{{ comanda.data_com_deb | formatDate }}</span>
					</div>
					<div>
						<span class="blue-grey-text">Termen: </span
						><span class="bold" :class="{ urgent: comanda.isUrgent }">{{
							comanda.termen_finalizare | formatDate
						}}</span>
					</div>
					<div>
						<span class="blue-grey-text">Client: </span
						><span>{{ comanda.client }}</span>
					</div>
				</div>
				<div class="col s2 right-border left-pad-20">
					<span class="bold">{{ comanda.profil_tip }}</span>
					<div class="mbottom-10">
						<span class="bold"
							>{{ comanda.cantitate_finala }} buc -
							{{ comanda.lungime_finala }} mm</span
						>
					</div>
					<div>
						<span class="blue-grey-text">Tol: </span
						><span>- {{ comanda.tol_min }}</span
						><span> | + {{ comanda.tol_max }}</span>
					</div>
					<div>
						<span class="blue-grey-text">Cota livrata: </span
						><span>{{ comanda.cota_livrata }} mm</span>
					</div>
				</div>
				<div class="col s3 left-pad-35">
					<!-- v-if="listaDebitari.length > 0" -->
					<div>
						<div v-if="comanda.status == 'ok'" class="status">
							<span class="blue-grey-text">Status: </span
							><status-indicator positive></status-indicator><label>ok</label>
						</div>
						<div v-else-if="comanda.status == 'incompleta'" class="status">
							<span class="blue-grey-text">Status: </span
							><status-indicator intermediary></status-indicator
							><label>incompleta</label>
						</div>
						<div class="mbottom-10">
							<span class="blue-grey-text">Materiale debitate: </span
							><span
								>{{ comanda.cantitate_debitata }} buc x
								{{ comanda.lungime_finala }} mm</span
							>
						</div>
						<div>
							<span class="blue-grey-text">Buc scrap : </span>
							<span v-if="comanda.scrap_buc != 0"
								>{{ comanda.scrap_buc }} buc x
								{{ comanda.lungime_finala }} mm</span
							>
							<span v-else>0</span>
						</div>
						<div>
							<span class="blue-grey-text">Scrap rezultat: </span>
							<span v-if="comanda.scrap * comanda.cota_scrap != 0"
								>{{ comanda.scrap }} buc x {{ comanda.cota_scrap }} mm</span
							>
							<span v-else>0</span>
						</div>
					</div>
				</div>
				<div class="col s3 left-pad-35">
					<p>
						<span class="blue-grey-text">Impachetare: </span
						><span
							>{{ comanda.pachete }} pac x {{ comanda.profil_bucpac }} buc</span
						><span v-if="comanda.rem != 0"
							>, 1 pac x {{ comanda.rem }} buc</span
						>
					</p>
					<span v-if="comanda.aviz_mat == 1" class="teal-text"
						><i class="material-icons left">local_shipping</i>Materiale
						avizate</span
					>
					<!-- <span v-if="comanda.aviz_scrap == 1" class="teal-text">| Scrap avizat</span> -->
				</div>
				<div class="col s2 left-pad-20">
					<a
						class="modal-trigger delete-icon"
						href="#deletecomanda"
						v-on:click="markComandaDelete(comanda.id_com_deb)"
						><i class="material-icons right grey-text mbottom-20"
							>delete_forever</i
						></a
					>
					<label
						class="mbottom-20 inline-title"
						v-if="comanda.status != 'noua'"
					>
						<input type="checkbox" v-model="comanda.selected" />
						<span>Selecteaza</span>
					</label>
					<a
						class="btn btn-small modal-trigger"
						href="#adddebitare"
						v-on:click="selectComanda(comanda.id_com_deb)"
						>Adauga debitare</a
					>
				</div>
			</div>
		</div>
		<div v-else>
			<p>Nu exista comenzi de afisat</p>
		</div>
		<!--  Modal begin -->
		<div id="adddebitare" class="modal modal-medium modal-adddebitare">
			<div class="modal-content">
				<AddDebitare
					v-if="selectedComanda"
					:comanda="selectedComanda"
				></AddDebitare>
				<br />
			</div>
		</div>
		<!--  Modal end -->
		<!--  Modal begin -->
		<div
			id="deletecomanda"
			class="modal modal-delete modal-del-deletecomandadeb"
		>
			<div class="modal-content">
				<h6>Sterge definitiv comanda?</h6>
				<br />
				<button class="btn red" v-on:click="deleteComanda(selectdelComanda)">
					Sterge
				</button>
				<a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
			</div>
		</div>
		<!--  Modal end -->
		<div class="row">
			<div class="col s4">
				<!-- <pre>{{selectedComanda}}</pre> -->
				<!-- <pre>{{comenziDeb}}</pre> -->
			</div>
			<div class="col s4">
				<!-- <pre>{{listaDebitari}}</pre> -->
				<!-- <pre>{{$data}}</pre> -->
			</div>
		</div>
	</div>
</template>

<script>
import AddDebitare from "@/components/Debitare/AddDebitare";
import { StatusIndicator } from "vue-status-indicator";
import moment from "moment";
import JsonExcel from "vue-json-excel";

export default {
	name: "ComenziDebFinalizate",
	data() {
		return {
			filterInput: "",
			today: moment(),
			selectedComanda: {},
			selectdelComanda: "",
			finalizata: true,
			rem: [],
		};
	},
	computed: {
		comenziDeb() {
			return this.$store.getters.comenziDebFinalizate;
		},
		listaDebitari() {
			return this.$store.getters.debitari;
		},
		loading() {
			return this.$store.getters.loading;
		},
		status() {
			this.comenziDeb.forEach((comanda) => {
				let debitari = this.listaDebitari.filter((obj) => {
					return obj.comanda_id == comanda.id_com_deb;
				});
				let ct_deb = 0;
				let ct_scrap_buc = 0;
				let ct_scrap = 0;
				let aviz_mat = 0;

				debitari.forEach((debitare) => {
					ct_deb = ct_deb + Number(debitare.cantitate);
					ct_scrap_buc = ct_scrap_buc + Number(debitare.buc_scrap);
					ct_scrap = ct_scrap + Number(debitare.scrap);
					if (debitare.aviz_mat == 1) {
						aviz_mat = 1;
					}
				});
				comanda.cantitate_debitata = String(ct_deb);
				comanda.scrap_buc = String(ct_scrap_buc);
				comanda.scrap = String(ct_scrap);
				comanda.aviz_mat = aviz_mat;
				let nr = Math.trunc(comanda.cantitate_debitata / comanda.profil_bucpac);
				let rem = comanda.cantitate_debitata % comanda.profil_bucpac;
				comanda.pachete = nr;
				comanda.rem = rem;

				if (comanda.cantitate_finala == comanda.cantitate_debitata) {
					comanda.status = "ok";
				} else {
					comanda.status = "incompleta";
				}
			});
			return "status_ok";
		},
	},
	components: {
		StatusIndicator,
		AddDebitare,
		JsonExcel,
	},
	watch: {
		comenziDeb: function() {
			this.fetchListaDeb();
			// this.status
		},
		listaDebitari: function() {
			this.status;
		},
		// comenziDeb: {
		//   handler: function (val, oldVal) {
		//     this.fetchListaDeb()
		//   },
		//   deep: true
		// },
		// listaDebitari: {
		//   handler: function (val, oldVal) {
		//     this.status
		//   },
		//   deep: true
		// }
	},
	methods: {
		filterBy(list, value) {
			value = value.charAt(0).toLowerCase() + value.slice(1);
			return list.filter(function(comanda) {
				return (
					comanda.profil_tip.toLowerCase().indexOf(value) > -1 ||
					comanda.comanda_obj.nr.toLowerCase().indexOf(value) > -1
				);
			});
		},
		changeStatus() {
			let comenzi = [];
			this.comenziDeb.forEach((comanda) => {
				if (comanda.selected) {
					comenzi.push(comanda.id_com_deb);
				}
			});
			if (comenzi.length == 0) {
				alert("Selecteaza cel putin o comanda!");
			} else {
				this.$store.dispatch("fstateComDeb", { arr_ids: comenzi, status: 0 });
			}
		},
		selectComanda(id) {
			// console.log(id);
			let comanda = this.comenziDeb.find((comanda) => {
				return comanda.id_com_deb == id;
			});
			let obj = {
				comanda_id: comanda.id_com_deb,
				comanda_obj: comanda.comanda_obj,
				cota_livrata: comanda.cota_livrata,
				lungime_finala: comanda.lungime_finala,
				profil_id: comanda.profil_id,
				cantitate:
					Number(comanda.cantitate_finala) - Number(comanda.cantitate_debitata),
				buc_profil: Number(comanda.buc_profil),
			};
			this.selectedComanda = obj;
		},
		markComandaDelete(id) {
			this.selectdelComanda = id;
		},
		deleteComanda(id) {
			this.$store.dispatch("deleteComandaDeb", id);
			this.selectdelComanda = "";
		},
		prepareCSVData() {
			let labels = [];
			this.comenziDeb.forEach((comanda) => {
				if (comanda.selected) {
					let label = {
						profil: comanda.profil_tip,
						comanda:
							comanda.data_com_deb.slice(2, 4) +
							"   " +
							comanda.comanda_obj.nr +
							"   " +
							comanda.comanda_obj.pos +
							"   " +
							comanda.comanda_obj.subpos,
						lungime: comanda.lungime_finala,
						bucati: comanda.profil_bucpac,
						etichete: comanda.pachete,
					};
					labels.push(label);

					if (comanda.rem != 0) {
						let label = {
							profil: comanda.profil_tip,
							comanda:
								comanda.data_com_deb.slice(2, 4) +
								"   " +
								comanda.comanda_obj.nr +
								"   " +
								comanda.comanda_obj.pos +
								"   " +
								comanda.comanda_obj.subpos,
							lungime: comanda.lungime_finala,
							bucati: comanda.rem,
							etichete: 1,
						};
						labels.push(label);
					}
				}
				comanda.selected = false;
			});
			return labels;
		},
		isEmpty(obj) {
			for (var key in obj) {
				if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
			}
			return true;
		},
		// avizeaza() {
		//   let arr = []
		//   this.comenziDeb.forEach(comanda => {
		//     if (comanda.selected == true) {
		//       arr.push(comanda)
		//       comanda.selected = false
		//     }
		//   })
		//   if (this.isEmpty(arr)) {
		//     alert('It\'s NOT ok')
		//   } else {
		//     this.createPDF(1,arr)
		//   }
		// },
		// createPDF(de,arr){
		// //createPDF(de){
		//   let pdfName = 'Aviz';
		//   var doc = new jsPDF();
		//
		//   doc.setFont('arial')
		//   doc.setFontStyle('normal')
		//   doc.setFontSize(9)
		//   doc.setTextColor(100)
		//
		//   //Document header
		//   doc.setFontStyle('bold')
		//   doc.text("Furnizor: ", 10, 15)
		//   doc.text("CUI: ", 10, 20)
		//   doc.text("Nr. ONRC: ", 10, 25)
		//   doc.text("Sediu/Pct. lucru: ", 10, 30)
		//   doc.setFontStyle('normal')
		//   doc.text("Cuprolli Manufacturing srl", 25, 15)
		//   doc.text("RO35671874", 18, 20)
		//   doc.text("J35/468/2016", 27, 25)
		//   doc.text("Dumbravita / Ghiroda", 36, 30)
		//   doc.text("Str. Caprioarei, nr.11, jud. Timis", 10, 35)
		//
		//   doc.setFontStyle('bold')
		//   doc.text("Cumparator: ", 130, 15)
		//   doc.text("CUI: ", 130, 20)
		//   doc.text("Nr. ONRC: ", 130, 25)
		//   doc.text("Sediu/Pct. lucru: ", 130, 30)
		//   doc.setFontStyle('normal')
		//   doc.text("Hydro Extrusion srl", 150, 15)
		//   doc.text("RO16344329", 138, 20)
		//   doc.text("J02/682/2004", 147, 25)
		//   doc.text("Chisineu-Cris, jud. Arad", 156, 30)
		//   doc.text("Str. Tudor Vladimirescu, FN, DN79/A", 130, 35)
		//
		//   //center v line
		//   //doc.line(105, 40, 105, 60)
		//
		//
		//
		//   doc.setFontSize(13)
		//   doc.text("AVIZ DE INSOTIRE A MARFII", 75, 60)
		//   doc.setFontSize(9)
		//   doc.setTextColor(100)
		//   doc.text("Seria CM", 90, 65)
		//   doc.text("Nr. 367", 110, 65)
		//   doc.text("Data: "+this.today.format("DD.MM.YYYY"),93, 70)
		//
		//   // Products table
		//   if (this.comenziDeb) {
		//     //Table head
		//     doc.setFontStyle("bold")
		//     doc.text("Nr.", 10, 86)
		//     doc.text("Denumire produs", 18, 86)
		//     doc.text("U.M.", 110, 86)
		//     doc.text("Cantitate", 125, 86)
		//     doc.text("Pachete", 150, 86)
		//     doc.text("Stadiu comanda", 175, 86)
		//     doc.setDrawColor(150)
		//     doc.setLineWidth(0.4)
		//     doc.line(10, 88, 200, 88)
		//     //Table lines (content)
		//     let space = 93;
		//     doc.setFontStyle("normal")
		//     let nr = 1
		//     arr.forEach(comanda => {
		//       doc.text(String(nr), 11, space)
		//       doc.text(comanda.profil_tip+'     '+comanda.data_com_deb.slice(2, 4)+ '   '+comanda.comanda_obj.nr+'   '+comanda.comanda_obj.pos+'   '+comanda.comanda_obj.subpos+ '     L='+comanda.lungime_finala+'mm', 19, space)
		//       doc.text("buc", 110, space)
		//       doc.text(comanda.cantitate_debitata, 129, space)
		//       doc.text(comanda.cantitate_finala, 157, space)
		//       doc.text(comanda.status, 180, space)
		//       doc.setLineWidth(0.2)
		//       doc.line(10, space+3, 200, space+3)
		//       nr++
		//       space = space + 8
		//     })
		//   }
		//
		//   // Print PDF
		//   if (de == 1){
		//     doc.save(pdfName + '.pdf');
		//   }
		//   //else if (de == 2){
		//   //   var pdf = btoa(doc.output());
		//   //   // Send doc to php script for emailing forward
		//   //   this.$http.post('http://hub.cuprolli.ro/app_back/mail.php', {data: pdf, client_mail: this.oferta.email})
		//   //     .then(function(response){
		//   //       M.toast({html: 'Oferta trimisa!'})
		//   //     })
		//   // }
		// },
		fetchComenziDeb() {
			this.$store.dispatch("fetchComenziDebFinalizate");
		},
		fetchListaDeb() {
			this.$store.dispatch("fetchDebitari");
		},
	},
	created: function() {
		this.fetchComenziDeb();
		// this.fetchListaDeb()
	},
	mounted: function() {
		M.Modal.init(document.querySelector(".modal-adddebitare"));
		M.Modal.init(document.querySelector(".modal-del-deletecomandadeb"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.legend li {
	display: inline-block;
	margin-right: 10px;
}
.legend label {
	margin-left: 6px;
}
.box-status-green {
	border-left: 10px solid #4bd28f;
}
.box-status-blue {
	border-left: 10px solid #0095ff;
}
.box-status-red {
	border-left: 10px solid #ff4d4d;
}
.box-status-orange {
	border-left: 10px solid #ffaa00;
}
.status {
	padding: 0;
}
.status label {
	margin-left: 6px;
	color: #000;
	font-weight: bold;
}
.delete-icon i:hover {
	color: red !important;
}
.mbottom-10 {
	margin-bottom: 10px;
}
.mbottom-20 {
	margin-bottom: 20px;
}
.mright-3 {
	margin-right: 3%;
}
.no-left-pad {
	padding-left: 0;
}
.left-pad-20 {
	padding-left: 20px;
}
.left-pad-35 {
	padding-left: 35px;
}
.right-border {
	border-right: 1px solid #ddd;
}
.switch-comenzi {
	margin-right: 230px;
}
.comanda i {
	font-size: 22px;
}
</style>
