<template>
	<div class="row">
		<div id="garduri" class="col s12">
			<div class="bg-white shadow p-8">
				<div v-if="loading" class="progress">
					<div class="indeterminate"></div>
				</div>
				<div v-else>
					<div class="row">
						<div class="col s6">
							<h6 class="grey lighten-4 title">Modele garduri</h6>
						</div>
						<div class="col s6 btns-right">
							<button
								data-target="modal_add_gard"
								class="btn right modal-trigger"
								@click="resetModelGard"
							>
								Adauga model
							</button>
						</div>
					</div>
					<table v-if="modeleGard.length > 0">
						<thead>
							<tr>
								<th class="width3">Nr.</th>
								<th>Denumire model</th>
								<!-- <th>Latime profil</th> -->
								<!-- <th>Distanta intre profile</th> -->
								<th>
									Distanta minima <br />
									intre profile
								</th>
								<th>Pret panou</th>
								<th>Pret panou <br />cu rama</th>
								<th>Pret poarta <br />mica</th>
								<th>Pret poarta <br />mare</th>
								<th>Pret poarta <br />autoportanta</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(model, index) in modeleGard">
								<td>{{ index + 1 }}</td>
								<td>
									<a
										class="tooltipped"
										data-position="right"
										:data-tooltip="tooltip(model.image_panou)"
										>{{ model.denumire_model }}</a
									>
								</td>
								<!-- <td>{{model.latime_profil}} mm</td> -->
								<!-- <td>{{model.distanta_gol}} mm</td> -->
								<td>{{ model.min_gol }} mm</td>
								<td>{{ model.pret_panou }} euro / m²</td>
								<td>{{ model.pret_panou_rama }} euro / m²</td>
								<td>{{ model.pret_poarta_mica }} euro / m²</td>
								<td>{{ model.pret_poarta_mare }} euro / m²</td>
								<td>{{ model.pret_poarta_mare_ap }} euro / m²</td>
								<td>
									<button
										data-target="modal_add_gard"
										class="modal-trigger"
										@click="loadModelGard(index)"
									>
										Edit
									</button>
								</td>
								<!-- <td @click="selectedModelGard(index)"><button data-target="modal_del_gard" class="right red-text modal-trigger">X</button></td> -->
							</tr>
						</tbody>
					</table>
					<p v-else>Nu exista modele de gard definite</p>
				</div>
			</div>

			<!-- <pre>{{$data}}</pre> -->
			<!-- <pre>{{modeleGard}}</pre> -->
		</div>
		<!-- MODALS -->
		<div id="modal_add_gard" class="modal modal-add modal-add-gard">
			<div class="modal-content">
				<AddEditGard :currentModel="model" :edit="edit"></AddEditGard>
			</div>
		</div>
		<div id="modal_del_gard" class="modal modal-delete modal-del-gard">
			<div class="modal-content">
				<h6>Sterge definitiv modelul de gard</h6>
				<br />
				<button class="btn red" v-on:click="deleteModelGard()">Sterge</button>
				<a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
			</div>
		</div>
		<!-- MODALS END -->
	</div>
</template>

<script>
import AddEditGard from "@/components/Setari/SetariGarduri/AddEditGard";

export default {
	name: "SetariGarduri",
	data() {
		return {
			model: {
				denumire_model: "",
				gol: "",
				min_gol: "",
				pret_panou: "",
				pret_poarta_mica: "",
				pret_poarta_mare: "",
			},
			edit: false,
			delIndex: "",
		};
	},
	computed: {
		loading() {
			return this.$store.getters.loading;
		},
		modeleGard() {
			return this.$store.getters.modele_gard;
		},
	},
	components: {
		AddEditGard,
	},
	methods: {
		tooltip(path) {
			let tooltip = {
				html: `<img src="http://hub.cuprolli.ro/garduri/${path}" width="300" height="150">`,
			};
			return tooltip.html;
		},
		resetModelGard() {
			this.model = {
				denumire_model: "",
				gol: "",
				min_gol: "",
				pret_panou: "",
				pret_poarta_mica: "",
				pret_poarta_mare: "",
			};
			this.edit = false;
		},
		loadModelGard(idx) {
			this.model = this.modeleGard[idx];
			this.edit = true;
		},
		selectedModelGard(index) {
			this.delIndex = index;
		},
		deleteModelGard() {
			let id = this.modeleGard[this.delIndex].id_model;
			this.$store.dispatch("deleteModelGard", id);
		},
	},
	created: function() {
		this.$store.dispatch("fetchModeleGard");
	},
	mounted: function() {
		M.Modal.init(document.querySelector(".modal-add-gard"));
		M.Modal.init(document.querySelector(".modal-del-gard"));
	},
	updated: function() {
		M.Tooltip.init(document.querySelectorAll(".tooltipped"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
td.delete {
	cursor: pointer;
	color: #e65100;
}
</style>
