<template>
	<div class="container">
		<div class="flex flex-col lg:flex-row justify-between items-center mt-6">
			<h4 v-if="isNull" class="header">Adauga materiale</h4>
			<h4 v-else class="header">Editeaza materiale</h4>
		</div>
		<EditMateriale
			v-if="currentOferta"
			:alte_obj="isNull ? materiale : currentOferta.alte_obj"
			:tip_lucrare="currentOferta.tip_lucrare"
			:tip="'oferta'"
			:id="currentOferta.id_oferta"
		></EditMateriale>
		<!-- <pre>{{currentOferta}}</pre> -->
	</div>
</template>

<script>
import EditMateriale from "@/components/Productie/EditMateriale";

export default {
	name: "OfertaMateriale",
	data() {
		return {
			materiale: [
				{
					nr: "1",
					denumire: "",
					cantitate: "",
					um: "",
					pret: "",
					pret_v: "",
					marja: "",
					pret_t: "",
				},
			],
		};
	},
	components: {
		EditMateriale,
	},
	computed: {
		currentOferta() {
			return this.$store.getters.currentOfertaProductie;
		},
		isNull() {
			if (this.currentOferta.alte_obj) {
				return false;
			} else {
				return true;
			}
		},
	},
	methods: {},
	created: function() {
		this.$store.dispatch("fetchOfertaProductie", this.$route.params.id);
	},
	mounted: function() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
