import jsPDF from "jspdf";
import fonts from "../assets/fonts/fonts.js";
import images from "../assets/oferta_images.js";
import router from "../router";

export const pdf_contract = {
  createContractPDF(content) {
    return new Promise(resolve => {
      try {
        const doc = new jsPDF({
          orientation: "p",
          unit: "mm",
          format: "a4"
        });
        const logo = images.data.logo_istruct_cuprolli

        //add the font to jsPDF
        doc.addFileToVFS("Roboto-Regular.ttf", fonts.robotoRegular);
        doc.addFileToVFS("Roboto-Bold.ttf", fonts.robotoBold);
        doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
        doc.addFont("Roboto-Bold.ttf", "Roboto", "bold");

        // set font
        doc.setFont("Roboto", "normal");

        //
        // Document - Top bar Info
        // Draw grey header rectangle
        doc.setFillColor(242, 244, 248);
        doc.rect(0, 0, 210, 25, "F");
        // Add Logo
        doc.addImage(logo, "JPEG", 8, 9, 50, 11);
        // Add Info
        doc.setFontSize(8);
        doc.setTextColor(150);
        doc.text(["Cuprolli Manufacturing srl", "CUI: RO 35671874", "RegCom: J35/468/2016"], 69, 11, {
          lineHeightFactor: 1.6,
        });
        doc.text(["Adresa:", "Strada Caprioarei nr.11", "307200 Ghiroda, jud. Timis"], 114, 11, {
          lineHeightFactor: 1.6,
        });
        doc.text(["Email: office@i-struct.ro", "https://i-struct.ro", "https://garduri-aluminiu.ro"], 162, 11, {
          lineHeightFactor: 1.6,
        });
        doc.setDrawColor(160, 171, 192);
        doc.setLineWidth(0.5);
        doc.line(0, 25, 210, 25);

        let space = 40
        doc.setTextColor(100);
        
        let existingPage = 1
        let lastPage = false

        content.forEach((element) => {
          // print Element
          if (element.content || typeof(element.content) != "undefined") {
            let printText = []
            let alignText = element.attrs ? element.attrs.textAlign : 'left'
            let dropSpace = 5
            
            // Modifying options and assign text to print to main var
            if (element.type == 'bulletList' || element.type == 'orderedList') {
              let dropspaceAdd = 1
              element.content.forEach(listItem => {
                let listStyle = element.type == 'bulletList' ? '• ' : ''
                listItem.content.forEach(item => {
                  doc.setFont("Roboto", "normal");
                  let listItemText = ''

                  if (item.content) {
                    listItemText += listStyle
                    item.content.forEach(textItem => {
                      listItemText += textItem.text                  
                    })
                    let itemText = doc.splitTextToSize(listItemText, 180)
                    itemText.forEach(textLine => {
                      printText.push(textLine)
                    })
                    alignText = item.attrs.textAlign
                  }
                })
              })
              dropSpace = 1 + printText.length * 4.5 * dropspaceAdd;
            } else if (element.type == 'heading') {
              element.content.forEach(item => {
                if (element.attrs.level == 1) {
                  doc.setFontSize(12);
                } else if (element.attrs.level == 2) {
                  doc.setFontSize(9);
                  dropSpace = 6
                }
                doc.setFont("Roboto", "bold");
                printText.push(item.text)
              })

            } else if (element.type == 'paragraph') {
              let pText = ''
              element.content.forEach((item, index) => {
                doc.setFontSize(9);
                if (item.marks && item.marks[0].type == 'bold' && index == 0) {
                  doc.setFont("Roboto", "bold");
                } else {
                  doc.setFont("Roboto", "normal");
                }
                pText += item.text
              })
              // how many lines has the paragraph
              let pSize = doc.getTextWidth(pText);
              // drop space with 4.5mm for each row - equivalent to 1.4 lineHeightFactor paragraph
              // 185 - average 180 -> 190 - max width from A4 doc size 210mm - 10mm margin left and right
              dropSpace = 5 + Math.floor(pSize / 186) * 4.5;
              printText = doc.splitTextToSize(pText, 190);
            }
                
            // If printText var is 'executant name' print stamp image
            if (printText[0] == 'EXECUTANT') {
              if (space > 240) {
                doc.text(existingPage.toString(), 200, 284, {align: 'right'});
                doc.setFont("Roboto", "normal");
                doc.text("Continuare pe pagina urmatoare", 10, 284);
                doc.addPage();
                existingPage += 1;
                doc.text(existingPage.toString(), 200, 284, {align: 'right'});
                doc.setFont("Roboto", "bold");
                space = 15;
              }
              lastPage = true
              doc.addImage(images.data.stamp_manufacturing, "JPEG", 35, space + 6 , 40, 22.66);
            }

            // Print main var with text
            let indent = alignText == 'center' ? 105 : alignText == 'left' ? 10 : 200
            doc.text(printText, indent, space, { align: alignText, lineHeightFactor: 1.4, })

            space += dropSpace

            // doc.text(String(space), 1, space)

            if (space > 260 && !lastPage) {
              doc.setFont("Roboto", "normal");
              doc.text(existingPage.toString(), 200, 284, {align: 'right'});
              doc.text("Continuare pe pagina urmatoare", 10, 284);
              doc.addPage();
              existingPage += 1;
              doc.text(existingPage.toString(), 200, 284, {align: 'right'});
              space = 15;
            }

          } else if (element.type == 'blockquote') {
            space += 0
          } else {
            // If empty space add extra line
            space += 5
          }
        })

        

        // doc.addImage(images.data.stamp_manufacturing, "JPEG", 35, space - 48 , 40, 22.66);
        // if (isMontajSeparat) {
        //   const denStamp = firmaMontaj.toLowerCase().split(" ").splice(-1)
        //   const imgStamp = 'stamp_'+denStamp.toString()
        //   console.log(imgStamp.toString())
        //   doc.addImage(images.data[imgStamp], "JPEG", 35, space - 8, 40, 22.66);
        // }

        resolve(doc)
      }
      catch(err) {
        console.error(err)
        router.push({
          query: M.toast({
            html: "Eroare la generare pdf!",
            classes: "red",
          }),
        });
      }
    });
  }
};