<template>
	<div class="card-panel box-status pb-12" :class="{
		'box-status-green': comanda.marfa_comandata == '1',
		'box-status-red': comanda.problema == '1',
		'box-status-grey': comanda.stare == 'finalizata',
	}">
		<div class="row mbottom-20">
			<div class="col s5 no-lp">
				<div class="mbottom-20">
					<span class="blue-grey-text">Nr. </span><span class="bold">{{ comanda.id_comanda }}</span>
					<span class="ml-20 blue-grey-text">Data: </span><span class="bold">{{ comanda.data | formatDate
						}}</span>
					<span class="ml-20 blue-grey-text">Oferta: </span><span v-if="comanda.oferta_id"
						class="bold teal-text btn-icon" @click="loadOferta(comanda.oferta_id)">{{ comanda.oferta_id
						}}</span><span v-else>-</span>
				</div>
				<h6 class="bold">{{ comanda.nume }}</h6>
				<p class="mbottom-0">
					<span class="bold">Adresa livrare: </span>
					<span v-if="showLivrareField">
						<input type="text" class="validate" v-model="comanda.adresa_livrare" />
						<button class="btn-text teal-text mtop-0" @click="saveLivrare">
							Salveaza
						</button>
					</span>
					<span v-else>{{ comanda.adresa_livrare | NoData }}</span>
				</p>
				<button v-if="!showLivrareField" class="btn-text teal-text" @click="editLivrare">
					{{ comanda.adresa_livrare ? "Editeaza" : "Adauga" }}
				</button>
			</div>
			<div class="col s7 no-rp">
				<div class="btns">
					<v-btn color="cyan darken-3" class="uppercase" dark @click="loadFisa">
						<v-icon class="mr-1">mdi-truck</v-icon>
						Fisa
					</v-btn>
					<v-btn v-if="comanda.email" color="teal lighten-2" class="uppercase" dark @click="trimiteStatus">
						<v-icon class="mr-1">mdi-email</v-icon>
						Trimite Status
					</v-btn>
					<v-btn v-if="comanda.stare != 'finalizata'" color="green lighten-1" class="uppercase" dark
						@click="finalizareComanda">
						<v-icon class="mr-1">mdi-check</v-icon>
						Finalizeaza
					</v-btn>
					<v-btn color="teal darken-1" class="uppercase" dark :to="'/comenzi/edit/' + comanda.id_comanda">
						<v-icon class="mr-1">mdi-pencil</v-icon>
						Editeaza
					</v-btn>
					<v-btn color="red lighten-1" icon fab small @click="deleteComanda">
						<v-icon>mdi-delete-forever</v-icon>
					</v-btn>
				</div>
				<div class="btns-small mtop-20">
					<span v-if="comanda.problema == '0'" class="btn-text teal-text"
						@click="changeProblemaStatus">Problema</span>
					<span v-else class="red-text caps-text mr-10">
						<i class="material-icons align-middle">notification_important</i>
						<span class="bold align-middle">Problema:</span>
						<span v-if="comanda.problema_text" class="problem-text align-middle">{{ comanda.problema_text
							}}</span>
						<span class="undo-problem align-middle ml-10 bold" @click="undoProblemaStatus">X</span>
					</span>
					<span v-if="comanda.marfa_comandata == '0'" class="btn-text teal-text"
						@click="changeOrderedStatus(true)">Marfa comandata?</span>
					<span v-else class="green-text caps-text bold">
						<i class="material-icons align-middle mr-10">assignment_turned_in</i>
						<span class="align-middle">Marfa comandata</span>
						<span class="undo-problem align-middle ml-10 red-text" @click="changeOrderedStatus(0)">X</span>
					</span>
				</div>
			</div>
		</div>
		<div class="grey lighten-5">
			<table class="highlight responsive-table">
				<thead>
					<tr>
						<th class="width3">Nr.</th>
						<th class="width20">Denumire produs</th>
						<th class="center-align">Cantitate</th>
						<th class="center-align">Greutate</th>
						<th class="center-align">Pret / kg</th>
						<th class="center-align">Data livrare</th>
						<th class="center-align">Status livrare</th>
						<th class="center-align">Furnizor</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(produs, index) in filterProducts(productsArray)" :key="index">
						<td class="width3">{{ produs.nr }}</td>
						<td class="width40">
							<span v-if="produs.denumire.value">{{ produs.denumire.value }}</span>
							<span v-else>{{ produs.denumire }}</span>
							<span> {{ produs.material }}</span>
							<span v-if="produs.detalii">{{ " - " + produs.detalii }}</span>
							<span v-if="produs.dimensiune">{{ ", " + produs.dimensiune }}</span>
							<span v-if="produs.aliaj">{{ ", aliaj: " + produs.aliaj }}</span>
						</td>
						<td class="center-align">
							{{ produs.cantitate + " " + produs.um }}
						</td>
						<td class="center-align">{{ produs.greutate | NoData }} kg</td>
						<td class="center-align">
							{{ produs.pret_kg | NoData }} {{ comanda.moneda }}
						</td>
						<td v-if="produs.status_livrare === 'livrare anulata'" class="center-align">
							-
						</td>
						<td v-else-if="!produs.data_actualizata" class="green-text center-align">
							{{ produs.data_livrare | formatDate | NoData }}
						</td>
						<td v-else class="center-align red-text">
							{{ produs.data_actualizata | formatDate | NoData }}
						</td>
						<td v-if="produs.status_livrare === 'livrare finalizata'" class="green-text center-align">
							{{ produs.status_livrare | NoData }}
						</td>
						<td v-else-if="produs.status_livrare === 'livrare anulata'" class="red-text center-align">
							{{ produs.status_livrare | NoData }}
						</td>
						<td v-else class="center-align">
							{{ produs.status_livrare | NoData }}
						</td>
						<td class="center-align">{{ produs.furnizor | NoData }}</td>
					</tr>
					<tr v-if="productsArray.length > 3" class="btn-icon">
						<td v-if="productsNo < 4" colspan="8" class="center-align blue-grey lighten-5 red-text bold"
							@click="showAllProducts">
							Vezi toate produsele
						</td>
						<td v-else colspan="8" class="center-align blue-grey lighten-5" @click="showOnlyProducts">
							<i class="material-icons">expand_less</i>
						</td>
					</tr>
					<tr>
						<td></td>
						<td class="bold">
							<span v-if="comanda.transport_obj != null && comanda.transport_obj.form">Transport
								contra cost</span>
							<span v-else>Transport inclus</span>
						</td>
					</tr>
				</tbody>
			</table>

		</div>
		<div class="py-2">
			<span class="blue-grey-text">Status trimis la: </span><span class="bold">{{
		comanda.ultimul_status |
		formatDateTime | NoData }}</span>
		</div>

		<div class="row">
			<div class="col s6">
				<!-- <pre>{{comanda}}</pre> -->
			</div>
			<div class="col s6">
				<!-- <pre>{{productsArray}}</pre> -->
			</div>
		</div>
		<!-- <pre>{{comanda}}</pre> -->
	</div>
</template>

<script>
export default {
	name: "Comanda",
	data() {
		return {
			productsNo: 3,
			showLivrareField: false,
		};
	},
	props: ["comanda"],
	computed: {
		matDepozit() {
			const list = this.comanda.produse_obj.map((produs) => ({
				denumire: `${produs.denumire.value} ${produs.material
					} ${this.dimensiune_text(produs)}, aliaj: ${produs.aliaj}`,
				cantitate:
					produs.cantitate + " " + produs.um + " / " + produs.greutate + " kg",
			}));
			return list;
		},
		productsArray() {
			return this.comanda.produse_obj.concat(this.comanda.custom_obj);
		},
	},
	watch: {},
	methods: {
		dimensiune_text(produs) {
			let str = "";
			if (produs.denumire.value == "Profil U") {
				str = produs.dimH;
			} else {
				str = produs.dimL;
			}
			if (produs.denumire.value == "Profil U") {
				str += " x " + produs.dimL;
			}
			if (produs.dimH) {
				str += " x " + produs.dimH;
			}
			if (produs.dimG) {
				str += " x " + produs.dimG;
			}
			if (str == null || str == "") {
				return "";
			} else {
				if (!produs.dimH && !produs.dimG) {
					str = "D " + str;
				}
				return str + " mm";
			}
		},
		filterProducts(list) {
			return list.slice(0, this.productsNo);
		},
		editLivrare() {
			this.showLivrareField = true;
		},
		saveLivrare() {
			this.showLivrareField = false;
			this.$store.dispatch("updAdresaLivrare", {
				id: this.comanda.id_comanda,
				object: {
					adresa_livrare: this.comanda.adresa_livrare,
				},
			});
		},
		showAllProducts() {
			this.productsNo = this.productsArray.length;
		},
		showOnlyProducts() {
			this.productsNo = 3;
		},
		loadClientData() {
			this.$emit("loadClientData", {
				nume: this.comanda.nume,
				persoana: this.comanda.persoana,
				email: this.comanda.email,
				tel: this.comanda.tel,
			});
		},
		loadFisa() {
			if (
				this.comanda.adresa_livrare == null ||
				this.comanda.adresa_livrare == undefined
			) {
				alert("Adauga adresa de livrare!");
			} else {
				this.$emit("loadFisa", {
					materiale: this.matDepozit,
					adresa: this.comanda.adresa_livrare,
					id: this.comanda.id_comanda,
				});
				this.loadClientData();
			}
		},
		loadOferta(ofertaId) {
			this.$store.dispatch("fetchOferta", ofertaId);
			this.$store.dispatch("fetchProforma", ofertaId);
			this.$emit("loadOferta", ofertaId);
		},
		trimiteStatus() {
			this.$emit("trimiteStatus", this.comanda.id_comanda);
			this.loadClientData();
		},
		changeOrderedStatus(status) {
			this.$store.dispatch("changeOrderedStatus", {
				id: this.comanda.id_comanda,
				marfa_comandata: status,
			});
		},
		changeProblemaStatus() {
			this.$emit("problemaComanda", this.comanda.id_comanda);
		},
		undoProblemaStatus() {
			this.$store.dispatch("saveProblemaComanda", {
				id: this.comanda.id_comanda,
				object: {
					status: 0,
					problema_text: "",
				},
			});
		},
		finalizareComanda() {
			this.$emit("finalizareComanda", this.comanda.id_comanda);
		},
		deleteComanda() {
			this.$emit("deleteComanda", this.comanda.id_comanda);
		},
	},
	created: function () { },
	mounted: function () { },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.box-status {
	border-left: 10px solid #2196f3;
}

.box-status-green {
	/* border-left: 10px solid #4bd28f; */
	border-color: #4bd28f;
}

.box-status-red {
	border-left: 10px solid #ff4d4d;
}

.box-status-grey {
	border-left: 10px solid #607d8b;
}

.btns,
.obs {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.btns-small {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-size: 12px;
}

.btns>* {
	margin-left: 10px;
}

.btns-small>* {
	margin-top: 0;
	margin-left: 20px;
}

.problem-text {
	color: #000000;
	text-transform: none;
}

.undo-problem {
	cursor: pointer;
}
</style>
