<template>
  <div>
    <div class="card-panel">
      <table class="highlight responsive-table">
        <thead v-if="listaPacheteScrap.length > 0 || listaSaciScrap.length > 0">
          <tr>
            <th class="width15">Denumire</th>
            <th>Greutate neta</th>
            <th>Cantitate</th>
            <th>Incarca pe aviz</th>
          </tr>
        </thead>
        <thead v-else>
          <p class="bold">Nu exista scrap in stoc</p>
        </thead>
        <tbody>
          <tr>
            <td class="width15">Pachete scrap</td>
            <td class="width15">aprox. {{weightBucScrap}} kg</td>
            <td v-if="!visibleEditPachete" class="width10">
              <p>{{stocPachete.buc}} pachete</p>
              <a class="btn-text teal-text" @click="openUpdPacheteForm()">Modifica</a>
            </td>
            <td v-else class="width10">
              <div class="input-field">
                <input id="pac_total" type="number" class="validate" v-model="stocPachete.buc" />
                <!-- <label for="pac_total">Total pachete</label> -->
              </div>
              <a class="btn-text teal-text" @click="updStocScrap('pachete')">Salveaza</a>
            </td>
            <td class="width10">
              <div class="input-field">
                <input
                  id="pac_aviz"
                  type="number"
                  :class="stocPachete.valid"
                  v-model="stocPachete.aviz"
                  @input="validate(1)"
                />
                <label for="pac_aviz">Pachete aviz</label>
              </div>
            </td>
            <td class="width20" v-if="!isNaN(gt_pachete)">
              <span class="msg">Cantitatea aviz: {{gt_pachete}} kg</span>
            </td>
            <td class="width20" v-else>
              <span class="msg">0 kg</span>
            </td>
            <td class="width20" v-if="!isNaN(gt_pachete)">
              <label>
                <input type="checkbox" value="true" v-model="checkboxPachete" />
                <span></span>
              </label>
            </td>
            <td class="width20" v-else></td>
          </tr>
          <tr>
            <td class="width15">Saci scrap</td>
            <td class="width15">aprox. {{weightScrap}} kg</td>
            <td v-if="!visibleEditSaci" class="width10">
              <p>{{stocSaci.buc}} saci</p>
              <a class="btn-text teal-text" @click="openUpdSaciForm()">Modifica</a>
            </td>
            <td v-else class="width10">
              <div class="input-field">
                <input id="sac_total" type="number" class="validate" v-model="stocSaci.buc" />
                <!-- <label for="sac_total">Total saci</label> -->
              </div>
              <a class="btn-text teal-text" @click="updStocScrap('saci')">Salveaza</a>
            </td>
            <td class="width10">
              <div class="input-field">
                <input
                  id="sac_aviz"
                  type="number"
                  :class="stocSaci.valid"
                  v-model="stocSaci.aviz"
                  @input="validate(2)"
                />
                <label for="sac_aviz">Saci aviz</label>
              </div>
            </td>
            <td class="width20" v-if="!isNaN(gt_saci)">
              <span class="msg">Cantitatea aviz: {{gt_saci}} kg</span>
            </td>
            <td class="width20" v-else>
              <span class="msg">0 kg</span>
            </td>
            <td class="width20" v-if="!isNaN(gt_saci)">
              <label>
                <input type="checkbox" value="true" v-model="checkboxSaci" />
                <span></span>
              </label>
            </td>
            <td class="width20" v-else></td>
          </tr>
          <tr>
            <td class="width15">Saci span</td>
            <td class="width15">aprox. {{weightSpan}} kg</td>
            <td v-if="!visibleEditSpan" class="width10">
              <p>{{stocSpan.buc}} saci</p>
              <a class="btn-text teal-text" @click="openUpdSpanForm()">Modifica</a>
            </td>
            <td v-else class="width10">
              <div class="input-field">
                <input
                  id="span_total"
                  type="number"
                  step="0.25"
                  class="validate"
                  v-model="stocSpan.buc"
                />
                <!-- <label for="sac_total">Total saci</label> -->
              </div>
              <a class="btn-text teal-text" @click="updStocScrap('span')">Salveaza</a>
            </td>
            <td class="width10">
              <div class="input-field">
                <input
                  id="span_aviz"
                  type="number"
                  :class="stocSpan.valid"
                  v-model="stocSpan.aviz"
                  @input="validate(3)"
                />
                <label for="span_aviz">Saci aviz</label>
              </div>
            </td>
            <td class="width20" v-if="!isNaN(gt_span)">
              <span class="msg">Cantitatea aviz: {{gt_span}} kg</span>
            </td>
            <td class="width20" v-else>
              <span class="msg">0 kg</span>
            </td>
            <td class="width20" v-if="!isNaN(gt_span)">
              <label>
                <input type="checkbox" value="true" v-model="checkboxSpan" />
                <span></span>
              </label>
            </td>
            <td class="width20" v-else></td>
          </tr>
          <br />
        </tbody>
      </table>
    </div>
    <div class="card-panel">
      <div
        class="input-field filter-input"
        v-if="listaPacheteScrap.length > 0 || listaSaciScrap.length > 0"
      >
        <input placeholder="Cauta profil" v-model="filterInput" />
      </div>
      <div class="row">
        <div class="col s6">
          <h6>Pachete scrap</h6>
          <table class="highlight responsive-table">
            <thead v-if="listaPacheteScrap.length > 0 || listaSaciScrap.length > 0">
              <tr>
                <th class="width3">Nr.</th>
                <th class="width15">Tip profil</th>
                <th>Lungime</th>
                <th>Cantitate</th>
                <th>Greutate neta</th>
                <th>Comanda</th>
              </tr>
            </thead>
            <thead v-else>
              <p class="bold">Nu exista scrap in stoc</p>
            </thead>
            <tbody>
              <tr v-for="material, index in filterBy(listaPacheteScrap, filterInput)">
                <td>{{index + 1}}</td>
                <td>{{material.profil_tip}}</td>
                <td>{{material.lungime_finala}} mm</td>
                <td>{{material.buc_scrap}}</td>
                <td>{{greutate(material.lungime_finala, material.profil_greutate_ml, material.buc_scrap)}} kg</td>
                <td
                  v-if="material.comanda_obj != undefined"
                >{{material.comanda_obj.nr}} | {{material.comanda_obj.pos}} | {{material.comanda_obj.subpos}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col s6">
          <h6>Saci scrap</h6>
          <table class="highlight responsive-table">
            <thead v-if="listaPacheteScrap.length > 0 || listaSaciScrap.length > 0">
              <tr>
                <th class="width3">Nr.</th>
                <th class="width15">Tip profil</th>
                <th>Lungime</th>
                <th>Cantitate</th>
                <th>Greutate neta</th>
                <th>Comanda</th>
              </tr>
            </thead>
            <thead v-else>
              <p class="bold">Nu exista scrap in stoc</p>
            </thead>
            <tbody>
              <tr v-for="material, index in filterBy(listaPacheteScrap, filterInput)">
                <td>{{index + 1}}</td>
                <td>{{material.profil_tip}}</td>
                <td>{{material.cota_scrap }} mm</td>
                <td>{{material.scrap}} buc</td>
                <td>{{greutate(material.cota_scrap, material.profil_greutate_ml, material.scrap)}} kg</td>
                <td v-if="material.comanda_obj != undefined">{{material.comanda_obj.nr}} | {{material.comanda_obj.pos}} | {{material.comanda_obj.subpos}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <pre>{{$data}}</pre> -->
      <!-- <pre>{{stocPachete}}</pre> -->
      <!-- <pre>{{stocSpan}}</pre> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "StocScrap",
  data() {
    return {
      filterInput: "",
      visibleEditPachete: false,
      checkboxPachete: false,
      selectedPachete: [],
      visibleEditSaci: false,
      checkboxSaci: false,
      selectedSaci: [],
      visibleEditSpan: false,
      checkboxSpan: false
    };
  },
  props: [
    "listaSaciScrap",
    "listaPacheteScrap",
    "stocPachete",
    "stocSaci",
    "stocSpan"
  ],
  computed: {
    weightBucScrap() {
      let kg = 0;
      this.listaPacheteScrap.forEach(item => {
        kg =
          kg +
          this.greutate(
            item.lungime_finala,
            item.profil_greutate_ml,
            item.buc_scrap
          );
      });
      return kg;
    },
    weightScrap() {
      let kg = 0;
      this.listaSaciScrap.forEach(item => {
        kg =
          kg +
          this.greutate(item.cota_scrap, item.profil_greutate_ml, item.scrap);
      });
      return kg;
    },
    weightSpan() {
      let kg = 1000;
      let buc = this.stocSpan.buc;
      let w = kg * buc;
      return w;
    },
    gt_pachete() {
      let a = Number(this.stocPachete.buc);
      let b = Number(this.stocPachete.aviz);
      if (a >= b && b > 0) {
        return Math.round((this.weightBucScrap / a) * b);
      } else {
        return "Nan";
      }
    },
    gt_saci() {
      let a = Number(this.stocSaci.buc);
      let b = Number(this.stocSaci.aviz);
      if (a >= b && b > 0) {
        return Math.round((this.weightScrap / a) * b);
      } else {
        return "Nan";
      }
    },
    gt_span() {
      let a = Number(this.stocSpan.buc);
      let b = Number(this.stocSpan.aviz);
      if (a >= b && b > 0) {
        return Math.round((this.weightSpan / a) * b);
      } else {
        return "Nan";
      }
    }
  },
  watch: {
    checkboxPachete: function(val) {
      if (val == true) {
        this.arrComenzi(1);
        this.$emit("selectPachete", {
          comenzi: this.selectedPachete,
          kg: this.gt_pachete,
          nr: this.stocPachete.aviz,
          buc: this.stocPachete.buc,
          denumire: this.stocPachete.denumire
        });
      } else if (val == false) {
        this.selectedPachete = [];
        this.$emit("selectPachete", {});
      }
    },
    checkboxSaci: function(val) {
      if (val == true) {
        this.arrComenzi(2);
        this.$emit("selectSaci", {
          comenzi: this.selectedSaci,
          kg: this.gt_saci,
          nr: this.stocSaci.aviz,
          buc: this.stocSaci.buc,
          denumire: this.stocSaci.denumire
        });
      } else if (val == false) {
        this.selectedSaci = [];
        this.$emit("selectSaci", {});
      }
    },
    checkboxSpan: function(val) {
      if (val == true) {
        this.$emit("selectSpan", {
          kg: this.gt_span,
          nr: this.stocSpan.aviz,
          buc: this.stocSpan.buc,
          denumire: this.stocSpan.denumire
        });
      } else if (val == false) {
        this.$emit("selectSpan", {});
      }
    }
  },
  methods: {
    filterBy(list, value) {
      value = value.charAt(0).toLowerCase() + value.slice(1);
      return list.filter(function(profil) {
        return profil.profil_tip.toLowerCase().indexOf(value) > -1;
      });
    },
    openUpdPacheteForm() {
      this.visibleEditPachete = true;
    },
    openUpdSaciForm() {
      this.visibleEditSaci = true;
    },
    openUpdSpanForm() {
      this.visibleEditSpan = true;
    },
    updStocScrap(tip) {
      let obj;
      if (tip == "pachete") {
        obj = {
          id: this.stocPachete.id,
          denumire: this.stocPachete.denumire,
          buc: this.stocPachete.buc
        };
        this.visibleEditPachete = false;
      } else if (tip == "saci") {
        obj = {
          id: this.stocSaci.id,
          denumire: this.stocSaci.denumire,
          buc: this.stocSaci.buc
        };
        this.visibleEditSaci = false;
      } else if (tip == "span") {
        obj = {
          id: this.stocSpan.id,
          denumire: this.stocSpan.denumire,
          buc: this.stocSpan.buc
        };
        this.visibleEditSpan = false;
      }

      this.$store.dispatch("updateStocScrap", obj);
    },
    validate(tip) {
      if (tip == 1) {
        let a = Number(this.stocPachete.buc);
        let b = Number(this.stocPachete.aviz);
        if (a >= b && b > 0) {
          this.stocPachete.valid = "valid";
        } else {
          this.stocPachete.valid = "invalid";
        }
      } else if (tip == 2) {
        let a = Number(this.stocSaci.buc);
        let b = Number(this.stocSaci.aviz);
        if (a >= b && b > 0) {
          this.stocSaci.valid = "valid";
        } else {
          this.stocSaci.valid = "invalid";
        }
      } else if (tip == 3) {
        let a = Number(this.stocSpan.buc);
        let b = Number(this.stocSpan.aviz);
        if (a >= b && b > 0) {
          this.stocSpan.valid = "valid";
        } else {
          this.stocSpan.valid = "invalid";
        }
      }
    },
    arrComenzi(tip) {
      if (tip == 1) {
        let list = this.listaPacheteScrap;
        let arr = [];
        let tw = this.gt_pachete;
        let sum = 0;

        for (let i = 0; i < list.length; i++) {
          let w = this.greutate(
            list[i].lungime_finala,
            list[i].profil_greutate_ml,
            list[i].buc_scrap
          );
          sum = sum + w;
          if (sum > tw) {
            break;
          }
          arr.push(list[i].comanda_id);
        }
        this.selectedPachete = arr;
      } else if (tip == 2) {
        let list = this.listaSaciScrap;
        let arr = [];
        let tw = this.gt_saci;
        let sum = 0;

        for (let i = 0; i < list.length; i++) {
          let w = this.greutate(
            list[i].cota_scrap,
            list[i].profil_greutate_ml,
            list[i].scrap
          );
          sum = sum + w;
          if (sum > tw) {
            break;
          }
          arr.push(list[i].comanda_id);
        }
        this.selectedSaci = arr;
      }
    },
    greutate(cota, gml, buc) {
      return Math.round((((cota / 1000) * gml) / 1000) * buc);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.info {
  margin-top: 20px;
}
.info h6 {
  vertical-align: middle;
  margin: 0;
  display: inline-block;
}
.info i {
  vertical-align: middle;
  margin-right: 10px;
  display: inline-block;
}
table .input-field {
  margin-bottom: 0;
}
table .msg {
  margin: 10px 0 0 10px;
  display: block;
}
</style>
