<template>
	<div>
		<h3 class="text-lg font-bold my-2 py-2">
			Setari preturi pentru
			<a class="text-blue-400" href="https://ofertare.cuprolli.ro" target="_blank">
				ofertare.cuprolli.ro
				<i class="material-icons align-middle">open_in_new</i>
			</a>
		</h3>
		<div class="col w-1/3">
			<div v-if="!visibleEditLME" class="flex gap-4 py-10">
				<div class="flex flex-col gap-2">
					<span><span class="font-bold">LME:</span> {{ LME.lme }} &#x20AC; / tona</span>
					<span>Ultima actualizare: {{ LME.data | formatDate }}</span>
				</div>

				<span class="cursor-pointer" @click="loadCurrentLME"><i class="material-icons">edit</i></span>
			</div>
			<div v-else class="flex items-center gap-4">
				<div>
					<label for="lme" :class="visibleEditLME ? 'active' : ''">LME</label>
					<input id="lme" type="number" class="validate w-1/2" v-model="updatedLME" />
				</div>
				<span class="cursor-pointer" @click="updateLME"><i class="material-icons text-green-500">check</i></span>
				<span class="cursor-pointer" @click="visibleEditLME = false"><i class="material-icons">close</i></span>
			</div>
		</div>

		<div class="my-10">
			<h3 class="text-lg font-bold my-2 bg-gray-200 px-4 py-2">Preturi fixe / tona</h3>
			<div class="my-6">
				<table class="w-1/2">
					<thead>
						<tr class="font-bold bg-gray-100">
							<td class="w-80">Profile</td>
							<td>6060</td>
							<td></td>
						</tr>
					</thead>
					<tbody>
						<tr v-if="fixedPricesMateriePrima.profile">
							<td></td>
							<td>
								<div v-if="!visibleEditProfile">
									{{ fixedPricesMateriePrima.profile[6060] }} &#x20AC;
								</div>
								<div v-else class="w-16">
									<input type="number" step="1" class="validate m-0" v-model="updatedProfile[6060]" />
								</div>
							</td>
							<td>
								<div v-if="visibleEditProfile" class="flex gap-4">
									<span class="cursor-pointer" @click="updateFix('Profile')"><i
											class="material-icons text-green-500">check</i></span>
									<span class="cursor-pointer" @click="visibleEditProfile = false"><i
											class="material-icons">close</i></span>
								</div>
								<div v-else>
									<span class="cursor-pointer" @click="loadFixPrices('Profile')">
										<i class="material-icons">edit</i>
									</span>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div class="my-6">
				<table class="w-1/2">
					<thead>
						<tr class="font-bold bg-gray-100">
							<td class="w-80">Profile vopsite</td>
							<td>6060</td>
							<td></td>
						</tr>
					</thead>
					<tbody>
						<tr v-if="fixedPricesMateriePrima.profileVopsite">
							<td></td>
							<td>
								<div v-if="!visibleEditProfileVopsite">
									{{ fixedPricesMateriePrima.profileVopsite[6060] }} &#x20AC;
								</div>
								<div v-else class="w-16">
									<input type="number" step="1" class="validate m-0"
										v-model="updatedProfileVopsite[6060]" />
								</div>
							</td>
							<td>
								<div v-if="visibleEditProfileVopsite" class="flex gap-4">
									<span class="cursor-pointer" @click="updateFix('ProfileVopsite')"><i
											class="material-icons text-green-500">check</i></span>
									<span class="cursor-pointer" @click="visibleEditProfileVopsite = false"><i
											class="material-icons">close</i></span>
								</div>
								<div v-else>
									<span class="cursor-pointer" @click="loadFixPrices('ProfileVopsite')">
										<i class="material-icons">edit</i>
									</span>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div class="my-6">
				<table>
					<thead>
						<tr class="font-bold bg-gray-100">
							<td class="w-80">Tabla lisa</td>
							<td>1050</td>
							<td>5754</td>
							<td>5083</td>
							<td>6082</td>
							<td></td>
						</tr>
					</thead>
					<tbody>
						<tr v-if="fixedPricesMateriePrima.table">
							<td></td>
							<td>
								<div v-if="!visibleEditTable">
									{{ fixedPricesMateriePrima.table[1050] }} &#x20AC;
								</div>
								<div v-else class="w-16">
									<input type="number" step="1" class="validate m-0" v-model="updatedTable[1050]" />
								</div>
							</td>
							<td>
								<div v-if="!visibleEditTable">
									{{ fixedPricesMateriePrima.table[5754] }} &#x20AC;
								</div>
								<div v-else class="w-16">
									<input type="number" step="1" class="validate m-0" v-model="updatedTable[5754]" />
								</div>
							</td>
							<td>
								<div v-if="!visibleEditTable">
									{{ fixedPricesMateriePrima.table[5083] }} &#x20AC;
								</div>
								<div v-else class="w-16">
									<input type="number" step="1" class="validate m-0" v-model="updatedTable[5083]" />
								</div>
							</td>
							<td>
								<div v-if="!visibleEditTable">
									{{ fixedPricesMateriePrima.table[6082] }} &#x20AC;
								</div>
								<div v-else class="w-16">
									<input type="number" step="1" class="validate m-0" v-model="updatedTable[6082]" />
								</div>
							</td>
							<td class="w-20">
								<div v-if="visibleEditTable" class="flex gap-4">
									<span class="cursor-pointer" @click="updateFix('Table')"><i
											class="material-icons text-green-500">check</i></span>
									<span class="cursor-pointer" @click="visibleEditTable = false"><i
											class="material-icons">close</i></span>
								</div>
								<div v-else>
									<span class="cursor-pointer" @click="loadFixPrices('Table')">
										<i class="material-icons">edit</i>
									</span>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div class="my-6">
				<table>
					<thead>
						<tr class="font-bold bg-gray-100">
							<td class="w-80">Tabla groasa</td>
							<td>5754</td>
							<td>5083</td>
							<td>6082</td>
							<td>7075</td>
							<td></td>
						</tr>
					</thead>
					<tbody>
						<tr v-if="fixedPricesMateriePrima.placi">
							<td></td>
							<td>
								<div v-if="!visibleEditPlaci">
									{{ fixedPricesMateriePrima.placi[5754] }} &#x20AC;
								</div>
								<div v-else class="w-16">
									<input type="number" step="1" class="validate m-0" v-model="updatedPlaci[5754]" />
								</div>
							</td>
							<td>
								<div v-if="!visibleEditPlaci">
									{{ fixedPricesMateriePrima.placi[5083] }} &#x20AC;
								</div>
								<div v-else class="w-16">
									<input type="number" step="1" class="validate m-0" v-model="updatedPlaci[5083]" />
								</div>
							</td>
							<td>
								<div v-if="!visibleEditPlaci">
									{{ fixedPricesMateriePrima.placi[6082] }} &#x20AC;
								</div>
								<div v-else class="w-16">
									<input type="number" step="1" class="validate m-0" v-model="updatedPlaci[6082]" />
								</div>
							</td>
							<td>
								<div v-if="!visibleEditPlaci">
									{{ fixedPricesMateriePrima.placi[7075] }} &#x20AC;
								</div>
								<div v-else class="w-16">
									<input type="number" step="1" class="validate m-0" v-model="updatedPlaci[7075]" />
								</div>
							</td>
							<td class="w-20">
								<div v-if="visibleEditPlaci" class="flex gap-4">
									<span class="cursor-pointer" @click="updateFix('Placi')"><i
											class="material-icons text-green-500">check</i></span>
									<span class="cursor-pointer" @click="visibleEditPlaci = false"><i
											class="material-icons">close</i></span>
								</div>
								<div v-else>
									<span class="cursor-pointer" @click="loadFixPrices('Placi')">
										<i class="material-icons">edit</i>
									</span>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div class="my-6">
				<table class="w-1/2">
					<thead>
						<tr class="font-bold bg-gray-100">
							<td class="w-80">Tabla striatae</td>
							<td>5754</td>
							<td></td>
						</tr>
					</thead>
					<tbody>
						<tr v-if="fixedPricesMateriePrima.tableStriate">
							<td></td>
							<td>
								<div v-if="!visibleEditTableStriate">
									{{ fixedPricesMateriePrima.tableStriate[5754] }} &#x20AC;
								</div>
								<div v-else class="w-16">
									<input type="number" step="1" class="validate m-0"
										v-model="updatedTableStriate[5754]" />
								</div>
							</td>
							<td>
								<div v-if="visibleEditTableStriate" class="flex gap-4">
									<span class="cursor-pointer" @click="updateFix('TableStriate')"><i
											class="material-icons text-green-500">check</i></span>
									<span class="cursor-pointer" @click="visibleEditTableStriate = false"><i
											class="material-icons">close</i></span>
								</div>
								<div v-else>
									<span class="cursor-pointer" @click="loadFixPrices('TableStriate')">
										<i class="material-icons">edit</i>
									</span>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div class="my-6">
				<table class="w-1/2">
					<thead>
						<tr class="font-bold bg-gray-100">
							<td class="w-80">Bare pline</td>
							<td>6060</td>
							<td></td>
						</tr>
					</thead>
					<tbody>
						<tr v-if="fixedPricesMateriePrima.bare">
							<td></td>
							<td>
								<div v-if="!visibleEditBare">
									{{ fixedPricesMateriePrima.bare[6060] }} &#x20AC;
								</div>
								<div v-else class="w-16">
									<input type="number" step="1" class="validate m-0" v-model="updatedBare[6060]" />
								</div>
							</td>
							<td>
								<div v-if="visibleEditBare" class="flex gap-4">
									<span class="cursor-pointer" @click="updateFix('Bare')"><i
											class="material-icons text-green-500">check</i></span>
									<span class="cursor-pointer" @click="visibleEditBare = false"><i
											class="material-icons">close</i></span>
								</div>
								<div v-else>
									<span class="cursor-pointer" @click="loadFixPrices('Bare')">
										<i class="material-icons">edit</i>
									</span>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div class="w-1/2 y-4">
			<h3 class="text-lg font-bold my-2 bg-gray-200 px-4 py-2">Praguri greutate</h3>
			<table>
				<thead>
					<tr class="font-bold">
						<td class="w-80">Prag</td>
						<td>Adaos</td>
						<td>
							<div v-if="visibleEditLevels" class="flex gap-4">
								<span class="cursor-pointer" @click="updateLevels"><i
										class="material-icons text-green-500">check</i></span>
								<span class="cursor-pointer" @click="visibleEditLevels = false"><i
										class="material-icons">close</i></span>
							</div>
							<div v-else>
								<span class="cursor-pointer" @click="loadCurrentLevels"><i
										class="material-icons">edit</i></span>
							</div>
						</td>
					</tr>
				</thead>
				<tbody>
					<tr v-for="level in levels" :key="level.id">
						<td>{{ level.name }} kg</td>
						<td>
							<div v-if="!visibleEditLevels">{{ level.margin }} ({{ (level.margin * 100).toFixed(0) }}%)</div>
							<div v-else class="w-16">
								<input type="number" step=".01" class="validate m-0"
									v-model="updatedLevels[findLevelIdx(level.id)].margin" />
							</div>
						</td>
						<td></td>
					</tr>
				</tbody>
			</table>
		</div>
		<pre>
			<!-- Levels: {{ levels }} -->
			<!-- UpdLevels: {{ updatedLevels }} -->
			<!-- Fix: {{ fixedPricesMateriePrima }} -->
		</pre>
	</div>
</template>

<script>
import moment from "moment";

export default {
	name: "SetariPreturiMateriePrima",
	data() {
		return {
			visibleEditLME: false,
			visibleEditProfile: false,
			visibleEditProfileVopsite: false,
			visibleEditTable: false,
			visibleEditPlaci: false,
			visibleEditTableStriate: false,
			visibleEditBare: false,
			updatedLME: null,
			visibleEditLevels: false,
			updatedLevels: [],
			updatedProfile: {},
			updatedProfileVopsite: {},
			updatedTable: {},
			updatedPlaci: {},
			updatedTableStriate: {},
			updatedBare: {},
			today: moment().format("YYYY-MM-DD"),
		};
	},
	computed: {
		LME() {
			return this.$store.getters.LME
		},
		levels() {
			return this.$store.getters.levelsVanzari
		},
		fixedPricesMateriePrima() {
			return this.$store.getters.fixedPricesMateriePrima
		}
	},
	watch: {

	},
	components: {},
	methods: {
		loadCurrentLME() {
			this.visibleEditLME = true
			this.updatedLME = this.LME.lme
		},
		updateLME() {
			const newLME = {
				lme: this.updatedLME,
				data: this.today
			}
			this.$store.dispatch('updLMEVanzari', newLME)
			this.visibleEditLME = false
		},
		loadCurrentLevels() {
			this.visibleEditLevels = true
			this.updatedLevels = this.levels
		},
		updateLevels() {
			this.$store.dispatch("updLevelsVanzari", this.updatedLevels)
			this.visibleEditLevels = false
		},
		findLevelIdx(levelId) {
			const isSameLevel = (element) => element.id == levelId;
			let idx = this.updatedLevels.findIndex(isSameLevel);
			return idx
		},
		loadFixPrices(type) {
			const editType = `visibleEdit${type}`
			this[editType] = true
			const fixType = `updated${type}`
			const objType = type.charAt(0).toLowerCase() + type.slice(1);
			this[fixType] = this.fixedPricesMateriePrima[objType]
		},
		updateFix(type) {
			const fixType = `updated${type}`
			const editType = `visibleEdit${type}`
			const updObj = {
				type: type,
				prices: this[fixType],
				data: this.today
			}
			this.$store.dispatch('updateFixMateriePrima', updObj)
			this[editType] = false
		}
	},
	created: function () {
		this.$store.dispatch("fetchLMEVanzari");
		this.$store.dispatch("fetchLevelsVanzari");
		this.$store.dispatch("fetchFixedPricesMateriePrima");
	},
};
</script>

<style scoped></style>
