import { render, staticRenderFns } from "./EditServicii.vue?vue&type=template&id=5a5c3638&scoped=true"
import script from "./EditServicii.vue?vue&type=script&lang=js"
export * from "./EditServicii.vue?vue&type=script&lang=js"
import style0 from "./EditServicii.vue?vue&type=style&index=0&id=5a5c3638&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a5c3638",
  null
  
)

export default component.exports