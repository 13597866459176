<template>
	<div class="container">
		<button data-target="modal_del_profil" class="btn right red modal-trigger">
			Sterge profil
		</button>
		<h5 class="header">Editeaza profil</h5>
		<br />
		<form v-on:submit="updateProfil">
			<div v-if="profil" class="card-panel">
				<div class="row">
					<div class="input-field col s2">
						<input
							id="tip"
							type="text"
							class="validate"
							v-model="profil.profil_tip"
						/>
						<label for="tip">Tip profil</label>
					</div>
					<div class="input-field col s2">
						<input
							id="greutateML"
							type="number"
							step=".01"
							class="validate"
							v-model="profil.profil_greutate_ml"
						/>
						<label for="greutateML">Greutate / ML</label>
					</div>
					<div class="input-field col s2">
						<input
							id="bucpac"
							type="number"
							class="validate"
							v-model="profil.profil_bucpac"
						/>
						<label for="bucpac">Buc / pachet</label>
					</div>
				</div>
				<div class="row">
					<div class="col s4">
						<h6>Adauga cota</h6>
						<div class="input-field col s6">
							<input
								id="cota"
								type="text"
								class="validate"
								v-model="new_cota.cota"
							/>
							<label for="cota">cota (mm)</label>
						</div>
						<div class="input-field col s5">
							<input
								id="pret"
								type="number"
								step="0.01"
								class="validate"
								v-model="new_cota.pret"
							/>
							<label for="pret">pret (eur)</label>
						</div>
						<div class="input-field col s1">
							<span
								><button class="btn" @click.prevent="addCota">+</button></span
							>
						</div>
					</div>
					<ul class="cote col s7 offset-s1">
						<h6>Cote:</h6>
						<span
							v-if="
								profil.profil_cote === undefined ||
									profil.profil_cote.length == 0
							"
							>Adaugati cote</span
						>
						<li v-for="(item, index) in profil.profil_cote">
							{{ item.cota }} mm - {{ item.pret }} eur
							<span class="delete" @click.prevent="deleteCota(index)">X</span>
						</li>
					</ul>
				</div>
			</div>
			<div class="btns right">
				<a
					href="javascript:history.go(-1)"
					class="btn-large blue-grey lighten-2"
					>Anuleaza</a
				>
				<button type="submit" class="btn-large">Salveaza</button>
			</div>
		</form>
		<div id="modal_del_profil" class="modal modal-del-profil modal-delete">
			<div class="modal-content">
				<h6>Sterge definitiv profilul: {{ profil.profil_tip }}?</h6>
				<br />
				<button class="btn red" v-on:click="deleteProfil(profil.profil_id)">
					Sterge
				</button>
				<a href="#!" class="modal-action modal-close btn blue-grey">Anuleaza</a>
			</div>
		</div>
		<!-- <pre>{{$data}}</pre> -->
		<!-- <pre>{{profil}}</pre> -->
	</div>
</template>

<script>
export default {
	name: "EditProfil",
	data() {
		return {
			new_cota: {},
		};
	},
	computed: {
		profil() {
			return this.$store.getters.profil;
		},
	},
	methods: {
		addCota() {
			if (this.new_cota.cota !== "" || this.new_cota.pret !== "") {
				this.profil.profil_cote.push(this.new_cota);
				function sortNumber(a, b) {
					return a.cota - b.cota;
				}
				this.profil.profil_cote.sort(sortNumber);
				this.new_cota = {};
			}
		},
		deleteCota(i) {
			this.profil.profil_cote.splice(i, 1);
		},
		fetchProfil(id) {
			this.$store.dispatch("fetchProfil", id);
		},
		updateProfil(e) {
			if (
				!this.profil.profil_tip ||
				!this.profil.profil_cote ||
				!this.profil.profil_greutate_ml ||
				!this.profil.profil_bucpac
			) {
				M.toast({ html: "Completati toate campurile!" });
			} else {
				let updProfil = {
					profil_tip: this.profil.profil_tip,
					profil_cote: JSON.stringify(this.profil.profil_cote),
					profil_greutate_ml: this.profil.profil_greutate_ml,
					profil_bucpac: this.profil.profil_bucpac,
				};

				this.$store.dispatch("updProfil", {
					id: this.profil.profil_id,
					obj: updProfil,
				});

				e.preventDefault();
			}
			e.preventDefault();
		},
		deleteProfil(id) {
			this.$store.dispatch("deleteProfil", id);
		},
	},
	created: function() {
		this.fetchProfil(this.$route.params.id);
	},
	mounted: function() {
		M.Modal.init(document.querySelector(".modal-del-profil"));
	},
	components: {},
	updated: function() {
		M.updateTextFields();
		M.FormSelect.init(document.querySelectorAll("select"));
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
ul.cote {
	display: block;
	margin: 0;
	padding: 0;
}
ul.cote span {
	color: #aaaaaa;
	font-size: 12px;
}
ul li {
	display: inline-block;
	background-color: #f1f1f1;
	border-radius: 4px;
	margin: 0 10px 10px 0;
	padding: 2px 10px;
}
li span.delete {
	cursor: pointer;
	margin-left: 10px;
	color: #e65100;
}
</style>
