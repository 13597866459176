<template>
  <div class="container">
    <router-link class="btn blue-grey lighten-2 back-btn left" to="/debitare">Inapoi</router-link>
    <div class="btns right">
      <router-link class="btn" to="/debitare/profil/add">Adauga profil</router-link>
    </div>
    <h4>Profile</h4>
    <div class="row">
      <div class="input-field filter-input col s7">
        <input placeholder="Cauta profil" v-model="filterInput">
      </div>
    </div>
    <div class="card-panel">
      <table class="highlight responsive-table">
        <thead>
          <tr>
            <th>Tip profil</th>
            <th>Cote debitare</th>
            <th class="center-align">Greutate/ML</th>
            <th class="center-align">Buc / pachet</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="profil in filterBy(profile, filterInput)">
            <td>{{profil.profil_tip}}</td>
            <td class="width55"><span v-for="item in profil.profil_cote">{{item.cota}} mm - {{item.pret}} eur | </span></td>
            <td class="center-align">{{profil.profil_greutate_ml+" g"}}</td>
            <td class="center-align">{{profil.profil_bucpac}}</td>
            <td><router-link class="btn blue-grey lighten-2 right" v-bind:to="'/debitare/profil/edit/'+profil.profil_id">Editeaza</router-link></td>
          </tr>
        </tbody>
      </table>
      <!-- <pre>{{$data}}</pre> -->
      <!-- <pre>{{profile}}</pre> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'Profile',
  data () {
    return {
      // profile: [],
      filterInput: ''
    }
  },
  computed: {
    profile() {
      return this.$store.getters.profile
    },
  },
  methods: {
    fetchProfile() {
      this.$store.dispatch('fetchProfile')
    },
    filterBy(list, value){
      value = value.charAt(0).toLowerCase() + value.slice(1);
      return list.filter(function(profil){
        return profil.profil_tip.toLowerCase().indexOf(value) > -1;
      });
    }
  },
  created: function(){
    this.fetchProfile();
  }

  // updated: function(){
  //   this.fetchClienti();
  // },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
