<template>
	<div class="container pt-6 pb-10">
		<div class="flex flex-row justify-between items-center py-2 mb-4">
			<div class="flex flex-row justify-start items-center gap-8">
				<v-btn color="grey darken-1" class="uppercase" dark to="/oferte">
					<v-icon>mdi-chevron-left</v-icon>
					Inapoi
				</v-btn>
				<h1 class="page-title-secondary">Oferta vanzari nr. {{ oferta.id }}</h1>
			</div>
			<div v-if="!loadingOferta" class="flex flex-row justify-end items-center gap-2">
				<Facturi :proformaDate="proforma.data" :clientEmail="oferta.email" :ofertaGrandTotal="grand_total"
					:statusUpfrontInvoice="oferta.status_factura_avans" @sendAccountingEmail="emailFactAvans"
					@generateProforma="generateProforma" @updateProforma="updateFacturaProforma"
					@downloadProforma="saveProforma" @emailProforma="sendProforma" />
				<!-- <ToComanda /> -->
				<v-dialog v-model="visibleComandaModal" max-width="600px">
					<template v-slot:activator="{ on, attrs }">
						<v-btn color="blue darken-1" class="uppercase" dark v-bind="attrs" v-on="on"
							@click="loadMateriale()">
							<v-icon>mdi-arrow-right-bottom</v-icon>
							Comanda
						</v-btn>
					</template>
					<v-card class="p-6">
						<ComandaNoua v-if="matDepozit" :matDepozit="matDepozit" @comandaNoua="addComanda"
							@closeComandaModal="visibleComandaModal = false" @furnizorUnic="furnizorUnic">
						</ComandaNoua>
					</v-card>
				</v-dialog>
				<DocPDF :oferta="oferta" :sending-email="sendingEmail" @download="downloadOfertaPDF"
					@sendEmail="emailOfertaPDF" />
				<v-btn color="teal" dark class="uppercase" :to="'/oferte/edit/' + oferta.id">
					<v-icon>mdi-pencil</v-icon>
					Editeaza
				</v-btn>
				<v-menu offset-y>
					<template v-slot:activator="{ on, attrs }">
						<v-btn icon v-bind="attrs" v-on="on">
							<v-icon>mdi-dots-vertical</v-icon>
						</v-btn>
					</template>
					<v-list>
						<v-list-item link @click="openDuplicateModale">
							<v-icon class="mr-1">mdi-content-duplicate</v-icon>
							Duplica oferta
						</v-list-item>
						<v-list-item link @click="visibleDeleteModal = true">
							<v-icon class="mr-1">mdi-delete-forever-outline</v-icon>
							Sterge oferta
						</v-list-item>
					</v-list>
				</v-menu>
			</div>
		</div>
		<v-progress-linear v-if="loadingOferta" indeterminate color="teal darken-1" class="mb-4"></v-progress-linear>
		<div v-else class="flex flex-col gap-6">
			<v-card class="doc-header flex flex-row justify-between gap-10 px-6 py-6">
				<div>
					<v-label class="text-sm font-medium uppercase block pb-2">Detalii client</v-label>
					<div class="text-lg">
						<p class="font-semibold text-black mb-0">{{ oferta.nume }}</p>
						<v-list color="transparent" dense>
							<v-list-item v-if="oferta.cui || oferta.reg_com">{{ oferta.cui }} | {{ oferta.reg_com
								}}</v-list-item>
							<v-list-item v-if="oferta.adresa">{{ oferta.adresa }}<span v-if="oferta.oras">, {{
								oferta.oras }}
								</span></v-list-item>
						</v-list>
						<v-list color="transparent" dense>
							<v-list-item v-if="oferta.persoana">Reprezentant: <span class="font-semibold mx-1">{{
								oferta.persoana }}</span></v-list-item>
							<v-list-item v-if="oferta.tel">{{ oferta.tel }}</v-list-item>
							<v-list-item v-if="oferta.email">{{ oferta.email }}</v-list-item>
							<v-list-item v-if="oferta.cc_obj">
								<span v-for="item, idx in oferta.cc_obj">{{ item }}
									<span v-if="idx != oferta.cc_obj.length - 1">, </span>
								</span>
							</v-list-item>
							<v-btn color="teal" class="my-1" text @click="visibleEmailCCModal = true">Adauga email
								CC</v-btn>
						</v-list>
					</div>
				</div>
				<div>
					<v-label class="text-sm font-medium uppercase block pb-2">Detalii oferta</v-label>
					<div class="text-lg">
						<v-list color="transparent" dense>
							<v-list-item>Data oferta:<span class="font-semibold mx-1">{{ oferta.data | formatDate
									}}</span></v-list-item>
							<v-list-item>Intocmit de: <span class="font-semibold mx-1">{{ oferta.username
									}}</span></v-list-item>
						</v-list>
						<v-chip :color="getStatusColor(oferta.status_oferta)" small dark>
							{{ oferta.status_oferta }}
						</v-chip>
					</div>
				</div>
			</v-card>

			<v-card>
				<v-card-title>Produse</v-card-title>
				<v-card-text>
					<table class="responsive-table text-base">
						<thead>
							<tr>
								<th class="center-align">Nr.</th>
								<th>Denumire produs</th>
								<th class="center-align">Termen livrare</th>
								<th class="center-align">Cantitate</th>
								<th class="right-align">Pret unitar</th>
								<th class="right-align">Greutate</th>
								<th class="right-align">Pret / kg</th>
								<th class="right-align">Valoare fara TVA</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(produs, index) in oferta.produse_obj" :key="produs.nr">
								<td class="center-align">{{ index + 1 }}</td>
								<td class="width40" :class="produs.modified ? 'red-text' : ''">
									<span v-if="produs.denumire.value">{{
										produs.denumire.value
										}}</span>
									<span v-else>{{ produs.denumire }}</span>
									<span> {{ produs.material }}</span>
									<!--
                    -->
									<span v-if="produs.detalii">{{ " - " + produs.detalii }}</span>
									<!--
                    -->
									<span v-if="produs.dimL">, <span>{{ dimensiune_text(produs) }}</span></span>
									<!--
                    -->
									<span>{{ ", aliaj: " + produs.aliaj }}</span>
								</td>
								<td class="center-align">{{ produs.t_livrare | NoData }}</td>
								<td class="center-align">
									{{ produs.cantitate + " " + produs.um }}
								</td>
								<td class="right-align">{{ produs.pret_u }} {{ oferta.moneda }}</td>
								<td class="right-align">{{ produs.greutate | NoData }} kg</td>
								<td class="right-align">
									{{ produs.pret_kg | NoData }} {{ oferta.moneda }}
								</td>
								<td class="right-align">{{ produs.pret_t }} {{ oferta.moneda }}</td>
							</tr>
							<tr v-for="produs in oferta.custom_obj">
								<td class="center-align">{{ produs.nr }}</td>
								<td class="width40">
									<span>{{ produs.denumire }}</span>
									<!--
                    -->
									<span v-if="produs.detalii">{{ " - " + produs.detalii }}</span>
									<!--
                    -->
									<span v-if="produs.dimensiune_text">{{
										" - " + produs.dimensiune_text
										}}</span>
									<!--
                    -->
								</td>
								<td class="center-align">{{ produs.t_livrare | NoData }}</td>
								<td class="center-align">
									{{ produs.cantitate + " " + produs.um }}
								</td>
								<td class="right-align">{{ produs.pret_u }} {{ oferta.moneda }}</td>
								<td class="right-align">-</td>
								<td class="right-align">-</td>
								<td class="right-align">{{ produs.pret_t }} {{ oferta.moneda }}</td>
							</tr>
							<tr v-if="oferta.debitare_obj != null && oferta.debitare_obj.form">
								<td v-if="oferta.produse_obj" class="center-align">
									{{ products_no_oferta + 1 }}
								</td>
								<td class="bold">{{ oferta.debitare_obj.name }}</td>
								<td class="center-align">-</td>
								<td class="center-align">
									{{ oferta.debitare_obj.cantitate + " " + oferta.debitare_obj.um }}
								</td>
								<td class="right-align">
									{{ oferta.debitare_obj.pret }} {{ oferta.moneda }}
								</td>
								<td class="right-align">-</td>
								<td class="right-align">-</td>
								<td class="right-align">
									{{ oferta.debitare_obj.val }} {{ oferta.moneda }}
								</td>
							</tr>
							<tr v-if="oferta.transport_obj != null && oferta.transport_obj.form">
								<td v-if="oferta.produse_obj" class="center-align">
									{{
										oferta.debitare_obj.form == true
											? products_no_oferta + 2
											: products_no_oferta + 1
									}}
								</td>
								<td class="bold">{{ oferta.transport_obj.name }}</td>
								<td class="center-align">-</td>
								<td class="center-align">
									{{
										oferta.transport_obj.cantitate + " " + oferta.transport_obj.um
									}}
								</td>
								<td class="right-align">
									{{ oferta.transport_obj.pret }} {{ oferta.moneda }}
								</td>
								<td class="right-align">-</td>
								<td class="right-align">-</td>
								<td class="right-align">
									{{ oferta.transport_obj.val }} {{ oferta.moneda }}
								</td>
							</tr>
							<tr v-else-if="oferta.transport_obj != null && oferta.transport_obj.included">
								<td v-if="oferta.produse_obj" class="center-align">
									{{
										oferta.debitare_obj.form == true
											? products_no_oferta + 2
											: products_no_oferta + 1
									}}
								</td>
								<td class="bold">Transport inclus</td>
							</tr>
							<tr v-else>
								<td v-if="oferta.produse_obj" class="center-align">
									{{
										oferta.debitare_obj.form == true
											? products_no_oferta + 2
											: products_no_oferta + 1
									}}
								</td>
								<td class="bold">Nu include transport</td>
							</tr>
						</tbody>
						<tr></tr>
						<tfoot>
							<tr class="table-totals">
								<td colspan="6"></td>
								<td class="right-align bold">Total fara TVA</td>
								<td class="right-align bold">
									{{ oferta.val_fara_tva }} {{ oferta.moneda }}
								</td>
							</tr>
							<tr>
								<td colspan="6"></td>
								<td class="right-align">TVA</td>
								<td class="right-align">
									{{ oferta.val_tva }} {{ oferta.moneda }}
								</td>
							</tr>
							<tr>
								<td colspan="6"></td>
								<td class="right-align bold">TOTAL</td>
								<td class="right-align bold">
									{{ oferta.suma }} {{ oferta.moneda }}
								</td>
							</tr>
						</tfoot>
					</table>
				</v-card-text>
			</v-card>
			<v-card class="mb-10">
				<v-card-title>Detalii suplimentare</v-card-title>
				<v-card-text class="text-base">
					<div v-if="oferta.obs" class="flex flex-row justify-start items-start gap-1">
						<div class="mb-1">Observatii:</div>
						<p class="text-base font-semibold">{{ oferta.obs }}</p>
					</div>
					<div class="flex flex-row justify-between items-center">
						<div>Conditii livrare: <span class="font-semibold mx-1">{{ oferta.t_livrare | NoData }}</span>
						</div>
						<div>Conditii plata: <span class="font-semibold mx-1">{{ oferta.t_plata | NoData }}</span></div>
						<div>Valabilitate oferta: <span class="font-semibold mx-1">{{ oferta.valid | NoData }}</span>
						</div>
					</div>
				</v-card-text>
			</v-card>
		</div>

		<!-- MODALS -->

		<v-dialog v-model="visibleEmailCCModal" max-width="400px">
			<v-card>
				<v-form ref="emailCCForm" v-model="validEmailCCForm" @submit.prevent="addCC">
					<v-card-title>
						Adauga adresa email in CC
					</v-card-title>
					<v-card-text>
						<v-text-field label="Email" text outlined dense :rules="[rules.required, rules.email]"
							v-model="ccmail"></v-text-field>
					</v-card-text>
					<div class="flex flex-row justify-end gap-2 p-4">
						<v-btn color="blue-grey" class="uppercase" dark large
							@click="visibleEmailCCModal = false">Anuleaza</v-btn>
						<v-btn color="teal" dark class="uppercase" large type="submit">Adauga email</v-btn>
					</div>
				</v-form>
			</v-card>
		</v-dialog>

		<v-dialog v-model="visibleDuplicateModal" max-width="400px">
			<v-card>
				<v-card-title>
					Poti genera o noua oferta, bazata pe cea existenta
				</v-card-title>
				<v-card-text>
					<label for="client_id">Alege clientul</label>
					<model-select id="client_id" :options="clienti" v-model="newClient"
						placeholder="Nume client"></model-select>
					<br />
					<div class="input-field">
						<input id="data" type="date" class="validate" v-model="today" />
						<label for="data">Data</label>
					</div>
				</v-card-text>
				<div class="flex flex-row justify-end gap-2 mt-4 p-4">
					<v-btn color="blue-grey" class="uppercase" dark large
						@click="visibleDuplicateModal = false">Anuleaza</v-btn>
					<v-btn color="teal" dark class="uppercase" large
						@click="duplicateOferta(oferta.id)">Genereaza</v-btn>
				</div>
			</v-card>
		</v-dialog>

		<v-dialog v-model="visibleDeleteModal" max-width="400px">
			<v-card>
				<v-card-title>
					Sterge definitiv oferta nr. {{ oferta.id }}?
				</v-card-title>
				<div class="flex flex-row justify-end gap-2 mt-4 p-4">
					<v-btn color="blue-grey" class="uppercase" dark large
						@click="visibleDeleteModal = false">Anuleaza</v-btn>
					<v-btn color="red darken-1" dark class="uppercase" large
						@click="deleteOferta(oferta.id)">Sterge</v-btn>
				</div>
			</v-card>
		</v-dialog>

		<!-- OLD MODALS -->

		<!-- Modal Livrare
    <div id="modal_livrare" class="modal modal-livrare">
      <div class="modal-content">
        <FisaDepozit v-if="matDepozit" :matDepozit="matDepozit" :client="client" :idComanda="oferta.id"></FisaDepozit>
      </div>
    </div> -->


		<!-- Modal Whasapp -->
		<!-- <div id="modal_whatsapp" class="modal modal-whatsapp modal-small">
			<div class="modal-content">
				<h6 class="text-xl mb-4">Trimite oferta PDF pe Whasapp</h6>
				<p v-if="oferta.tel">Tel client: {{ oferta.tel }}</p>
				<p v-else class="text-red-400">
					Clientul nu are numar de telefon
				</p>
				<div class="btn-group flex justify-start mt-4">
					<button v-if="oferta.tel" href="#!" class="btn modal-close green" v-on:click="sendWhatsapp()">
						<i class="material-icons left">whatsapp</i>Trimite PDF
					</button>
					<a href="#!" class="modal-action modal-close btn blue-grey">Inchide</a>
				</div>
			</div>
		</div> -->


		<div class="row container">
			<div class="col s6" style="overflow: hidden;">
				<!-- <pre>{{ user }}</pre> -->
				<!-- <pre>{{proforma}}</pre> -->
				<!-- <pre>{{ oferta }}</pre> -->
				<!-- <pre>File: {{file}}</pre> -->
				<!-- <pre>Image Url: {{imgUrl}}</pre> -->
			</div>
			<div class="col s6">
				<!-- <pre>{{oferta.cc_obj}}</pre> -->
				<!-- <pre>Pdf: {{pdfFile}}</pre> -->
				<!-- <pre>Image: {{image}}</pre> -->
			</div>
		</div>
	</div>
</template>

<script>
import Facturi from "./Facturi.vue";
import DocPDF from "./DocPDF.vue";
// import ToComanda from "./ToComanda.vue";
import { ModelSelect } from "vue-search-select";
// import FisaDepozit from "@/components/Comenzi/FisaDepozit";
import ComandaNoua from "@/components/Oferte/ComandaNoua";
import images from "../../assets/oferta_images.js";
import moment from "moment";
import jsPDF from "jspdf";
import fonts from "../../assets/fonts/fonts.js";
import { carport_ad } from "@/pdf_functions/addon_func/carport_aluminiu.js";
import { mag_pubele_ad } from "@/pdf_functions/addon_func/magazii_pubele.js";

export default {
	name: "detaliioferta",
	data() {
		return {
			validEmailCCForm: false,
			visibleComandaModal: false,
			visibleEmailCCModal: false,
			visibleDuplicateModal: false,
			visibleDeleteModal: false,
			sendingEmail: false,
			today: moment().format("YYYY-MM-DD"),
			docType: "Oferta",
			tipFactura: "avans",
			visibleFactAvans: false,
			factAvans: null,
			factAvansMsg: "",
			observatii: "avans 100%",
			procent_proforma: "100",
			ccmail: null,
			ccfields: [],
			bccfields: [],
			matDepozit: [],
			client: {},
			newClient: {
				value: "",
				text: "",
			},
			file: "",
			imgUrl: "",
			pdfFile: "",
			image: "",
			pdfExtraInfo: false,
			rules: {
				required: value => !!value || 'Câmp obligatoriu',
				email: value => !value || /.+@.+\..+/.test(value) || "Adresa email invalida",
			}
		};
	},
	components: {
		Facturi,
		DocPDF,
		// ToComanda,
		ModelSelect,
		// FisaDepozit,
		ComandaNoua,
	},
	computed: {
		loadingOferta() {
			return this.$store.getters.loadingOferta
		},
		user() {
			return this.$store.getters.loggedUser;
		},
		oferta() {
			return this.$store.getters.oferta;
		},
		oferta_id() {
			return this.oferta.id;
		},
		proforma() {
			return this.$store.getters.proforma;
		},
		clienti() {
			return this.$store.getters.selectClienti;
		},
		curs() {
			return this.$store.getters.curs;
		},
		pdfName() {
			return `${this.docType} nr. ${this.oferta.id} ${this.oferta.nume}`;
		},
		proformaName() {
			return `${this.docType} nr. ${this.proforma.id_proforma} ${this.oferta.nume}`;
		},
		grand_total() {
			let total = this.oferta.suma;
			if (this.oferta.moneda == "eur") {
				total = (total * this.curs).toFixed(2);
			}
			return total;
		},

		products_no_oferta() {
			return this.oferta.produse_obj.length + this.oferta.custom_obj.length;
		},
		products_no_proforma() {
			return this.proforma.produse_obj.length + this.proforma.custom_obj.length;
		},
		statusColor() {
			if (this.oferta.status_oferta == 'noua') return 'bg-red-400'
			else if (this.oferta.status_oferta == 'contactat') return 'bg-yellow-400'
			else if (this.oferta.status_oferta == 'acceptata') return 'bg-green-400'
			else if (this.oferta.status_oferta == 'nu e acceptata') return 'bg-gray-400'
		}
	},
	watch: {
		oferta_id(value) {
			this.$store.dispatch("fetchProforma", value);
		},
		tipFactura(value) {
			if (value == "finala") {
				this.observatii = "100%";
			} else if (value == "avans") {
				this.factAvans = null;
				this.observatii = "avans 100%";
			}
		},
		factAvans(value) {
			if (value == "true") {
				this.factAvansMsg = "Este factura de avans";
			} else if (value == "false") {
				this.factAvansMsg = "NU este factura de avans";
			} else {
				this.factAvansMsg = "";
			}
		},
	},
	methods: {
		getStatusColor(status) {
			if (status == 'noua') return 'red lighten-1'
			else if (status == 'contactat') return 'orange darken-1'
			else if (status == 'acceptata') return 'green darken-1'
			else if (status == 'nu e acceptata') return 'grey darken-1'
		},
		replaceSpecialCh(str) {
			if (str) {
				return str
					.replace(/ș|Ș/g, "s")
					.replace(/ț|Ț/g, "t")
					.replace(/î|Î/g, "i")
					.replace(/ă|â|Â|Ă/g, "a")
			} else {
				return ""
			}
		},
		formatPhoneNo(str) {
			if (str.charAt(0) == "+" || str.charAt(0) == "(") {
				return str;
			} else {
				return "+4" + str;
			}
		},
		openDuplicateModale() {
			this.fetchClienti()
			this.visibleDuplicateModal = true
		},
		generateProforma(percent) {
			this.procent_proforma = percent
			this.generateFacturaProforma()
		},
		clientInfo() {
			let cui = this.oferta.cui ? "CUI: " + this.oferta.cui + "<br>" : "";
			let reg_com = this.oferta.reg_com
				? "Reg.Com. " + this.oferta.reg_com + "<br>"
				: "";
			let adresa = this.oferta.adresa
				? "Adresa: " + this.oferta.adresa + "<br>"
				: "";
			let oras = this.oferta.oras ? "Oras: " + this.oferta.oras : "";
			return cui + reg_com + adresa + oras;
		},
		async addCC() {
			const validEmailCCForm = await this.$refs.emailCCForm.validate();
			if (validEmailCCForm) {
				this.$store.dispatch("addCCMail", {
					id_client: this.oferta.id_client,
					mail: this.ccmail,
				});
				this.visibleEmailCCModal = false
				this.ccmail = null;
			}
		},
		// isModified(produs) {
		//   let mod = false;
		//   if (produs.dim_text && produs.dim_text != produs.dim_str) {
		//     mod = true;
		//   }
		//   produs.modified = mod;
		// },
		dimensiune_text(produs) {
			let str = "";
			if (produs.denumire.value == "Profil U") {
				str = produs.dimH;
			} else {
				str = produs.dimL;
			}
			if (produs.denumire.value == "Profil U") {
				str += " x " + produs.dimL;
			}
			if (produs.dimH) {
				str += " x " + produs.dimH;
			}
			if (produs.dimG) {
				str += " x " + produs.dimG;
			}
			if (str == null || str == "") {
				return "";
			} else {
				if (!produs.dimH && !produs.dimG) {
					str = "D " + str;
				}
				return str + " mm";
			}
		},
		loadMateriale() {
			let materiale = [];
			this.oferta.produse_obj.forEach((produs) => {
				let denumire_produs = `${produs.denumire.value} ${produs.material
					} ${this.dimensiune_text(produs)}, aliaj: ${produs.aliaj}`;
				let material = {
					denumire: denumire_produs,
					cantitate: produs.cantitate + " " + produs.um,
					furnizor: "",
				};
				materiale.push(material);
			});
			this.matDepozit = materiale;
			this.client = {
				nume: this.oferta.nume,
				adresa:
					this.replaceSpecialCh(this.oferta.adresa) +
					(this.oferta.oras
						? `, ${this.replaceSpecialCh(this.oferta.oras)}`
						: ""),
				persoana: this.oferta.persoana,
				tel: this.oferta.tel,
			};
		},
		fetchClienti() {
			this.$store.dispatch("fetchClienti");
		},
		duplicateOferta(id) {
			if (this.newClient.value == "") {
				alert("Alege clientul!");
			} else {
				let newOferta = {
					oferta_id: id,
					client_id: this.newClient.value,
					data: this.today,
					user_id: this.user.id_user,
				};
				this.$store.dispatch("duplicateOferta", newOferta);
				// console.log('duplicate offer', newOferta)
			}
		},
		changeDocType(type) {
			if (this.docType != type) {
				this.docType = type;
			}
		},
		generateFacturaProforma() {
			let newProforma = {
				client_id: this.oferta.id_client,
				oferta_id: this.oferta.id,
				oferta_data: this.oferta.data,
				user_id: this.oferta.id_user,
				data: this.today,
				moneda: this.oferta.moneda,
				curs: this.curs,
				procent_proforma: this.procent_proforma,
				suma: this.oferta.suma,
				val_fara_tva: this.oferta.val_fara_tva,
				val_tva: this.oferta.val_tva,
				valid: this.oferta.valid,
				t_livrare: this.oferta.t_livrare,
				produse_obj: JSON.stringify(this.oferta.produse_obj),
				custom_obj: JSON.stringify(this.oferta.custom_obj),
				debitare_obj: JSON.stringify(this.oferta.debitare_obj),
				transport_obj: JSON.stringify(this.oferta.transport_obj),
			};
			this.$store.dispatch("addProforma", newProforma);
		},
		updateFacturaProforma() {
			let updProforma = {
				proforma_id: this.proforma.id_proforma,
				oferta_id: this.oferta.id,
				data: this.today,
				moneda: this.oferta.moneda,
				curs: this.curs,
				procent_proforma: this.procent_proforma,
				suma: this.oferta.suma,
				val_fara_tva: this.oferta.val_fara_tva,
				val_tva: this.oferta.val_tva,
				valid: this.oferta.valid,
				t_livrare: this.oferta.t_livrare,
				produse_obj: JSON.stringify(this.oferta.produse_obj),
				custom_obj: JSON.stringify(this.oferta.custom_obj),
				debitare_obj: JSON.stringify(this.oferta.debitare_obj),
				transport_obj: JSON.stringify(this.oferta.transport_obj),
			};
			this.$store.dispatch("updProforma", updProforma);
		},

		// handleFileUpload() {
		//   this.image = this.$refs.file.files[0];
		// },
		// sendFile() {
		//   const formData = new FormData();
		//   formData.append("file", this.image);

		//   this.$http
		//     .post(
		//       "https://hub.cuprolli.ro/app_back/index.php/api/proforma/uploadfile",
		//       formData,
		//       {
		//         headers: {
		//           "Content-Type": "multipart/form-data"
		//         }
		//       }
		//     )
		//     .then(function(response) {
		//       console.log(response);
		//     })
		//     .catch(function(error) {
		//       console.error(error);
		//     });
		// },
		createProformaPDF() {
			var imgData = images.data.logo;
			var doc = new jsPDF();

			const printProduct = (produs) => {
				let detalii = produs.detalii;

				if (detalii != undefined && detalii != "") {
					detalii = " - " + detalii;
				} else {
					detalii = "";
				}

				let dim = this.dimensiune_text(produs);
				if (dim == "") {
					dim = produs.dimensiune;
				}

				// For old offers that do not have 'denumire' in object format
				let denumire = "";
				if (produs.denumire.value) {
					denumire = `${produs.denumire.value} ${produs.material}${detalii}, ${dim}, aliaj: ${produs.aliaj}`;
				} else {
					denumire = `${produs.denumire} ${produs.material}${detalii}, ${produs.dimensiune}, aliaj: ${produs.aliaj}`;
				}

				if (denumire.length > 64) {
					denumire = doc.splitTextToSize(denumire, 95);
				}

				doc.text(String(produs.nr), 11, space);
				doc.text(denumire, 18, space);

				doc.text(produs.cantitate + " " + produs.um, 122, space, {
					align: "left",
				});
				doc.text("(" + produs.greutate + " kg)", 122, space + 4, {
					align: "left",
				});
				let pret_unitar = (produs.pret_u * curs).toFixed(2);
				let valoare = (pret_unitar * produs.cantitate).toFixed(2);
				doc.text(pret_unitar + " ron/" + produs.um, 170, space, {
					align: "right",
				});
				doc.text(valoare + " ron", 199, space, {
					align: "right",
				});

				total_fara_tva = total_fara_tva + Number(valoare);

				space = space + 3;
			};

			// add the font to jsPDF
			doc.addFileToVFS("Roboto-Regular.ttf", fonts.robotoRegular);
			doc.addFileToVFS("Roboto-Bold.ttf", fonts.robotoBold);
			doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
			doc.addFont("Roboto-Bold.ttf", "Roboto", "bold");

			// set font
			doc.setFont("Roboto", "normal");
			doc.setTextColor(100);

			// Add Logo
			doc.addImage(imgData, "JPEG", 10, 9, 27, 12);

			//
			// Header - Left side
			doc.setFont("Roboto", "bold");
			doc.setFontSize(10);
			doc.text("Cuprolli Manufacturing srl", 10, 30);
			doc.setFontSize(9);
			doc.setFont("Roboto", "normal");
			doc.text(["CUI/CIF: RO 35671874", "RC: J35/468/2016"], 10, 35, {
				lineHeightFactor: 1.25,
			});
			doc.text(
				["Strada Caprioarei nr.11", "307200 Ghiroda, jud. Timis"],
				10,
				43
			);
			// doc.text("Dumbravita, str. Tarcului nr. 7 ap. 5, jud. Timis", 10, 43);
			doc.setFont("Roboto", "bold");
			doc.text(
				["Banca: Banca Transilvania", "IBAN: RO42 BTRL RONC RT03 3757 8301"],
				10,
				53
			);
			doc.setFont("Roboto", "normal");
			doc.text(
				[
					"Dl/Dna: " + this.proforma.username,
					"Email: " + this.proforma.email_user,
					"Tel: " + this.proforma.tel_user,
				],
				10,
				62,
				{
					lineHeightFactor: 1.35,
				}
			);
			doc.setFont("Roboto", "bold");
			doc.text("Cota TVA: 19%", 10, 78);
			doc.setFont("Roboto", "normal");

			//
			// Header - Right side
			let data = moment(String(this.proforma.data)).format("DD.MM.YYYY");
			doc.setFontSize(16);
			doc.setTextColor(243, 153, 46);
			doc.text("FACTURA  PROFORMA", 130, 15);
			doc.setFontSize(9);
			doc.setTextColor(100);
			doc.text("Nr: " + this.proforma.id_proforma + " / " + data, 130, 20);

			// Client data
			doc.setFont("Roboto", "bold");
			doc.setFontSize(10);
			doc.text("Client", 130, 30);
			doc.setFontSize(9);
			doc.setFont("Roboto", "normal");
			let nume = this.replaceSpecialCh(this.proforma.nume);
			let drop = 35;
			let numeSplit = doc.splitTextToSize(nume, 62);
			doc.text(numeSplit, 130, drop, {
				lineHeightFactor: 1.35,
			});
			if (nume.length <= 44) {
				drop = drop + 5;
			} else {
				drop = drop + 9;
			}

			if (this.proforma.cui) {
				doc.text("CUI/CIF: " + this.proforma.cui, 130, drop);
				drop = drop + 4;
			}

			if (this.proforma.adresa) {
				let adresa = this.replaceSpecialCh(this.proforma.adresa);
				if (this.proforma.oras) {
					adresa += ", " + this.replaceSpecialCh(this.proforma.oras);
				}
				let adresaSplit = doc.splitTextToSize(adresa, 62);
				doc.text(adresaSplit, 130, drop);
				if (adresa.length != 0 && adresa.length <= 44) {
					drop = drop + 4;
				} else if (adresa.length != 0 && adresa.length <= 73) {
					drop = drop + 8;
				} else if (adresa.length != 0) {
					drop = drop + 12;
				}
			}

			drop = drop + 1;

			let details = [];
			if (this.proforma.persoana) {
				details.push("Dl/Dna: " + this.proforma.persoana);
			}
			if (this.proforma.email) {
				details.push("Email: " + this.proforma.email);
			}
			if (this.proforma.tel && this.proforma.tel != "-") {
				details.push("Tel: " + this.formatPhoneNo(this.proforma.tel));
			}
			doc.text(details, 130, drop, {
				lineHeightFactor: 1.35,
			});

			//
			// Products table print
			let curs = this.proforma.moneda == "ron" ? 1 : this.proforma.curs;
			let total_fara_tva = 0;
			let nr_produse = this.proforma.produse_obj.length;
			let x = nr_produse;
			let max_products_firstpage = 18;
			let secondpage = false;

			if (nr_produse > max_products_firstpage) {
				x = max_products_firstpage;
				secondpage = true;
			}

			// Table header
			doc.setFont("Roboto", "bold");
			doc.text("Nr.", 10, 86);
			doc.text("Denumire produs", 18, 86);
			doc.text("Cantitate", 122, 86, {
				align: "left",
			});
			doc.text("Pret unitar", 170, 86, {
				align: "right",
			});
			doc.text("Valoare fara TVA", 200, 86, {
				align: "right",
			});
			doc.setDrawColor(150);
			doc.setLineWidth(0.4);
			doc.line(10, 88, 200, 88);
			let space = 93;
			doc.setFont("Roboto", "normal");

			// Print first page products
			for (let i = 0; i < x; i++) {
				// Products
				printProduct(this.proforma.produse_obj[i]);
				doc.setLineWidth(0.2);
				doc.line(10, space + 3, 200, space + 3);
				space = space + 8;
			}

			// If second page exists print remaining products
			if (secondpage) {
				doc.setTextColor(150);
				doc.text("Continuare pe pagina 2", 18, space);
				doc.addPage();
				doc.setTextColor(100);
				space = 20;
				// Print second page products
				// Table header
				doc.setFont("Roboto", "bold");
				doc.text("Nr.", 10, space);
				doc.text("Denumire produs", 18, space);
				doc.text("Cantitate", 122, 86, {
					align: "left",
				});
				doc.text("Pret unitar", 170, 86, {
					align: "right",
				});
				doc.text("Valoare fara TVA", 200, 86, {
					align: "right",
				});
				doc.setDrawColor(150);
				doc.setLineWidth(0.4);
				doc.line(10, space + 2, 200, space + 2);
				doc.setFont("Roboto", "normal");

				space = space + 7;

				for (let i = max_products_firstpage; i < nr_produse; i++) {
					// Products
					printProduct(this.proforma.produse_obj[i]);
					doc.setLineWidth(0.2);
					doc.line(10, space + 3, 200, space + 3);
					space = space + 8;
				}
			}

			if (this.proforma.custom_obj.length > 0) {
				this.proforma.custom_obj.forEach(function (produs) {
					let detalii = produs.detalii;
					if (detalii != undefined && detalii != "") {
						detalii = " - " + detalii;
					} else {
						detalii = "";
					}

					let dimensiune_text = produs.dimensiune_text;
					if (dimensiune_text != undefined && dimensiune_text != "") {
						dimensiune_text = " - " + dimensiune_text;
					} else {
						dimensiune_text = "";
					}

					doc.text(produs.nr.toString(), 11, space);
					let denumire = `${produs.denumire}${detalii} ${dimensiune_text}`;
					doc.text(denumire, 18, space);

					let pret_unitar = (produs.pret_u * curs).toFixed(2);
					let valoare = (pret_unitar * produs.cantitate).toFixed(2);
					doc.text(produs.cantitate + " " + produs.um, 122, space, {
						align: "left",
					});
					doc.text(pret_unitar + " ron", 170, space, {
						align: "right",
					});
					doc.text(valoare + " ron", 199, space, {
						align: "right",
					});
					total_fara_tva = total_fara_tva + Number(valoare);
					doc.line(10, space + 3, 200, space + 3);
					space = space + 8;
				});
			}

			if (
				this.proforma.debitare_obj != null &&
				this.proforma.debitare_obj.form
			) {
				doc.text(String(this.products_no_proforma + 1), 11, space);
				doc.setFont("Roboto", "bold");
				doc.text(this.proforma.debitare_obj.name, 18, space);
				doc.setFont("Roboto", "normal");
				doc.text(
					this.proforma.debitare_obj.cantitate +
					" " +
					this.proforma.debitare_obj.um,
					122,
					space
				);

				let pretDebitare = (this.proforma.debitare_obj.pret * curs).toFixed(2);
				let valDebitare = (
					pretDebitare * this.proforma.debitare_obj.cantitate
				).toFixed(2);
				doc.text(pretDebitare + " ron", 170, space, {
					align: "right",
				});
				doc.text(valDebitare + " ron", 199, space, {
					align: "right",
				});
				total_fara_tva = total_fara_tva + Number(valDebitare);

				doc.line(10, space + 3, 200, space + 3);
				space = space + 8;
			}

			if (
				this.proforma.transport_obj != null &&
				this.proforma.transport_obj.form
			) {
				doc.text(
					String(
						this.proforma.debitare_obj.form
							? this.products_no_proforma + 2
							: this.products_no_proforma + 1
					),
					11,
					space
				);
				doc.setFont("Roboto", "bold");
				doc.text(this.proforma.transport_obj.name, 18, space);
				doc.setFont("Roboto", "normal");

				doc.text(
					this.proforma.transport_obj.cantitate +
					" " +
					this.proforma.transport_obj.um,
					122,
					space
				);
				let pretTransport = (this.proforma.transport_obj.pret * curs).toFixed(
					2
				);
				let valTransport = (
					pretTransport * this.proforma.transport_obj.cantitate
				).toFixed(2);
				doc.text(pretTransport + " ron", 170, space, {
					align: "right",
				});
				doc.text(valTransport + " ron", 199, space, {
					align: "right",
				});
				total_fara_tva = total_fara_tva + Number(valTransport);
			} else if (this.proforma.transport_obj != null && this.proforma.transport_obj.included) {
				doc.text(
					String(
						this.oferta.debitare_obj.form
							? this.products_no_proforma + 2
							: this.products_no_proforma + 1
					),
					11,
					space
				);
				doc.setFont("Roboto", "bold");
				doc.text("Transport inclus", 18, space);
			} else {
				doc.text(
					String(
						this.oferta.debitare_obj.form
							? this.products_no_proforma + 2
							: this.products_no_proforma + 1
					),
					11,
					space
				);
				doc.setFont("Roboto", "bold");
				doc.text("Nu include transport", 18, space);
			}

			doc.line(10, space + 5, 200, space + 5);
			space = space + 10;

			doc.text("Total fara TVA", 170, space + 2, {
				align: "right",
			});
			doc.text(total_fara_tva.toFixed(2) + " ron", 199, space + 2, {
				align: "right",
			});
			doc.text("TVA", 170, space + 7, {
				align: "right",
			});
			doc.text((total_fara_tva * 0.19).toFixed(2) + " ron", 199, space + 7, {
				align: "right",
			});
			doc.text("TOTAL", 170, space + 12, {
				align: "right",
			});
			doc.text(
				(total_fara_tva + total_fara_tva * 0.19).toFixed(2) + " ron",
				199,
				space + 12,
				{
					align: "right",
				}
			);

			doc.setFont("Roboto", "bold");
			if (this.proforma.t_livrare) {
				doc.text("Termen livrare: " + this.proforma.t_livrare, 12, space + 2);
			}
			if (this.proforma.valid) {
				doc.text("Termen de plata: " + this.proforma.valid, 12, space + 7);
			}
			doc.line(10, space + 18, 200, space + 18);

			space = space + 22;

			if (this.proforma.procent_proforma < 100) {
				doc.text(
					`Total de plata in avans: ${this.proforma.procent_proforma}%`,
					12,
					space + 2
				);
				let proforma_fara_tva =
					(total_fara_tva * this.proforma.procent_proforma) / 100;
				doc.text("Total fara TVA", 170, space + 2, {
					align: "right",
				});
				doc.text(proforma_fara_tva.toFixed(2) + " ron", 199, space + 2, {
					align: "right",
				});
				doc.text("TVA", 170, space + 7, {
					align: "right",
				});
				doc.text(
					(proforma_fara_tva * 0.19).toFixed(2) + " ron",
					199,
					space + 7,
					{
						align: "right",
					}
				);
				doc.text("TOTAL", 170, space + 12, {
					align: "right",
				});
				doc.text(
					(proforma_fara_tva + proforma_fara_tva * 0.19).toFixed(2) + " ron",
					199,
					space + 12,
					{
						align: "right",
					}
				);
			}

			return doc;
		},
		saveProforma() {
			this.changeDocType("Proforma");
			const doc = this.createProformaPDF();
			doc.save(this.proformaName + ".pdf");
		},
		sendProforma() {
			this.changeDocType("Proforma");
			const doc = this.createProformaPDF();
			const pdf = btoa(doc.output());
			const payload = {
				data: pdf,
				id_oferta: this.proforma.id_proforma,
				doctype: this.docType,
				client_mail: this.proforma.email,
				cc_arr: JSON.stringify(this.oferta.cc_obj),
				sender: this.user.email_user,
				bcc_arr: JSON.stringify(this.bccfields),
			};
			// Send doc to php script for emailing forward
			// this.$http
			// 	.post(
			// 		process.env.VUE_APP_HUB_API_HOST + "email/vanzari/oferta",
			// 		payload,
			// 		{
			// 			headers: { x_api_key: process.env.VUE_APP_HUB_API_KEY },
			// 		}
			// 	)
			// 	.then(function (response) {
			// 		console.log(response.body);
			// 		M.toast({ html: response.body, classes: "blue" });
			// 	})
			// 	.catch(function (error) {
			// 		console.log(error.body);
			// 		M.toast({
			// 			html: "Eroare! Proforma nu a fost trimisa",
			// 			classes: "red darken-1",
			// 		});
			// 	});
			console.log('send proforma', payload)
		},
		createOfertaPDF() {
			var imgData = images.data.imgData;
			var doc = new jsPDF();

			// add the font to jsPDF
			doc.addFileToVFS("Roboto-Regular.ttf", fonts.robotoRegular);
			doc.addFileToVFS("Roboto-Bold.ttf", fonts.robotoBold);
			doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
			doc.addFont("Roboto-Bold.ttf", "Roboto", "bold");

			// set font
			doc.setFont("Roboto", "normal");

			//
			// Document - Top bar Info
			// Draw grey header rectangle
			doc.setFillColor(242, 244, 248);
			doc.rect(0, 0, 210, 25, "F");
			// Add Logo
			doc.addImage(imgData, "JPEG", 10, 8, 27, 12);
			// Add Info
			doc.setFontSize(8);
			doc.setTextColor(150);
			doc.text(["CUI: RO 35671874", "RegCom: J35/468/2016"], 52, 14);
			doc.text(
				["Strada Caprioarei nr.11", "307200 Ghiroda, jud. Timis"],
				98,
				14
			);
			doc.text(["Email: office@cuprolli.ro", "Web: www.cuprolli.ro"], 147, 14);
			doc.setDrawColor(243, 153, 46);
			doc.setLineWidth(0.5);
			doc.line(0, 25, 210, 25);

			//
			// Header - Center Info
			doc.setFont("Roboto", "normal");
			let data = moment(String(this.oferta.data)).format("DD.MM.YYYY");

			doc.setFontSize(16);
			doc.setTextColor(243, 153, 46);

			doc.text(this.docType.toUpperCase(), 94, 68);
			doc.setFontSize(9);
			doc.setTextColor(100);
			doc.text("Nr: " + this.oferta.id + " / " + data, 91, 74);

			//
			// Header - Left side
			doc.setFont("Roboto", "bold");
			doc.text("Catre", 10, 35);
			doc.setFont("Roboto", "normal");
			let nume = this.replaceSpecialCh(this.oferta.nume);
			let drop = 40;
			let numeSplit = doc.splitTextToSize(nume, 72);
			doc.text(numeSplit, 10, drop);

			if (numeSplit.length == 1) {
				drop = drop + 5;
			} else {
				drop = drop + 9;
			}

			if (this.oferta.cui) {
				doc.text("CUI/CIF: " + this.oferta.cui, 10, drop);
				drop = drop + 4;
			}

			let adresa = this.replaceSpecialCh(this.oferta.adresa);
			if (this.oferta.oras) {
				adresa += ", " + this.replaceSpecialCh(this.oferta.oras);
			}
			let adresaSplit = doc.splitTextToSize(adresa, 72);
			doc.text(adresaSplit, 10, drop);
			if (adresa.length != 0 && adresa.length <= 44) {
				drop = drop + 4;
				console.log('First ' + adresa.length)
			} else if (adresa.length != 0 && adresa.length <= 73) {
				drop = drop + 8;
				console.log('FirSecst ' + adresa.length)
			} else if (adresa.length != 0) {
				drop = drop + 12;
				console.log('Thr ' + adresa.length)
			}

			drop = drop + 1;

			let details = [];
			if (this.oferta.persoana) {
				details.push("Dl/Dna: " + this.oferta.persoana);
			}
			if (this.oferta.email) {
				details.push("Email: " + this.oferta.email);
			}
			if (this.oferta.tel && this.oferta.tel != "-") {
				details.push("Tel: " + this.formatPhoneNo(this.oferta.tel));
			}
			doc.text(details, 10, drop, {
				lineHeightFactor: 1.4,
			});

			//
			// Header - Right side
			doc.setFont("Roboto", "bold");
			doc.text("Intocmit de:", 147, 35);
			doc.setFont("Roboto", "normal");

			doc.text(
				[
					"Dl/Dna: " + this.oferta.username,
					"Email: " + this.oferta.email_user,
					"Tel: +4 " + this.oferta.tel_user,
				],
				147,
				40,
				{
					lineHeightFactor: 1.4,
				}
			);
			let moneda = this.oferta.moneda;

			//
			// Products table print
			// Products table
			if (this.oferta.produse_obj.length < 12) {
				doc.setFont("Roboto", "bold");
				doc.text("Nr.", 10, 86);
				doc.text("Denumire produs", 18, 86);
				doc.text("Cantitate", 118, 86);
				doc.text("Pret unitar", 136, 86);
				doc.text("Pret/kg", 160, 86);
				doc.text("Valoare", 198, 86, {
					align: "right",
				});
				doc.setDrawColor(150);
				doc.setLineWidth(0.4);
				doc.line(10, 88, 200, 88);

				let space = 93;
				doc.setFont("Roboto", "normal");

				this.oferta.produse_obj.forEach((produs) => {
					let detalii = produs.detalii;
					if (detalii != undefined && detalii != "") {
						detalii = " - " + detalii;
					} else {
						detalii = "";
					}

					let dim = this.dimensiune_text(produs);
					if (dim == "") {
						dim = produs.dimensiune;
					}

					// For old offers that do not have denumire in object format
					let denumire = "";
					if (produs.denumire.value) {
						denumire =
							produs.denumire.value +
							" " +
							produs.material +
							detalii +
							", " +
							dim +
							", aliaj: " +
							produs.aliaj;
					} else {
						denumire =
							produs.denumire +
							" " +
							produs.material +
							detalii +
							", " +
							produs.dimensiune +
							", aliaj: " +
							produs.aliaj;
					}

					let twoLine = false;

					if (denumire.length > 64) {
						if (produs.t_livrare) {
							denumire += " - Livrare: " + produs.t_livrare;
						}
						denumire = doc.splitTextToSize(denumire, 95);
						twoLine = true;
					}

					doc.text(produs.nr.toString(), 11, space);
					if (produs.modified) {
						doc.setTextColor(244, 67, 54);
					}
					doc.text(denumire, 18, space);
					doc.setTextColor(100);
					doc.text(produs.cantitate + " " + produs.um, 118, space);
					doc.text(produs.pret_u + " " + moneda, 136, space, {
						align: "left",
					});
					doc.text(produs.pret_kg + " " + moneda + "/kg", 160, space, {
						align: "left",
					});
					doc.text(produs.pret_t + " " + moneda, 200, space, {
						align: "right",
					});

					if (this.pdfExtraInfo) {
						let tl = 0;
						let curs = this.curs;

						if (this.oferta.moneda == "ron") {
							curs = 1;
						}
						let pret_kg_lei = (produs.pret_kg * curs).toFixed(2);

						if (produs.um != "kg") {
							doc.text(produs.greutate + " kg", 118, space + 4);
							doc.text(pret_kg_lei + " ron", 136, space + 4);
							doc.text(
								(produs.greutate * pret_kg_lei).toFixed(2) + " ron",
								180,
								space + 4
							);
							tl = tl + 1;
						} else if (this.oferta.moneda == "eur") {
							doc.text(pret_kg_lei + " ron", 136, space + 4);
							doc.text(
								(produs.greutate * pret_kg_lei).toFixed(2) + " ron",
								180,
								space + 4
							);
							tl = tl + 1;
						}
						if (tl > 0) {
							twoLine = true;
						}
					}

					if (produs.t_livrare && !twoLine) {
						doc.text("- Livrare: " + produs.t_livrare, 18, space + 4);
						twoLine = true;
					}

					if (twoLine) {
						space = space + 3;
					}
					doc.setLineWidth(0.2);
					doc.line(10, space + 3, 200, space + 3);
					space = space + 8;
				});

				if (this.oferta.custom_obj.length > 0) {
					this.oferta.custom_obj.forEach(function (produs) {
						let detalii = produs.detalii;
						if (detalii != undefined && detalii != "") {
							detalii = " - " + detalii;
						} else {
							detalii = "";
						}

						let dimensiune_text = produs.dimensiune_text;
						if (dimensiune_text != undefined && dimensiune_text != "") {
							dimensiune_text = " - " + dimensiune_text;
						} else {
							dimensiune_text = "";
						}

						doc.text(produs.nr.toString(), 11, space);
						let denumire = `${produs.denumire}${detalii} ${dimensiune_text}`;
						doc.text(denumire, 18, space);
						doc.text(produs.cantitate + " " + produs.um, 118, space);
						doc.text(produs.pret_u + " " + moneda, 136, space);
						doc.text("-", 164, space);
						doc.text(produs.pret_t + " " + moneda, 200, space, {
							align: "right",
						});

						doc.line(10, space + 3, 200, space + 3);
						space = space + 8;
					});
				}

				if (this.oferta.debitare_obj != null && this.oferta.debitare_obj.form) {
					doc.text(String(this.products_no_oferta + 1), 11, space);
					doc.setFont("Roboto", "bold");
					doc.text(this.oferta.debitare_obj.name, 18, space);
					doc.setFont("Roboto", "normal");
					doc.text(
						this.oferta.debitare_obj.cantitate +
						" " +
						this.oferta.debitare_obj.um,
						119,
						space
					);
					doc.text(this.oferta.debitare_obj.pret + " " + moneda, 136, space);
					doc.text("-", 164, space);
					doc.text(this.oferta.debitare_obj.val + " " + moneda, 200, space, {
						align: "right",
					});

					doc.line(10, space + 3, 200, space + 3);
					space = space + 8;
				}

				if (
					this.oferta.transport_obj != null &&
					this.oferta.transport_obj.form
				) {
					doc.text(
						String(
							this.oferta.debitare_obj.form
								? this.products_no_oferta + 2
								: this.products_no_oferta + 1
						),
						11,
						space
					);
					doc.setFont("Roboto", "bold");
					doc.text(this.oferta.transport_obj.name, 18, space);
					doc.setFont("Roboto", "normal");
					doc.text(
						this.oferta.transport_obj.cantitate +
						" " +
						this.oferta.transport_obj.um,
						119,
						space
					);
					doc.text(this.oferta.transport_obj.pret + " " + moneda, 136, space);
					doc.text("-", 164, space);
					doc.text(this.oferta.transport_obj.val + " " + moneda, 200, space, {
						align: "right",
					});
				} else if (this.oferta.transport_obj != null && this.oferta.transport_obj.included) {
					doc.text(
						String(
							this.oferta.debitare_obj.form
								? this.products_no_oferta + 2
								: this.products_no_oferta + 1
						),
						11,
						space
					);
					doc.setFont("Roboto", "bold");
					doc.text("Transport inclus", 18, space);
				} else {
					doc.text(
						String(
							this.oferta.debitare_obj.form
								? this.products_no_oferta + 2
								: this.products_no_oferta + 1
						),
						11,
						space
					);
					doc.setFont("Roboto", "bold");
					doc.text("Nu include transport", 18, space);
				}
				doc.line(10, space + 3, 200, space + 3);
				space = space + 8;

				doc.setFont("Roboto", "bold");
				doc.text("Total fara TVA", 153, space + 2);
				doc.text(
					this.oferta.val_fara_tva + " " + this.oferta.moneda,
					200,
					space + 2, { align: "right" }
				);
				doc.setFont("Roboto", "normal");
				doc.text("TVA", 168, space + 7);
				doc.text(
					this.oferta.val_tva + " " + this.oferta.moneda,
					200,
					space + 7, { align: "right" }
				);
				doc.setFont("Roboto", "bold");
				doc.text("TOTAL", 164, space + 12);
				doc.text(this.oferta.suma + " " + this.oferta.moneda, 200, space + 12, { align: "right" });
				doc.line(10, space + 16, 200, space + 16);

				// Products footer
				doc.setFont("Roboto", "normal");
				if (this.oferta.t_livrare) {
					doc.text("Conditii livrare: " + this.oferta.t_livrare, 10, space + 2);
					if (this.oferta.t_plata && this.oferta.valid) {
						doc.text("Conditii plata: " + this.oferta.t_plata, 10, space + 7);
						doc.text(
							"Valabilitate oferta: " + this.oferta.valid,
							10,
							space + 12
						);
					} else if (this.oferta.t_plata) {
						doc.text("Conditii plata: " + this.oferta.t_plata, 10, space + 7);
					} else if (this.oferta.valid) {
						doc.text(
							"Valabilitate oferta: " + this.oferta.valid,
							10,
							space + 7
						);
					}
				} else {
					if (this.oferta.t_plata && this.oferta.valid) {
						doc.text("Conditii plata: " + this.oferta.t_plata, 10, space + 2);
						doc.text(
							"Valabilitate oferta: " + this.oferta.valid,
							10,
							space + 7
						);
					} else if (this.oferta.t_plata) {
						doc.text("Conditii plata: " + this.oferta.t_plata, 10, space + 2);
					} else if (this.oferta.valid) {
						doc.text(
							"Valabilitate oferta: " + this.oferta.valid,
							10,
							space + 2
						);
					}
				}

				space = space + 23;

				if (this.oferta.obs && this.oferta.moneda == "eur") {
					let obs_pdf = this.oferta.obs;
					let obsSplit = doc.splitTextToSize(obs_pdf);
					doc.setFont("Roboto", "bold");
					doc.text("Observatii: " + obsSplit, 10, space);
					space = space + 5;
					if (obs_pdf.length > 244) {
						space = space + 10;
					} else if (obs_pdf.length > 118) {
						space = space + 5;
					}

					doc.setFont("Roboto", "normal");
					let currencyText =
						"* Valoarea finala a facturii va fi calculata in lei la cursul de schimb al Bancii Transilvania din data facturarii.";
					doc.setFontSize(8);
					doc.text(currencyText, 10, space);
					doc.setFontSize(9);
				} else if (this.oferta.obs && this.oferta.moneda != "eur") {
					let obs_pdf = this.oferta.obs;
					let obsSplit = doc.splitTextToSize(obs_pdf);
					doc.setFont("Roboto", "bold");
					doc.text("Observatii: " + obsSplit, 10, space);
				} else if (this.oferta.moneda == "eur") {
					doc.setFont("Roboto", "normal");
					let currencyText =
						"* Valoarea finala a facturii va fi calculata in lei la cursul de schimb al Bancii Transilvania din data facturarii.";
					doc.setFontSize(8);
					doc.text(currencyText, 10, space);
					doc.setFontSize(9);
				}

				space = space + 12;
				// doc.text('space: '+space, 70, space)

				doc.setDrawColor(150);
				doc.setLineWidth(0.3);
				doc.setLineDash([1, 1], 0);
				doc.line(10, space, 200, space);

				doc.setLineDash([], 0);
				doc.setFontSize(10);
				doc.setFont("Roboto", "normal");
				doc.text("Oferim urmatoarele servicii:", 10, space + 7);
				doc.setFont("Roboto", "bold");
				doc.setFontSize(9);
				doc.text("DEBITARE:", 10, space + 15);
				doc.setFontSize(9);
				doc.text("Pentru tabla dreapta sau cu striatii:", 10, space + 20);
				doc.setFont("Roboto", "normal");
				doc.text("- aluminiu: pana la grosimea de 8 mm", 10, space + 24);
				doc.text("Lungime Ghilotina: 3000 mm", 10, space + 28);

				doc.setFont("Roboto", "bold");
				doc.text("Pentru profile:", 10, space + 36);
				doc.setFont("Roboto", "normal");
				doc.text("- aluminiu", 10, space + 40);
				doc.text("- debitare dreapta sau in unghi", 10, space + 44);

				doc.setFont("Roboto", "bold");
				doc.setFontSize(9);
				doc.text("INDOIRE:", 100, space + 15);
				doc.setFontSize(9);
				doc.text("Pentru tabla dreapta sau cu striatii:", 100, space + 20);
				doc.setFont("Roboto", "normal");
				doc.text("- aluminiu: pana la grosimea de 8 mm", 100, space + 24);
				doc.text("Lungime Abkant: 3000 mm", 100, space + 28);

				space = space + 40;

				if (space > 217) {
					doc.setFont("Roboto", "normal");
					doc.text("Continuare pe pagina urmatoare", 8, space + 10);
					doc.addPage();
					// doc.addImage(imgSide, 'JPEG', 170, 10, 40, 160)
					// doc.text("Print image - 2", 30, 294)
					space = 10;
				}

				space = space + 10;

				// Document footer
				doc.setFont("Roboto", "bold");
				doc.setFontSize(10);
				doc.setTextColor(100);
				doc.setLineWidth(0.3);
				doc.setLineDash([1, 1], 0);
				doc.line(10, space, 200, space);

				doc.setLineDash([], 0);
				doc.addImage(images.data.gard, "JPEG", 100, space + 10, 100, 31);
				space = space + 7;
				doc.text("GARDURI SI PORTI DIN ALUMINIU", 10, space + 10);
				doc.setFontSize(9);
				doc.setFont("Roboto", "normal");
				doc.text("CONFIGURATOR ONLINE", 10, space + 15);

				doc.text("--> https://garduri-aluminiu.ro", 10, space + 22);
				doc.text("--> https://configurator-gard.cuprolli.ro", 10, space + 27);
				space = space + 27;

				if (space > 217) {
					doc.setFont("Roboto", "normal");
					doc.text("Continuare pe pagina urmatoare", 8, 280);
					doc.addPage();
					// doc.addImage(imgSide, 'JPEG', 170, 10, 40, 160)
					// doc.text("Print image - 2", 30, 294)
					space = 10;
				}

				carport_ad.print_carport_ad(doc, space + 6, images);
				space += 70
				mag_pubele_ad.print_mag_pubele_ad(doc, space + 6, images);
			} else {
				// - if produse_obj.length > 12 -
				// Products table
				doc.setFont("Roboto", "bold");
				doc.text("Nr.", 10, 86);
				doc.text("Denumire produs", 18, 86);
				doc.text("Cantitate", 119, 86);
				doc.text("Pret unitar", 138, 86);
				doc.text("Pret/kg", 164, 86);
				doc.text("Valoare", 198, 86, {
					align: "right",
				});
				doc.setDrawColor(150);
				doc.setLineWidth(0.4);
				doc.line(10, 92, 200, 92);

				let space = 98;
				doc.setFont("Roboto", "normal");

				this.oferta.produse_obj.forEach((produs, idx) => {
					if (idx <= 13) {
						let detalii = produs.detalii;
						if (detalii != undefined && detalii != "") {
							detalii = " - " + detalii;
						} else {
							detalii = "";
						}

						let dim = this.dimensiune_text(produs);
						if (dim == "") {
							dim = produs.dimensiune;
						}

						// For old offers that are not denumire in object format
						let denumire = "";
						if (produs.denumire.value) {
							denumire =
								produs.denumire.value +
								" " +
								produs.material +
								detalii +
								", " +
								dim +
								", aliaj: " +
								produs.aliaj;
						} else {
							denumire =
								produs.denumire +
								" " +
								produs.material +
								detalii +
								", " +
								produs.dimensiune +
								", aliaj: " +
								produs.aliaj;
						}

						let twoLine = false;

						if (denumire.length > 64) {
							if (produs.t_livrare) {
								denumire += " - Livrare: " + produs.t_livrare;
							}
							denumire = doc.splitTextToSize(denumire, 95);
							twoLine = true;
						}

						doc.text(produs.nr.toString(), 11, space);
						if (produs.modified) {
							doc.setTextColor(244, 67, 54);
						}
						doc.text(denumire, 18, space);
						doc.setTextColor(100);
						doc.text(produs.cantitate + " " + produs.um, 119, space);
						doc.text(produs.pret_u + " " + moneda, 136, space, {
							align: "left",
						});
						doc.text(produs.pret_kg + " " + moneda + "/kg", 160, space, {
							align: "left",
						});
						doc.text(produs.pret_t + " " + moneda, 200, space, {
							align: "right",
						});

						if (this.pdfExtraInfo) {
							doc.text(produs.greutate + " kg", 119, space + 4);
							let pret_kg_lei = (produs.pret_kg * this.curs).toFixed(2);
							doc.text(pret_kg_lei + " lei", 136, space + 4);
							doc.text(
								(produs.greutate * pret_kg_lei).toFixed(2) + " lei",
								180,
								space + 4
							);
							twoLine = true;
						}

						if (produs.t_livrare && !twoLine) {
							doc.text("- Livrare: " + produs.t_livrare, 18, space + 4);
							twoLine = true;
						}

						if (twoLine) {
							space = space + 3;
						}

						doc.setLineWidth(0.2);
						doc.line(10, space + 3, 200, space + 3);
						space = space + 8;
					}
				});
				doc.setTextColor(150);
				doc.text("Continuare pe pagina 2 ", 18, space);

				doc.addPage();

				// Document header
				// Draw grey header rectangle
				doc.setFillColor(242, 244, 248);
				doc.rect(0, 0, 210, 25, "F");

				// Logo
				doc.addImage(imgData, "JPEG", 10, 9, 27, 12);
				doc.setFont("Roboto", "normal");
				// Header info
				doc.setFontSize(8);
				doc.setTextColor(150);
				doc.text(
					["Strada Caprioarei nr.11", "307200 Ghiroda, jud. Timis"],
					90,
					14
				);
				doc.text(
					["Email: office@cuprolli.ro", "Web: www.cuprolli.ro"],
					140,
					14
				);
				doc.setDrawColor(243, 153, 46);
				doc.setLineWidth(0.5);
				doc.line(0, 25, 210, 25);

				doc.setDrawColor(150);
				doc.setLineWidth(0.4);
				doc.setTextColor(100);
				doc.setFont("Roboto", "normal");
				doc.setFontSize(9);
				space = 40;

				this.oferta.produse_obj.forEach((produs, idx) => {
					if (idx > 13) {
						let detalii = produs.detalii;
						if (detalii != undefined && detalii != "") {
							detalii = " - " + detalii;
						} else {
							detalii = "";
						}

						let dim = this.dimensiune_text(produs);
						if (dim == "") {
							dim = produs.dimensiune;
						}

						// For old offers that are not denumire in object format
						let denumire = "";
						if (produs.denumire.value) {
							denumire =
								produs.denumire.value +
								" " +
								produs.material +
								detalii +
								", " +
								dim +
								", aliaj: " +
								produs.aliaj;
						} else {
							denumire =
								produs.denumire +
								" " +
								produs.material +
								detalii +
								", " +
								produs.dimensiune +
								", aliaj: " +
								produs.aliaj;
						}

						let twoLine = false;

						if (denumire.length > 64) {
							if (produs.t_livrare) {
								denumire += " - Livrare: " + produs.t_livrare;
							}
							denumire = doc.splitTextToSize(denumire, 95);
							twoLine = true;
						}

						doc.text(produs.nr.toString(), 11, space);
						if (produs.modified) {
							doc.setTextColor(244, 67, 54);
						}
						doc.text(denumire, 18, space);
						doc.setTextColor(100);
						doc.text(produs.cantitate + " " + produs.um, 119, space);
						doc.text(produs.pret_u + " " + moneda, 136, space, {
							align: "left",
						});
						doc.text(produs.pret_kg + " " + moneda + "/kg", 160, space, {
							align: "left",
						});
						doc.text(produs.pret_t + " " + moneda, 200, space, {
							align: "right",
						});

						if (this.pdfExtraInfo) {
							doc.text(produs.greutate + " kg", 119, space + 4);
							let pret_kg_lei = (produs.pret_kg * this.curs).toFixed(2);
							doc.text(pret_kg_lei + " lei", 136, space + 4);
							doc.text(
								(produs.greutate * pret_kg_lei).toFixed(2) + " lei",
								180,
								space + 4
							);
							twoLine = true;
						}

						if (produs.t_livrare && !twoLine) {
							doc.text("- Livrare: " + produs.t_livrare, 18, space + 4);
							twoLine = true;
						}

						if (twoLine) {
							space = space + 3;
						}

						doc.setLineWidth(0.2);
						doc.line(10, space + 3, 200, space + 3);
						space = space + 8;
					}
				});

				if (this.oferta.custom_obj.length > 0) {
					this.oferta.custom_obj.forEach(function (produs) {
						doc.text(produs.nr.toString(), 11, space);
						let denumire = `${produs.denumire} - ${produs.dimensiune_text}`;
						doc.text(denumire, 18, space);
						doc.text(produs.cantitate + " " + produs.um, 119, space);
						doc.text(produs.pret_u + " " + moneda, 136, space);
						doc.text("-", 164, space);
						doc.text(produs.pret_t + " " + moneda, 200, space, {
							align: "right",
						});

						doc.line(10, space + 3, 200, space + 3);
						space = space + 8;
					});
				}

				if (this.oferta.debitare_obj != null && this.oferta.debitare_obj.form) {
					doc.text(String(this.products_no_oferta + 1), 11, space);
					doc.setFont("Roboto", "bold");
					doc.text(this.oferta.debitare_obj.name, 18, space);
					doc.setFont("Roboto", "normal");
					doc.text(
						this.oferta.debitare_obj.cantitate +
						" " +
						this.oferta.debitare_obj.um,
						119,
						space
					);
					doc.text(this.oferta.debitare_obj.pret + " " + moneda, 136, space);
					doc.text("-", 164, space);
					doc.text(this.oferta.debitare_obj.val + " " + moneda, 200, space);

					doc.line(10, space + 3, 200, space + 3);
					space = space + 8;
				}

				if (
					this.oferta.transport_obj != null &&
					this.oferta.transport_obj.form
				) {
					doc.text(
						String(
							this.oferta.debitare_obj.form
								? this.products_no_oferta + 2
								: this.products_no_oferta + 1
						),
						11,
						space
					);
					doc.setFont("Roboto", "bold");
					doc.text(this.oferta.transport_obj.name, 18, space);
					doc.setFont("Roboto", "normal");
					doc.text(
						this.oferta.transport_obj.cantitate +
						" " +
						this.oferta.transport_obj.um,
						119,
						space
					);
					doc.text(this.oferta.transport_obj.pret + " " + moneda, 136, space);
					doc.text("-", 164, space);
					doc.text(this.oferta.transport_obj.val + " " + moneda, 200, space, {
						align: "right",
					});
				} else if (this.oferta.transport_obj != null && this.oferta.transport_obj.included) {
					doc.text(
						String(
							this.oferta.debitare_obj.form
								? this.products_no_oferta + 2
								: this.products_no_oferta + 1
						),
						11,
						space
					);
					doc.setFont("Roboto", "bold");
					doc.text("Transport inclus", 18, space);
				} else {
					doc.text(
						String(
							this.oferta.debitare_obj.form
								? this.products_no_oferta + 2
								: this.products_no_oferta + 1
						),
						11,
						space
					);
					doc.setFont("Roboto", "bold");
					doc.text("Nu include transport", 18, space);
				}
				doc.line(10, space + 3, 200, space + 3);
				space = space + 8;

				doc.setFont("Roboto", "bold");
				doc.text("Total fara TVA", 153, space + 2);
				doc.text(
					this.oferta.val_fara_tva + " " + this.oferta.moneda,
					200,
					space + 2, { align: "right" }
				);
				doc.setFont("Roboto", "normal");
				doc.text("TVA", 168, space + 7);
				doc.text(
					this.oferta.val_tva + " " + this.oferta.moneda,
					200,
					space + 7, { align: "right" }
				);
				doc.setFont("Roboto", "bold");
				doc.text("TOTAL", 164, space + 12);
				doc.text(this.oferta.suma + " " + this.oferta.moneda, 200, space + 12, { align: "right" });
				doc.line(10, space + 16, 200, space + 16);

				// Products footer
				doc.setFont("Roboto", "normal");
				doc.text("Conditii livrare: " + this.oferta.t_livrare, 10, space + 2);
				doc.text("Conditii plata: " + this.oferta.t_plata, 10, space + 7);
				if (this.oferta.valid) {
					doc.text("Valabilitate oferta: " + this.oferta.valid, 10, space + 12);
				}

				if (this.oferta.obs && this.oferta.moneda == "eur") {
					let obs_pdf = this.oferta.obs;
					let obsSplit = doc.splitTextToSize(obs_pdf);
					doc.setFont("Roboto", "bold");
					doc.text("Observatii: " + obsSplit, 10, space + 25);
					space = space + 5;
					if (obs_pdf.length > 244) {
						space = space + 10;
					} else if (obs_pdf.length > 118) {
						space = space + 5;
					}

					doc.setFont("Roboto", "normal");
					let currencyText =
						"* Valoarea finala a facturii va fi calculata in lei la cursul de schimb al Bancii Transilvania din data facturarii.";
					doc.setFontSize(8);
					doc.text(currencyText, 10, space + 25);
					doc.setFontSize(9);
				} else if (this.oferta.obs && this.oferta.moneda != "eur") {
					let obs_pdf = this.oferta.obs;
					let obsSplit = doc.splitTextToSize(obs_pdf);
					doc.setFont("Roboto", "bold");
					doc.text("Observatii: " + obsSplit, 10, space + 25);
				} else if (this.oferta.moneda == "eur") {
					doc.setFont("Roboto", "normal");
					let currencyText =
						"* Valoarea finala a facturii va fi calculata in lei la cursul de schimb al Bancii Transilvania din data facturarii.";
					doc.setFontSize(8);
					doc.text(currencyText, 10, space + 28);
					doc.setFontSize(9);
				}

				// if (this.oferta.obs && this.oferta.moneda == "eur") {
				// } else if (this.oferta.obs && this.oferta.moneda != "eur") {
				// } else if (this.oferta.moneda == "eur") {
				// }

				space = space + 45;

				// doc.text("space: " + space, 70, space);

				doc.setDrawColor(150);
				doc.setLineWidth(0.3);
				doc.setLineDash([1, 1], 0);
				doc.line(10, space, 200, space);

				doc.setLineDash([], 0);
				doc.setFontSize(10);
				doc.setFont("Roboto", "normal");
				doc.text("Oferim urmatoarele servicii:", 10, space + 7);
				doc.setFont("Roboto", "bold");
				doc.setFontSize(9);
				doc.text("DEBITARE:", 10, space + 15);
				doc.setFontSize(9);
				doc.text("Pentru tabla dreapta sau cu striatii:", 10, space + 20);
				doc.setFont("Roboto", "normal");
				doc.text("- aluminiu: pana la grosimea de 8 mm", 10, space + 24);
				doc.text("Lungime Ghilotina: 3000 mm", 10, space + 28);

				doc.setFont("Roboto", "bold");
				doc.text("Pentru profile:", 10, space + 42);
				doc.setFont("Roboto", "normal");
				doc.text("- aluminiu", 10, space + 46);
				doc.text("- debitare dreapta sau in unghi", 10, space + 50);

				doc.setFont("Roboto", "bold");
				doc.setFontSize(9);
				doc.text("INDOIRE:", 100, space + 15);
				doc.setFontSize(9);
				doc.text("Pentru tabla dreapta sau cu striatii:", 100, space + 20);
				doc.setFont("Roboto", "normal");
				doc.text("- aluminiu: pana la grosimea de 8 mm", 100, space + 24);
				doc.text("Lungime Abkant: 3000 mm", 100, space + 28);

				space = space + 60;

				if (space > 217) {
					doc.setFont("Roboto", "normal");
					doc.text("Continuare pe pagina urmatoare", 8, space + 10);
					doc.addPage();
					// doc.addImage(imgSide, 'JPEG', 170, 10, 40, 160)
					// doc.text("Print image - 2", 30, 294)
					space = 10;
				}

				space = space + 10;

				// Document footer
				doc.setFont("Roboto", "bold");
				doc.setFontSize(10);
				doc.setTextColor(100);
				doc.setLineWidth(0.3);
				doc.setLineDash([1, 1], 0);
				doc.line(10, space, 200, space);

				doc.setLineDash([], 0);
				doc.addImage(images.data.gard, "JPEG", 100, space + 10, 100, 31);
				space = space + 7;
				doc.text("GARDURI SI PORTI DIN ALUMINIU", 10, space + 10);
				doc.setFontSize(9);
				doc.setFont("Roboto", "normal");
				doc.text("CONFIGURATOR ONLINE", 10, space + 15);

				doc.text("--> https://garduri-aluminiu.ro", 10, space + 22);
				doc.text("--> https://configurator-gard.cuprolli.ro", 10, space + 27);

				space = space + 24;

				if (space > 217) {
					doc.setFont("Roboto", "normal");
					doc.text("Continuare pe pagina urmatoare", 8, 280);
					doc.addPage();
					// doc.addImage(imgSide, 'JPEG', 170, 10, 40, 160)
					// doc.text("Print image - 2", 30, 294)
					space = 10;
				}

				carport_ad.print_carport_ad(doc, space + 6, images);
				space += 70
				mag_pubele_ad.print_mag_pubele_ad(doc, space + 6, images);
			}

			return doc;
		},
		downloadOfertaPDF() {
			this.changeDocType("Oferta");
			const doc = this.createOfertaPDF();
			doc.save(this.pdfName + ".pdf");
		},
		sendWhatsapp() {
			this.changeDocType("Oferta");
			console.log("sendWhatsapp");
		},
		emailOfertaPDF() {
			this.changeDocType("Oferta");
			this.sendingEmail = true
			const doc = this.createOfertaPDF();
			const pdf = btoa(doc.output());
			const payload = {
				data: pdf,
				id_oferta: this.oferta.id,
				doctype: this.docType,
				client_mail: this.oferta.email,
				cc_arr: JSON.stringify(this.oferta.cc_obj),
				sender: this.user.email_user,
				bcc_arr: JSON.stringify(this.bccfields),
			};
			// Send doc to php script for emailing forward
			this.$http
				.post(
					process.env.VUE_APP_HUB_API_HOST + "email/vanzari/oferta",
					payload,
					{
						headers: { x_api_key: process.env.VUE_APP_HUB_API_KEY },
					}
				)
				.then(function (response) {
					console.log(response.body);
					M.toast({ html: response.body, classes: "blue" });
					this.sendingEmail = false
				})
				.catch(function (error) {
					console.log(error.body);
					this.sendingEmail = false
					M.toast({
						html: "Eroare! Oferta nu a fost trimisa",
						classes: "red darken-1",
					});
				});
		},
		emailFactAvans(newInvoice) {
			this.pdfExtraInfo = true;
			const doc = this.createOfertaPDF();
			const pdf = btoa(doc.output());
			this.pdfExtraInfo = false;
			// Send doc to php script for emailing forward
			let obj = {
				data: pdf,
				id_oferta: this.oferta.id,
				client: this.oferta.nume,
				tip_factura: newInvoice.typeInvoice,
				obs: newInvoice.obs,
			};
			if (newInvoice.invoiceMsg != "") {
				obj.avans_msg = newInvoice.invoiceMsg;
			}
			this.$http
				.post(
					process.env.VUE_APP_HUB_API_HOST + "email/vanzari/factura_avans",
					obj,
					{
						headers: { x_api_key: process.env.VUE_APP_HUB_API_KEY },
					}
				)
				.then(function (response) {
					this.oferta.status_factura_avans = moment()
						.format()
						.slice(0, 19)
						.replace("T", " ");
					let ultimulStatus = {
						ultimul_status: this.oferta.status_factura_avans,
						id: this.oferta.id,
					};
					this.$store.dispatch("updOfertaStatusFactAvans", ultimulStatus);

					M.toast({ html: response.body, classes: "blue" });
				})
				.catch(function (error) {
					console.log(error.body);
					M.toast({
						html: "Eroare! Oferta nu a fost trimisa",
						classes: "red darken-1",
					});
				});

		},
		fetchOferta(id) {
			this.$store.dispatch("fetchOferta", id);
		},
		deleteOferta(id) {
			this.$store.dispatch("deleteOferta", id);
			this.visibleDeleteModal = false
		},
		furnizorUnic(value) {
			this.matDepozit.forEach(function (produs) {
				produs.furnizor = value;
			});
		},
		addComanda() {
			let products = this.oferta.produse_obj;
			products.forEach((produs, index) => {
				produs.dimensiune = produs.dim_str;
				produs.status_livrare = "in asteptare";
				produs.furnizor = this.matDepozit[index].furnizor;
			});
			let customProd = this.oferta.custom_obj;
			customProd.forEach((produs) => {
				produs.status_livrare = "in asteptare";
			});
			let newComanda = {
				client_id: this.oferta.id_client,
				oferta_id: this.oferta.id,
				data: this.today,
				firma: this.oferta.firma,
				stare: "activa",
				moneda: this.oferta.moneda,
				suma: this.oferta.suma,
				val_fara_tva: this.oferta.val_fara_tva,
				val_tva: this.oferta.val_tva,
				p_avans: null,
				suma_avans: 0,
				suma_termen: this.oferta.suma,
				t_plata: null,
				transport: null,
				obs: null,
				user_id: this.oferta.id_user,
				produse_obj: JSON.stringify(products),
				custom_obj: JSON.stringify(this.oferta.custom_obj),
				transport_obj: JSON.stringify(this.oferta.transport_obj),
			};
			// console.log('newComanda', newComanda);
			this.$store.dispatch("addComanda", newComanda);
		},
		dataStr(date) {
			return date;
		},
	},
	created: function () {
		this.fetchOferta(this.$route.params.id);
		this.$store.dispatch("fetchCurrency");
	},
	mounted: function () {
		M.Modal.init(document.querySelectorAll(".modal"));
	},
	updated: function () {
		M.Dropdown.init(document.querySelectorAll(".dropdown-trigger"), {
			constrainWidth: false,
		});
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.doc-header .v-list .v-list-item {
	min-height: fit-content;
	padding: 0;
}

.card-panel {
	padding: 15px 50px;
}

.document-header ul li {
	margin: 5px 0;
}

.ccfield input {
	display: inline-block;
	width: 50% !important;
}

li .btn-text {
	display: block;
}

hr {
	color: #d5d5d5;
}

#dropdown {
	transform: translate(0, -25px);
}

#facturi,
#pdf {
	transform: translate(0, -40px);
}

.alert-msg {
	padding: 2px 10px;
}
</style>
