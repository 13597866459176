export default {
  version: (state) => {
    return state.version
  },
  today: (state) => {
    return state.today
  },
  loading: (state) => {
    return state.loading;
  },
  loadingCompanyDetails: (state) => {
    return state.loadingCompanyDetails;
  },
  loadingOferte: (state) => {
    return state.loadingOferte
  },
  isOffersSearchList: (state) => {
    return state.isOffersSearchList
  },
  loadingOferta: (state) => {
    return state.loadingOferta
  },
  loadingClienti: (state) => {
    return state.loadingClienti
  },
  loadingClient: (state) => {
    return state.loadingClient
  },
  loadingCereri: (state) => {
    return state.loadingCereri
  },
  loadingCerere: (state) => {
    return state.loadingCerere
  },
  loadingAliaje: (state) => {
    return state.loadingAliaje;
  },
  loadingReports: (state) => {
    return state.loadingReports
  },
  loadingOferteGarduri: (state) => {
    return state.loadingOferteGarduri
  },
  isFenceOffersSearchList: (state) => {
    return state.isFenceOffersSearchList
  },
  loadingOfertaGarduri: (state) => {
    return state.loadingOfertaGarduri
  },
  loadingOferteConfigurator: (state) => {
    return state.loadingOferteConfigurator
  },
  loadingListaReveniriConfigurator: (state) => {
    return state.loadingListaReveniriConfigurator
  },
  loadingOferteConfiguratorEU: (state) => {
    return state.loadingOferteConfiguratorEU
  },
  loggedUser: (state) => {
    return state.user;
  },
  userLoaded: (state) => {
    return state.userLoaded;
  },
  users: (state) => {
    return state.users;
  },
  wpCereri: (state) => {
    return state.wpCereri;
  },
  wpDetails: (state) => {
    return state.wpDetails;
  },
  wpCerere: (state) => {
    return state.wpCerere;
  },
  wpOrderReports: (state) => {
    return state.wpOrderReports
  },
  arrayBuc: (state) => {
    return state.arrayBuc;
  },
  arr_benzi: (state) => {
    return state.arr_benzi;
  },
  arr_bareP: (state) => {
    return state.arr_bareP;
  },
  arr_table: (state) => {
    return state.arr_table;
  },
  arr_teviP: (state) => {
    return state.arr_teviP;
  },
  arr_teviR: (state) => {
    return state.arr_teviR;
  },
  arr_profileLT: (state) => {
    return state.arr_profileLT;
  },
  arr_profile_preturi: (state) => {
    return state.arr_profile_preturi
  },
  arr_table_preturi: (state) => {
    return state.arr_table_preturi
  },
  arr_profile_vopsite_preturi: (state) => {
    return state.arr_profile_vopsite_preturi
  },
  oferte: (state) => {
    return state.oferte;
  },
  ofertePage: (state) => {
    return state.ofertePage
  },
  oferta: (state) => {
    return state.oferta;
  },
  proforme: (state) => {
    return state.proforme;
  },
  proforma: (state) => {
    return state.proforma;
  },
  proformeProductie: (state) => {
    return state.proformeProductie;
  },
  proformaProductie: (state) => {
    return state.proformaProductie;
  },
  contracteProductie: (state) => {
    return state.contracteProductie;
  },
  contractProductie: (state) => {
    return state.contractProductie;
  },
  aditionalExtra: (state) => {
    return state.aditionalExtra
  },
  comenziActive: (state) => {
    return state.comenzi_active;
  },
  comenziFinalizate: (state) => {
    return state.comenzi_finalizate;
  },
  comenziByUser: (state) => {
    return state.comenzi_by_user;
  },
  currentComenziByUser: (state) => {
    return state.current_comenzi_by_user;
  },
  comenziBonus: (state) => {
    return state.comenzi_bonus;
  },
  comanda: (state) => {
    return state.comanda;
  },
  contract: (state) => {
    return state.contract;
  },
  factura: (state) => {
    return state.factura;
  },
  awb: (state) => {
    return state.awb;
  },
  curs: (state) => {
    return state.curs.curs;
  },
  bonus: (state) => {
    return state.bonus;
  },
  clientiCount: (state) => {
    return state.clientiCount
  },
  selectClienti: (state) => {
    let clientiArray = [];
    state.clienti.forEach(function (item) {
      let obj = {
        value: item.id_client,
        text: item.nume,
      };
      clientiArray.push(obj);
    });
    return clientiArray.sort(function (a, b) {
      if (a.text < b.text) {
        return -1;
      }
      if (a.text > b.text) {
        return 1;
      }
      return 0;
    });
  },
  clienti: (state) => {
    return state.clientiData;
  },
  client: (state) => {
    return state.client;
  },
  returnClientId: (state) => {
    return state.returnClientId;
  },
  profile: (state) => {
    return state.profile;
  },
  profil: (state) => {
    return state.profil;
  },
  selectProfile: (state) => {
    let profileArray = [];
    state.profile.forEach(function (item) {
      let obj = {
        value: item.profil_id,
        text: item.profil_tip,
      };
      profileArray.push(obj);
    });
    return profileArray;
  },
  selectCote: (state, getters) => (id) => {
    let findProfil = getters.profile.find((obj) => {
      return obj.profil_id === id;
    });
    let coteArray = [];
    findProfil.profil_cote.forEach(function (item) {
      let obj = {
        value: item.cota,
        text: item.cota + " mm",
      };
      coteArray.push(obj);
    });
    return coteArray;
  },
  echipeDeb: (state) => {
    return state.echipeDeb;
  },

  stocProfile: (state) => {
    return state.stocProfile;
  },
  selectStocProfile: (state, getters) => {
    let stocArray = [];
    let i = 1;
    getters.listaStocProfile.forEach(function (item) {
      let obj = {
        nr: i,
        stoc_id: item.stoc_id,
        profil_id: item.profil_id,
        profil_tip: item.profil_tip,
        cota_profil: item.cota_profil,
        cantitate: item.cantitate,
        comenzi_obj: item.comenzi_obj,
      };
      i++;
      stocArray.push(obj);
    });
    return stocArray.sort();
  },
  comenziDeb: (state) => {
    return state.comenziDeb;
  },
  comenziDebFinalizate: (state) => {
    return state.comenziDebFinalizate;
  },
  debitare: (state) => {
    return state.debitare;
  },
  debitari: (state) => {
    return state.debitari;
  },
  listaDebitari: (state, getters) => {
    let list = [];
    const listDebitari = {
      list: getters.debitari,
    };
    listDebitari.list.forEach(function (item) {
      let idx = list.findIndex(
        (obj) =>
          obj.id_profil === item.id_profil &&
          obj.cota_livrata == item.cota_livrata &&
          obj.comanda_id === item.comanda_id
      );
      if (idx >= 0) {
        list[idx].cantitate =
          Number(list[idx].cantitate) + Number(item.cantitate);
        list[idx].scrap = Number(list[idx].scrap) + Number(item.scrap);
      } else {
        list.push(item);
      }
    });
    return list;
  },
  // non-caching getter with ()
  listaMaterialeStoc: (state, getters) => () => {
    const listMat = [];
    let id = 1;
    getters.listaDebitari.forEach(function (item) {
      if (item.aviz_mat == 0) {
        // let idx = listMat.findIndex((obj) => (obj.id_profil === item.id_profil) && (obj.comanda_id === item.comanda_id) && (obj.cota_livrata === item.cota_livrata) && (obj.lungime_finala === item.lungime_finala))
        let idx = listMat.findIndex(
          (obj) => obj.comanda_id === item.comanda_id
        );
        if (idx >= 0) {
          listMat[idx].cantitate =
            Number(listMat[idx].cantitate) + Number(item.cantitate);
          // listMat[idx].buc_scrap = Number(listMat[idx].buc_scrap) + Number(item.buc_scrap)
        } else {
          item.id = id;
          id++;
          listMat.push(item);
        }
      }
    });
    return listMat;
  },
  listaScrapPacheteStoc: (state) => {
    let list = [];
    state.listaDebitari.forEach(function (item) {
      if (item.aviz_pachete_scrap == 0) {
        // item.selected = false
        let idx = list.findIndex(
          (obj) =>
            obj.id_profil === item.id_profil &&
            obj.cota_livrata === item.cota_livrata &&
            obj.comanda_id === item.comanda_id
        );
        if (idx >= 0) {
          list[idx].buc_scrap =
            Number(list[idx].buc_scrap) + Number(item.buc_scrap);
        } else {
          list.push(item);
        }
      }
    });
    return list;
  },
  listaScrapSaciStoc: (state, getters) => {
    let list = [];
    getters.listaDebitari.forEach(function (item) {
      if (item.aviz_saci_scrap == 0) {
        // item.selected = false
        let idx = list.findIndex(
          (obj) =>
            obj.id_profil === item.id_profil &&
            obj.cota_livrata === item.cota_livrata &&
            obj.comanda_id === item.comanda_id
        );
        if (idx >= 0) {
          list[idx].scrap = Number(list[idx].scrap) + Number(item.scrap);
        } else {
          list.push(item);
        }
      }
    });
    return list;
  },
  listaStocProfile: (state, getters) => {
    let stocMat = [];
    let lista_debitari = getters.listaDebitari;
    let id = 1;

    state.stocProfile.forEach((profil) => {
      if (profil.profil_id == 37 && profil.cota_profil == 2570) {
        console.log("profil");
      }
      if (profil.avizat == 0) {
        let cant = 0;
        profil.id = id;
        id++;

        // // Find matching debitare and cumulate 'cant'
        // let debitare = lista_debitari.find(obj => {return (obj.id_profil == profil.profil_id) && (obj.cota_livrata == profil.cota_profil) && (obj.stoc_id == profil.stoc_id)})
        // if (debitare) {
        //   cant = Number(profil.cantitate) - Number(debitare.scrap)
        // }
        // if (!debitare && cant == 0) {
        //   stocMat.push(profil)
        // } else {
        //   stocMat.push({
        //     stoc_id: profil.stoc_id,
        //     profil_id: profil.profil_id,
        //     profil_tip: profil.profil_tip,
        //     profil_greutate_ml: debitare.profil_greutate_ml,
        //     cota_profil: profil.cota_profil,
        //     cantitate: cant,
        //     comanda: debitare.comanda_obj,
        //     data_com_deb: debitare.data_com_deb
        //   })
        // }

        // Cumulated entries - profile stoc
        // Find matching debitari - cumulate qty and push to new array comanda object
        let scrap = 0;
        let comenzi = [];

        let debitari = lista_debitari.filter((obj) => {
          return (
            obj.id_profil == profil.profil_id &&
            obj.cota_livrata == profil.cota_profil
          );
        });

        debitari.forEach((debitare) => {
          scrap = scrap + Number(debitare.scrap);
          let comanda = {
            nr: debitare.comanda_obj.nr,
            pos: debitare.comanda_obj.pos,
            subpos: debitare.comanda_obj.subpos,
            data: debitare.data_com_deb,
          };
          comenzi.push(comanda);
        });
        // console.log(debitari);
        cant = Number(profil.cantitate) - scrap;

        // Push Profil as it is, when it has no debitari
        if (comenzi.length == 0) {
          // console.log(profil.profil_tip+' ; '+profil.cota_profil)
          stocMat.push(profil);
        }
        // Exit if 0 qty
        else if (cant == 0) {
          return;
        }
        // Push new obj to array
        else {
          // console.log(profil.profil_tip+' ; '+profil.cota_profil+' ; '+cant)
          stocMat.push({
            stoc_id: profil.stoc_id,
            profil_id: profil.profil_id,
            profil_tip: profil.profil_tip,
            cota_profil: profil.cota_profil,
            profil_greutate_ml: profil.profil_greutate_ml,
            cantitate: cant,
            avizat: profil.avizat,
            comenzi_obj: profil.comenzi_obj,
            comenzi: comenzi,
            id: profil.id,
          });
        }
      }
    });
    return stocMat;
  },
  pachete: (state) => {
    return state.pachete;
  },
  saci: (state) => {
    return state.saci;
  },
  span: (state) => {
    return state.span;
  },
  materiale: (state) => {
    return state.materiale;
  },
  selectMateriale: (state) => {
    let matArray = [];
    state.materiale.forEach(function (item) {
      let obj = {
        value: item.denumire_material.toLowerCase().replace(/\s/g, ""),
        text: item.denumire_material,
        id: item.id_material,
      };
      matArray.push(obj);
    });
    return matArray;
  },
  aliaje: (state) => {
    return state.aliaje;
  },
  selectAliaje: (state, getters) => (material) => {
    let filteredAliaje = getters.aliaje.filter((obj) => {
      return (
        obj.denumire_material.toLowerCase().replace(/\s/g, "") === material
      );
    });
    let aliajeArray = [];
    filteredAliaje.forEach(function (item) {
      let obj = {
        value: item.denumire_aliaj.replace(/\s/g, ""),
        text: item.denumire_aliaj,
        gs: item.gs,
        pret: item.pret,
      };
      aliajeArray.push(obj);
    });
    return aliajeArray;
  },
  consumabile: (state) => {
    return state.consumabile;
  },
  selectConsumabile: (state) => {
    let obiecteArray = [];
    state.consumabile.forEach(function (item) {
      let obj = {
        value: item.denumire,
        text: item.denumire,
        id: item.id,
        pret: item.pret,
        um: item.um,
      };
      obiecteArray.push(obj);
    });
    return obiecteArray;
  },
  manopera: (state) => {
    return state.manopera;
  },
  selectManopera: (state) => {
    let tipArray = [];
    state.manopera.forEach(function (item) {
      let obj = {
        value: item.tip_manopera,
        text: item.tip_manopera,
        id: item.id,
        pret: item.pret_manopera,
        um: item.um_manopera,
      };
      tipArray.push(obj);
    });
    return tipArray;
  },
  preturiVanzari: state => {
    return state.preturiVanzari;
  },
  formulaVanzari: state => {
    return state.formulaVanzari;
  },
  LME: state => {
    return state.LME
  },
  levelsVanzari: state => {
    return state.levelsVanzari
  },
  fixedPricesMateriePrima: state => {
    return state.fixedPricesMateriePrima
  },
  preturiDebitare: state => {
    return state.preturiDebitare
  },
  preturiTransport: state => {
    return state.preturiTransport
  },
  preturiVopsit: (state) => {
    return state.preturiVopsit;
  },
  preturiServ: (state) => {
    return state.preturiServ;
  },
  furnizori: (state) => {
    return state.furnizori;
  },
  selectFurnizori: (state) => {
    let furnizoriArray = [];
    state.furnizori.forEach(function (item) {
      let obj = {
        value: item.id_furnizor,
        text: item.denumire_furnizor,
        email: item.email_furnizor,
      };
      furnizoriArray.push(obj);
    });
    return furnizoriArray;
  },
  selectPreturiServ: (state) => {
    let servArray = [];
    state.preturiServ.forEach(function (item) {
      let obj = {
        value: item.denumire_serv,
        text: item.denumire_serv,
        id: item.id,
        pret: item.pret_serv,
        um: item.um_pret_serv,
      };
      servArray.push(obj);
    });
    return servArray;
  },
  ums: (state) => {
    return state.ums;
  },
  ral_colors: (state) => {
    return state.ral_colors;
  },
  furnizoriVanzari: (state) => {
    return state.furnizoriVanzari;
  },
  listaProduse: (state) => {
    return state.listaProduse;
  },
  //selectProduse - denumire produse -->
  denumiri: (state) => {
    let prodArray = [];
    state.listaProduse.forEach(function (item) {
      let obj = {
        value: item.denumire_produs,
        text: item.denumire_produs,
        um_produs: item.um_produs,
        id: item.id_produs,
      };
      prodArray.push(obj);
    });
    return prodArray;
  },
  oferte_productie_garduri: (state) => {
    return state.oferte_productie_garduri;
  },
  oferte_productie_configurator_gard: (state) => {
    return state.oferte_productie_configurator_gard;
  },
  lista_reveniri_configurator_gard_counts: (state) => {
    return state.lista_reveniri_configurator_gard_counts;
  },
  lista_reveniri_configurator_gard_active: (state) => {
    return state.lista_reveniri_configurator_gard.filter(item => item.completed == '0')
  },
  lista_reveniri_configurator_gard_completed: (state) => {
    return state.lista_reveniri_configurator_gard.filter(item => item.completed == '1')
  },
  oferte_productie_configurator_eu: (state) => {
    return state.oferte_productie_configurator_eu;
  },
  oferte_constructii_configurator_gard: (state) => {
    return state.oferte_constructii_configurator_gard;
  },
  oferte_productie_general: (state) => {
    return state.oferte_productie_general;
  },
  automationList: (state) => {
    return state.automationList
  },
  oferte_productieByUser: (state) => {
    return state.oferte_productie_by_user;
  },
  oferte_productieBonus: (state) => {
    return state.oferte_productie_bonus;
  },
  currentOfertaProductie: (state) => {
    return state.currentOfertaProductie;
  },
  lucrari: (state) => {
    return state.lucrari;
  },
  currentLucrare: (state) => {
    return state.currentLucrare;
  },
  target: (state) => {
    return state.target;
  },
  currency: (state) => {
    return state.currency;
  },
  p_bonus: (state) => {
    return state.p_bonus;
  },
  avize: (state) => {
    return state.avize.sort(function (a, b) {
      return b.id_aviz - a.id_aviz;
    });
  },
  aviz: (state) => {
    return state.aviz;
  },
  objCerere: (state) => {
    return state.objCerere;
  },
  calendarEvents: (state) => {
    return state.calendarEvents;
  },
  oameni_productie: (state) => {
    return state.oameni_productie;
  },
  modele_gard: (state) => {
    return state.modele_gard;
  },
  firme_montaj: (state) => {
    return state.firme_montaj
  },
  firma_montaj_activa: (state) => {
    return state.firme_montaj.find((firma) => firma.id_firma == firma.id_firma_activa);
  },
  firma_montaj_contract: (state) => (id_firma_contract) => {
    return state.firme_montaj.find((firma) => firma.id_firma == id_firma_contract);
  },
  executant_firma_text: (state) => {
    return state.executant_firma_text
  },
  montaj_firma1_text: (state) => {
    return state.montaj_firma1_text
  },
  montaj_firma2_text: (state) => {
    return state.montaj_firma2_text
  },
  montaj_firma3_text: (state) => {
    return state.montaj_firma3_text
  },
  montaj_firma4_text: (state) => {
    return state.montaj_firma4_text
  },
  montaj_firma5_text: (state) => {
    return state.montaj_firma5_text
  },
};
