<template>
	<div class="container pt-6 pb-10">

		<div class="flex flex-row justofy-between gap-10">
			<div class="w-1/2">
				<h2 class="text-3xl font-medium">Module</h2>
			</div>
			<div class="w-1/2 h-96 bg-white">

				<v-stage :config="configKonva">
					<v-layer>
						<v-circle :config="configCircle"></v-circle>
					</v-layer>
				</v-stage>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			configKonva: {
				width: 200,
				height: 200
			},
			configCircle: {
				x: 100,
				y: 100,
				radius: 70,
				fill: "red",
				stroke: "black",
				strokeWidth: 4
			}
		};
	},
};
</script>

<style scoped></style>
